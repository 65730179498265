import { Grid, GridProps, InfoOutlinedIcon, Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

const nowrapStyle = { whiteSpace: 'nowrap', flexWrap: 'nowrap' };

type Props = {
  label: React.ReactNode;
  tooltipText?: string | null;
  justifyContent?: GridProps['justifyContent'];
  maxWidth?: number;
  tooltipPlacement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
};

const LabelWithTooltip = ({
  label,
  tooltipText,
  justifyContent = 'flex-start',
  maxWidth = 22,
  tooltipPlacement = 'top',
}: Props) => {
  return (
    <Grid container justifyContent={justifyContent} alignItems="center" gap={0.5} sx={nowrapStyle}>
      {label}
      {tooltipText && (
        <Tooltip
          title={
            <Grid maxWidth={theme => theme.spacing(maxWidth)}>
              <Typography variant="tooltip">{tooltipText}</Typography>
            </Grid>
          }
          placement={tooltipPlacement}
          variant="info"
        >
          <InfoOutlinedIcon fontSize="inherit" color="action" />
        </Tooltip>
      )}
    </Grid>
  );
};

export default LabelWithTooltip;
