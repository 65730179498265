import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';

import calendar from '../../features/calendar/ducks';
import datalab from '../../features/datalab/ducks';
import { DatalabState } from '../../features/datalab/types';
import myDashboard, { ReducerState as MyDashboardState } from '../../features/my-dashboard/ducks';
import ioiTracker from '../../features/offering-dl/ioi-tracker/ducks';
import offeringDl, { OfferingSharedState } from '../../features/offering-dl/shared/ducks';
import offeringNotes, {
  OfferingNotesState,
} from '../../features/offering-dl/shared/offering-notes/ducks';
import underwriterCredits, {
  UnderwriterCreditsState,
} from '../../features/offering-dl/underwriter-credits/ducks';
import shared, { SharedState } from '../../features/shared/ducks';
import notifications, {
  ReducerState as NotificationsState,
} from '../../features/shared/notifications/ducks';
import spinner from '../../features/shared/spinner/ducks';

export type RootState = {
  calendar: any;
  datalab: DatalabState;
  ioiTracker: any;
  modal: any;
  notifications: NotificationsState;
  offeringDl: OfferingSharedState;
  offeringNotes: OfferingNotesState;
  shared: SharedState;
  spinner: any;
  underwriterCredits: UnderwriterCreditsState;
  myDashboard: MyDashboardState;
};

const createRootReducer = () =>
  combineReducers<RootState>({
    calendar,
    datalab,
    ioiTracker,
    modal,
    offeringDl,
    offeringNotes,
    shared,
    spinner,
    underwriterCredits,
    notifications,
    myDashboard,
  });

export default createRootReducer;
