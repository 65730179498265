import { ConfirmModal, SelectField, TextAreaField } from '@cmg/common';
import { FormikBag, FormikProps, withFormik } from 'formik';
import capitalize from 'lodash/capitalize';
import React from 'react';
import * as yup from 'yup';

import {
  OfferingNote,
  OfferingNoteCreate,
  OfferingNoteUpdate,
  Subject,
} from '../../../../../types/domain/offering/offering-note';
import { SRow, StyledTrashButton } from './OfferingNoteModal.styles';

const subjectOptions = Object.keys(Subject).map(key => ({
  value: key,
  label: capitalize(Subject[key]),
}));

export const OfferingNoteSchema = yup.object().shape({
  note: yup.string().required('Note is required'),
  subject: yup.string().oneOf(Object.keys(Subject)).nullable().required('Subject is required'),
});

export type ModalProps = {
  onHide: () => void;
  onDelete: () => void;
  onCreate: (offeringNote: OfferingNoteCreate) => void;
  onUpdate: (offeringNote: OfferingNoteUpdate) => void;
  offeringNote?: OfferingNote;
  show: boolean;
};

export type Values = {
  subject: Subject | null;
  note: string;
};

export type Props = ModalProps & FormikProps<Values>;

export const OfferingNoteModalComponent: React.FC<Props> = ({
  handleSubmit,
  offeringNote,
  onHide,
  show,
  values,
  onDelete,
}) => {
  const canSubmit = Boolean(values.note && values.subject);

  return (
    <ConfirmModal
      title={offeringNote ? 'Edit Note' : 'Add Note'}
      confirmButtonCaption="Save"
      show={show}
      onHide={onHide}
      onSubmitForm={handleSubmit}
      confirmButtonEnabled={canSubmit}
      renderFooter={({ defaultButtons }) => (
        <React.Fragment>
          {offeringNote && <StyledTrashButton testId="delete" onClick={onDelete} />}
          {defaultButtons}
        </React.Fragment>
      )}
      closeButton
    >
      <SRow>
        <SelectField
          options={subjectOptions}
          aria-label="subject"
          name="subject"
          fullWidth={true}
        />
      </SRow>

      <SRow>
        <TextAreaField name="note" aria-label="note" fullWidth={true} resize="vertical" />
      </SRow>
    </ConfirmModal>
  );
};

export const OfferingNoteModalWithFormik = withFormik<ModalProps, Values>({
  enableReinitialize: true,
  mapPropsToValues: (props: ModalProps) => ({
    subject: props.offeringNote ? props.offeringNote.subject : null,
    note: props.offeringNote ? props.offeringNote.note : '',
    show: props.show, // ensure form values get updated on open/close
  }),
  handleSubmit: (values: Values, formikBag: FormikBag<ModalProps, Values>) => {
    if (formikBag.props.offeringNote?.id) {
      formikBag.props.onUpdate({
        id: formikBag.props.offeringNote.id,
        note: values.note,
        subject: values.subject!,
      });
    } else {
      formikBag.props.onCreate({
        note: values.note,
        subject: values.subject!,
      });
    }
  },
  validationSchema: OfferingNoteSchema,
})(OfferingNoteModalComponent);

export default OfferingNoteModalWithFormik;
