import { Box, Typography } from '@cmg/design-system';
import React from 'react';

type Props = {
  title: string;
  description: string;
};

const UpsellFeature: React.FC<Props> = ({ title, description }) => {
  return (
    <Box marginTop={theme => theme.spacing(2)}>
      <Typography variant="highlight1" color={theme => theme.palette.primary.main}>
        {title}
      </Typography>
      <Typography color={theme => theme.palette.text.secondary}>{description}</Typography>
    </Box>
  );
};

export default UpsellFeature;
