import React from 'react';

import SpinElement from './SpinElement';
import { LoaderMessage, LoaderOverlay } from './Spinner.styles';

type OwnProps = {
  show: boolean;
  priority?: number;
  backgroundStyle?: {
    backgroundColor?: string;
  };
  size?: 'small' | 'default';
  fullScreen?: boolean;
  testId?: string;
  className?: string;
};

export type Props = React.PropsWithChildren<OwnProps>;

const Spinner: React.FC<Props> = ({
  show,
  children,
  testId,
  size = 'default',
  backgroundStyle = { backgroundColor: 'rgba(0,0,0,0.2)' },
  className,
}) => {
  return (
    <React.Fragment>
      {children}
      {show && (
        <LoaderOverlay
          backgroundColor={backgroundStyle.backgroundColor}
          style={backgroundStyle}
          className={className}
        >
          <LoaderMessage>
            <SpinElement size={size} testId={testId} />
          </LoaderMessage>
        </LoaderOverlay>
      )}
    </React.Fragment>
  );
};

export default Spinner;
