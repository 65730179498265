import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import {
  AsyncMultiSelectField,
  CheckboxSelectField,
  DateRangeField,
  dateRangeUtil,
  NumericRangeInputField,
  PercentRangeInputField,
  TreeSelectField,
} from '@cmg/common';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';

import { getFeatureToggles } from '../../../../config/appSettings';
import { OfferingType } from '../../../../graphql/__generated__/index';
import { DatalabScreens } from '../../../datalab/constants';
import SectorToggleLabel from '../../../shared/sectors/SectorToggleLabel';
import { useFetchCustomSectors } from './hooks/useFetchCustomSectors';
import { useFetchRegionsAndCountries } from './hooks/useFetchRegionsAndCountries';
import { useFetchSectors } from './hooks/useFetchSectors';
import { useOfferingTypes } from './hooks/useOfferingTypes';
import { useSearchAdvisers } from './hooks/useSearchAdvisers';
import { useLoadMissingLeftLeads, useSearchLeftleads } from './hooks/useSearchLeftleads';
import { useSearchShareholders } from './hooks/useSearchShareholders';
import { useLoadMissingUnderwriters, useSearchUnderwriters } from './hooks/useSearchUnderwriters';
import {
  atmOfferingStatusOptions,
  OfferingsFilterFormType,
  useGetInitialFilterValues,
} from './OfferingsFilterForm.model';
import { StyledCheckboxField, StyledFiltersRow, StyledWrapper } from './OfferingsFilterForm.styles';

const validationSchema = yup.object().shape({
  date: yup
    .object({
      end: yup.string().nullable(),
      start: yup.string().nullable(),
    })
    .nullable(),
  type: yup.array().of(yup.string()).nullable(),
  sectors: yup.array().of(yup.string()).nullable(),
  customSectors: yup.array().of(yup.string()).nullable(),
  countries: yup.array().of(yup.string()).nullable(),
  marketCap: yup
    .object({
      min: yup.number().positive('Value must be a positive number').nullable(),
      max: yup.number().positive('Value must be a positive number').nullable(),
    })
    .nullable(),
  grossProceedsBase: yup
    .object({
      min: yup.number().positive('Value must be a positive number').nullable(),
      max: yup.number().positive('Value must be a positive number').nullable(),
    })
    .nullable(),
  latestProgramSize: yup
    .object({
      min: yup.number().positive('Value must be a positive number').nullable(),
      max: yup.number().positive('Value must be a positive number').nullable(),
    })
    .nullable(),
  sellingShareholderPct: yup
    .object({
      min: yup.number().positive('Value must be a positive number').nullable(),
      max: yup.number().positive('Value must be a positive number').nullable(),
    })
    .nullable(),
  underwriters: yup.array().of(yup.string()).nullable(),
  leftleads: yup.array().of(yup.string()).nullable(),
  shareholders: yup.array().of(yup.string()).nullable(),
  advisers: yup.array().of(yup.string()).nullable(),
  include144A: yup.bool().nullable(),
});

export type Props = {
  setFilter: (newFilter: OfferingsFilterFormType) => void;
  reportScreen?: DatalabScreens;
  excludedOfferingTypes?: OfferingType[];
};

const OfferingsFilterForm: React.FC<Props> = ({
  setFilter,
  reportScreen,
  excludedOfferingTypes,
}) => {
  const { offeringTypeOptions } = useOfferingTypes({ excludedOfferingTypes });
  const sectorOptions = useFetchSectors();
  const { defaultOptions: managerDefaultOptions, loadOptions: managerLoadOptions } =
    useSearchUnderwriters();
  const { defaultOptions: leftleadDefaultOptions, loadOptions: leftleadLoadOptions } =
    useSearchLeftleads();

  const { isConvertOfferingsUpdatedFiltersOn } = getFeatureToggles();

  const canReadAtmSelldown = useCheckPermissions([permissionsByEntity.ATMSelldown.READ]);
  const customSectorOptions = useFetchCustomSectors({ skip: !isConvertOfferingsUpdatedFiltersOn });
  const { defaultOptions: shareholderDefaultOptions, loadOptions: shareholderLoadOptions } =
    useSearchShareholders({ skip: !isConvertOfferingsUpdatedFiltersOn });
  const { defaultOptions: adviserDefaultOptions, loadOptions: adviserLoadOptions } =
    useSearchAdvisers({ skip: !isConvertOfferingsUpdatedFiltersOn });
  const { regionCountryOptions, getRegionCountries } = useFetchRegionsAndCountries({
    skip: !isConvertOfferingsUpdatedFiltersOn && reportScreen !== DatalabScreens.GLOBAL_LEAGUE,
  });

  const onFilterChange = React.useCallback(
    ({ countries, ...values }: OfferingsFilterFormType) => {
      setFilter({ ...values, countries: getRegionCountries(countries) });
    },
    [setFilter, getRegionCountries]
  );

  const formik = useFormik<OfferingsFilterFormType>({
    initialValues: useGetInitialFilterValues({ screen: reportScreen, excludedOfferingTypes }),
    onSubmit: onFilterChange,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema,
  });

  const debouncedSubmit = useDebouncedCallback(() => formik.handleSubmit(), 500);

  const [isUsingCustomSectors, setIsUsingCustomSectors] = React.useState(false);

  const handleSetIsUsingCustomSectors = React.useCallback(
    (newIsUsingCustomSectors: boolean) => {
      // reset the other field so data reloads without the filter (expected behaviour)
      formik.setFieldValue(newIsUsingCustomSectors ? 'sectors' : 'customSectors', []);
      formik.handleSubmit();
      setIsUsingCustomSectors(newIsUsingCustomSectors);
    },
    [formik]
  );

  const canViewInternationalData = useCheckPermissions([
    permissionsByEntity.InternationalOffering.READ,
  ]);
  const isInternationalOfferingsOn = canViewInternationalData;

  const loadMissingUnderwriters = useLoadMissingUnderwriters();
  const loadMissingLeftLeads = useLoadMissingLeftLeads();

  return (
    <StyledWrapper>
      <FormikProvider value={formik}>
        <StyledFiltersRow>
          <DateRangeField
            name="date"
            label="Date Range"
            withMargin
            presetOptions={dateRangeUtil.getDefaultPresetOptions(new Date())}
            onChange={() => formik.handleSubmit()}
          />
          {reportScreen === DatalabScreens.GLOBAL_LEAGUE && (
            <CheckboxSelectField
              testId="offeringType-select"
              name="offeringTypes"
              label="Offering Type"
              options={offeringTypeOptions}
              resetValues={offeringTypeOptions}
              withMargin
              width={250}
              onChange={() => formik.handleSubmit()}
            />
          )}
          {isUsingCustomSectors && isConvertOfferingsUpdatedFiltersOn ? (
            <TreeSelectField
              name="customSectors"
              treeData={customSectorOptions}
              label={<SectorToggleLabel onChange={() => handleSetIsUsingCustomSectors(false)} />}
              withMargin
              onChange={() => formik.handleSubmit()}
              testId="OfferingsFilterForm-CustomSectors"
            />
          ) : (
            <TreeSelectField
              name="sectors"
              treeData={sectorOptions}
              label={
                isConvertOfferingsUpdatedFiltersOn ? (
                  <SectorToggleLabel onChange={() => handleSetIsUsingCustomSectors(true)} />
                ) : (
                  'Sectors'
                )
              }
              withMargin
              onChange={() => formik.handleSubmit()}
              testId="OfferingsFilterForm-Sectors"
            />
          )}
          {reportScreen === DatalabScreens.ATM && (
            <CheckboxSelectField
              name="status"
              label="Status"
              options={atmOfferingStatusOptions}
              resetValues={atmOfferingStatusOptions}
              withMargin
              width={250}
              onChange={() => formik.handleSubmit()}
            />
          )}
          {(isConvertOfferingsUpdatedFiltersOn || reportScreen === DatalabScreens.GLOBAL_LEAGUE) &&
            isInternationalOfferingsOn && (
              <TreeSelectField
                name="countries"
                treeData={regionCountryOptions}
                label="Region/Country"
                withMargin
                onChange={() => formik.handleSubmit()}
              />
            )}
          {isConvertOfferingsUpdatedFiltersOn && (
            <NumericRangeInputField
              name="marketCap"
              label="Market Cap ($M)"
              inputsProps={{ precision: 0 }}
              withMargin
              onChange={() => debouncedSubmit()}
            />
          )}
          {reportScreen === DatalabScreens.ATM ? (
            <NumericRangeInputField
              name="latestProgramSize"
              label="ATM Program Size ($M)"
              inputsProps={{ precision: 0 }}
              withMargin
              onChange={() => debouncedSubmit()}
            />
          ) : (
            <NumericRangeInputField
              name="grossProceedsBase"
              label={
                reportScreen === DatalabScreens.GLOBAL_LEAGUE
                  ? 'Gross Proceeds Total ($M)'
                  : 'Gross Proceeds Base ($M)'
              }
              inputsProps={{ precision: 0 }}
              withMargin
              onChange={() => debouncedSubmit()}
            />
          )}
          {reportScreen === DatalabScreens.ATM && (
            <NumericRangeInputField
              name="marketCapPreOffering"
              label="Market Cap Pre-Offering ($M)"
              inputsProps={{ precision: 0 }}
              withMargin
              onChange={() => debouncedSubmit()}
            />
          )}
          {reportScreen === DatalabScreens.ATM && canReadAtmSelldown && (
            <NumericRangeInputField
              name="programRemaining"
              label="Program Remaining ($M)"
              inputsProps={{ precision: 0 }}
              withMargin
              onChange={() => debouncedSubmit()}
            />
          )}
          {isConvertOfferingsUpdatedFiltersOn && (
            <PercentRangeInputField
              name="sellingShareholderPct"
              label="Selling ShareHl %"
              withMargin
              onChange={() => debouncedSubmit()}
            />
          )}
          <AsyncMultiSelectField
            name="underwriters"
            label={reportScreen === DatalabScreens.ATM ? 'Sales Agents' : 'Underwriter'}
            defaultOptions={managerDefaultOptions}
            loadMissingOptions={loadMissingUnderwriters}
            loadOptions={managerLoadOptions}
            withMargin
            onChange={() => debouncedSubmit()}
          />
          <AsyncMultiSelectField
            name="leftleads"
            label="Left Lead"
            defaultOptions={leftleadDefaultOptions}
            loadMissingOptions={loadMissingLeftLeads}
            loadOptions={leftleadLoadOptions}
            withMargin
            onChange={() => debouncedSubmit()}
          />
          {isConvertOfferingsUpdatedFiltersOn && (
            <React.Fragment>
              <AsyncMultiSelectField
                name="shareholders"
                label="Sponsor"
                defaultOptions={shareholderDefaultOptions}
                loadOptions={shareholderLoadOptions}
                withMargin
                onChange={() => debouncedSubmit()}
              />
              <AsyncMultiSelectField
                name="advisers"
                label="Advisory"
                defaultOptions={adviserDefaultOptions}
                loadOptions={adviserLoadOptions}
                withMargin
                onChange={() => debouncedSubmit()}
              />
            </React.Fragment>
          )}
          {(reportScreen === DatalabScreens.CONVERTS ||
            reportScreen === DatalabScreens.GLOBAL_ECM) && (
            <StyledCheckboxField
              name="include144A"
              withMargin
              onChange={() => formik.handleSubmit()}
            >
              Include 144A
            </StyledCheckboxField>
          )}
        </StyledFiltersRow>
      </FormikProvider>
    </StyledWrapper>
  );
};

export default OfferingsFilterForm;
