import { PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';

import { SButtonWrapper, SRankingTag } from './RankingTag.styles';

export const options = [
  { label: 'Top 5', value: 'top_5' },
  { label: 'Top 10', value: 'top_10' },
  { label: 'Top 15', value: 'top_15' },
  { label: 'Top 25', value: 'top_25' },
  { label: 'Top 50', value: 'top_50' },
  { label: 'Over 50', value: 'over_50' },
];

type Props = {
  editable?: boolean;
  ranking?: string;
  onChangeRanking?: (value: string) => void;
};

const RankingTag: React.FC<Props> = ({ editable, ranking, onChangeRanking }) => {
  const renderEditView = () => (
    <SRankingTag>
      {options.map((option, index) => {
        const Button = option.value === ranking ? PrimaryButton : SecondaryButton;

        return (
          <SButtonWrapper key={index}>
            <Button
              disabled={!editable}
              onClick={() => onChangeRanking && onChangeRanking(option.value)}
            >
              {option.label}
            </Button>
          </SButtonWrapper>
        );
      })}
    </SRankingTag>
  );

  const renderSingleButton = () => {
    const option = options.find(option => option.value === ranking);

    if (!option) {
      return null;
    }

    return (
      <SButtonWrapper>
        <SRankingTag>
          <PrimaryButton disabled>{option.label}</PrimaryButton>
        </SRankingTag>
      </SButtonWrapper>
    );
  };

  if (editable) {
    return renderEditView();
  }

  if (ranking) {
    return renderSingleButton();
  }

  return null;
};

export default RankingTag;
