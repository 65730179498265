import React from 'react';

import { formatNullableBoolean } from '../../../common/helpers/helpers';
import { CornerstoneInvestmentType } from '../../../graphql/__generated__';
import { getFormattedCurrencyInMillion } from '../../shared/model/utils';
import { type Calendar_OfferingFieldsFragment } from '../graphql';
import { calculateTotalInvestors } from './CornerstoneInvestorsRenderer.model';
import {
  HeaderLabel,
  SFooter,
  STooltipTable,
  StyledPopover,
} from './CornerstoneInvestorsRenderer.styles';
import CornerstoneInvestorsTableContent from './CornerstoneInvestorsTableContent';

export type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const CornerstoneInvestorsRenderer: React.FC<Props> = ({ offering }) => {
  const {
    pricingCurrency,
    hasCornerstoneInvestors,
    cornerstoneOfferingParticipantsAmount,
    cornerstonePrivatePlacementAmount,
    cornerstoneTotalAmount,
  } = offering.attributes || {};
  const { cornerstoneInvestments } = offering;

  const offeringParticipants =
    cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.OfferingParticipant) ??
    [];

  const privatePlacement =
    cornerstoneInvestments?.filter(c => c.type === CornerstoneInvestmentType.PrivatePlacement) ??
    [];

  if (!hasCornerstoneInvestors) {
    return <span>{formatNullableBoolean(hasCornerstoneInvestors)}</span>;
  }

  const offeringParticipantsTotalInvestors = calculateTotalInvestors(offeringParticipants);
  const privatePlacementTotalInvestors = calculateTotalInvestors(privatePlacement);
  const investorsTotalNumber = offeringParticipantsTotalInvestors + privatePlacementTotalInvestors;

  return (
    <StyledPopover
      content={
        <React.Fragment>
          <STooltipTable>
            <thead>
              <tr>
                <HeaderLabel className="cornerstone-header">
                  {`Cornerstone Investors (${investorsTotalNumber})`}
                  <div className="subtitle">
                    Cornerstone Investment Total
                    <span>
                      {getFormattedCurrencyInMillion(pricingCurrency, cornerstoneTotalAmount)}
                    </span>
                  </div>
                </HeaderLabel>
              </tr>
            </thead>
            <CornerstoneInvestorsTableContent
              cornerstoneInvestments={offeringParticipants}
              cornerstoneInvestorsCount={offeringParticipantsTotalInvestors}
              cornerstoneOfferingAmount={cornerstoneOfferingParticipantsAmount}
              investmentType={CornerstoneInvestmentType.OfferingParticipant}
              pricingCurrency={pricingCurrency}
            />
            <CornerstoneInvestorsTableContent
              cornerstoneInvestments={privatePlacement}
              cornerstoneInvestorsCount={privatePlacementTotalInvestors}
              cornerstoneOfferingAmount={cornerstonePrivatePlacementAmount}
              investmentType={CornerstoneInvestmentType.PrivatePlacement}
              pricingCurrency={pricingCurrency}
            />
          </STooltipTable>
          <SFooter>
            Offering Participant Cornerstone investments are included in deal metrics and Gross
            Proceeds calculations. Private Placement Cornerstone investoments are in addition to the
            offering and not included in Gross Proceeds calculations
          </SFooter>
        </React.Fragment>
      }
    >
      {formatNullableBoolean(hasCornerstoneInvestors)}
    </StyledPopover>
  );
};

export default CornerstoneInvestorsRenderer;
