import { Collapse, Icon, MediaQuery } from '@cmg/common';
import PropTypes from 'prop-types';
import React from 'react';

import { TableDataManagementType } from '../../../../features/shared/constants/constants';
import Filters from './shared/Filters';
import TableWrapper, { TABLE_TYPES } from './TableWrapper';
import VirtualizedTable from './virtualized-table/VirtualizedTable';
import { SToggleHandler } from './VirtualizedTableWidget.styles';
import WidgetWrapper from './widget-wrapper/WidgetWrapper';

// TODO typescript this file
const VirtualizedTableWidget = ({
  aboveFiltersPlaceholder,
  activeTab,
  className,
  downloadExport,
  downloadDropdown,
  fillViewport,
  filtersCollapsed, // used to set the table height
  renderFilters,
  hideFiltersReset,
  isHidingBoxHeader,
  isHidingColumnToggle,
  hidePaginationBottom,
  hidePaginationTop,
  large,
  noResultsMessage,
  onChangeTab,
  onResetFilters,
  screen,
  showRanking,
  striped,
  tabs,
  title,
  titleTooltip,
  collapsedFilters, // used in FiltersWrapper when filters are passed in as a prop
  handleCollapsedFiltersToggle,
  isMobile,
  staticColumns,
  categorizedColumns,
  groupedColumns,
  tableWrapperStyles,
  // used to render custom columns toggler
  columnsToggler,
  updateOrderBy = true,
  renderBanner,
  viewingCurrent,
  viewingTotal,
  ...props
}) => {
  return (
    <TableWrapper {...props} updateOrderBy={updateOrderBy} tableType={TABLE_TYPES.VIRTUALIZED}>
      {({
        activePage,
        columns,
        filters,
        groupBy,
        handleGroupBy,
        handleChangeItemsPerPage,
        handleOrderBy,
        handlePagination,
        itemsPerPage,
        metaData,
        numberOfHeaderRows,
        orderBy,
        orderByType,
        rows,
        simpleColumns,
        summaries,
        toggleColumnVisibility,
        totalPages,
        visibleColumns,
        visibleRows,
      }) => (
        <WidgetWrapper
          activePage={activePage}
          activeTab={activeTab}
          className={className}
          downloadExport={downloadExport}
          downloadDropdown={downloadDropdown}
          fillViewport={fillViewport}
          filtersCollapsed={filtersCollapsed}
          handleChangeItemsPerPage={handleChangeItemsPerPage}
          handlePagination={handlePagination}
          isHidingBoxHeader={isHidingBoxHeader}
          isHidingColumnToggle={isHidingColumnToggle}
          hidePaginationBottom={hidePaginationBottom}
          hidePaginationTop={hidePaginationTop}
          itemsPerPage={itemsPerPage}
          large={large}
          onChangeTab={onChangeTab}
          rows={rows}
          screen={screen}
          simpleColumns={simpleColumns}
          tabs={tabs}
          title={title}
          titleTooltip={titleTooltip}
          toggleColumnVisibility={toggleColumnVisibility}
          totalPages={totalPages}
          staticColumns={staticColumns}
          categorizedColumns={categorizedColumns}
          groupedColumns={groupedColumns}
          columnsToggler={columnsToggler}
          viewingCurrent={viewingCurrent}
          viewingTotal={viewingTotal}
          onResetFilters={onResetFilters}
        >
          {renderBanner && renderBanner()}
          <FiltersWrapper
            aboveFiltersPlaceholder={aboveFiltersPlaceholder}
            filters={filters}
            onResetFilters={onResetFilters}
            handlePagination={handlePagination}
            hideFiltersReset={hideFiltersReset}
            renderFilters={renderFilters}
            collapsedFilters={collapsedFilters}
          />
          {collapsedFilters !== undefined && (
            <SToggleHandler onClick={handleCollapsedFiltersToggle}>
              {collapsedFilters ? 'Show' : 'Hide'} Filters{' '}
              <Icon name={collapsedFilters ? 'angle-down' : 'angle-up'} />
            </SToggleHandler>
          )}
          <VirtualizedTable
            activePage={activePage}
            activeTab={activeTab}
            columns={columns}
            disableHeight={!fillViewport || isMobile}
            groupBy={groupBy}
            handleGroupBy={handleGroupBy}
            handleOrderBy={handleOrderBy}
            itemsPerPage={itemsPerPage}
            metaData={metaData}
            numberOfHeaderRows={numberOfHeaderRows}
            noResultsMessage={noResultsMessage}
            orderBy={orderBy}
            orderByType={orderByType}
            showRanking={showRanking}
            striped={striped}
            summaries={summaries}
            visibleColumns={visibleColumns}
            visibleRows={visibleRows}
            key={screen}
            tableWrapperStyles={tableWrapperStyles}
          />
        </WidgetWrapper>
      )}
    </TableWrapper>
  );
};

VirtualizedTableWidget.propTypes = {
  aboveFiltersPlaceholder: PropTypes.node,
  activeTab: PropTypes.object,
  className: PropTypes.string,
  downloadExport: PropTypes.func,
  /** Replaces downloadExport function by passing along a react node to be rendered in its place */
  downloadDropdown: PropTypes.node,
  fillViewport: PropTypes.bool.isRequired,
  filtersCollapsed: PropTypes.bool,
  renderFilters: PropTypes.func,
  hideFiltersReset: PropTypes.bool,
  isHidingBoxHeader: PropTypes.bool,
  isHidingColumnToggle: PropTypes.bool,
  hidePaginationBottom: PropTypes.bool,
  hidePaginationTop: PropTypes.bool,
  large: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  onChangeTab: PropTypes.func,
  onResetFilters: PropTypes.func,
  screen: PropTypes.string,
  showRanking: PropTypes.bool,
  striped: PropTypes.bool,
  tabs: PropTypes.array,
  title: PropTypes.node,
  paginationType: PropTypes.oneOf([TableDataManagementType.CLIENT, TableDataManagementType.SERVER])
    .isRequired,
  onReload: PropTypes.func,
  collapsedFilters: PropTypes.bool,
  handleCollapsedFiltersToggle: PropTypes.func,
  theme: PropTypes.object,
  updateOrderBy: PropTypes.bool,
  renderBanner: PropTypes.func,
  groupedColumns: PropTypes.object,
  tableWrapperStyles: PropTypes.object,
  viewingCurrent: PropTypes.number,
  viewingTotal: PropTypes.number,
};

VirtualizedTableWidget.defaultProps = {
  fillViewport: true,
};

VirtualizedTableWidget.defaultProps = {
  paginationType: TableDataManagementType.CLIENT,
};

const VirtualizedTableWidgetFC = props => (
  <MediaQuery.IsMediumUp>
    {matches => <VirtualizedTableWidget {...props} isMobile={!matches} />}
  </MediaQuery.IsMediumUp>
);

export default VirtualizedTableWidgetFC;

const FiltersWrapper = ({
  aboveFiltersPlaceholder,
  collapsedFilters,
  filters,
  renderFilters,
  handlePagination,
  hideFiltersReset,
  onResetFilters,
}) => {
  const content = (
    <div>
      {aboveFiltersPlaceholder}
      <Filters
        filters={filters}
        onResetFilters={onResetFilters}
        handlePagination={handlePagination}
        hideReset={hideFiltersReset}
      />
      {renderFilters && renderFilters()}
    </div>
  );

  return typeof collapsedFilters !== 'undefined' ? (
    <Collapse isExpanded={!collapsedFilters}>{content}</Collapse>
  ) : (
    content
  );
};

FiltersWrapper.propTypes = {
  aboveFiltersPlaceholder: PropTypes.node,
  collapsedFilters: PropTypes.bool,
  filters: PropTypes.array,
  renderFilters: PropTypes.func,
  handlePagination: PropTypes.func.isRequired,
  hideFiltersReset: PropTypes.bool,
  onResetFilters: PropTypes.func,
};
