import {
  Card,
  Divider,
  Grid,
  KeyValuePair,
  RegularBreakpoints,
  Section,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { cardHeight } from '../../shared/OfferingProfileCardItem';
import {
  ConvertStructurePricingTableHeader,
  StructurePricingRowLabels,
  StructurePricingTermName,
} from '../../shared/types';
import { StructurePricingTerms } from '../../shared/widgets/StructurePricingWidget';
import {
  bgColor,
  getBgColor,
  gridStyle,
  StyledTableContainer,
  tableCellStyle,
} from '../../shared/widgets/StructurePricingWidget.styles';
import { useGetConvertStructurePricingSectionData } from '../hooks/useGetConvertStucturePricing';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const ConvertStructurePricingWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const widgetHeaderTitle = 'Structure/Pricing';
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
  const matchesMediumDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(1770));
  const matchesSmallDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(1200));
  const { structurePricingTable, structurePricingRows } = useGetConvertStructurePricingSectionData(
    offeringProfile,
    matchesMediumDown,
    matchesSmallDown
  );
  const { column1, column2, column3 } = structurePricingRows;

  const keyValueItemHeight = name =>
    ![
      StructurePricingRowLabels.useOfProceeds,
      StructurePricingRowLabels.useOfProceedsNotes,
    ].includes(name)
      ? theme => theme.spacing(3.75)
      : undefined;

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section
          title={widgetHeaderTitle}
          aria-label={widgetHeaderTitle}
          paddingTop={theme => theme.spacing(3)}
        >
          <Stack rowGap={2} display="flex">
            {isSmallScreen ? (
              <Stack divider={<Divider />}>
                {structurePricingTable.map(
                  ({
                    name,
                    grossProceeds,
                    principalAmount,
                    principalAmountOvltAuth,
                    offerPrice,
                    coupon,
                    premium,
                  }) => {
                    return (
                      <Stack paddingX={2} paddingY={1} sx={bgColor[name]} key={name}>
                        <Typography variant="highlight1" paddingBottom={0.5}>
                          {name}
                        </Typography>
                        {name !== StructurePricingTermName.Initial &&
                          name !== StructurePricingTermName.Revised && (
                            <KeyValuePair
                              label={ConvertStructurePricingTableHeader.grossProceeds}
                              value={grossProceeds}
                            />
                          )}
                        <KeyValuePair
                          label={ConvertStructurePricingTableHeader.principalAmount}
                          value={principalAmount}
                        />
                        {name !== StructurePricingTermName.OvltExercised &&
                          name !== StructurePricingTermName.Total && (
                            <KeyValuePair
                              label={ConvertStructurePricingTableHeader.principalAmountOvltAuth}
                              value={principalAmountOvltAuth}
                            />
                          )}
                        {name !== StructurePricingTermName.Initial &&
                          name !== StructurePricingTermName.Revised && (
                            <KeyValuePair
                              label={ConvertStructurePricingTableHeader.offerPrice}
                              value={offerPrice}
                            />
                          )}
                        <KeyValuePair
                          label={ConvertStructurePricingTableHeader.coupon}
                          value={coupon}
                        />
                        <KeyValuePair
                          label={ConvertStructurePricingTableHeader.premium}
                          value={premium}
                        />
                      </Stack>
                    );
                  }
                )}
              </Stack>
            ) : (
              <StyledTableContainer>
                <Grid sx={gridStyle}>
                  <Table size="medium">
                    <TableHead>
                      <TableRow color="text.secondary">
                        <TableGroupHeaderCell></TableGroupHeaderCell>
                        <TableCell align="right" sx={tableCellStyle}>
                          {ConvertStructurePricingTableHeader.grossProceeds}
                        </TableCell>
                        <TableCell align="right" sx={tableCellStyle}>
                          {ConvertStructurePricingTableHeader.principalAmount}
                        </TableCell>
                        <TableCell align="right" sx={tableCellStyle}>
                          {ConvertStructurePricingTableHeader.principalAmountOvltAuth}
                        </TableCell>
                        <TableCell align="right" sx={tableCellStyle}>
                          {ConvertStructurePricingTableHeader.offerPrice}
                        </TableCell>
                        <TableCell align="right" sx={tableCellStyle}>
                          {ConvertStructurePricingTableHeader.coupon}
                        </TableCell>
                        <TableCell align="right" sx={tableCellStyle}>
                          {ConvertStructurePricingTableHeader.premium}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {structurePricingTable.map(
                        ({
                          name,
                          grossProceeds,
                          principalAmount,
                          principalAmountOvltAuth,
                          offerPrice,
                          coupon,
                          premium,
                        }) => {
                          return (
                            <TableRow key={name} sx={getBgColor(name)}>
                              <TableCell width="13%" sx={tableCellStyle}>
                                {name}
                              </TableCell>
                              <TableCell align="right" width="13%" sx={tableCellStyle}>
                                {grossProceeds}
                              </TableCell>
                              <TableCell align="right" width="14%" sx={tableCellStyle}>
                                {principalAmount}
                              </TableCell>
                              <TableCell align="right" width="20%" sx={tableCellStyle}>
                                {principalAmountOvltAuth}
                              </TableCell>
                              <TableCell align="right" width="14%" sx={tableCellStyle}>
                                {offerPrice}
                              </TableCell>
                              <TableCell align="right" width="13%" sx={tableCellStyle}>
                                {coupon}
                              </TableCell>
                              <TableCell align="right" width="13%" sx={tableCellStyle}>
                                {premium}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </StyledTableContainer>
            )}
            <StructurePricingTerms
              column1={column1}
              column2={column2}
              column3={column3}
              keyValueItemHeight={keyValueItemHeight}
            />
          </Stack>
        </Section>
      </Card>
    </Grid>
  );
};

export default ConvertStructurePricingWidget;
