import { Popover } from '@cmg/common';
import React from 'react';

import { SItemIconWrapper, STooltipContent, StyledIcon } from './HeaderLabelRenderer.styles';

export type Props = {
  label: string;
  tooltip: React.ReactNode;
};

const HeaderLabelRenderer: React.FC<Props> = ({ label, tooltip }) => {
  return (
    <Popover
      variant="TOOLTIP"
      placement="topRight"
      hideArrow
      content={<STooltipContent>{tooltip}</STooltipContent>}
    >
      <span>{label}</span>
      <SItemIconWrapper>
        <StyledIcon name="info-circle" />
      </SItemIconWrapper>
    </Popover>
  );
};

export default HeaderLabelRenderer;
