import { Collapse, ToastManager } from '@cmg/common';
import { datalabTableSelector } from '@cmg/e2e-selectors';
import { GridReadyEvent } from 'ag-grid-community';
import React from 'react';

import { downloadConvertibleOfferings } from '../../../api/dlgw/converts';
import FiltersToggleButton from '../../../common/components/filter-form-toggle-button/FilterFormToggleButton';
import { ErrorApiResponse, SuccessApiResponse } from '../../../types/api/ApiResponse';
import { DatalabScreens } from '../../datalab/constants';
import { isDownloadExcelOn } from '../../datalab/model/utils';
import {
  ROWS_PER_PAGE,
  useAGGridGetRows,
} from '../../offering-dl/offering-profile/hooks/useAGGridGetRows';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import { useOfferingFilters } from '../components/offerings-filter-form/hooks/useOfferingFilters';
import OfferingsFilterForm from '../components/offerings-filter-form/OfferingsFilterForm';
import OfferingsReportTable from '../components/offerings-report-table/OfferingsReportTable';
import {
  getExcelDownloadArgs,
  getSortingModel,
} from '../components/offerings-report-table/OfferingsReportTable.model';
import {
  alwaysVisibleColumns,
  categorizedConvertibleColumns,
  defaultSortModel,
  downloadOptions,
  orderedConvertibleColumns,
} from './ConvertOfferingsScreen.model';
import {
  SScreenContent,
  SScreenWrapper,
  STableWrapper,
  StyledPanel,
} from './ConvertOfferingsScreen.styles';
import { useConvertibleOfferings_ListQuery } from './graphql/__generated__/ConvertibleOfferings';

const ConvertOfferingsScreen: React.FC = () => {
  const gridRef = React.useRef<GridReadyEvent>();
  const {
    filters,
    setFilters,
    isFiltersExpanded,
    setIsFiltersExpanded,
    gqlFilterInput,
    filterCount,
  } = useOfferingFilters(DatalabScreens.CONVERTS);
  const [numRowsFetched, setNumRowsFetched] = React.useState({
    showing: 0,
    total: 0,
  });
  const { queryVariables, onDataUpdated, getRows } = useAGGridGetRows({
    getSortingModel,
    defaultSortModel,
  });
  const { data, error, loading } = useConvertibleOfferings_ListQuery({
    ...queryVariables,
    variables: {
      ...queryVariables.variables,
      where: gqlFilterInput,
    },
  });
  React.useEffect(() => {
    gridRef.current?.api.showLoadingOverlay();
    gridRef.current?.api.setRowCount(0);
    gridRef.current?.api.purgeInfiniteCache();
  }, [filters]);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Failed to load Convertible Offerings.');
      onDataUpdated({ error });
      return;
    }
    if (!loading && data) {
      const offerings = data?.offerings;
      const total = offerings?.totalCount || 0;
      const endRow = (offerings?.pageInfo.offset ?? 0) + ROWS_PER_PAGE;

      onDataUpdated({
        items: offerings?.items ?? [],
        lastRow: total <= endRow ? total : undefined,
      });

      setNumRowsFetched({
        showing: endRow < total ? endRow : total,
        total,
      });
      if (total === 0) {
        gridRef?.current?.api.showNoRowsOverlay(); // display "no rows message"
      } else {
        gridRef?.current?.api.hideOverlay();
      }
    }
  }, [data, error, loading, onDataUpdated]);

  const handleFiltersToggleClick = React.useCallback(() => {
    setIsFiltersExpanded(isFiltersExpanded => !isFiltersExpanded);
  }, [setIsFiltersExpanded]);

  const aggregations = data?.offerings?.summaries?.aggregations ?? undefined;

  const onDownload = React.useCallback(
    async (selectedOptions: string[]): Promise<ErrorApiResponse | SuccessApiResponse<Blob>> => {
      const selectedColumns = gridRef?.current?.columnApi.getAllDisplayedColumns();
      const sortModel = gridRef?.current?.api.getSortModel();
      const downloadArgs = getExcelDownloadArgs({
        selectedColumns,
        gqlFilterInput,
        sortModel,
        selectedOptions,
        defaultSortModel,
      });
      return await downloadConvertibleOfferings(downloadArgs, {});
    },
    [gqlFilterInput, gridRef]
  );

  return (
    <SScreenWrapper>
      <ScreenHeader />
      <SScreenContent>
        <StyledPanel>
          <FiltersToggleButton
            isFiltersExpanded={isFiltersExpanded}
            filterCount={filterCount}
            onClick={handleFiltersToggleClick}
          />
          <Collapse isExpanded={isFiltersExpanded}>
            <OfferingsFilterForm setFilter={setFilters} reportScreen={DatalabScreens.CONVERTS} />
          </Collapse>
        </StyledPanel>
        <STableWrapper data-test-id={datalabTableSelector('converts').testId}>
          <OfferingsReportTable
            gridRef={gridRef}
            getRows={getRows}
            isLoading={loading}
            numberOfRowsFetched={numRowsFetched.showing}
            totalRows={numRowsFetched.total}
            aggregations={aggregations}
            categorizedColumns={categorizedConvertibleColumns}
            alwaysVisibleColumns={alwaysVisibleColumns}
            columnsDef={orderedConvertibleColumns}
            downloadOptions={downloadOptions}
            onDownload={isDownloadExcelOn() ? onDownload : undefined}
            title="U.S. Converts"
          />
        </STableWrapper>
      </SScreenContent>
    </SScreenWrapper>
  );
};

export default ConvertOfferingsScreen;
