import { apiUtil } from '@cmg/common';

import { DatalabScreens } from '../../../../features/datalab/constants';
import { ApiResponse } from '../../../../types/api/ApiResponse';
import { dlgwApiClient } from '../../apiClient';

export const DATALAB_API_CALL_ID = 'DATALAB_API_CALL_ID';

export const fetchReportChart = (section: DatalabScreens, filters: any) =>
  dlgwApiClient.post<any, ApiResponse<any>>(`/compat/datalabs/${section}`, filters, {
    // @ts-ignore
    apiCallId: DATALAB_API_CALL_ID,
  });

export const fetchReportTable = (section: DatalabScreens, filters: any) =>
  dlgwApiClient.post<any, ApiResponse<any>>(`/compat/datalabs/${section}/table`, filters, {
    // @ts-ignore
    apiCallId: DATALAB_API_CALL_ID,
  });

export const downloadReport = (section: DatalabScreens, params: any) =>
  dlgwApiClient.post(`/compat/datalabs/${section}/download`, params, {
    responseType: 'blob',
    headers: { Accept: apiUtil.FILE_MIME_TYPES.XLSX },
    // @ts-ignore
    apiCallId: DATALAB_API_CALL_ID,
  });
