import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import { type Calendar_OfferingFieldsFragment } from '../graphql';
import { useIsUsFiledAndNotSellSideConsolidatedCalendar } from '../hooks/useIsUsFiledAndNotSellSideConsolidatedCalendar';

type Props = {
  label: string;
  offering: Calendar_OfferingFieldsFragment;
};

const spanStyle = {
  flex: '1 1 auto',
  paddingRight: 10,
};

const OfferingProfileLinkRenderer: React.FC<Props> = ({ label, offering }) => {
  const isLinkGrayedOut = useIsUsFiledAndNotSellSideConsolidatedCalendar(offering);
  const url = routeFactory.offerings.getUrlPath({ id: offering.id });

  return isLinkGrayedOut ? (
    <span style={spanStyle}>{label}</span>
  ) : (
    <Link to={url} style={spanStyle}>
      {label}
    </Link>
  );
};

export default OfferingProfileLinkRenderer;
