import { Option } from '@cmg/common';
import MobileDetect from 'mobile-detect';

import { allOption, FilterType } from '../../features/shared/constants/constants';

/**
 * Array helpers
 */
export const modifyObjectInArray = (array, selector, diff) => {
  const idx = array.findIndex(selector);

  return [
    ...array.slice(0, idx),
    {
      ...array[idx],
      ...diff,
    },
    ...array.slice(idx + 1),
  ];
};

export const removeFromArray = (array, selector) => {
  const idx = array.findIndex(selector);

  return [...array.slice(0, idx), ...array.slice(idx + 1)];
};

/**
 * Boolean helpers
 */

export const formatBoolean = (value: boolean): string => (value ? 'Yes' : 'No');

export const formatNullableBoolean = (value: boolean | null | undefined): string => {
  switch (value) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return '-';
  }
};

/**
 * Detect mobile device
 */
export const isMobileDevice = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  return !!md.phone();
};

/**
 * Detect Internet Explorer
 */
export const detectIEVersion = () => {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  return false;
};

export const isIE = () => !!detectIEVersion();

/**
 * Detect Landscape
 */
export const isLandscape = () => {
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

  return width > height;
};

/**
 * Filter helpers
 */
const filterByOneValue = (items, filter, field) => {
  if (filter.value === allOption.value) {
    return items;
  }

  return items.filter(item => {
    let keep = true;

    if (item[field] !== filter.value) {
      keep = false;
    }

    return keep;
  });
};

const filterByMoreValuesOR = (items, filters, field) => {
  if (filters.length === 0 || (filters.length === 1 && filters[0] === allOption.value)) {
    return items;
  }

  return items.filter(item => {
    let keep = false;

    for (const filterObj of filters) {
      if (item[field] === filterObj) {
        keep = true;
        break;
      }
    }

    return keep;
  });
};

const filterByValueInList = (items, filters, field) => {
  if (filters.length === 0 || (filters.length === 1 && filters[0].value === allOption.value)) {
    return items;
  }

  // will use a subfield if the items are an array of objects instead of numbers or strings
  const fields = field.split('.');
  const mainField = fields[0];
  const subField = fields.length >= 2 ? fields[1] : undefined;

  return items.filter(item => {
    let keep = false;

    for (const filterObj of filters) {
      for (const fieldItem of item[mainField]) {
        if (subField && fieldItem[subField] === filterObj.value) {
          keep = true;
          break;
        } else if (!subField && fieldItem[subField] === filterObj.value) {
          keep = true;
          break;
        }
      }
    }

    return keep;
  });
};

const filterBySearchText = (rows, filterValue, fieldName) => {
  if (filterValue === '') {
    return rows;
  }

  const value = filterValue.toLowerCase();

  return rows.filter(row => row[fieldName].toLowerCase().includes(value));
};

// @deprecated - we should use logic from filters.ts
export type Filter = {
  elType: string;
  field: string;
  filterBy: string;
  label: string;
  placeholder?: string;
  multi?: boolean;
  value: string | string[] | Option | Option[];
  onChange: (event: any) => void;
  hideIcon?: boolean;
  options?: Option[];
  searchable?: boolean;
  className?: string;
};

// @deprecated - we should use logic from filters.ts
export const applyFilters: <T>(rows: T, filters?: Filter[]) => T = (rows, filters) => {
  if (!filters || !filters.length) {
    return rows;
  }

  let filteredRows = rows;

  for (const filter of filters) {
    switch (filter.filterBy) {
      case FilterType.ONE_VALUE:
        filteredRows = filterByOneValue(filteredRows, filter.value, filter.field);
        break;
      case FilterType.MORE_VALUES_OR:
        filteredRows = filterByMoreValuesOR(filteredRows, filter.value, filter.field);
        break;
      case FilterType.FILTER_BY_VALUE_IN_LIST:
        filteredRows = filterByValueInList(filteredRows, filter.value, filter.field);
        break;
      case FilterType.SEARCH_TEXT:
        filteredRows = filterBySearchText(filteredRows, filter.value, filter.field);
        break;
      default:
    }
  }

  return filteredRows;
};

/**
 * URL helpers
 */
export const formatUrl = url => {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`;
  }

  return url;
};
