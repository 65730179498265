import { Alert } from '@cmg/common';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { useCanViewReports } from '../../../common/hooks/useCanViewReports';
import { registerOnUpdateAction as registerOnUpdateIoiTrackerAction } from '../../offering-dl/ioi-tracker/ducks';
import Spinner from '../../shared/spinner/Spinner';
import CompanyDatalabUpsellScreen from '../components/company-datalab-upsell-screen/CompanyDatalabUpsellScreen';
import CompanyScreen from '../components/CompanyScreen';
import useCompanyFullOrLiteQuery from '../hooks/useCompanyFullOrLiteQuery';
import { SContainer } from './CompanyContainer.styles';

export const CompanyContainer = () => {
  const { companyId } = useRouteMatch<{ companyId: string }>().params;
  const canViewReports = useCanViewReports();

  const { loading, error, fullQueryData, liteQueryData, refetch } = useCompanyFullOrLiteQuery(
    companyId,
    canViewReports
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // since the IoiTracker is redux-based class component not supporting current patterns,
    // better to register its onUpdate on the CompanyProfile side than vice-versa
    dispatch(
      registerOnUpdateIoiTrackerAction(() => {
        refetch();
      })
    );
  }, [dispatch, refetch]);

  if (loading) {
    return <Spinner show fullScreen />;
  }

  if (error) {
    return (
      <SContainer>
        <Alert severity="error" withMargin>
          {error.message}
        </Alert>
      </SContainer>
    );
  }

  if (!canViewReports) {
    return <CompanyDatalabUpsellScreen company={liteQueryData?.issuerById} />;
  }

  return <CompanyScreen company={fullQueryData?.issuerById} error={null} />;
};
