import { numericUtil, timeUtil } from '@cmg/common';
import { Grid, Tooltip, Typography } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React from 'react';

import { TermType } from '../../../../../../graphql/__generated__';
import BooleanIcon from '../../../../../shared/components/boolean-icon/BooleanIcon';
import BooleanIconWithTooltip from '../../../../../shared/components/boolean-icon/BooleanIconWithTooltip';
import {
  emptyValueTypography,
  getFormattedCurrency,
  getFormattedCurrencyInMillion,
  getFormattedPercentageRange,
  getFormattedPercentageValue,
} from '../../../../../shared/model/utils';
import type { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { getStructurePricingSectionColumns } from '../../shared/hooks/useGetStructurePricing';
import { getOfferPrice } from '../../shared/model/Shared.model';
import {
  type CardRowItem,
  ConvertStructurePricingRowLabels,
  type ConvertStructurePricingSectionData,
  type ConvertStructurePricingTableItem,
  emptyValue,
  StructurePricingTermName,
} from '../../shared/types';

const overflowStyles = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textDecoration: 'underline dotted',
};

export const getConvertStructurePricingTableData = (
  offeringProfile: OfferingProfile_ListQuery
): ConvertStructurePricingTableItem[] => {
  const offering = offeringProfile.offeringById;
  const { attributes, pricingCurrency, terms, initialTerm, finalTerm, latestRevisedTerm } =
    offering ?? {};
  const {
    latestGrossProceedsBase,
    latestGrossProceedsTotal,
    overAllotmentExercisedProceedsNotional,
  } = attributes ?? {};

  const revisedTerms: ConvertStructurePricingTableItem[] =
    terms
      ?.filter(term => term.type === TermType.Revised)
      .sort((t1, t2) => (t1.createdAt < t2.createdAt ? -1 : 1)) // sort revised term in the order it was added
      .map(term => ({
        name: StructurePricingTermName.Revised,
        grossProceeds: '',
        principalAmount: getFormattedCurrencyInMillion(
          pricingCurrency,
          term?.aggregatePrincipalAmount
        ),
        principalAmountOvltAuth: getFormattedCurrencyInMillion(
          pricingCurrency,
          term?.principalAmountOverallotmentAuthorized
        ),
        offerPrice: '',
        coupon: getFormattedPercentageRange(
          term?.couponTalkPercentageLow,
          term?.couponTalkPercentageHigh,
          3
        ).replace(/\s+/g, ''),
        premium: getFormattedPercentageRange(
          term?.premiumTalkLowPercentage,
          term?.premiumTalkHighPercentage,
          2
        ).replace(/\s+/g, ''),
      })) ?? [];
  return [
    {
      name: StructurePricingTermName.Initial,
      grossProceeds: '',
      principalAmount: initialTerm?.aggregatePrincipalAmount
        ? getFormattedCurrencyInMillion(pricingCurrency, initialTerm?.aggregatePrincipalAmount)
        : emptyValue,
      principalAmountOvltAuth: !isNil(initialTerm?.principalAmountOverallotmentAuthorized)
        ? getFormattedCurrencyInMillion(
            pricingCurrency,
            initialTerm?.principalAmountOverallotmentAuthorized
          )
        : emptyValue,
      offerPrice: '',
      coupon: getFormattedPercentageRange(
        initialTerm?.couponTalkPercentageLow,
        initialTerm?.couponTalkPercentageHigh,
        3
      ).replace(/\s+/g, ''),
      premium: getFormattedPercentageRange(
        initialTerm?.premiumTalkLowPercentage,
        initialTerm?.premiumTalkHighPercentage,
        2
      ).replace(/\s+/g, ''),
    },
    ...revisedTerms,
    {
      name: StructurePricingTermName.Priced,
      grossProceeds:
        finalTerm && latestGrossProceedsBase
          ? getFormattedCurrencyInMillion(pricingCurrency, latestGrossProceedsBase)
          : emptyValue,
      principalAmount: finalTerm?.aggregatePrincipalAmount
        ? getFormattedCurrencyInMillion(pricingCurrency, finalTerm?.aggregatePrincipalAmount)
        : emptyValue,
      principalAmountOvltAuth: finalTerm?.aggregatePrincipalAmount
        ? getFormattedCurrencyInMillion(
            pricingCurrency,
            finalTerm?.principalAmountOverallotmentAuthorized
          )
        : emptyValue,
      offerPrice: getOfferPrice(pricingCurrency, finalTerm),
      coupon: getFormattedPercentageValue(finalTerm?.couponPercentage, 3),
      premium: getFormattedPercentageValue(finalTerm?.premiumPercentage, 2),
    },
    {
      name: StructurePricingTermName.OvltExercised,
      grossProceeds:
        isNil(overAllotmentExercisedProceedsNotional) ||
        overAllotmentExercisedProceedsNotional === 0
          ? emptyValue
          : getFormattedCurrencyInMillion(pricingCurrency, overAllotmentExercisedProceedsNotional),
      principalAmount:
        isNil(finalTerm?.principalAmountOverallotmentExercised) ||
        finalTerm?.principalAmountOverallotmentExercised === 0
          ? emptyValue
          : getFormattedCurrencyInMillion(
              pricingCurrency,
              finalTerm?.principalAmountOverallotmentExercised
            ),
      principalAmountOvltAuth: '',
      offerPrice:
        isNil(finalTerm?.principalAmountOverallotmentExercised) ||
        finalTerm?.principalAmountOverallotmentExercised === 0
          ? emptyValue
          : getOfferPrice(pricingCurrency, finalTerm),
      coupon:
        isNil(finalTerm?.principalAmountOverallotmentExercised) ||
        finalTerm?.principalAmountOverallotmentExercised === 0
          ? emptyValue
          : getFormattedPercentageValue(finalTerm?.couponPercentage, 3),
      premium:
        isNil(finalTerm?.principalAmountOverallotmentExercised) ||
        finalTerm?.principalAmountOverallotmentExercised === 0
          ? emptyValue
          : getFormattedPercentageValue(finalTerm?.premiumPercentage, 2),
    },
    {
      name: StructurePricingTermName.Total,
      grossProceeds:
        finalTerm && latestGrossProceedsTotal
          ? getFormattedCurrencyInMillion(pricingCurrency, latestGrossProceedsTotal)
          : emptyValue,
      principalAmount:
        finalTerm?.aggregatePrincipalInclOverallotmentExercised ||
        latestRevisedTerm?.aggregatePrincipalInclOverallotmentExercised ||
        initialTerm?.aggregatePrincipalInclOverallotmentExercised
          ? getFormattedCurrencyInMillion(
              pricingCurrency,
              finalTerm?.aggregatePrincipalInclOverallotmentExercised ||
                latestRevisedTerm?.aggregatePrincipalInclOverallotmentExercised ||
                initialTerm?.aggregatePrincipalInclOverallotmentExercised
            )
          : emptyValue,
      principalAmountOvltAuth: '',
      offerPrice: getOfferPrice(pricingCurrency, finalTerm),
      coupon: getFormattedPercentageValue(finalTerm?.couponPercentage, 3),
      premium: getFormattedPercentageValue(finalTerm?.premiumPercentage, 2),
    },
  ];
};

export const getConvertStructurePricingTermsDataCol1 = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const {
    convertibleAttributes,
    finalTerm,
    pricingCurrency,
    latestRevisedTerm,
    initialTerm,
    maturityDate,
  } = offeringProfile.offeringById ?? {};

  const {
    conversionPrice,
    conversionRatio,
    effectiveConversionPremium,
    effectiveConversionPrice,
    conversionReferencePrice,
    versusTalkDisplayName,
  } = finalTerm ?? {};
  const { isPerpetual, tenor } = convertibleAttributes ?? {};
  const rows = [
    {
      name: ConvertStructurePricingRowLabels.parValue,
      value:
        isNil(finalTerm?.principalAmountPerNote) &&
        isNil(latestRevisedTerm?.principalAmountPerNote) &&
        isNil(initialTerm?.principalAmountPerNote)
          ? emptyValueTypography
          : getFormattedCurrency(
              pricingCurrency,
              finalTerm?.principalAmountPerNote ||
                latestRevisedTerm?.principalAmountPerNote ||
                initialTerm?.principalAmountPerNote
            ),
    },
    {
      name: ConvertStructurePricingRowLabels.conversionPrice,
      value: isNil(conversionPrice)
        ? emptyValueTypography
        : getFormattedCurrency(pricingCurrency, conversionPrice),
    },
    {
      name: ConvertStructurePricingRowLabels.initialConversionRatio,
      value: isNil(conversionRatio)
        ? emptyValueTypography
        : numericUtil.getDisplayValueForNumber(conversionRatio),
    },
    {
      name: ConvertStructurePricingRowLabels.effectiveConversionPremium,
      value: isNil(effectiveConversionPremium)
        ? emptyValueTypography
        : getFormattedPercentageValue(effectiveConversionPremium),
    },
    {
      name: ConvertStructurePricingRowLabels.effectiveConversionPrice,
      value: isNil(effectiveConversionPrice)
        ? emptyValueTypography
        : getFormattedCurrency(pricingCurrency, effectiveConversionPrice),
    },
    {
      name: ConvertStructurePricingRowLabels.conversionReferencePrice,
      value: isNil(conversionReferencePrice)
        ? emptyValueTypography
        : getFormattedCurrency(pricingCurrency, conversionReferencePrice),
    },
    {
      name: ConvertStructurePricingRowLabels.versusTalk,
      value: versusTalkDisplayName ?? emptyValueTypography,
    },
    {
      name: ConvertStructurePricingRowLabels.tenor,
      value: !isPerpetual && tenor ? `${tenor}` : emptyValueTypography,
    },
    {
      name: ConvertStructurePricingRowLabels.maturity,
      value: isPerpetual
        ? 'Perpetual'
        : timeUtil.formatAsMonthDateYear(maturityDate ?? '')?.replace(/\s+/g, '\u00A0') ??
          emptyValueTypography,
    },
  ];
  return rows;
};

export const getConvertStructurePricingTermsDataCol2Sect1 = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { convertibleAttributes } = offeringProfile.offeringById ?? {};

  const { isCallable, callableDate, callPrice } = convertibleAttributes ?? {};
  const rows = [
    {
      name: ConvertStructurePricingRowLabels.callable,
      value: <BooleanIcon value={isCallable} />,
    },
    {
      name: ConvertStructurePricingRowLabels.callableDate,
      value:
        timeUtil.formatAsMonthDateYear(callableDate ?? '')?.replace(/\s+/g, '\u00A0') ??
        emptyValueTypography,
    },
    {
      name: ConvertStructurePricingRowLabels.callPrice,
      value: isNil(callPrice) ? emptyValueTypography : getFormattedPercentageValue(callPrice),
    },
  ];
  return rows;
};

export const getConvertStructurePricingTermsDataCol2Sect2 = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { convertibleAttributes, attributes } = offeringProfile.offeringById ?? {};

  const {
    hasDividendProtection,
    hasContingentConversion,
    hasContingentPayment,
    contingentPaymentNote,
    changeOfControl,
    dividendProtectionNote,
    contingentConversionNote,
  } = convertibleAttributes ?? {};
  const { isConcurrentOffering } = attributes ?? {};
  const rows = [
    {
      name: ConvertStructurePricingRowLabels.changeOfControl,
      value: (
        <BooleanIconWithTooltip
          value={changeOfControl && changeOfControl.length > 0 ? true : false}
          tooltip={changeOfControl}
        />
      ),
    },
    {
      name: ConvertStructurePricingRowLabels.concurrentOffering,
      value: <BooleanIcon value={isConcurrentOffering} />,
    },
    {
      name: ConvertStructurePricingRowLabels.contingentConversion,
      value: (
        <BooleanIconWithTooltip
          value={hasContingentConversion}
          tooltip={contingentConversionNote}
        />
      ),
    },
    {
      name: ConvertStructurePricingRowLabels.contingentPayment,
      value: (
        <BooleanIconWithTooltip value={hasContingentPayment} tooltip={contingentPaymentNote} />
      ),
    },
    {
      name: ConvertStructurePricingRowLabels.dividendProtection,
      value: (
        <BooleanIconWithTooltip value={hasDividendProtection} tooltip={dividendProtectionNote} />
      ),
    },
  ];
  return rows;
};

export const getConvertStructurePricingTermsDataCol3 = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { convertibleAttributes, useOfProceedsDisplayNames, attributes } =
    offeringProfile.offeringById ?? {};

  const { useOfProceedsNote } = attributes ?? {};
  const { hasProvisionalCall, provisionalCallNote, isPuttable, isHedging, putNote, hedgingNote } =
    convertibleAttributes ?? {};
  const rows = [
    {
      name: ConvertStructurePricingRowLabels.hedging,
      value: <BooleanIconWithTooltip value={isHedging} tooltip={hedgingNote} />,
    },
    {
      name: ConvertStructurePricingRowLabels.provisionalCall,
      value: <BooleanIconWithTooltip value={hasProvisionalCall} tooltip={provisionalCallNote} />,
    },
    {
      name: ConvertStructurePricingRowLabels.puttable,
      value: <BooleanIconWithTooltip value={isPuttable} tooltip={putNote} />,
    },
    {
      name: ConvertStructurePricingRowLabels.zeroCoupon,
      value:
        isNil(offeringProfile.offeringById?.finalTerm?.couponPercentage) ||
        offeringProfile.offeringById?.finalTerm?.couponPercentage === 0 ? (
          <BooleanIconWithTooltip value={true} />
        ) : (
          <BooleanIconWithTooltip value={false} />
        ),
    },
    {
      name: ConvertStructurePricingRowLabels.useOfProceeds,
      value:
        useOfProceedsDisplayNames && useOfProceedsDisplayNames.length > 0
          ? useOfProceedsDisplayNames?.join(', ')
          : emptyValueTypography,
    },
    {
      name: ConvertStructurePricingRowLabels.useOfProceedsNotes,
      value: useOfProceedsNote ? (
        <Tooltip title={useOfProceedsNote} placement="top-start" variant="info">
          <Grid sx={overflowStyles}>
            <Typography>{useOfProceedsNote}</Typography>
          </Grid>
        </Tooltip>
      ) : (
        emptyValueTypography
      ),
    },
  ];
  return rows;
};

export const useGetConvertStructurePricingSectionData = (
  offeringProfile: OfferingProfile_ListQuery,
  matchesMediumDown: boolean,
  matchesSmallDown: boolean
): ConvertStructurePricingSectionData =>
  React.useMemo(() => {
    const structurePricingTable = getConvertStructurePricingTableData(offeringProfile);
    const structurePricingTermsCol1 = getConvertStructurePricingTermsDataCol1(offeringProfile);
    const structurePricingTermsCol2Sect1 =
      getConvertStructurePricingTermsDataCol2Sect1(offeringProfile);
    const structurePricingTermsCol2Sect2 =
      getConvertStructurePricingTermsDataCol2Sect2(offeringProfile);
    const structurePricingTermsCol3 = getConvertStructurePricingTermsDataCol3(offeringProfile);

    return {
      structurePricingTable,
      structurePricingRows: getStructurePricingSectionColumns(
        matchesMediumDown,
        matchesSmallDown,
        structurePricingTermsCol1,
        structurePricingTermsCol2Sect1,
        structurePricingTermsCol2Sect2,
        structurePricingTermsCol3
      ),
    };
  }, [matchesMediumDown, matchesSmallDown, offeringProfile]);
