import {
  Card,
  Grid,
  KeyValuePair,
  RegularBreakpoints,
  Section,
  Theme,
  useMediaQuery,
} from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { cardHeight } from '../../shared/OfferingProfileCardItem';
import { useGetAtmUnderwritingTermsSectionData } from '../model/Atm.model';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

export const AtmUnderWritingTermsWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const terms = useGetAtmUnderwritingTermsSectionData(offeringProfile);
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('xl'));
  const title = 'Underwriting Terms';

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          <Grid container columnSpacing={2}>
            <Grid item minWidth={theme => theme.spacing(32.5)} xs={isSmallScreen ? 12 : 3}>
              <KeyValuePair minWidth="xsmall" label={terms[0].name} value={terms[0].value} />
            </Grid>
            <Grid item xs={isSmallScreen ? 12 : 7}>
              <KeyValuePair minWidth="xsmall" label={terms[1].name} value={terms[1].value} />
            </Grid>
          </Grid>
        </Section>
      </Card>
    </Grid>
  );
};
