import { ApolloProvider } from '@apollo/client';
import { AuthProvider, AuthProviderConfig } from '@cmg/auth';
import { GlobalStyle, ThemeProvider } from '@cmg/common';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import { dlgwApiClient } from '../../api/dlgw/apiClient';
import { identityApi } from '../../api/id/apiClient';
import {
  allErrorResponseInterceptors,
  allRequestInterceptors,
  allSuccessResponseInterceptors,
  errorResponseInterceptors,
  requestInterceptors,
  successResponseInterceptors,
} from '../../api/interceptors';
import { EcmMixpanelAppContextProvider } from '../../common/providers/EcmMixpanelAppContextProvider';
import { browserHistory } from '../../common/redux/browser-history';
import store from '../../common/redux/store';
import routeFactory from '../../common/util/routeFactory';
import { getAppSettings } from '../../config/appSettings';
import graphqlApiClient from '../../graphql/dlgwGraphqlApiClient';
import App from './App';

// TODO: remove dependencies from interceptions in order to move following code to api-client files
dlgwApiClient.interceptors.request.use(allRequestInterceptors);
dlgwApiClient.interceptors.response.use(
  allSuccessResponseInterceptors,
  allErrorResponseInterceptors
);

identityApi.interceptors.request.use(requestInterceptors);
identityApi.interceptors.response.use(successResponseInterceptors, errorResponseInterceptors);

const appSettings = getAppSettings();
const authConfig: AuthProviderConfig = {
  env: {
    rootDomain: appSettings.env.rootDomain,
    secureCookies: appSettings.env.secureCookies,
  },
  client: {
    basename: appSettings.client.basename,
  },
  logging: {
    appName: 'Datalab',
    mixPanelToken: appSettings.logging.mixPanelToken,
  },
  auth: {
    clientId: appSettings.auth.clientId,
    oidcAuthorityBaseUrl: appSettings.auth.oidcAuthorityBaseUrl,
    tokenRenewalInterval: appSettings.auth.tokenRenewalIntervalInMinutes,
    scopes: Object.values(appSettings.auth.scopes),
  },
  actions: {
    onLogout: () => {
      window.location.href = routeFactory.loggedOut.getUrlPath();
    },
  },
};

const Root = () => (
  <AuthProvider authConfig={authConfig}>
    <Provider store={store}>
      <EcmMixpanelAppContextProvider>
        <Router history={browserHistory}>
          <React.Fragment>
            <GlobalStyle />
            <ThemeProvider>
              <ApolloProvider client={graphqlApiClient}>
                <IntlProvider locale="en" defaultLocale="en">
                  <App />
                </IntlProvider>
              </ApolloProvider>
            </ThemeProvider>
          </React.Fragment>
        </Router>
      </EcmMixpanelAppContextProvider>
    </Provider>
  </AuthProvider>
);

export default Root;
