import { ToastManager } from '@cmg/common';
import React from 'react';

import { Country, Region } from '../../../../../graphql/__generated__/index';
import { useOfferingsFilterForm_CountriesQuery } from '../graphql/__generated__/OfferingsFilterForm_Countries';
import { useCreateRegionCountryOptions } from '../OfferingsFilterForm.model';

export const useFetchRegionsAndCountries = ({ skip }: { skip?: boolean } = {}) => {
  const { data, error, loading } = useOfferingsFilterForm_CountriesQuery({
    skip,
    fetchPolicy: 'cache-first',
  });
  const regionCountries = useCreateRegionCountryOptions(data);
  const regionCountryOptions = React.useMemo(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching countries');
    }
    if (!loading && data) {
      return regionCountries;
    }
    return [];
  }, [data, error, loading, regionCountries]);

  const getRegionCountries = React.useCallback(
    (values: (Region | Country)[] | null) =>
      values
        ? (values
            .map(value => {
              const regionOption = regionCountryOptions.find(region => region.value === `${value}`);
              return regionOption ? regionOption.children.map(country => country.value) : value;
            })
            .flat() as Country[])
        : null,
    [regionCountryOptions]
  );

  return { regionCountryOptions, getRegionCountries };
};
