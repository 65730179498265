import { permissionsByEntity } from '@cmg/auth';
import { useCallback } from 'react';

import { useCanViewCalendar } from '../../../common/hooks/useCanViewCalendar';
import { useCanViewReports } from '../../../common/hooks/useCanViewReports';

const useRouterAccessCheck = () => {
  const hasCalendarTrait = useCanViewCalendar();
  const hasReportsTrait = useCanViewReports();

  const baseAccessCheck = useCallback((userPermissions: string[]) => {
    return userPermissions.includes(permissionsByEntity.DatalabUI.READ);
  }, []);

  const calendarAccessCheck = useCallback(
    (userPermissions: string[]) => {
      return baseAccessCheck(userPermissions) && hasCalendarTrait;
    },
    [hasCalendarTrait, baseAccessCheck]
  );

  const reportsAccessCheck = useCallback(
    (userPermissions: string[]) => {
      return baseAccessCheck(userPermissions) && hasReportsTrait;
    },
    [hasReportsTrait, baseAccessCheck]
  );

  return { calendarAccessCheck, reportsAccessCheck, baseAccessCheck };
};

export default useRouterAccessCheck;
