import React from 'react';

import {
  SFooterItem,
  SFooterWrapper,
  STitle,
  STitleWrapper,
  SWrapper,
} from './ScreenSubHeader.styles';

export type Props = {
  title: React.ReactNode;
  bottomData?: { value: any; label: any }[];
  rightData?: { value: any; label: any }[];
  rightButton?: React.ReactNode;
};

const ScreenSubHeader: React.FC<Props> = ({ title, bottomData, rightData, rightButton }) => {
  // Filter out null values from bottomData
  const bottomDataArr = bottomData?.filter(item => item.value !== null) || [];

  return (
    <SWrapper className="sub-header">
      <STitleWrapper>
        <STitle>{title}</STitle>

        {bottomDataArr.length > 0 && (
          <SFooterWrapper>
            {bottomDataArr.map((item, idx, arr) => (
              <span key={idx}>
                <span>{item.label}:</span> <SFooterItem>{item.value}</SFooterItem>
                &nbsp;{idx < arr.length - 1 && ','}&nbsp;
              </span>
            ))}
          </SFooterWrapper>
        )}
      </STitleWrapper>

      {rightData &&
        rightData.map((item, idx) => (
          <div className="right-box" key={idx}>
            <div className="right-box-title">{item.label}</div>
            <div className="right-box-content">{item.value}</div>
          </div>
        ))}

      {rightButton && <div className="right-button">{rightButton}</div>}
    </SWrapper>
  );
};

export default ScreenSubHeader;
