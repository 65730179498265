import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';

import {
  useCompanyProfileLiteQuery,
  useCompanyProfileQuery,
} from '../graphql/__generated__/CompanyProfile';

const useCompanyFullOrLiteQuery = (companyId: string, isFull: boolean) => {
  const {
    data: fullQueryData,
    loading: fullQueryLoading,
    error: fullQueryError,
    refetch,
  } = useCompanyProfileQuery({
    variables: {
      id: companyId,
      // DLGW GQL implementation uses standard pattern for authorization by populating
      // the result error collection with any/all authorization failures. This is a temporary
      // solution as currently FE will show error UI when error collection is populated.
      hasIoiPermission: useCheckPermissions([permissionsByEntity.Ioi.READ]),
      hasFundIoiPermission: useCheckPermissions([permissionsByEntity.FundIoi.READ]),
      hasAtmPermission: useCheckPermissions([permissionsByEntity.ATM.READ]),
      hasSellDownPermission: useCheckPermissions([permissionsByEntity.ATMSelldown.READ]),
      hasCustomSectorsPermission: useCheckPermissions([permissionsByEntity.CustomSectors.READ]),
    },
    fetchPolicy: 'no-cache',
    skip: !isFull,
  });

  const {
    data: liteQueryData,
    loading: liteQueryLoading,
    error: liteQueryError,
  } = useCompanyProfileLiteQuery({
    variables: {
      id: companyId,
    },
    fetchPolicy: 'no-cache',
    skip: isFull,
  });

  return {
    loading: isFull ? fullQueryLoading : liteQueryLoading,
    error: isFull ? fullQueryError : liteQueryError,
    fullQueryData,
    liteQueryData,
    refetch: isFull ? refetch : () => {},
  };
};

export default useCompanyFullOrLiteQuery;
