import { apiTypes, mixpanelUtil, ToastManager } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  createFund,
  fetchFunds as fetchFundApiCall,
  getFirmIoiTracker,
  getFundIoiTracker,
  submitFirmIoiTracker,
  submitFundIoiTracker,
} from '../../../api/dlgw';
import {
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  FAILURE,
  REQUEST,
  SUCCESS,
} from '../../../common/redux/reduxHelpers';
import { toastMessages } from '../../shared/constants/messages';

const { createMixPanelAction } = mixpanelUtil;

/**
 * ACTION TYPES
 */
const FETCH_FUNDS = 'ioi-tracker/FETCH_FUNDS';
const ADD_FUND = 'ioi-tracker/ADD_FUND';
const FETCH_IOI = 'ioi-tracker/FETCH_IOI';
const SAVE_ALLOCATION_AND_IOIS = 'ioi-tracker/SAVE_ALLOCATION_AND_IOIS';
const FETCH_IOI_FUNDS = 'ioi-tracker/FETCH_IOI_FUNDS';
const UPDATE_IOI_FUNDS = 'ioi-tracker/UPDATE_IOI_FUNDS';
const RESET_STATE = 'ioi-tracker/RESET_STATE';
const REGISTER_ON_UPDATE_ACTION = 'ioi-tracker/REGISTER_ON_UPDATE_ACTION';

/**
 * ACTIONS
 */
export const fetchFundsActions = createApiActionCreators(FETCH_FUNDS);
export const addFundActions = createApiActionCreators(ADD_FUND);
export const fetchIoiActions = createApiActionCreators(FETCH_IOI);
export const saveAllocationAndIoisActions = createApiActionCreators(SAVE_ALLOCATION_AND_IOIS);
export const fetchIoiFundsActions = createApiActionCreators(FETCH_IOI_FUNDS);
export const updateIoiFundsActions = createApiActionCreators(UPDATE_IOI_FUNDS);
export const resetState = createActionCreator(RESET_STATE);
export const registerOnUpdateAction = createActionCreator(REGISTER_ON_UPDATE_ACTION);

/**
 * REDUCERS
 */
export const initialState = {
  funds: [],
  ioiFunds: {
    iois: [],
    allocations: [],
    ioiOffering: null,
  },
  allocation: null,
  ioiOffering: null,
  iois: [],
  ioiType: '',
  ioiError: null,
  ioiFundsError: null,
  fundsError: null,
  onUpdateAction: null as (() => void) | null,
};

const funds = createReducer(initialState.funds, {
  [FETCH_FUNDS]: {
    [SUCCESS]: (state, payload) => payload,
  },
  [ADD_FUND]: {
    [SUCCESS]: (state, payload) => [...state, payload],
  },
});

const ioiFunds = createReducer(initialState.ioiFunds, {
  [FETCH_IOI_FUNDS]: {
    [SUCCESS]: (state, payload) => ({
      iois: payload.iois,
      allocations: payload.allocations,
      ioiOffering: payload.ioiOffering,
    }),
  },
  [UPDATE_IOI_FUNDS]: {
    [SUCCESS]: (state, payload) => payload,
  },
});

const allocation = createReducer(initialState.allocation, {
  [FETCH_IOI]: {
    [SUCCESS]: (state, payload) => payload.allocation,
  },
  [SAVE_ALLOCATION_AND_IOIS]: {
    [SUCCESS]: (state, payload) => payload.allocation,
  },
});

const ioiOffering = createReducer(initialState.ioiOffering, {
  [FETCH_IOI]: {
    [SUCCESS]: (state, payload) => payload.ioiOffering,
  },
  [SAVE_ALLOCATION_AND_IOIS]: {
    [SUCCESS]: (state, payload) => payload.ioiOffering,
  },
});

const iois = createReducer(initialState.iois, {
  [FETCH_IOI]: {
    [SUCCESS]: (state, payload) => payload.iois,
  },
  [SAVE_ALLOCATION_AND_IOIS]: {
    [SUCCESS]: (state, payload) => payload.iois,
  },
});

const ioiType = createReducer(initialState.ioiType, {
  [FETCH_IOI]: {
    [SUCCESS]: (state, payload) => payload.ioiType || '',
  },
  [SAVE_ALLOCATION_AND_IOIS]: {
    [SUCCESS]: (state, payload) => payload.ioiType || '',
  },
});

const ioiError = createReducer(initialState.ioiError, {
  [FETCH_IOI]: {
    [SUCCESS]: () => null,
    [FAILURE]: (state, { error }) => error,
  },
});

const ioiFundsError = createReducer(initialState.ioiFundsError, {
  [FETCH_IOI_FUNDS]: {
    [SUCCESS]: () => null,
    [FAILURE]: (state, { error }) => error,
  },
});

const fundsError = createReducer(initialState.fundsError, {
  [FETCH_FUNDS]: {
    [SUCCESS]: () => null,
    [FAILURE]: (state, { error }) => error,
  },
});

// note this variant doesn't support multiple registrees
// (would need to get updated into an array of actions if ever needed)
const onUpdateAction = createReducer(initialState.onUpdateAction, {
  [REGISTER_ON_UPDATE_ACTION]: (_state, payload) => payload,
});

const crossSliceReducer = createReducer(initialState, {
  [RESET_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers({
  funds,
  ioiFunds,
  allocation,
  ioiOffering,
  iois,
  ioiType,
  ioiError,
  ioiFundsError,
  fundsError,
  onUpdateAction,
});

export default function duckReducer(state, action) {
  const intermediateState = combinedReducers(state, action);
  const finalState = crossSliceReducer(intermediateState, action);

  return finalState;
}

/**
 * SELECTORS
 */
export const selectIoiTracker = state => state.ioiTracker;

export const selectFunds = state => selectIoiTracker(state).funds;
export const selectIoiFunds = state => selectIoiTracker(state).ioiFunds;
export const selectAllocation = state => selectIoiTracker(state).allocation;
export const selectIoiOffering = state => selectIoiTracker(state).ioiOffering;
export const selectIois = state => selectIoiTracker(state).iois;
export const selectIoiType = state => selectIoiTracker(state).ioiType;
export const selectError = (state): apiTypes.GenericServerError | null => {
  const { ioiError, ioiFundsError, fundsError } = selectIoiTracker(state);

  return ioiError || ioiFundsError || fundsError;
};
export const selectOnUpdateAction = state => selectIoiTracker(state).onUpdateAction;

/**
 * SAGAS
 */
export function* fetchFunds(action: AnyAction) {
  yield put(createMixPanelAction(FETCH_FUNDS, 'Funds Page'));

  const resp = yield call(fetchFundApiCall);

  if (resp.ok) {
    yield put(fetchFundsActions.success(resp.data));
  } else {
    yield put(fetchFundsActions.failure(resp.data));
  }
}

export function* addFund({ payload }: any) {
  yield put(createMixPanelAction(ADD_FUND, 'Funds Page'));

  const resp = yield call(createFund, { name: payload as string });

  if (resp.ok) {
    yield put(addFundActions.success(resp.data));
    ToastManager.success(toastMessages.success.saveFund);
    const onUpdateAction = yield select(selectOnUpdateAction);
    if (onUpdateAction) {
      onUpdateAction();
    }
  } else {
    yield put(addFundActions.failure(resp.data));
    ToastManager.error(toastMessages.error.saveFund);
  }
}

export function* fetchIoi({ payload }: any) {
  yield put(createMixPanelAction(FETCH_IOI, 'Fetch IoIs'));

  const resp = yield call(getFirmIoiTracker, { id: payload });

  if (resp.ok) {
    yield put(fetchIoiActions.success(resp.data));
  } else {
    yield put(fetchIoiActions.failure(resp.data));
  }
}

export function* saveAllocationAndIois({ payload }: any) {
  yield put(createMixPanelAction(SAVE_ALLOCATION_AND_IOIS, 'Save Allocation'));

  const resp = yield call(
    submitFirmIoiTracker,
    { id: payload.offeringId },
    payload.allocationAndIois
  );

  if (resp.ok) {
    yield put(saveAllocationAndIoisActions.success(resp.data));
    ToastManager.success(toastMessages.success.saveAllocation);
    const onUpdateAction = yield select(selectOnUpdateAction);
    if (onUpdateAction) {
      onUpdateAction();
    }
  } else {
    yield put(saveAllocationAndIoisActions.failure(resp.data));
    ToastManager.error(toastMessages.error.saveAllocation);
  }
}

export function* fetchIoiFunds({ payload }: any) {
  yield put(createMixPanelAction(FETCH_IOI_FUNDS, 'Fetch IoI Funds'));

  const resp = yield call(getFundIoiTracker, { id: payload });

  if (resp.ok) {
    yield put(fetchIoiFundsActions.success(resp.data));
  } else {
    yield put(fetchIoiFundsActions.failure(resp.data));
  }
}

export function* updateIoiFunds({ payload }: any) {
  yield put(createMixPanelAction(UPDATE_IOI_FUNDS, 'Update IoI Funds'));

  const resp = yield call(
    submitFundIoiTracker,
    { id: payload.offeringId },
    { iois: payload.iois, allocations: payload.allocations }
  );

  if (resp.ok) {
    yield put(updateIoiFundsActions.success(resp.data));
    ToastManager.success(toastMessages.success.updateIoiFunds);
    const onUpdateAction = yield select(selectOnUpdateAction);
    if (onUpdateAction) {
      onUpdateAction();
    }
  } else {
    yield put(updateIoiFundsActions.failure(resp.data));
    ToastManager.error(toastMessages.error.updateIoiFunds);
  }
}

export function* ioiTrackerSaga() {
  yield takeLatest(createActionType(FETCH_FUNDS, REQUEST), fetchFunds);
  yield takeLatest(createActionType(ADD_FUND, REQUEST), addFund);
  yield takeLatest(createActionType(FETCH_IOI, REQUEST), fetchIoi);
  yield takeLatest(createActionType(SAVE_ALLOCATION_AND_IOIS, REQUEST), saveAllocationAndIois);
  yield takeLatest(createActionType(FETCH_IOI_FUNDS, REQUEST), fetchIoiFunds);
  yield takeLatest(createActionType(UPDATE_IOI_FUNDS, REQUEST), updateIoiFunds);
}
