import { Box, Column, numericUtil, Row } from '@cmg/common';
import {
  underwriterProfileSellSideSelector,
  widgetPerformanceHeatMapByOfferingType,
} from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';

import { UnderwriterProfileSellSide } from '../../../../types/domain/datalab/underwriter-profile-sell-side';
import { selectUnderwriterProfileSellSideCharts } from '../../ducks';
import SingleVerticalBarChart from '../widgets/charts/SingleVerticalBarChart';
import WidgetHistoricalOfferingVolumeByType from '../widgets/underwriter-profile-sell-side/WidgetHistoricalOfferingVolumeByType/WidgetHistoricalOfferingVolumeByType';
import WidgetOfferingVolumeBySector from '../widgets/underwriter-profile-sell-side/WidgetOfferingVolumeBySector/WidgetOfferingVolumeBySector';
import WidgetAvgEconomicsRoleSector from '../widgets/WidgetAvgEconomicsRoleSector/WidgetAvgEconomicsRoleSector';
import WidgetAvgFeeRoleSector from '../widgets/WidgetAvgFeeRoleSector/WidgetAvgFeeRoleSector';
import WidgetFeesBySector from '../widgets/WidgetFeesBySector/WidgetFeesBySector';
import WidgetFeesByType from '../widgets/WidgetFeesByType/WidgetFeesByType';
import WidgetPerformanceHeatMap from '../widgets/WidgetPerformanceHeatMap/WidgetPerformanceHeatMap';

type Props = {
  datalab: UnderwriterProfileSellSide;
};

export class UnderwriterProfileSellSideScreenComponent extends React.Component<Props> {
  render() {
    const { datalab } = this.props;

    return (
      <div data-test-id={underwriterProfileSellSideSelector.testId}>
        <Row>
          <Column xs={24} sm={12}>
            <Box>
              <WidgetHistoricalOfferingVolumeByType
                chartData={datalab.historical_offering_volume_by_type}
              />
            </Box>
          </Column>

          <Column xs={24} sm={12}>
            <Box>
              <WidgetOfferingVolumeBySector
                chartData={datalab.sector_composition}
                title="Offering Volume by Sector"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24} sm={12}>
            <Box>
              <WidgetFeesByType chartData={datalab.fees_by_type} />
            </Box>
          </Column>

          <Column xs={24} sm={12}>
            <Box>
              <WidgetFeesBySector chartData={datalab.fees_by_sector} />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <SingleVerticalBarChart
                rawData={datalab.performance_by_sector}
                title="Performance by Sector (Offer to Current)"
                yLabelFormatter={value => numericUtil.formatPercents(value, 1)}
                valueFormatter={numericUtil.formatPercents}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetPerformanceHeatMap
                breakDownBy="label"
                breakDownLabel="Offering Type"
                chartData={datalab.performance_heat_map_by_offering_type}
                testId={widgetPerformanceHeatMapByOfferingType.testId}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24} sm={12}>
            <Box>
              <WidgetAvgEconomicsRoleSector
                chartData={datalab.average_economics_role}
                dataType="role"
              />
            </Box>
          </Column>

          <Column xs={24} sm={12}>
            <Box>
              <WidgetAvgEconomicsRoleSector
                chartData={datalab.average_economics_sector}
                dataType="sector"
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column xs={24}>
            <Box>
              <WidgetAvgFeeRoleSector chartData={datalab.average_fee_role_sector} />
            </Box>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    datalab: selectUnderwriterProfileSellSideCharts(state),
  };
}

export default connect(mapStateToProps, undefined)(UnderwriterProfileSellSideScreenComponent);
