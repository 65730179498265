import {
  OIDCLogin,
  OIDCLoginCallback,
  OIDCLogout,
  OIDCLogoutCallback,
  permissionsByEntity,
} from '@cmg/auth';
import React from 'react';
import { StaticContext } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../common/components/routing/private-route/PrivateRoute';
import routeFactory from '../../common/util/routeFactory';
import { getFeatureToggles } from '../../config/appSettings';
import CalendarContainer from '../calendar/containers/CalendarContainer';
import { CompanyContainer } from '../company/containers/CompanyContainer';
import DatalabContainer from '../datalab/containers/DatalabContainer';
import ATMOfferingsScreen from '../dlgw/atm-offerings-report/ATMOfferingsScreen';
import ConvertOfferingsScreen from '../dlgw/convert-offerings-report/ConvertOfferingsScreen';
import GlobalEcmV2Screen from '../dlgw/global-ecm-v2/GlobalEcmScreen';
import GlobalLeagueScreen from '../dlgw/global-league/GlobalLeagueScreen';
import ErrorRoute from '../error/error/ErrorRoute';
import ForbiddenRoute from '../error/forbidden/ForbiddenRoute';
import NotFoundRoute from '../error/not-found/NotFoundRoute';
import LoggedOutRoute from '../logged-out/LoggedOutRoute';
import DashboardRoute from '../my-dashboard/DashboardRoute';
import IoiTrackerContainer from '../offering-dl/ioi-tracker/containers/IoiTrackerContainer';
import OfferingProfileContainer from '../offering-dl/offering-profile/profiles/shared/OfferingProfileContainer';
import UnderwriterCreditsContainer from '../offering-dl/underwriter-credits/containers/UnderwriterCreditsContainer';
import CompaniesRoute from '../settings/companies/CompaniesRoute';
import SectorsRoute from '../settings/sectors/SectorsRoute';
import NoAccessRedirect from './components/NoAccessRedirect';
import useRouterAccessCheck from './hooks/useRouterAccessCheck';

/**
 * Top level routes. Only one can be active at a time and the active route takes up the main content area of the UI.
 * Nested within each of these components there may be more routes.
 */
const RootRouter: React.FC = () => {
  const { isGlobalEcmV2InDLOn } = getFeatureToggles();
  const { calendarAccessCheck, reportsAccessCheck, baseAccessCheck } = useRouterAccessCheck();

  return (
    <Switch>
      {/* Login/Logout routes */}
      <Route
        exact
        path={routeFactory.login.routePath}
        render={(routeProps: RouteComponentProps<any, StaticContext, any>) => {
          /**
           * if location is set, then it is set to { returnUrl }
           */
          const returnUrl = routeProps.location.state?.returnUrl || '/';

          return (
            <OIDCLogin
              onError={() => {
                routeProps.history.push(routeFactory.error.getUrlPath({}));
              }}
              returnUrl={returnUrl}
            />
          );
        }}
      />
      <Route
        exact
        path={routeFactory.logout.routePath}
        component={() => {
          return <OIDCLogout />;
        }}
      />
      <Route path={routeFactory.loggedOut.routePath} component={LoggedOutRoute} />
      {/* OIDC callback routes */}
      <Route
        exact
        path={routeFactory.oidcLoginCallback.routePath}
        render={routeProps => (
          <OIDCLoginCallback
            onSuccess={returnUrl => {
              routeProps.history.push(returnUrl || routeFactory.calendar.getUrlPath());
            }}
          />
        )}
      />
      <Route
        exact
        path={routeFactory.oidcLogoutCallback.routePath}
        render={routeProps => (
          <OIDCLogoutCallback
            onSuccess={() => {
              routeProps.history.push(routeFactory.loggedOut.getUrlPath());
            }}
          />
        )}
      />
      {/* Error handling routes */}
      <Route path={routeFactory.forbidden.routePath} component={ForbiddenRoute} />
      <Route path={routeFactory.error.routePath} component={ErrorRoute} />
      {/* Companies */}
      <PrivateRoute
        path={routeFactory.companies.routePath}
        accessCheck={baseAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={CompanyContainer}
      />
      {/** Dashboard */}
      <PrivateRoute
        path={routeFactory.myDashboard.routePath}
        accessCheck={reportsAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={DashboardRoute}
      />
      {/* Calendar */}
      <PrivateRoute
        path={routeFactory.calendar.routePath}
        accessCheck={calendarAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={CalendarContainer}
      />
      {/* Calendar redirect route */}
      <Redirect exact from="/" to={routeFactory.calendar.routePath} />

      <PrivateRoute
        path={routeFactory.datalabATMTable.routePath}
        accessCheck={reportsAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        requiredPermissions={[permissionsByEntity.ATM.READ]}
        component={ATMOfferingsScreen}
      />

      <PrivateRoute
        path={routeFactory.datalabConvertsTable.routePath}
        accessCheck={reportsAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        requiredPermissions={[permissionsByEntity.ConvertsOffering.READ]}
        component={ConvertOfferingsScreen}
      />

      {/* Global ECM V2 */}
      {isGlobalEcmV2InDLOn && (
        <PrivateRoute
          path={routeFactory.datalabGlobalEcmTable.routePath}
          accessCheck={reportsAccessCheck}
          renderNoAccess={props => <NoAccessRedirect {...props} />}
          component={GlobalEcmV2Screen}
        />
      )}

      <PrivateRoute
        path={routeFactory.datalabGlobalLeagueTable.routePath}
        accessCheck={reportsAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={GlobalLeagueScreen}
      />

      {/* Datalab */}
      <PrivateRoute
        path={routeFactory.datalab.routePath}
        accessCheck={reportsAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={DatalabContainer}
      />
      {/* DL Offering */}
      <PrivateRoute
        path={routeFactory.offerings.routePath}
        accessCheck={baseAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={OfferingProfileContainer}
      />
      <PrivateRoute
        path={routeFactory.ioi.routePath}
        accessCheck={baseAccessCheck}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={IoiTrackerContainer}
      />
      <PrivateRoute
        path={routeFactory.underwriterCredits.routePath}
        accessCheck={baseAccessCheck}
        requiredPermissions={[permissionsByEntity.Ioi.READ]}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={UnderwriterCreditsContainer}
      />
      {/* Settings / Companies */}
      <PrivateRoute
        path={routeFactory.settingsCompanies.routePath}
        accessCheck={baseAccessCheck}
        requiredPermissions={[permissionsByEntity.CustomSectors.READ]}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={CompaniesRoute}
      />
      {/* Settings / Sectors */}
      <PrivateRoute
        path={routeFactory.sectors.routePath}
        accessCheck={baseAccessCheck}
        requiredPermissions={[permissionsByEntity.CustomSectors.READ]}
        renderNoAccess={props => <NoAccessRedirect {...props} />}
        component={SectorsRoute}
      />
      {/* Fallback to 404 error */}
      <Route path="*" component={NotFoundRoute} />
    </Switch>
  );
};

export default RootRouter;
