import { apiTypes, routeUtil, urlUtil } from '@cmg/common';
import { Location } from 'history';

import { DatalabScreens, DatalabScreenViewTypes } from '../../features/datalab/constants';

const { createRoute } = routeUtil;

const routeFactory = {
  root: createRoute({
    routePath: '/',
  }),

  /**
   * USER ROUTES
   */
  oidcLoginCallback: createRoute({
    routePath: '/oidc-login-callback',
  }),

  oidcLogoutCallback: createRoute({
    routePath: '/oidc-logout-callback',
  }),

  login: createRoute({
    routePath: '/login',
    getUrlPath: () => `/login`,
    getRouteState: (params: { location: Location }) => ({
      returnUrl: params.location.pathname + params.location.search,
    }),
  }),

  logout: createRoute({
    routePath: '/logout',
    getUrlPath: () => '/logout',
  }),

  loggedOut: createRoute({
    routePath: '/logged-out',
    getUrlPath: ({ reason }: { reason?: string } = {}) =>
      `/logged-out${urlUtil.queryStringify({ reason })}`,
  }),

  forbidden: createRoute({
    routePath: '/forbidden',
    getUrlPath: () => `/forbidden`,
  }),

  error: createRoute({
    routePath: '/error',
    getUrlPath: ({ errorCode }: { errorCode?: apiTypes.ServiceErrorCode }) =>
      `/error${urlUtil.queryStringify({ errorCode })}`,
  }),

  companies: createRoute({
    routePath: '/companies/:companyId',
    getUrlPath: ({ id }: { id?: string } = {}) => `/companies/${id}`,
  }),

  myDashboard: createRoute({
    routePath: '/my-dashboard',
    getUrlPath: () => '/my-dashboard',
  }),
  /**
   * CALENDAR
   */
  calendar: createRoute({
    routePath: '/calendar',
    getUrlPath: () => `/calendar`,
  }),

  calendarLiveOfferings: createRoute({
    routePath: '/calendar/live',
  }),

  calendarPricedOfferings: createRoute({
    routePath: '/calendar/priced',
  }),

  calendarFiledOfferings: createRoute({
    routePath: '/calendar/filed',
  }),

  calendarPostponedOfferings: createRoute({
    routePath: '/calendar/postponed',
  }),

  calendarLockupExpirationOfferings: createRoute({
    routePath: '/calendar/lockup-expirations',
  }),

  calendarMyOfferings: createRoute({
    routePath: '/calendar/my-offerings',
  }),

  calendarMyOfferingsWithAllocation: createRoute({
    routePath: '/calendar/my-offerings-with-allocation',
  }),

  calendarDraftOfferings: createRoute({
    routePath: '/calendar/draft',
  }),

  /**
   * DATALAB OFFERING
   */
  underwriterCredits: createRoute({
    routePath: '/underwriter_credits/:offeringId',
    getUrlPath: ({ id }: { id?: string } = {}) => `/underwriter_credits/${id}`,
  }),

  offerings: createRoute({
    routePath: '/offerings/:offeringId',
    getUrlPath: ({ id }: { id?: string } = {}) => `/offerings/${id}`,
  }),

  ioi: createRoute({
    routePath: '/ioi/:offeringId',
    getUrlPath: ({ id }: { id?: string } = {}) => `/ioi/${id}`,
  }),

  /**
   * SETTINGS
   */
  sectors: createRoute({
    routePath: '/settings/sectors',
    getUrlPath: () => `/settings/sectors`,
  }),
  settingsCompanies: createRoute({
    routePath: '/settings/companies',
    getUrlPath: () => `/settings/companies`,
  }),

  /**
   * DATALAB
   */
  datalab: createRoute({
    routePath: '/datalab/:datalabScreen?/:datalabScreenType?',
    getUrlPath: ({
      screen,
      type,
      query,
    }: {
      screen?: DatalabScreens | 'report';
      type?: 'chart' | 'table' | DatalabScreenViewTypes;
      query?: {};
    } = {}) => {
      const screenString = screen ? `/${screen}` : '';
      const typeString = type ? `/${type}` : '';
      const queryString = query ? urlUtil.queryStringify(query) : '';

      return `/datalab${screenString}${typeString}${queryString}`;
    },
  }),
  datalabReport: createRoute({
    routePath: `/datalab/report`,
  }),
  datalabIpoStructure: createRoute({
    routePath: `/datalab/${DatalabScreens.IPO_STRUCTURE}`,
  }),
  datalabIpoStructureChart: createRoute({
    routePath: `/datalab/${DatalabScreens.IPO_STRUCTURE}/chart`,
  }),
  datalabIpoStructureTable: createRoute({
    routePath: `/datalab/${DatalabScreens.IPO_STRUCTURE}/table`,
  }),
  datalabIpoPricing: createRoute({
    routePath: `/datalab/${DatalabScreens.IPO_PRICING}`,
  }),
  datalabIpoPricingChart: createRoute({
    routePath: `/datalab/${DatalabScreens.IPO_PRICING}/chart`,
  }),
  datalabIpoPricingTable: createRoute({
    routePath: `/datalab/${DatalabScreens.IPO_PRICING}/table`,
  }),
  datalabIpoBacklogTable: createRoute({
    routePath: `/datalab/${DatalabScreens.IPO_BACKLOG}/table`,
  }),
  datalabFollowOnStructure: createRoute({
    routePath: `/datalab/${DatalabScreens.FOLLOW_ON_STRUCTURE}`,
  }),
  datalabFollowOnStructureChart: createRoute({
    routePath: `/datalab/${DatalabScreens.FOLLOW_ON_STRUCTURE}/chart`,
  }),
  datalabFollowOnStructureTable: createRoute({
    routePath: `/datalab/${DatalabScreens.FOLLOW_ON_STRUCTURE}/table`,
  }),
  datalabFollowOnPricing: createRoute({
    routePath: `/datalab/${DatalabScreens.FOLLOW_ON_PRICING}`,
  }),
  datalabFollowOnPricingChart: createRoute({
    routePath: `/datalab/${DatalabScreens.FOLLOW_ON_PRICING}/chart`,
  }),
  datalabFollowOnPricingTable: createRoute({
    routePath: `/datalab/${DatalabScreens.FOLLOW_ON_PRICING}/table`,
  }),
  datalabMarketPulse: createRoute({
    routePath: `/datalab/${DatalabScreens.MARKET_PULSE}`,
  }),
  datalabMarketPulseChart: createRoute({
    routePath: `/datalab/${DatalabScreens.MARKET_PULSE}/chart`,
  }),
  datalabMarketPulseTable: createRoute({
    routePath: `/datalab/${DatalabScreens.MARKET_PULSE}/table`,
  }),
  datalabCapitalRaisedMatrixChart: createRoute({
    routePath: `/datalab/${DatalabScreens.CAPITAL_RAISED_MATRIX}/chart`,
  }),
  datalabUnderwritingTerms: createRoute({
    routePath: `/datalab/${DatalabScreens.UNDERWRITING_TERMS}`,
  }),
  datalabUnderwritingTermsChart: createRoute({
    routePath: `/datalab/${DatalabScreens.UNDERWRITING_TERMS}/chart`,
  }),
  datalabUnderwritingTermsTable: createRoute({
    routePath: `/datalab/${DatalabScreens.UNDERWRITING_TERMS}/table`,
  }),
  datalabUnderwriterProfile: createRoute({
    routePath: `/datalab/${DatalabScreens.UNDERWRITER_PROFILE_SELL}`,
  }),
  datalabUnderwriterProfileChart: createRoute({
    routePath: `/datalab/${DatalabScreens.UNDERWRITER_PROFILE_SELL}/chart`,
  }),
  datalabUnderwriterProfileTable: createRoute({
    routePath: `/datalab/${DatalabScreens.UNDERWRITER_PROFILE_SELL}/table`,
  }),
  datalabLockupExpirationTable: createRoute({
    routePath: `/datalab/${DatalabScreens.LOCK_UP_EXPIRATION}/table`,
  }),
  datalabLeagueTable: createRoute({
    routePath: `/datalab/${DatalabScreens.LEAGUE_TABLE}/table`,
    getUrlPath: () => `/datalab/${DatalabScreens.LEAGUE_TABLE}/table`,
  }),
  datalabAftermarketMatrixTable: createRoute({
    routePath: `/datalab/${DatalabScreens.AFTERMARKET_MATRIX}/table`,
  }),
  datalabSponsorsLeagueTable: createRoute({
    routePath: `/datalab/${DatalabScreens.SPONSOR_LEAGUE_TABLE}/table`,
  }),
  datalabIoiParticipation: createRoute({
    routePath: `/datalab/${DatalabScreens.IOI_PARTICIPATION}`,
  }),
  datalabIoiParticipationChart: createRoute({
    routePath: `/datalab/${DatalabScreens.IOI_PARTICIPATION}/chart`,
  }),
  datalabIoiParticipationTable: createRoute({
    routePath: `/datalab/${DatalabScreens.IOI_PARTICIPATION}/table`,
  }),
  datalabIoiLeagueTable: createRoute({
    routePath: `/datalab/${DatalabScreens.IOI_LEAGUE_TABLE}/table`,
  }),
  datalabPlLeagueTable: createRoute({
    routePath: `/datalab/${DatalabScreens.PL_LEAGUE_TABLE}/table`,
  }),
  datalabFundIoiLeagueTable: createRoute({
    routePath: `/datalab/${DatalabScreens.FUND_IOI_LEAGUE_TABLE}/table`,
  }),
  datalabFundIoiParticipationTable: createRoute({
    routePath: `/datalab/${DatalabScreens.FUND_IOI_PARTICIPATION}/table`,
  }),
  datalabUnderwriterOfferingsTable: createRoute({
    routePath: `/datalab/${DatalabScreens.UNDERWRITER_OFFERINGS}/table`,
  }),
  datalabBrokerPlOfferingsTable: createRoute({
    routePath: `/datalab/${DatalabScreens.BROKER_PL_OFFERINGS}/table`,
  }),
  datalabAftermarketUnderwriterOfferingsTable: createRoute({
    routePath: `/datalab/${DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS}/table`,
  }),
  datalabConvertsTable: createRoute({
    routePath: `/datalab/${DatalabScreens.CONVERTS}/table`,
  }),
  datalabATMTable: createRoute({
    routePath: `/datalab/${DatalabScreens.ATM}/table`,
  }),
  datalabGlobalEcmTable: createRoute({
    routePath: `/datalab/${DatalabScreens.GLOBAL_ECM}/table`,
  }),
  datalabGlobalLeagueTable: createRoute({
    routePath: `/datalab/${DatalabScreens.GLOBAL_LEAGUE}/table`,
  }),
};

export default routeFactory;
