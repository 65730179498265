import { Modal, ModalContent } from '@cmg/common';
import React from 'react';
import { connectModal, hide, InjectedProps, show } from 'redux-modal';

import HubspotForm from './components/hubspot-form/HubspotForm';

type OwnProps = {
  title?: string;
  category?: 'GENERAL_INQUIRY' | 'TECHNICAL_ISSUE' | 'DATA_INQUIRY' | 'API_INQUIRY' | 'FEEDBACK';
  subject?: string;
  description?: string;
};

export type Props = InjectedProps & OwnProps;

/**
 * Modal window component to send feedback message to CMG support.
 */
export const SupportRequestModalComponent: React.FC<Props> = ({
  show,
  handleHide,
  category,
  subject,
  description,
  title = 'Please let us know of any questions or comments',
}) => (
  <Modal show={show} title={title} closeButton onHide={handleHide}>
    <ModalContent>
      <HubspotForm category={category} subject={subject} description={description} />
    </ModalContent>
  </Modal>
);

const name = 'SUPPORT/SUPPORT_REQUEST_MODAL';
export const openSupportRequestModal = (props: OwnProps) => show(name, props);
export const closeSupportRequestModal = () => hide(name);

export default connectModal({ name })(SupportRequestModalComponent);
