import { Box, numericUtil } from '@cmg/common';
import React, { useMemo, useState } from 'react';

import { BarChart, BarChartProps } from '../../../../../common/components/charts';
import Toggler from '../../../../shared/layout/components/Toggler';
import { barChartColors } from '../../../colors';
import valueAmountButtons, {
  ValueAmountButtons,
} from '../../../model/buttons/value-amount-buttons';
import { SButtonBox, SChart, SDatalabChart } from '../shared/styled';

type Props = {
  chartData?: {
    label: string;
    values: {
      value: {
        marketFO: number;
        overnightFO: number;
        registeredBlocks: number;
        unregisteredBlocks: number;
        registeredDirect: number;
        rights: number;
      };
      amount: {
        marketFO: number;
        overnightFO: number;
        registeredBlocks: number;
        unregisteredBlocks: number;
        registeredDirect: number;
        rights: number;
      };
    };
  }[];
};

const transformData = (
  chartData: Props['chartData'] = [],
  valueType: string
): BarChartProps['data'] => {
  return {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        label: 'Rights',
        data: chartData.map(item => item.values[valueType].rights as number),
        backgroundColor: barChartColors.lightAlpha30,
      },
      {
        label: 'Marketed FOs',
        data: chartData.map(item => item.values[valueType].marketFO as number),
        backgroundColor: barChartColors.lighter,
      },
      {
        label: 'Overnight FOs',
        data: chartData.map(item => item.values[valueType].overnightFO as number),
        backgroundColor: barChartColors.light,
      },
      {
        label: 'Registered Blocks',
        data: chartData.map(item => item.values[valueType].registeredBlocks as number),
        backgroundColor: barChartColors.dark,
      },
      {
        label: 'Unregistered Blocks',
        data: chartData.map(item => item.values[valueType].unregisteredBlocks as number),
        backgroundColor: barChartColors.darker,
      },
    ],
  };
};

const getOptions = (valueType: string): BarChartProps['options'] => {
  return {
    datasets: {
      bar: {
        borderRadius: 0,
        categoryPercentage: 0.3,
        maxBarThickness: 100, // overrides default thin bars
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: value => {
            if (typeof value !== 'number') {
              return value;
            }

            if (valueType === ValueAmountButtons.VALUE) {
              return numericUtil.formatCurrencyInBillions(value);
            }
            return numericUtil.formatInteger(value);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const { dataset, parsed } = tooltipItem;

            return `${dataset.label ? `${dataset.label}: ` : ''}${
              valueType === valueAmountButtons[0].value
                ? numericUtil.formatCurrencyInBillions(parsed.y)
                : numericUtil.formatInteger(parsed.y)
            }`;
          },
        },
      },
    },
  };
};

const WidgetHistoricalOfferingVolume: React.FC<Props> = ({ chartData = [] }) => {
  const [activeBtn, setActiveBtn] = useState(valueAmountButtons[0]);
  const transformedData = useMemo(
    () => transformData(chartData, activeBtn.value),
    [chartData, activeBtn]
  );
  const options = useMemo(() => getOptions(activeBtn.value), [activeBtn.value]);

  const handleButtonChange = (activeBtn: any) => {
    setActiveBtn(activeBtn);
  };

  return (
    <SDatalabChart>
      <Box.Header title="Historical Offering Volume" />

      <SButtonBox>
        <Toggler
          buttons={valueAmountButtons}
          activeButton={activeBtn}
          onChange={handleButtonChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <SChart fixedHeight>
          <BarChart data={transformedData} options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetHistoricalOfferingVolume;
