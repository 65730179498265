import { Box, Divider, Stack } from '@cmg/design-system';
import React from 'react';

import { useActiveSectionOnScroll } from '../../../../../common/hooks/useActiveSectionOnScroll';
import { OfferingProfile_ListQuery } from '../../graphql/__generated__/OfferingProfile';
import { profileSectionPrefix, useGetProfileSections } from '../shared/model/Shared.model';
import { ProfileSummary, SideNavBar } from '../shared/OfferingProfile';
import {
  dividerStyle,
  mainSectionBoxStyle,
  sideNavBoxStyle,
  x12_l12_m12_s12,
} from '../shared/OfferingProfile.styles';
import { OfferingProfileFieldName } from '../shared/types';
import AdvisersWidget from '../shared/widgets/AdvisersWidget';
import CornerstoneInvestorsWidget from '../shared/widgets/CornerstoneInvestorsWidget';
import DocumentationWidget from '../shared/widgets/DocumentationWidget';
import LockupsWidget from '../shared/widgets/LockupsWidget';
import ManagersWidget from '../shared/widgets/ManagersWidget';
import TimingWidget from '../shared/widgets/TimingWidget';
import UnderwritingTermsWidget from '../shared/widgets/UnderwritingTermsWidget';
import UserNotesWidget from '../shared/widgets/UserNotesWidget';
import { useGetConvertSideBarData } from './model/Convert.model';
import ConvertStructurePricingWidget from './widgets/ConvertStructurePricingWidget';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  userNote?: string | null;
  onSaveUserNote: (userNote: string | null) => void;
  isSavingUserNote: boolean;
};

export const ConvertProfile: React.FC<Props> = ({
  offeringProfile,
  userNote,
  onSaveUserNote,
  isSavingUserNote,
}) => {
  const [mainSectionHeight, setmainSectionHeight] = React.useState('100vh');
  const offeringData = useGetConvertSideBarData(offeringProfile);
  const sectionsConfig = useGetProfileSections(offeringProfile);
  const mainSectionId = 'convert_offering_profile_main_section';
  const valueHighlightItemHeight = (name: string) =>
    OfferingProfileFieldName.UnderlyingExchangeRegionCountry === name ||
    OfferingProfileFieldName.InstrumentDescription === name
      ? undefined
      : 24;
  const { activeSection, handleClick } = useActiveSectionOnScroll({
    sectionsConfig,
    mainSectionId,
  });

  React.useEffect(() => {
    const mainSection = document.getElementById(mainSectionId);
    const handleResize = () => {
      mainSection && setmainSectionHeight(`calc(100vh - ${mainSection.offsetTop}px - 1px)`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Box sx={sideNavBoxStyle} height={mainSectionHeight}>
        <ProfileSummary
          offeringData={offeringData}
          valueHighlightItemHeight={valueHighlightItemHeight}
        />
        <Divider orientation="horizontal" sx={dividerStyle} />
        <SideNavBar
          sectionsConfig={sectionsConfig}
          activeSection={activeSection}
          handleClick={handleClick}
        />
      </Box>
      <Box sx={mainSectionBoxStyle} id={mainSectionId} maxHeight={mainSectionHeight}>
        <React.Fragment>
          <TimingWidget
            cardSizes={x12_l12_m12_s12}
            offeringProfile={offeringProfile}
            id={`${profileSectionPrefix}_timing`}
          />
          <ConvertStructurePricingWidget
            cardSizes={x12_l12_m12_s12}
            offeringProfile={offeringProfile}
            id={`${profileSectionPrefix}_structure`}
          />
          <ManagersWidget cardSizes={x12_l12_m12_s12} offeringProfile={offeringProfile} />
          <UnderwritingTermsWidget
            cardSizes={x12_l12_m12_s12}
            offeringProfile={offeringProfile}
            id={`${profileSectionPrefix}_underwritingTerms`}
          />
          <LockupsWidget
            cardSizes={x12_l12_m12_s12}
            offeringProfile={offeringProfile}
            id={`${profileSectionPrefix}_lockups`}
          />
          <CornerstoneInvestorsWidget
            cardSizes={x12_l12_m12_s12}
            offeringProfile={offeringProfile}
            id={`${profileSectionPrefix}_cornerstone`}
          />
          <AdvisersWidget
            cardSizes={x12_l12_m12_s12}
            offeringProfile={offeringProfile}
            id={`${profileSectionPrefix}_advisor`}
          />
          <DocumentationWidget
            cardSizes={x12_l12_m12_s12}
            offeringProfile={offeringProfile}
            id={`${profileSectionPrefix}_documentation`}
          />
          <UserNotesWidget
            cardSizes={x12_l12_m12_s12}
            onSaveUserNote={onSaveUserNote}
            isSaving={isSavingUserNote}
            userNote={userNote}
            id={`${profileSectionPrefix}_notes`}
          />
        </React.Fragment>
      </Box>
    </Stack>
  );
};
