import { styled, TableCell, TableContainer } from '@cmg/design-system';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: theme.spacing(14),
}));

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.neutral[400]};
  }
`;
