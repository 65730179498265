/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingsFilterForm_CountriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OfferingsFilterForm_CountriesQuery = {
  __typename?: 'Query';
  countries?: {
    __typename?: 'CountriesCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'EnumLabelOfCountry';
      id: Types.Country;
      value: number;
      displayName: string;
      region?: {
        __typename?: 'EnumLabelOfRegion';
        id: Types.Region;
        displayName: string;
        value: number;
      } | null;
    }> | null;
  } | null;
};

export const OfferingsFilterForm_CountriesDocument = gql`
  query OfferingsFilterForm_Countries {
    countries(take: 500) {
      items {
        id
        value
        displayName
        region {
          id
          displayName
          value
        }
      }
      totalCount
    }
  }
`;

/**
 * __useOfferingsFilterForm_CountriesQuery__
 *
 * To run a query within a React component, call `useOfferingsFilterForm_CountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingsFilterForm_CountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingsFilterForm_CountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfferingsFilterForm_CountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingsFilterForm_CountriesQuery,
    OfferingsFilterForm_CountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingsFilterForm_CountriesQuery,
    OfferingsFilterForm_CountriesQueryVariables
  >(OfferingsFilterForm_CountriesDocument, options);
}
export function useOfferingsFilterForm_CountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingsFilterForm_CountriesQuery,
    OfferingsFilterForm_CountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingsFilterForm_CountriesQuery,
    OfferingsFilterForm_CountriesQueryVariables
  >(OfferingsFilterForm_CountriesDocument, options);
}
export function useOfferingsFilterForm_CountriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingsFilterForm_CountriesQuery,
        OfferingsFilterForm_CountriesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingsFilterForm_CountriesQuery,
    OfferingsFilterForm_CountriesQueryVariables
  >(OfferingsFilterForm_CountriesDocument, options);
}
export type OfferingsFilterForm_CountriesQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_CountriesQuery
>;
export type OfferingsFilterForm_CountriesLazyQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_CountriesLazyQuery
>;
export type OfferingsFilterForm_CountriesSuspenseQueryHookResult = ReturnType<
  typeof useOfferingsFilterForm_CountriesSuspenseQuery
>;
export type OfferingsFilterForm_CountriesQueryResult = Apollo.QueryResult<
  OfferingsFilterForm_CountriesQuery,
  OfferingsFilterForm_CountriesQueryVariables
>;
