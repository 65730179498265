import { checkPermissions, permissionsByEntity } from '@cmg/auth';
import { DropdownButton, DropdownOption, IconButton, theme } from '@cmg/common';
import { ecmSelectors } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { useCanViewReports } from '../../../../../common/hooks/useCanViewReports';
import { UserSettings } from '../../../../../types/domain/user/userSettings';
import NotificationsIcon from '../../../notifications/components/NotificationsIcon';
import ProfileDropdownButton from '../profile-dropdown-button/ProfileDropdownButton';
import SupportRequestModal, {
  openSupportRequestModal,
} from '../support-request-modal/SupportRequestModal';
import {
  SAccountMenuWrapper,
  SDropdownButtonWrapper,
  SMenu,
  SMenuOption,
  SSectorsToggleListItem,
  StyledIconWrapper,
  StyledPopover,
  StyledToggleIcon,
} from './AccountMenu.styles';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openSupportRequestModal,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = {
  userSettings?: UserSettings | null;
  userFullName?: string | null;
  isLoggedIn: boolean;
  // User's access token or a 'shared route' token
  token: string;
  userPermissions: string[];
  logoutUrl: string;
  myProfileUrl: string;
  manageAccountUrl: string;
  privacyPolicyUrl: string;
  setUserSettings: (params: { useCustomSectors: boolean }) => void;
};

export type Props = OwnProps & DispatchProps;

export const AccountMenuComponent: React.FC<Props> = ({
  actions,
  userSettings,
  token,
  userFullName,
  logoutUrl,
  myProfileUrl,
  manageAccountUrl,
  isLoggedIn,
  userPermissions,
  setUserSettings,
  privacyPolicyUrl,
}) => {
  const handleSectorToggle = () => {
    setUserSettings({
      useCustomSectors: !(userSettings || {}).useCustomSectors,
    });
  };

  const canViewReports = useCanViewReports();
  const handleSupportButtonClick = () => actions.openSupportRequestModal({});
  const canReadCustomSectors = checkPermissions(userPermissions, [
    permissionsByEntity.CustomSectors.READ,
  ]);
  const useCustomSectors = Boolean(userSettings && userSettings.useCustomSectors);
  const canReadAccount = checkPermissions(userPermissions, [permissionsByEntity.Account.READ]);

  const options: DropdownOption[] = [
    canReadCustomSectors && {
      onClick: handleSectorToggle,
      label: () => (
        <SSectorsToggleListItem role="presentation">
          {useCustomSectors ? (
            <React.Fragment>
              <StyledToggleIcon name="toggle-on" /> My Sectors On
            </React.Fragment>
          ) : (
            <React.Fragment>
              <StyledToggleIcon name="toggle-off" /> My Sectors Off
            </React.Fragment>
          )}
        </SSectorsToggleListItem>
      ),
      testId: ecmSelectors.accountMenuSectorsSelector.testId,
    },
    {
      href: myProfileUrl,
      target: '_blank',
      label: 'My Profile',
    },
    canReadAccount && {
      href: manageAccountUrl,
      target: '_blank',
      label: 'Manage Account',
    },
    {
      href: privacyPolicyUrl,
      target: '_blank',
      label: 'Privacy',
      testId: ecmSelectors.accountMenuPrivacySelector.testId,
    },
    {
      asComponent: Link,
      to: logoutUrl,
      label: 'Logout',
      testId: ecmSelectors.accountMenuLogoutSelector.testId,
    },
  ].filter(entry => entry !== false) as DropdownOption[];

  return (
    <SAccountMenuWrapper>
      {isLoggedIn && canViewReports && (
        <React.Fragment>
          <NotificationsIcon />
        </React.Fragment>
      )}
      <StyledPopover
        trigger="click"
        content={
          <SMenu>
            <SMenuOption
              onClick={handleSupportButtonClick}
              data-test-id={ecmSelectors.supportButtonSelector.testId}
            >
              Support
            </SMenuOption>
          </SMenu>
        }
      >
        <StyledIconWrapper>
          <IconButton
            inline
            size="large"
            icon={{ name: 'question-circle' }}
            color={theme.text.color.light}
          />
        </StyledIconWrapper>
      </StyledPopover>
      {isLoggedIn && (
        <SDropdownButtonWrapper>
          <DropdownButton
            withBorder={false}
            options={options}
            testId={ecmSelectors.accountMenuTriggerSelector.testId}
          >
            <ProfileDropdownButton userGivenAndFamilyName={userFullName} />
          </DropdownButton>
        </SDropdownButtonWrapper>
      )}
      <SupportRequestModal />
    </SAccountMenuWrapper>
  );
};

export default connect(undefined, mapDispatchToProps)(AccountMenuComponent);
