import {
  Box,
  DesignSystemProvider,
  Divider,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@cmg/design-system';
import { companyScreenSubHeaderTestId, companyScreenTestId } from '@cmg/e2e-selectors';
import React from 'react';

import ScreenContent from '../../../shared/layout/ScreenContent';
import ScreenHeader from '../../../shared/layout/ScreenHeader';
import ScreenSubHeader from '../../../shared/layout/ScreenSubHeader';
import { CompanyProfileLite_Issuer_FieldsFragment } from '../../graphql/__generated__/CompanyProfile';
import CompanyUpsellModal from '../company-upsell-modal/CompanyUpsellModal';
import UpgradeDatalabAccountRequestModal from '../upgrade-datalab-account-request-modal/UpgradeDatalabAccountRequestModal';

type Props = {
  company?: CompanyProfileLite_Issuer_FieldsFragment | null;
};

const BlurredSkeleton = () => (
  <Skeleton
    variant="rectangular"
    animation={false}
    sx={theme => ({
      filter: 'blur(5px)',
      marginBottom: theme.spacing(2.25),
      backgroundColor: theme.palette.grey[300],
    })}
  />
);

type BlurredSkeletonGridProps = {
  rows: number;
  columns: number;
};

const BlurredSkeletonGrid: React.FC<BlurredSkeletonGridProps> = ({ rows, columns }) => (
  <Grid container spacing={1}>
    {Array.from({ length: columns }).map((_, index) => (
      <Grid item xs={12 / columns} key={index}>
        {Array.from({ length: rows }).map((_, index) => (
          <BlurredSkeleton key={index} />
        ))}
      </Grid>
    ))}
  </Grid>
);

type SectionProps = {
  title: string;
};

const Section: React.FC<SectionProps> = ({ title }) => (
  <Box paddingLeft={4} paddingRight={4} paddingTop={2}>
    <Divider />
    <Typography
      marginTop={theme => theme.spacing(1.5)}
      marginBottom={theme => theme.spacing(2)}
      variant="h4"
      color={theme => theme.palette.text.disabled}
    >
      {title}
    </Typography>
    <BlurredSkeletonGrid rows={3} columns={6} />
  </Box>
);

const ComapnyDatalabUpsellScreen: React.FC<Props> = ({ company }) => {
  const [showSupportRequestModal, setShowSupportRequestModal] = React.useState(false);

  return (
    <DesignSystemProvider>
      <Box data-test-id={companyScreenTestId.testId}>
        <ScreenHeader />
        <ScreenContent
          renderSubHeader={() =>
            company && (
              <ScreenSubHeader
                title={
                  <Box data-test-id={companyScreenSubHeaderTestId.testId}>
                    {company.name} <span className="ticker">({company.primarySymbol})</span>
                  </Box>
                }
              />
            )
          }
        >
          <UpgradeDatalabAccountRequestModal
            handleHide={() => {
              setShowSupportRequestModal(false);
            }}
            show={showSupportRequestModal}
          />
          <CompanyUpsellModal onContactSalesClick={() => setShowSupportRequestModal(true)} />
          <Box bgcolor={theme => theme.palette.background.paper} minHeight="83vh">
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <Divider />
                <Tabs
                  TabIndicatorProps={{
                    sx: (theme: any) => ({
                      backgroundColor: theme.palette.grey[300],
                    }),
                  }}
                  sx={theme => ({
                    boxShadow: '0px -1px 0px 0px #0000001F inset',
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(2),
                    marginLeft: theme.spacing(4),
                    marginRight: theme.spacing(4),
                  })}
                  indicatorColor="secondary"
                  value="PRICE_AND_PERFORMANCE"
                >
                  <Tab
                    label="Price & Performance"
                    value="PRICE_AND_PERFORMANCE"
                    disabled
                    sx={{ paddingLeft: 0 }}
                  />
                  <Tab label="Company Profile" value="COMPANY_PROFILE" disabled />
                </Tabs>
                <Box paddingLeft={4} paddingRight={4} paddingTop={2}>
                  <BlurredSkeletonGrid rows={6} columns={2} />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Section title="Summary" />
              </Grid>

              <Grid item xs={12}>
                <Section title="ATM Summary" />
              </Grid>
            </Grid>
          </Box>
        </ScreenContent>
      </Box>
    </DesignSystemProvider>
  );
};

export default ComapnyDatalabUpsellScreen;
