import { apiTypes, Option, UnpackPromise } from '@cmg/common';

import { SponsorOption } from '../../../types/domain/firm/sponsorOptions';
import {
  makeFetchListRequest,
  makeParameterlessFetchEntityRequest,
} from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';

export type SponsorOptionsQuery = {
  sponsorIds?: string[];
  searchText?: string;
  page: number;
  perPage?: number;
  includeTotals?: boolean;
};

export const searchSponsorOptions = makeFetchListRequest<
  apiTypes.PaginatedResponse<SponsorOption[]>,
  SponsorOptionsQuery
>(dlgwApiClient, '/filterOptions/sponsors');

export type FetchSponsorOptionsResponse = UnpackPromise<ReturnType<typeof searchSponsorOptions>>;

export const fetchManagerOptions = makeParameterlessFetchEntityRequest<Option[]>(
  dlgwApiClient,
  '/filterOptions/managers'
);

export type FetchManagerOptionsResponse = UnpackPromise<ReturnType<typeof fetchManagerOptions>>;

export const fetchAdvisorOptions = makeParameterlessFetchEntityRequest<Option[]>(
  dlgwApiClient,
  '/filterOptions/advisors'
);

export type FetchAdvisorOptionsResponse = UnpackPromise<ReturnType<typeof fetchAdvisorOptions>>;
