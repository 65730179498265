import React from 'react';

import { SupportRequestModalComponent } from '../../../shared/layout/components/support-request-modal/SupportRequestModal';

type Props = {
  handleHide: () => void;
  show: boolean;
};

const UpgradeDatalabAccountRequestModal: React.FC<Props> = ({ handleHide, show }) => {
  return (
    <SupportRequestModalComponent
      handleDestroy={() => {}}
      handleHide={handleHide}
      show={show}
      title="Upgrade account Subscription"
      category="DATA_INQUIRY"
      subject="Upgrade account Subscription"
    />
  );
};

export default UpgradeDatalabAccountRequestModal;
