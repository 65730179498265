import {
  Alert,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@cmg/design-system';
import React from 'react';

import {
  gridStyle,
  StyledGrid,
  StyledTableContainer,
  StyledTableHeader,
} from '../../../../../common/components/table/table.styles';
import { Atm_Attributes_FieldsFragment } from '../../../../../common/graphql/__generated__/commonFragments';
import { Currency, TermType } from '../../../../../graphql/__generated__';
import { useGetAtmSellDownTableModel } from '../sell-down/AtmSellDownReport.model';
import {
  AnnouncedTableRow,
  RevisedTableRow,
  SelldownTableRow,
  SmallAnnouncedRow,
  SmallRevisedRow,
  SmallSelldownRow,
  SmallTotalRow,
  TotalTableRow,
} from './BlurTableRows';

export type Term = {
  type?: TermType | null;
  revisedDate?: string | null;
  atmProgramSize?: number | null;
  atmProgramSizeInSecurities?: number | null;
};

export type Props = (Atm_Attributes_FieldsFragment | undefined) & {
  pricingCurrency?: Currency | null;
  isAtmProgramSize: boolean;
  publicFilingDate?: string | null | undefined;
  shrink?: boolean;
  showAlert?: boolean;
  showAttributes?: boolean;
  terms: Array<Term>;
  showRevisions?: boolean;
};

export const AtmBlurTable: React.FC<Props> = ({
  shrink,
  terms,
  showAlert,
  showAttributes,
  isAtmProgramSize,
  pricingCurrency,
  publicFilingDate,
  showRevisions,
}) => {
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down(1200));
  const model = useGetAtmSellDownTableModel({ isAtmProgramSize });

  return (
    <StyledGrid>
      {showAlert && (
        <Alert severity="info" color="info" sx={{ marginBottom: 2 }}>
          Please contact sales@cmgx.io to get access to ATM Selldown Data.
        </Alert>
      )}
      {shrink && isSmallScreen ? (
        <Stack divider={<Divider />}>
          <SmallAnnouncedRow
            showAttributes={showAttributes}
            row={terms.filter(s => s.type === TermType.Initial)[0]}
            pricingCurrency={pricingCurrency}
            isAtmProgramSize={isAtmProgramSize}
            publicFilingDate={publicFilingDate}
          />
          <SmallSelldownRow />
          <SmallSelldownRow />
          {showRevisions &&
            terms
              .filter(s => s.type === TermType.Revised)
              .map(row => (
                <SmallRevisedRow
                  showAttributes={showAttributes}
                  row={row}
                  pricingCurrency={pricingCurrency}
                  isAtmProgramSize={isAtmProgramSize}
                />
              ))}
          <SmallTotalRow />
        </Stack>
      ) : (
        <StyledTableContainer>
          <Grid sx={gridStyle}>
            <Table data-testid="atm-selldown-blur-table" size="medium">
              <TableHead>
                <TableRow color="text.secondary">
                  {model.map((column, index) => (
                    <StyledTableHeader key={index} {...column.headerStyles}>
                      {column.label}
                    </StyledTableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <AnnouncedTableRow
                  publicFilingDate={publicFilingDate}
                  showAttributes={showAttributes}
                  row={terms.filter(s => s.type === TermType.Initial)[0]}
                  pricingCurrency={pricingCurrency}
                  isAtmProgramSize={isAtmProgramSize}
                />
                <SelldownTableRow showAttributes={showAttributes} />
                <SelldownTableRow showAttributes={showAttributes} />
                {showRevisions &&
                  terms
                    .filter(s => s.type === TermType.Revised)
                    .map(row => (
                      <RevisedTableRow
                        showAttributes={showAttributes}
                        row={row}
                        pricingCurrency={pricingCurrency}
                        isAtmProgramSize={isAtmProgramSize}
                      />
                    ))}
                <TotalTableRow showAttributes={showAttributes} />
              </TableBody>
            </Table>
          </Grid>
        </StyledTableContainer>
      )}
    </StyledGrid>
  );
};
