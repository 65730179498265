import { makeFetchEntityRequest, makeUpdateEntityRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import {
  UnderwriterCreditsPayload,
  UnderwriterCreditsSubmitData,
} from './types/UnderwriterCredits';

export const getUnderwriterCredits = makeFetchEntityRequest<UnderwriterCreditsPayload>(
  dlgwApiClient,
  '/offerings/:id/underwriterCredits'
);

export const submitUnderwriterCredits = makeUpdateEntityRequest<
  UnderwriterCreditsSubmitData,
  UnderwriterCreditsPayload
>(dlgwApiClient, '/offerings/:id/underwriterCredits');
