import { Box, numericUtil } from '@cmg/common';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { RadarChart, RadarChartProps } from '../../../../../common/components/charts';
import { barChartColors } from '../../../colors';
import { SChart, SDatalabChart } from '../shared/styled';

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

type Props = {
  chartData?: {
    label: string;
    value: number;
    count: number;
  }[];
  dataType: 'role' | 'sector';
};

type RawItem = {
  r: number;
  count: number;
};

type ChartProps = RadarChartProps<RawItem[]>;

const getChartData = (
  chartData: Props['chartData'] = [],
  dataType: Props['dataType']
): ChartProps['data'] => {
  const color = dataType === 'role' ? barChartColors.lightAlpha : barChartColors.ipoBgAlpha;
  const orderedData = sortBy(chartData, 'label');

  return {
    labels: orderedData.map(item => item.label),
    datasets: [
      {
        label: capitalizeFirstLetter(dataType),
        data: orderedData.map((item): RawItem => ({ r: item.value, count: item.count })),
        fill: true,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: color,
      },
    ],
  };
};

const getChartOptions = (): ChartProps['options'] => {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const raw = tooltipItem.raw as RawItem;

            const countString = ` | Count: ${numericUtil.formatInteger(raw.count)}`;
            return ` ${numericUtil.formatPercents(tooltipItem.parsed.r)}${countString ?? ''}`;
          },
        },
      },
    },
    scales: {
      r: {
        ticks: {
          callback: value =>
            typeof value === 'number' ? numericUtil.formatPercents(value, 1) : value,
        },
      },
    },
  };
};

const WidgetAvgEconomicsRoleSector: React.FC<Props> = ({ dataType, chartData = [] }) => {
  const data = React.useMemo(() => getChartData(chartData, dataType), [chartData, dataType]);
  const options = React.useMemo(() => getChartOptions(), []);

  return (
    <SDatalabChart>
      <Box.Header title={`Average Economics by ${capitalizeFirstLetter(dataType)}`} />

      <Box.Content>
        <SChart>
          <RadarChart data={data} options={options} />
        </SChart>
      </Box.Content>
    </SDatalabChart>
  );
};

export default WidgetAvgEconomicsRoleSector;
