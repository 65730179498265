import { AccountType, useAuth } from '@cmg/auth';

import { OfferingStatus } from '../graphql';
import { type Calendar_OfferingFieldsFragment } from '../graphql';

export const useIsUsFiledAndNotSellSideConsolidatedCalendar = (
  offering: Calendar_OfferingFieldsFragment
): boolean => {
  const { oidcUserAccountType, webAppSettings } = useAuth();
  const ENABLE_CONSOLIDATED_SELL_SIDE_CALENDAR =
    webAppSettings?.ENABLE_CONSOLIDATED_SELL_SIDE_CALENDAR;
  const accountType = oidcUserAccountType as AccountType;
  const isConsolidatedCalendarEnabled = ENABLE_CONSOLIDATED_SELL_SIDE_CALENDAR === 'true';

  const { exchangeCountry, status } = offering.attributes || {};

  const isSellSideAndConsolidatedCalendarEnabled =
    accountType === AccountType.SELL_SIDE && isConsolidatedCalendarEnabled;

  return (
    exchangeCountry === 'US' &&
    status === OfferingStatus.Filed &&
    !isSellSideAndConsolidatedCalendarEnabled
  );
};
