import { ToastManager, UUID } from '@cmg/common';

import { useOfferingProfile_SaveUserNoteMutation } from '../../../graphql/__generated__/OfferingProfile';

type Params = { onCompleted?: () => void; refetchQueries?: string[] };
export default function useSaveUserNoteMutation({ onCompleted, refetchQueries }: Params = {}) {
  const [saveUserNoteMutation, { loading }] = useOfferingProfile_SaveUserNoteMutation({
    refetchQueries,
    onCompleted,
  });

  const saveUserNote = async (offeringId: UUID, note: string | null) => {
    try {
      await saveUserNoteMutation({
        variables: {
          input: {
            offeringId,
            note,
          },
        },
      });
      ToastManager.success(`User note saved.`);
    } catch (error) {
      ToastManager.error(
        `An error has occurred while trying to save your note. Please try again later.`
      );
    }
  };

  return {
    saveUserNote,
    loading,
  };
}
