import { Button, Highlights, Link, Stack } from '@cmg/design-system';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import React from 'react';
import { connect } from 'react-redux';

import routeFactory from '../../../../../common/util/routeFactory';
import { EntityType, OfferingType } from '../../../../../graphql/__generated__/index';
import { selectUserSettings } from '../../../../shared/ducks';
import { OfferingProfile_ListQuery } from '../../graphql/__generated__/OfferingProfile';
import { SectorHeader } from './components/SectorHeader';
import { StyledHighlightsTextItem } from './components/SectorHeader.styles';

const mapStateToProps = state => ({
  userSettings: selectUserSettings(state),
});

export type ChildProps = {
  offeringProfile: OfferingProfile_ListQuery;
  isFollowing?: boolean;
  onToggleFollowOffering?: () => void;
};
export type Props = ChildProps & ReturnType<typeof mapStateToProps>;

const OfferingProfileSubHeader: React.FC<Props> = ({
  offeringProfile,
  isFollowing,
  onToggleFollowOffering,
  userSettings,
}) => {
  const { attributes, issuer, type } = offeringProfile.offeringById ?? {};

  const entityDisplay = React.useMemo(() => {
    const { isSpac } = attributes ?? {};
    const entityTypes = issuer?.entityTypes ? [...issuer?.entityTypes] : [];
    if (isSpac && !issuer?.entityTypes?.includes(EntityType.Spac)) {
      entityTypes.push(EntityType.Spac);
    }
    return entityTypes.length > 0 ? entityTypes.join(', ') : null;
  }, [attributes, issuer?.entityTypes]);

  const title = issuer?.id ? (
    <Link variant="h2" href={routeFactory.companies.getUrlPath({ id: issuer?.id })}>
      {issuer?.name}
    </Link>
  ) : (
    issuer?.name
  );

  const actions = (
    <React.Fragment>
      {issuer?.id && (
        <Button href={routeFactory.companies.getUrlPath({ id: issuer?.id })}>
          View Company Profile
        </Button>
      )}
      <Button
        onClick={onToggleFollowOffering}
        startIcon={
          isFollowing ? (
            <PushPinIcon color="primary" fontSize="small" />
          ) : (
            <PushPinOutlinedIcon color="primary" fontSize="small" />
          )
        }
      >
        {isFollowing ? 'Unfollow' : 'Follow'}
      </Button>
    </React.Fragment>
  );

  if (type === OfferingType.Atm) {
    return (
      <Highlights
        divider={false}
        title={title}
        subtitle={issuer?.primarySymbol?.toUpperCase()}
        titleItems={
          <Stack columnGap={2} direction="row" flexWrap="wrap" alignSelf="flex-end">
            {issuer && <SectorHeader issuer={issuer} userSettings={userSettings} />}
            {entityDisplay && <StyledHighlightsTextItem name="Entity Type" text={entityDisplay} />}
          </Stack>
        }
        actions={
          <React.Fragment>
            {issuer?.id && (
              <Button href={routeFactory.companies.getUrlPath({ id: issuer?.id })}>
                View Company Profile
              </Button>
            )}
          </React.Fragment>
        }
      />
    );
  }

  return (
    <Highlights
      divider={false}
      title={title}
      subtitle={issuer?.primarySymbol?.toUpperCase()}
      titleItems={
        <Stack columnGap={2} direction="row" flexWrap="wrap" alignSelf="flex-end">
          {issuer && <SectorHeader issuer={issuer} userSettings={userSettings} />}
          {entityDisplay && <StyledHighlightsTextItem name="Entity Type" text={entityDisplay} />}
        </Stack>
      }
      actions={actions}
    />
  );
};

const OfferingProfileSubHeaderProvider = connect(mapStateToProps)(OfferingProfileSubHeader);

export default OfferingProfileSubHeaderProvider;
