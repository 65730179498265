import {
  Card,
  Grid,
  KeyValuePair,
  RegularBreakpoints,
  Section,
  Typography,
} from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetAdvisersData } from '../hooks/useGetAdvisersData';
import { cardHeight } from '../OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};
const AdvisersWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const advisers = useGetAdvisersData(offeringProfile);
  const title = 'Advisors';
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          {!advisers.length ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <Grid container direction="column" columnSpacing={2}>
              {advisers.map(({ name, value }) => {
                return (
                  <Grid item key={`${name}-adviser`}>
                    <KeyValuePair key={`${name}_${value}`} label={name} value={value} />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Section>
      </Card>
    </Grid>
  );
};

export default AdvisersWidget;
