import { AuthenticatedSignalRContextProvider } from '@cmg/auth';
import cn from 'classnames';
import { UnregisterCallback } from 'history';
import debounce from 'lodash/debounce';
import React from 'react';
import BodyClassName from 'react-body-classname';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { DATALAB_API_CALL_ID } from '../../api/dlgw';
import { getViewportHeight, getViewportWidth } from '../../common/helpers/device-helpers';
import { isIE, isLandscape, isMobileDevice } from '../../common/helpers/helpers';
import {
  fetchUserSettingsRequest,
  selectUserSettingsLoaded,
  setDeviceViewportDimensions,
} from '../shared/ducks';
import { selectIsInProgress, selectShowSpinner } from '../shared/spinner/ducks';
import ErrorBoundary from './components/ErrorBoundary';
import MainWrapper from './components/MainWrapper';
import withMixpanelLogger, {
  MixpanelLogProps,
} from './components/with-mixpanel/withMixpanelLogger';
import RootRouter from './RootRouter';

export const mapStateToProps = state => ({
  showSpinner: selectShowSpinner(state) && !selectIsInProgress(state, DATALAB_API_CALL_ID),
  userSettingsLoaded: selectUserSettingsLoaded(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setDeviceViewportDimensions,
      fetchUserSettings: fetchUserSettingsRequest,
    },
    dispatch
  ),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  MixpanelLogProps &
  RouteComponentProps;

export class AppComponent extends React.Component<Props> {
  isMobile = isMobileDevice();
  onResizeRef = debounce(() => {
    this.onResize(getViewportWidth(), getViewportHeight());
  }, 200);

  state = {
    showOrientationWarning: false,
  };

  unregisterHistoryListener?: UnregisterCallback;

  componentDidMount() {
    const { history, logMixpanel } = this.props;
    this.unregisterHistoryListener = history.listen((location, action) => {
      logMixpanel('Browser Navigation', {
        path: location.pathname,
        historyAction: action,
      });
    });

    this.onResize(getViewportWidth(), getViewportHeight());
    window.addEventListener('resize', this.onResizeRef);
  }

  componentWillUnmount() {
    this.unregisterHistoryListener?.();
    window.removeEventListener('resize', this.onResizeRef);
  }

  componentDidUpdate() {
    // Moved from Router onUpdate callback prop
    window.scrollTo(0, 0);
  }

  onResize = (width: number, height: number) => {
    if (this.isMobile) {
      this.setState({
        showOrientationWarning: !isLandscape(),
      });
    }
    this.props.actions.setDeviceViewportDimensions({ width, height });
  };

  render() {
    const { showSpinner, actions, userSettingsLoaded } = this.props;
    const { showOrientationWarning } = this.state;

    const className = cn({
      ie: isIE(),
      'is-mobile-device': this.isMobile,
    });

    return (
      <BodyClassName className={className}>
        <ErrorBoundary>
          <AuthenticatedSignalRContextProvider>
            <MainWrapper
              showOrientationWarning={showOrientationWarning}
              defaultCollapsed={this.isMobile}
              showSpinner={showSpinner}
              onFetchUserSettings={actions.fetchUserSettings}
              userSettingsLoaded={userSettingsLoaded}
            >
              <RootRouter />
            </MainWrapper>
          </AuthenticatedSignalRContextProvider>
        </ErrorBoundary>
      </BodyClassName>
    );
  }
}

const AppComponentWithMixpanelLogger = withMixpanelLogger(AppComponent);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppComponentWithMixpanelLogger)
);
