import { numericUtil } from '@cmg/common';

import {
  getCurrencyFormatInMillions,
  getFormattedCurrencyValue,
  getFormattedPercentageValue,
} from '../../../shared/model/utils';
import { percentagePerformanceFormatter } from './OfferingsReportTable.model';

export const aggregationMapping: {
  [colId: string]: { fieldName: string; formatter: (value: number) => string | JSX.Element };
} = {
  convertibleAttributes_callPrice: {
    fieldName: 'callPrice',
    formatter: value => getFormattedPercentageValue(value),
  },
  finalTerm_conversionPrice: {
    fieldName: 'conversionPrice',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  finalTerm_conversionRatio: {
    fieldName: 'conversionRatio',
    formatter: value => numericUtil.getDisplayValueForNumber(value),
  },
  finalTerm_conversionReferencePrice: {
    fieldName: 'conversionReferencePrice',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  finalTerm_pctOfferPrice: {
    fieldName: 'pctOfferPrice',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_priceUsd: {
    fieldName: 'priceUsd',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  finalTerm_couponPercentage: {
    fieldName: 'couponPercentage',
    formatter: value => getFormattedPercentageValue(value, 3),
  },
  finalTerm_premiumPercentage: {
    fieldName: 'premiumPercentage',
    formatter: value => getFormattedPercentageValue(value),
  },
  finalTerm_aggregatePrincipalAmount: {
    fieldName: 'aggregatePrincipalAmount',
    formatter: value => getCurrencyFormatInMillions({ value, pricingCurrencyCode: 'USD' }) ?? '-',
  },
  finalTerm_aggregatePrincipalInclOverallotmentExercised: {
    fieldName: 'aggregatePrincipalInclOverallotmentExercised',
    formatter: value => getCurrencyFormatInMillions({ value, pricingCurrencyCode: 'USD' }) ?? '-',
  },
  finalTerm_principalAmountPerNote: {
    fieldName: 'principalAmountPerNote',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  convertibleAttributes_tenor: {
    fieldName: 'tenor',
    formatter: value => numericUtil.getDisplayValueForNumber(value),
  },
  attributes_pctGrossSpread: {
    fieldName: 'pctGrossSpread',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_pctOfferToOpen: {
    fieldName: 'pctOfferToOpen',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo1Day: {
    fieldName: 'pctOfferTo1Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo3Day: {
    fieldName: 'pctOfferTo3Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo7Day: {
    fieldName: 'pctOfferTo7Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo14Day: {
    fieldName: 'pctOfferTo14Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo30Day: {
    fieldName: 'pctOfferTo30Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo90Day: {
    fieldName: 'pctOfferTo90Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo180Day: {
    fieldName: 'pctOfferTo180Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferToCurrent: {
    fieldName: 'pctOfferToCurrent',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_netPrice: {
    fieldName: 'netPrice',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  attributes_latestSizeInSecuritiesBase: {
    fieldName: 'latestSizeInSecuritiesBase',
    formatter: value => numericUtil.formatInteger(value),
  },
  attributes_latestPctSecondaryShares: {
    fieldName: 'latestPctSecondaryShares',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_latestGrossProceedsBaseUsd: {
    fieldName: 'latestGrossProceedsBaseUsd',
    formatter: value => getCurrencyFormatInMillions({ value, pricingCurrencyCode: 'USD' }) ?? '-',
  },
  attributes_latestGrossProceedsTotalUsd: {
    fieldName: 'latestGrossProceedsTotalUsd',
    formatter: value => getCurrencyFormatInMillions({ value, pricingCurrencyCode: 'USD' }) ?? '-',
  },
  attributes_marketCapAtPricingUsd: {
    fieldName: 'marketCapAtPricingUsd',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  attributes_marketCapPreOfferingUsd: {
    fieldName: 'marketCapPreOfferingUsd',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  attributes_latestPostOfferingShares: {
    fieldName: 'latestPostOfferingShares',
    formatter: value => numericUtil.formatInteger(value),
  },
  attributes_preOfferingShares: {
    fieldName: 'preOfferingShares',
    formatter: value => numericUtil.formatInteger(value),
  },
  attributes_pctMarketCapAtPricing: {
    fieldName: 'pctMarketCapAtPricing',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_pctMarketCapPreOffering: {
    fieldName: 'pctMarketCapPreOffering',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_sizeAsMultipleOfAdtv: {
    fieldName: 'sizeAsMultipleOfAdtv',
    formatter: value => numericUtil.formatMultipleFactor(value),
  },
  attributes_pctFileToOffer: {
    fieldName: 'pctFileToOffer',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_pctToLastTrade: {
    fieldName: 'pctToLastTrade',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_pctToVwap: {
    fieldName: 'pctToVwap',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_pctTo52WeekHigh: {
    fieldName: 'pctTo52WeekHigh',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_grossSpreadTotalUsd: {
    fieldName: 'grossSpreadTotalUsd',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  attributes_allInCost: {
    fieldName: 'allInCost',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_estimatedFeeUsd: {
    fieldName: 'estimatedFeeUsd',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  attributes_initialIndicativeGrossProceedsUsd: {
    fieldName: 'initialIndicativeGrossProceedsUsd',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  attributes_pctVsMidpoint: {
    fieldName: 'pctVsMidpoint',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_pctChangeInSize: {
    fieldName: 'pctChangeInSize',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_reOfferLowUsd: {
    fieldName: 'reOfferLowUsd',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  attributes_reOfferHighUsd: {
    fieldName: 'reOfferHighUsd',
    formatter: value =>
      getFormattedCurrencyValue({ value, pricingCurrencyCode: 'USD', decimal: 2 }),
  },
  attributes_pctReOfferLow: {
    fieldName: 'pctReOfferLow',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_pctReOfferHigh: {
    fieldName: 'pctReOfferHigh',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_firstDayTurnover: {
    fieldName: 'firstDayTurnover',
    formatter: value => numericUtil.formatMultipleFactor(value),
  },
  attributes_pctOfferToOneYear: {
    fieldName: 'pctOfferToOneYear',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferToPriorQuarter: {
    fieldName: 'pctOfferToPriorQuarter',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_offerToVwap1Day: {
    fieldName: 'offerToVwap1Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_totalManagers: {
    fieldName: 'totalManagers',
    formatter: value => numericUtil.formatInteger(value),
  },
  attributes_totalBookrunners: {
    fieldName: 'totalBookrunners',
    formatter: value => numericUtil.formatInteger(value),
  },
  attributes_totalNonBookrunners: {
    fieldName: 'totalNonBookrunners',
    formatter: value => numericUtil.formatInteger(value),
  },
  attributes_totalPctToBookrunners: {
    fieldName: 'totalPctToBookrunners',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_totalPctToNonBookrunners: {
    fieldName: 'totalPctToNonBookrunners',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_totalPctToLeftLead: {
    fieldName: 'totalPctToLeftLead',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_lockUpPeriod: {
    fieldName: 'lockUpPeriod',
    formatter: value => numericUtil.formatInteger(value),
  },
};
