import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import upsellImage from '../../../../assets/calendar-upsell.svg';
import routeFactory from '../../../../common/util/routeFactory';
import UpsellFeature from './UpsellFeature';

type Props = {
  onContactSalesClick: () => void;
};

const transformSx = {
  transform: 'translateX(-50%)',
};

const CompanyUpsellModal: React.FC<Props> = ({ onContactSalesClick }) => {
  return (
    <Box
      position="absolute"
      top="10%"
      left="50%"
      sx={transformSx}
      width="90%"
      maxWidth={theme => theme.spacing(112.5)}
      zIndex={theme => theme.zIndex.appBar}
    >
      <Paper elevation={2} width="100%" margin="0 auto" component={Box}>
        <DialogTitle textTransform="capitalize">
          Upgrade account Subscription to view the company profile
        </DialogTitle>
        <DialogContent dividers>
          <Alert severity="warning">
            To discuss adding company data to your DataLab account subscription, contact
            sales@cmgx.io
          </Alert>
          <Grid
            container
            marginTop={theme => theme.spacing(4)}
            marginBottom={theme => theme.spacing(4)}
          >
            <Grid xs={6} item paddingRight={theme => theme.spacing(11)}>
              <Typography variant="h3" color={theme => theme.palette.text.secondary}>
                Gain access to an issuer's:
              </Typography>
              <UpsellFeature
                title="Price & Performance"
                description="Detailed pricing data and performance metrics"
              />
              <UpsellFeature
                title="Profile"
                description="Detailed company information including exchange, share class, sector, and business profile"
              />
              <UpsellFeature
                title="Offering Summary"
                description="Listing of all public transactions, including at-the-market (ATM) offerings"
              />
            </Grid>
            <Grid xs={6} item>
              <img width="100%" src={upsellImage} alt="Upsell Illustration" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button href={routeFactory.calendar.getUrlPath()} variant="outlined">
            Go To Calendar
          </Button>
          <Button variant="contained" color="primary" onClick={onContactSalesClick}>
            Contact Sales
          </Button>
        </DialogActions>
      </Paper>
    </Box>
  );
};

export default CompanyUpsellModal;
