import styled from 'styled-components/macro';

interface LoaderOverlayProps {
  backgroundColor?: string;
}

export const LoaderOverlay = styled.div<LoaderOverlayProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.backgroundColor || 'rgba(0, 0, 0, 0.2)'};
`;

export const LoaderMessage = styled.div`
  max-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 100px;
`;
