import { getOperationName } from '@apollo/client/utilities';
import { useAuth } from '@cmg/auth';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { checkIsFollowingOffering } from '../../../../shared/follow-offering/checkIsFollowingOffering';
import useFollowOfferingMutation from '../../../../shared/follow-offering/useFollowOfferingMutation';
import {
  OfferingProfile_LiteDocument,
  useOfferingProfile_LiteQuery,
} from '../../graphql/__generated__/OfferingProfile';
import useSaveUserNoteMutation from './hooks/useSaveUserNoteMutation';
import OfferingProfileScreen from './OfferingProfileScreen';

type RouterProps = RouteComponentProps<{ offeringId: string }>;

type Props = RouterProps;

export const OfferingProfileContainer: React.FC<Props> = ({ match }) => {
  const { offeringId } = match.params;

  const { oidcUserId, userPermissions } = useAuth();

  const documents = [getOperationName(OfferingProfile_LiteDocument)!];

  const { saveUserNote, loading } = useSaveUserNoteMutation({
    refetchQueries: documents,
  });

  const { setFollowingOffering } = useFollowOfferingMutation({
    refetchQueries: documents,
  });

  const { data: offeringProfileLite, error } = useOfferingProfile_LiteQuery({
    variables: { offeringId },
    fetchPolicy: 'no-cache',
  });

  const onSaveUserNote = async (note: string | null) => {
    await saveUserNote(offeringId, note);
  };

  const onToggleFollowOffering = async (follow: boolean) => {
    await setFollowingOffering(offeringId, follow);
  };

  const offering = offeringProfileLite?.offeringById;

  if (!offering && !error) {
    return null;
  }

  const userNote = offering?.offeringNotes?.[0]?.note ?? null;

  return (
    <OfferingProfileScreen
      offeringId={offeringId}
      offeringType={offering?.type!}
      userNote={userNote}
      onSaveUserNote={onSaveUserNote}
      isSavingUserNote={loading}
      isFollowing={checkIsFollowingOffering(offering!, oidcUserId!, userPermissions)}
      onToggleFollowOffering={() =>
        onToggleFollowOffering(!checkIsFollowingOffering(offering!, oidcUserId!, userPermissions))
      }
    />
  );
};

export default OfferingProfileContainer;
