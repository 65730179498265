import { numericUtil } from '@cmg/common';
import {
  Box,
  Card,
  Grid,
  KeyValuePair,
  RegularBreakpoints,
  Section,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
  useTheme,
} from '@cmg/design-system';

import { OfferingType } from '../../../../../../graphql/__generated__';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { cardHeight } from '../../shared/OfferingProfileCardItem';
import { useGetPerformanceData } from '../hooks/useGetPerformanceData';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const tableSectionSizes: RegularBreakpoints = { xl: 6, lg: 6, md: 12, sm: 12 };

const PerformanceWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const { firstDayVolume, firstDayTurnover } = offeringProfile.offeringById?.attributes ?? {};

  const isLargeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const performance = useGetPerformanceData(offeringProfile);
  const title = 'Performance';
  const isAtm = offeringProfile?.offeringById?.type === OfferingType.Atm;

  const theme = useTheme();

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          {!isAtm && (
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Box
                width={theme.spacing(36.25)} // 290px
                height={theme.spacing(3.5)} // 28px
                marginRight={theme.spacing(2.5)} // 20px
                flexShrink={0}
              >
                <KeyValuePair
                  minWidth="xsmall"
                  label="First Day Volume"
                  value={
                    firstDayVolume ? numericUtil.getDisplayValueForInteger(firstDayVolume) : '-'
                  }
                />
              </Box>
              <Box
                width={theme.spacing(36.25)} // 290px
                height={theme.spacing(3.5)} // 28px
                flexShrink={0}
              >
                <KeyValuePair
                  minWidth="xsmall"
                  label="First Day Turnover"
                  value={
                    firstDayTurnover
                      ? `${numericUtil.getDisplayValueForNumber(firstDayTurnover, 1)}x`
                      : '-'
                  }
                />
              </Box>
            </Stack>
          )}
          <TableContainer>
            <Grid container columnSpacing={3}>
              <Grid item {...tableSectionSizes}>
                <Table size="medium">
                  <TableHead>
                    <TableRow color="text.secondary">
                      <TableCell></TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">%</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {performance.slice(0, isAtm ? 5 : 6).map(({ name, value, value2 }) => {
                      return (
                        <TableRow key={name}>
                          <TableCell width="33%">{name}</TableCell>
                          <TableCell align="right" width="33%">
                            {value}
                          </TableCell>
                          <TableCell align="right" width="33%">
                            {value2}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item {...tableSectionSizes}>
                <Table size="medium">
                  {isLargeScreen && (
                    <TableHead>
                      <TableRow color="text.secondary">
                        <TableCell></TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">%</TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {performance.slice(isAtm ? 5 : 6).map(({ name, value, value2 }) => {
                      return (
                        <TableRow key={name}>
                          <TableCell width="33%">{name}</TableCell>
                          <TableCell align="right" width="33%">
                            {value}
                          </TableCell>
                          <TableCell align="right" width="33%">
                            {value2}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </TableContainer>
        </Section>
      </Card>
    </Grid>
  );
};

export default PerformanceWidget;
