export enum IoiType {
  // Values returned for new datalab gateway endpoints. These will need to be updated to screaming snake case when merged back to dev :(
  NORMAL = 'NORMAL', // not sure what this is for, but we don't use it
  NO_ACCESS = 'NO_ACCESS', // we use user's permissions to make ioi access control determinations
  PASS = 'PASS', // offering has been passed on
  REG_M = 'REG_M', // offering has been passed on due to reg m conflict
  NOT_INDICATED = 'NOT_INDICATED',
  // Legacy IOI types from ruby endpoints that have not been transitioned to gateway api
  /** @deprecated */
  LEGACY_PASS = 'pass',
  /** @deprecated */
  LEGACY_REG_M = 'reg-m',
}

export const passTypeLabels = {
  [IoiType.PASS]: 'Pass',
  [IoiType.REG_M]: 'Reg-M Conflict',
  [IoiType.LEGACY_PASS]: 'Pass',
  [IoiType.LEGACY_REG_M]: 'Reg-M Conflict',
};
