import { Box, InfoOutlinedIcon, Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

interface TableHeaderTooltipProps {
  tooltip: string;
}

const TableHeaderTooltip: React.FC<TableHeaderTooltipProps> = ({ tooltip }) => {
  return (
    <Box
      display="flex"
      marginTop={theme => theme.spacing(0)}
      marginLeft={theme => theme.spacing(0.5)}
    >
      <Tooltip
        title={<Typography variant="tooltip">{tooltip}</Typography>}
        placement="top-start"
        variant="info"
      >
        <InfoOutlinedIcon aria-label="info-icon" fontSize="inherit" color="action" />
      </Tooltip>
    </Box>
  );
};

export default TableHeaderTooltip;
