import { Box, Icon, LinkButton, timeUtil } from '@cmg/common';
import React, { useState } from 'react';

import { Pill } from '../../../../../obsolete/components/ui';
import { UUID } from '../../../../../types/common';
import {
  OfferingNote,
  OfferingNoteCreate,
  OfferingNoteUpdate,
} from '../../../../../types/domain/offering/offering-note';
import OfferingNoteModal from './OfferingNoteModal';
import {
  SNoteContent,
  SNoteFooter,
  SNoteHeader,
  SPillContainer,
  SWrapper,
} from './OfferingNotes.styles';

export type Props = {
  offeringId: UUID;
  offeringNotes: OfferingNote[];
  createOfferingNote: (args: { offeringId: UUID; offeringNote: OfferingNoteCreate }) => void;
  updateOfferingNote: (args: {
    offeringId: UUID;
    noteId: UUID;
    offeringNote: OfferingNoteUpdate;
  }) => void;
  deleteOfferingNote: (args: { offeringId: UUID; noteId: UUID }) => void;
};

const OfferingNotes: React.FC<Props> = ({
  offeringId,
  offeringNotes,
  createOfferingNote,
  updateOfferingNote,
  deleteOfferingNote,
}) => {
  const [activeOfferingNote, setActiveOfferingNote] = useState<OfferingNote | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setActiveOfferingNote(undefined);
    setShowModal(false);
  };

  const onOpen = () => {
    setShowModal(true);
  };

  const onEdit = (offeringNote: OfferingNote) => {
    setActiveOfferingNote(offeringNote);
    setShowModal(true);
  };

  const onUpdate = (offeringNote: OfferingNoteUpdate) => {
    updateOfferingNote({
      offeringId,
      noteId: offeringNote.id,
      offeringNote,
    });
    onClose();
  };

  const onCreate = (offeringNote: OfferingNoteCreate) => {
    createOfferingNote({ offeringId, offeringNote });
    onClose();
  };

  const onDelete = () => {
    if (activeOfferingNote) {
      deleteOfferingNote({
        offeringId,
        noteId: activeOfferingNote.id,
      });
    }
    onClose();
  };

  return (
    <Box>
      <SWrapper>
        <OfferingNoteModal
          onHide={onClose}
          onDelete={onDelete}
          onCreate={onCreate}
          onUpdate={onUpdate}
          offeringNote={activeOfferingNote}
          show={showModal}
        />

        <Box.Header title="Notes">
          <LinkButton size="small" onClick={onOpen}>
            <Icon size="lg" name="plus" title="Add Note" />
          </LinkButton>
        </Box.Header>

        <Box.Content>
          <ul>
            {offeringNotes.map(offeringNote => (
              <li key={offeringNote.id}>
                <SNoteHeader>
                  <SPillContainer>
                    <Pill type="primary" uppercase>
                      {offeringNote.subject}
                    </Pill>
                  </SPillContainer>
                  <LinkButton size="small" inline onClick={() => onEdit(offeringNote)}>
                    <Icon name="edit" size="lg" title="Edit Note" />
                  </LinkButton>
                </SNoteHeader>
                <SNoteContent>{offeringNote.note}</SNoteContent>
                <SNoteFooter>
                  {`Last saved by ${offeringNote.lastEditedBy} ${timeUtil.formatAsDisplayDateTime(
                    offeringNote.lastEditedAt
                  )}`}
                </SNoteFooter>
              </li>
            ))}
          </ul>
        </Box.Content>
      </SWrapper>
    </Box>
  );
};

export default OfferingNotes;
