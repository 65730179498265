import { Option } from '@cmg/common';
import { datalabScreenLoadingIndicator, screenSwitch } from '@cmg/e2e-selectors';
import React, { memo, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { dispatchBrowserResizeEvent } from '../../../common/helpers/browser-events';
import { useDecisionShowLiveAndFiled } from '../../../common/hooks/useDecisionShowLiveAndFiled';
import routeFactory from '../../../common/util/routeFactory';
import { SponsorOption } from '../../../types/domain/firm/sponsorOptions';
import Toggler from '../../shared/layout/components/Toggler';
import ScreenContent from '../../shared/layout/ScreenContent';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import Spinner from '../../shared/spinner/Spinner';
import { DatalabScreens, DatalabScreenViewTypes, datalabSections } from '../constants';
import { DownloadReportProps } from '../containers/DatalabContainer';
import { hasToggler } from '../model/datalab.model';
import { getOfferingTypeConfig } from '../model/filters.model';
import { TableConfigType } from '../model/table.model';
import { DatalabFilterValues, FilterSettings } from '../types';
import DatalabTable from './DatalabTable';
import DatalabFilters from './filter/DatalabFilters';
import AllReportsModal from './modals/AllReportsModal';
import ReportDetailsModal from './modals/ReportDetailsModal';
import CapitalRaisedMatrixScreen from './screens/CapitalRaisedMatrixScreen';
import FollowOnPricingScreen from './screens/FollowOnPricingScreen';
import FollowOnStructure from './screens/FollowOnStructureScreen';
import IoiParticipationScreen from './screens/IoiParticipationScreen';
import IpoPricingScreen from './screens/IpoPricingScreen';
import IpoStructureScreen from './screens/IpoStructureScreen';
import MarketPulseScreen from './screens/MarketPulseScreen';
import UnderwriterProfileSellSideScreen from './screens/UnderwriterProfileSellSideScreen';
import UnderwritingTermsScreen from './screens/UnderwritingTermsScreen';
import DatalabToolbar from './toolbar/DatalabToolbar';

const STableWrapper = styled.div`
  margin: 14px 14px 0;
  flex: 1 1 auto;
  height: 100%;
`;
export const SDatalabWrapper = styled.div`
  height: 100%;
`;

type OwnProps = {
  screen: DatalabScreens;
  screenType: DatalabScreenViewTypes;
  advisoryOptions: Option[];
  fundsOptions: Option[];
  sponsorOptions: Option[];
  handleSponsorSearch: (input: string) => Promise<SponsorOption[]>;
  filterSettings: FilterSettings;
  filterDefaultValues: DatalabFilterValues;
  underwriterOptions: Option[];
  spacOptions: Option[];
  onFilterChange: (values: DatalabFilterValues) => void;
  onTogglerChanged: (value) => void;
  onQuickFilterChange: (values: Partial<DatalabFilterValues>) => void;
  onDownload: (props: DownloadReportProps) => void;
  onPaginationOrOrderByChange: ({ pagination, order }: { pagination: any; order: any }) => void;
  isLoading: boolean;
  tableConfig: TableConfigType;
  activeFilterCount: number;
};

export const DatalabScreenComponent: React.FC<OwnProps> = props => {
  const [isFilterPanelExpanded, setIsFilterPanelExpanded] = useState(false);
  const handleToggleFilters = () => setIsFilterPanelExpanded(!isFilterPanelExpanded);
  useEffect(() => {
    dispatchBrowserResizeEvent();
  }, [isFilterPanelExpanded]);
  const showLiveAndFiled = useDecisionShowLiveAndFiled();

  const {
    isLoading,
    screen,
    screenType,
    advisoryOptions,
    fundsOptions,
    sponsorOptions,
    handleSponsorSearch,
    underwriterOptions,
    spacOptions,
    filterSettings,
    filterDefaultValues,
    onQuickFilterChange,
    onFilterChange,
    onDownload,
    onPaginationOrOrderByChange,
    activeFilterCount,
    onTogglerChanged,
    tableConfig,
  } = props;

  const tableProps = {
    tableConfig,
    onQuickFilterChange,
    onDownload,
    onReload: onPaginationOrOrderByChange,
    isFilterPanelExpanded,
  };

  const redirectToDatalabMarketPulseChart = () => {
    return (
      <Redirect
        to={{
          pathname: routeFactory.datalabMarketPulseChart.routePath,
          state: { isRedirectedFromIPOBacklog: true },
        }}
      />
    );
  };

  return (
    <div>
      <ScreenHeader
        renderContent={() =>
          hasToggler(screen) && (
            <Toggler
              activeButton={screenType === 'table' ? datalabSections.table : datalabSections.chart}
              onChange={activeToggler => {
                onTogglerChanged(activeToggler.value);
              }}
              buttons={Object.values(datalabSections)}
              testId={screenSwitch.testId}
            />
          )
        }
      />
      <ScreenContent>
        <SDatalabWrapper>
          <DatalabToolbar
            screen={screen}
            screenType={screenType}
            isFilterPanelExpanded={isFilterPanelExpanded}
            toggleFilters={handleToggleFilters}
            filterCount={activeFilterCount}
            tableConfig={tableConfig}
          />
          <DatalabFilters
            filterCount={activeFilterCount}
            filterDefaultValues={filterDefaultValues}
            advisoryOptions={advisoryOptions}
            fundsOptions={fundsOptions}
            sponsorOptions={sponsorOptions}
            handleSponsorSearch={handleSponsorSearch}
            underwriterOptions={underwriterOptions}
            spacOptions={spacOptions}
            filterSettings={filterSettings}
            onFilterChange={onFilterChange}
            isFilterPanelExpanded={isFilterPanelExpanded}
            offeringTypesDefaultValues={getOfferingTypeConfig(screen).defaultSelectedOption}
          />
          <Spinner show={isLoading} testId={datalabScreenLoadingIndicator.testId}>
            <STableWrapper>
              <Switch>
                {/* U.S. ECM Chart */}
                <Route
                  path={routeFactory.datalabMarketPulseChart.routePath}
                  component={MarketPulseScreen}
                />
                {/* U.S. ECM Table */}
                <Route path={routeFactory.datalabMarketPulseTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.MARKET_PULSE} />
                </Route>
                {/* U.S. ECM Redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.MARKET_PULSE,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect to={routeFactory.datalabMarketPulseChart.routePath + search} />
                    );
                  }}
                />

                {/* U.S. ECM Matrix */}
                <Route exact path={routeFactory.datalabCapitalRaisedMatrixChart.routePath}>
                  <CapitalRaisedMatrixScreen
                    staticColumns={tableConfig.staticColumns}
                    columnsConfig={tableConfig.columnsConfig}
                    downloadExport={onDownload}
                    filtersCollapsed={false}
                    orderBy={tableConfig.orderBy}
                    orderByType={tableConfig.orderByType}
                    title={tableConfig.title}
                  />
                </Route>
                {/* U.S. ECM Matrix redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.CAPITAL_RAISED_MATRIX,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={routeFactory.datalabCapitalRaisedMatrixChart.routePath + search}
                      />
                    );
                  }}
                />

                {/* IPO Structure Chart */}
                <Route
                  path={routeFactory.datalabIpoStructureChart.routePath}
                  component={IpoStructureScreen}
                />
                {/* IPO Structure Table */}
                <Route path={routeFactory.datalabIpoStructureTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.IPO_STRUCTURE} />
                </Route>
                {/* IPO Structure redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.IPO_STRUCTURE,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect to={routeFactory.datalabIpoStructureChart.routePath + search} />
                    );
                  }}
                />

                {/* IPO Pricing chart */}
                <Route
                  path={routeFactory.datalabIpoPricingChart.routePath}
                  component={IpoPricingScreen}
                />
                {/* IPO Pricing table */}
                <Route path={routeFactory.datalabIpoPricingTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.IPO_PRICING} />
                </Route>
                {/* IPO Pricing redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.IPO_PRICING,
                  })}
                  render={({ location: { search } }) => {
                    return <Redirect to={routeFactory.datalabIpoPricingChart.routePath + search} />;
                  }}
                />

                {/* IPO Backlog table */}
                <Route path={routeFactory.datalabIpoBacklogTable.routePath}>
                  {showLiveAndFiled ? (
                    <DatalabTable {...tableProps} screen={DatalabScreens.IPO_BACKLOG} />
                  ) : (
                    redirectToDatalabMarketPulseChart()
                  )}
                </Route>
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.IPO_BACKLOG,
                  })}
                  render={({ location: { search } }) => {
                    return showLiveAndFiled ? (
                      <Redirect to={routeFactory.datalabIpoBacklogTable.routePath + search} />
                    ) : (
                      redirectToDatalabMarketPulseChart()
                    );
                  }}
                />

                {/* Follow on structure chart */}
                <Route
                  path={routeFactory.datalabFollowOnStructureChart.routePath}
                  component={FollowOnStructure}
                />
                {/* Follow on structure table */}
                <Route path={routeFactory.datalabFollowOnStructureTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.FOLLOW_ON_STRUCTURE} />
                </Route>
                {/* Follow on structure redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.FOLLOW_ON_STRUCTURE,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={routeFactory.datalabFollowOnStructureChart.routePath + search}
                      />
                    );
                  }}
                />

                {/* Follow on pricing chart */}
                <Route
                  path={routeFactory.datalabFollowOnPricingChart.routePath}
                  component={FollowOnPricingScreen}
                />
                {/* Follow on pricing table */}
                <Route path={routeFactory.datalabFollowOnPricingTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.FOLLOW_ON_PRICING} />
                </Route>
                {/* Follow on pricing redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.FOLLOW_ON_PRICING,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect to={routeFactory.datalabFollowOnPricingChart.routePath + search} />
                    );
                  }}
                />

                {/* Underwriting terms chart */}
                <Route
                  path={routeFactory.datalabUnderwritingTermsChart.routePath}
                  component={UnderwritingTermsScreen}
                />
                {/* Underwriting terms table */}
                <Route path={routeFactory.datalabUnderwritingTermsTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.UNDERWRITING_TERMS} />
                </Route>
                {/* Underwriting terms redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.UNDERWRITING_TERMS,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={routeFactory.datalabUnderwritingTermsChart.routePath + search}
                      />
                    );
                  }}
                />

                {/* Underwriter profile chart */}
                <Route
                  path={routeFactory.datalabUnderwriterProfileChart.routePath}
                  component={UnderwriterProfileSellSideScreen}
                />
                {/* Underwriter profile table */}
                <Route path={routeFactory.datalabUnderwriterProfileTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.UNDERWRITER_PROFILE_SELL} />
                </Route>
                {/* Underwriting profile redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.UNDERWRITER_PROFILE_SELL,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={routeFactory.datalabUnderwriterProfileChart.routePath + search}
                      />
                    );
                  }}
                />

                {/* Lockup report table */}
                <Route path={routeFactory.datalabLockupExpirationTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.LOCK_UP_EXPIRATION} />
                </Route>
                {/* Lockup report redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.LOCK_UP_EXPIRATION,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect to={routeFactory.datalabLockupExpirationTable.routePath + search} />
                    );
                  }}
                />

                {/* League tables table */}
                <Route path={routeFactory.datalabLeagueTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.LEAGUE_TABLE} />
                </Route>
                {/* League tables redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.LEAGUE_TABLE,
                  })}
                  render={({ location: { search } }) => {
                    return <Redirect to={routeFactory.datalabLeagueTable.routePath + search} />;
                  }}
                />

                {/* Global League table */}
                <Route path={routeFactory.datalabGlobalLeagueTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.GLOBAL_LEAGUE} />
                </Route>
                {/* Global League table redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.GLOBAL_LEAGUE,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect to={routeFactory.datalabGlobalLeagueTable.routePath + search} />
                    );
                  }}
                />

                {/* Aftermarket matrix table */}
                <Route path={routeFactory.datalabAftermarketMatrixTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.AFTERMARKET_MATRIX} />
                </Route>
                {/* Aftermarket matrix redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.AFTERMARKET_MATRIX,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={routeFactory.datalabAftermarketMatrixTable.routePath + search}
                      />
                    );
                  }}
                />

                {/* Sponsor league table */}
                <Route path={routeFactory.datalabSponsorsLeagueTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.SPONSOR_LEAGUE_TABLE} />
                </Route>
                {/* Sponsor league redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.SPONSOR_LEAGUE_TABLE,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect to={routeFactory.datalabSponsorsLeagueTable.routePath + search} />
                    );
                  }}
                />

                {/* IOI Participation chart */}
                <Route
                  path={routeFactory.datalabIoiParticipationChart.routePath}
                  component={IoiParticipationScreen}
                />
                {/* IOI Participation table */}
                <Route path={routeFactory.datalabIoiParticipationTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.IOI_PARTICIPATION} />
                </Route>
                {/* IOI Participation redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.IOI_PARTICIPATION,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect to={routeFactory.datalabIoiParticipationChart.routePath + search} />
                    );
                  }}
                />

                {/* Broker analysis table */}
                <Route path={routeFactory.datalabIoiLeagueTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.IOI_LEAGUE_TABLE} />
                </Route>
                {/* Broker analysis redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.IOI_LEAGUE_TABLE,
                  })}
                  render={({ location: { search } }) => {
                    return <Redirect to={routeFactory.datalabIoiLeagueTable.routePath + search} />;
                  }}
                />

                {/* Broker P&L table */}
                <Route path={routeFactory.datalabPlLeagueTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.PL_LEAGUE_TABLE} />
                </Route>
                {/* Broker P&L redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.PL_LEAGUE_TABLE,
                  })}
                  render={({ location: { search } }) => {
                    return <Redirect to={routeFactory.datalabPlLeagueTable.routePath + search} />;
                  }}
                />

                {/* Fund level analysis table */}
                <Route path={routeFactory.datalabFundIoiLeagueTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.FUND_IOI_LEAGUE_TABLE} />
                </Route>
                {/* Fund level analysis redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.FUND_IOI_LEAGUE_TABLE,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect to={routeFactory.datalabFundIoiLeagueTable.routePath + search} />
                    );
                  }}
                />

                {/* Fund IOI participation table */}
                <Route path={routeFactory.datalabFundIoiParticipationTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.FUND_IOI_PARTICIPATION} />
                </Route>
                {/* Fund IOI participation redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.FUND_IOI_PARTICIPATION,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={routeFactory.datalabFundIoiParticipationTable.routePath + search}
                      />
                    );
                  }}
                />

                {/* Underwriter offerings table */}
                <Route path={routeFactory.datalabUnderwriterOfferingsTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.UNDERWRITER_OFFERINGS} />
                </Route>
                {/* Underwriter offerings redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.UNDERWRITER_OFFERINGS,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={routeFactory.datalabUnderwriterOfferingsTable.routePath + search}
                      />
                    );
                  }}
                />

                {/* Broker PL offerings table */}
                <Route path={routeFactory.datalabBrokerPlOfferingsTable.routePath}>
                  <DatalabTable {...tableProps} screen={DatalabScreens.BROKER_PL_OFFERINGS} />
                </Route>
                {/* Broker PL offerings redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.BROKER_PL_OFFERINGS,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={routeFactory.datalabBrokerPlOfferingsTable.routePath + search}
                      />
                    );
                  }}
                />

                {/* Aftermarket underwriter offerings table */}
                <Route path={routeFactory.datalabAftermarketUnderwriterOfferingsTable.routePath}>
                  <DatalabTable
                    {...tableProps}
                    screen={DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS}
                  />
                </Route>
                {/* Aftermarket underwriter offerings redirect */}
                <Route
                  path={routeFactory.datalab.getUrlPath({
                    screen: DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS,
                  })}
                  render={({ location: { search } }) => {
                    return (
                      <Redirect
                        to={
                          routeFactory.datalabAftermarketUnderwriterOfferingsTable.routePath +
                          search
                        }
                      />
                    );
                  }}
                />
                {/* DL Shared Report empty waiting screen - redirect occurs in duck */}
                <Route exact path={routeFactory.datalabReport.routePath} />

                {/* Default redirect to IPO Structure chart */}
                <Route
                  render={() => <Redirect to={routeFactory.datalabIpoStructureChart.routePath} />}
                />
              </Switch>
            </STableWrapper>
          </Spinner>
        </SDatalabWrapper>
      </ScreenContent>
      <AllReportsModal />
      <ReportDetailsModal />
    </div>
  );
};

export default memo(DatalabScreenComponent);
