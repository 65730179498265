import {
  Box,
  Card,
  Grid,
  RegularBreakpoints,
  Section,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetDocumentationData } from '../hooks/useGetDocumentationData';
import { cardHeight } from '../OfferingProfileCardItem';
import { StyledNewsTableContainer, StyledTableCell } from './DocumentationWidget.styles';

type NewsTableProps = {
  news: { name: string; link: string }[];
  documents: { name: string; link: string }[];
};

const SecNewsTable = ({ news, documents }: NewsTableProps) => {
  const [newsSearchTerm] = React.useState('');

  const theme = useTheme();

  return news.length > 0 ? (
    <React.Fragment>
      <Box paddingTop={documents.length > 0 ? 2 : 0}>
        <Typography variant="highlight1" color={theme.palette.text.primary}>
          SEC Filings
        </Typography>
      </Box>
      <StyledNewsTableContainer>
        <Table>
          <TableBody>
            {news
              .filter(({ name }) => name.toLowerCase().includes(newsSearchTerm.toLowerCase()))
              .map(({ name, link }) => (
                <TableRow key={name}>
                  <StyledTableCell>
                    <Tooltip title="Opens in a new tab">
                      <a href={link} target="_blank" rel="noreferrer">
                        <Typography variant="highlight1">{name}</Typography>
                      </a>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledNewsTableContainer>
    </React.Fragment>
  ) : null;
};

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const DocumentationWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const { documents, news } = useGetDocumentationData(offeringProfile);
  const title = 'Documentation';

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          {!documents.length && !news.length ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <React.Fragment>
              <TableContainer>
                <Table>
                  <TableBody>
                    {documents.map(({ name, link }) => (
                      <TableRow key={name}>
                        <StyledTableCell>
                          <Tooltip title="Opens in a new tab">
                            <a href={link} target="_blank" rel="noreferrer">
                              <Typography variant="highlight1">{name}</Typography>
                            </a>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <SecNewsTable news={news} documents={documents} />
            </React.Fragment>
          )}
        </Section>
      </Card>
    </Grid>
  );
};

export default DocumentationWidget;
