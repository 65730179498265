import { IconNameParts, Popover, PrimaryButton, SecondaryButton } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

const SWrapper = styled.span`
  margin-left: 5px;
`;

export type Props = {
  onClick: () => void;
  primary?: boolean;
  hint: string;
  icon: IconNameParts['name'];
};

const TopButton: React.FC<Props> = ({ primary, onClick, hint, icon }) => {
  const Button = primary ? PrimaryButton : SecondaryButton;

  return (
    <Popover content={hint} placement="top" variant="TOOLTIP">
      <SWrapper>
        <Button onClick={onClick} iconLeft={{ name: icon }} />
      </SWrapper>
    </Popover>
  );
};

export default TopButton;
