import { Box, Column, Row } from '@cmg/common';
import { datalabTableSelector } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import VirtualizedTableWidget from '../../../obsolete/components/ui/table/VirtualizedTableWidget';
import { isDownloadExcelOn } from '../../datalab/model/utils';
import Toggler from '../../shared/layout/components/Toggler';
import { DatalabScreens } from '../constants';
import {
  selectDatalabTable,
  selectTableQuickFilter,
  selectVisibleColumns,
  setVisibleColumns,
} from '../ducks';
import { TableConfigType } from '../model/table.model';
import { DatalabFilterValues } from '../types';
import { SButtonBox } from './widgets/shared/styled';

const mapStateToProps = (state, props: OwnProps) => ({
  datalabTable: selectDatalabTable(state)(props.screen),
  quickFilter: selectTableQuickFilter(state, props.screen),
  visibleColumns: selectVisibleColumns(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setVisibleColumns: setVisibleColumns,
    },
    dispatch
  ),
});

type OwnProps = {
  tableConfig: TableConfigType;
  screen: DatalabScreens;
  onQuickFilterChange: (value: Partial<DatalabFilterValues>) => void;
  onDownload: any; // todo VirtualizedTableWidget's type definitions is bad
  onReload: Function;
  isFilterPanelExpanded: boolean;
};

export type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class DatalabTableComponent extends React.Component<Props> {
  handleToggleViewableFields = visibleColumns => {
    this.props.actions.setVisibleColumns({ visibleColumns: visibleColumns });
  };

  handleQuickFilterChange = activeQuickFilter => {
    switch (this.props.screen) {
      case DatalabScreens.SPONSOR_LEAGUE_TABLE:
      case DatalabScreens.AFTERMARKET_MATRIX:
        this.props.onQuickFilterChange({ avgMethod: activeQuickFilter.value });
        break;
      case DatalabScreens.PL_LEAGUE_TABLE:
      case DatalabScreens.BROKER_PL_OFFERINGS:
        this.props.onQuickFilterChange({ plCredit: activeQuickFilter.value });
        break;
    }
  };

  render() {
    const {
      tableConfig,
      datalabTable,
      quickFilter,
      screen,
      visibleColumns,
      onDownload,
      onReload,
      isFilterPanelExpanded,
    } = this.props;

    const { table_rows, table_summaries, pagination } = datalabTable;
    const aboveFiltersPlaceholder = tableConfig.quickFilterOptions && (
      <SButtonBox>
        <Toggler
          buttons={tableConfig.quickFilterOptions}
          activeButton={
            tableConfig.quickFilterOptions.find(o => o.value === quickFilter) ||
            tableConfig.quickFilterOptions[0]
          }
          onChange={this.handleQuickFilterChange}
          size="small"
        />
      </SButtonBox>
    );

    const { activePage, perPage, totalPages, totalCount } = pagination ?? {};

    const summaries =
      typeof totalCount !== 'undefined'
        ? { ...table_summaries, companies_issuer: { count: totalCount } }
        : table_summaries;

    const grid = (
      // @ts-ignore for some reason, typescripts marks all props defined via PropTypes as required
      <VirtualizedTableWidget
        activePage={activePage}
        columnsConfig={tableConfig.columnsConfig}
        downloadExport={isDownloadExcelOn() ? onDownload : undefined}
        handleToggleViewableFields={this.handleToggleViewableFields}
        itemsPerPage={perPage}
        onReload={onReload}
        orderBy={tableConfig.orderBy}
        orderByType={tableConfig.orderByType}
        paginationType={tableConfig.paginationType}
        rows={table_rows}
        screen={screen}
        showRanking={tableConfig.showRanking}
        summaries={summaries}
        title={tableConfig.title}
        titleTooltip={tableConfig.titleTooltip}
        totalPages={totalPages}
        visibleColumns={visibleColumns}
        staticColumns={tableConfig.staticColumns}
        categorizedColumns={tableConfig.categorizedColumns}
        groupedColumns={tableConfig.groupedColumns}
        aboveFiltersPlaceholder={aboveFiltersPlaceholder}
        fillViewport
        filtersCollapsed={!isFilterPanelExpanded}
      />
    );

    return (
      <Row testId={datalabTableSelector(screen).testId}>
        <Column xs={24}>
          <Box>{grid}</Box>
        </Column>
      </Row>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatalabTableComponent);
