import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { Box, DesignSystemProvider, Stack, SxProps, Theme } from '@cmg/design-system';
import React from 'react';

import { OfferingType } from '../../../../../graphql/__generated__';
import ScreenHeader from '../../../../shared/layout/ScreenHeader';
import { useOfferingProfile_ListQuery } from '../../graphql/__generated__/OfferingProfile';
import { ConvertProfile } from '../converts/ConvertProfile';
import { OfferingProfile } from './OfferingProfile';
import { StyledContentBox } from './OfferingProfile.styles';
import OfferingProfileSubHeader from './OfferingProfileSubHeader';

export type Props = {
  offeringId: UUID;
  userNote?: string | null;
  onSaveUserNote: (userNote: string | null) => void;
  isSavingUserNote: boolean;
  isFollowing?: boolean;
  onToggleFollowOffering?: () => void;
  token?: string;
  offeringType: OfferingType;
};
const cardStyle: SxProps<Theme> = {
  zIndex: 1,
  boxShadow: 4,
  paddingTop: 0,
};
const OfferingProfileScreen: React.FC<Props> = ({
  offeringId,
  offeringType,
  userNote,
  onSaveUserNote,
  isSavingUserNote,
  isFollowing,
  onToggleFollowOffering,
  token,
}) => {
  const { data: offeringProfile, error } = useOfferingProfile_ListQuery({
    variables: {
      offeringId: offeringId,
      isAtm: offeringType === OfferingType.Atm,
      isConvert: offeringType === OfferingType.Convertible,
      hasSellDownPermission: useCheckPermissions([permissionsByEntity.ATMSelldown.READ]),
      hasMySectorsPermission: useCheckPermissions([permissionsByEntity.CustomSectors.READ]),
    },
    fetchPolicy: 'no-cache',
  });
  const isConvert = offeringProfile?.offeringById?.type === OfferingType.Convertible;
  const isAtm = offeringProfile?.offeringById?.type === OfferingType.Atm;

  return (
    // This provider is temporarily here until we adopt design-system for the whole ECM app
    <DesignSystemProvider>
      <Stack>
        <ScreenHeader shareToken={token} />
        {offeringProfile && !error ? (
          <Stack>
            <Box sx={cardStyle}>
              <OfferingProfileSubHeader
                offeringProfile={offeringProfile}
                isFollowing={isFollowing}
                onToggleFollowOffering={onToggleFollowOffering}
              />
            </Box>
            <StyledContentBox>
              {isConvert && (
                <ConvertProfile
                  offeringProfile={offeringProfile}
                  onSaveUserNote={onSaveUserNote}
                  userNote={userNote}
                  isSavingUserNote={isSavingUserNote}
                />
              )}
              {isAtm && (
                <React.Fragment>
                  <OfferingProfile
                    offeringProfile={offeringProfile}
                    onSaveUserNote={onSaveUserNote}
                    isSavingUserNote={isSavingUserNote}
                    userNote={userNote}
                  />
                </React.Fragment>
              )}
              {!isConvert && !isAtm && (
                <OfferingProfile
                  offeringProfile={offeringProfile}
                  onSaveUserNote={onSaveUserNote}
                  isSavingUserNote={isSavingUserNote}
                  userNote={userNote}
                />
              )}
            </StyledContentBox>
          </Stack>
        ) : null}
      </Stack>
    </DesignSystemProvider>
  );
};

export default OfferingProfileScreen;
