import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import {
  Card,
  Grid,
  KeyValuePair,
  RegularBreakpoints,
  Section,
  Stack,
  TableContainer,
} from '@cmg/design-system';

import { AtmBlurTable } from '../../../../../shared/components/atm/blur-table/AtmBlurTable';
import {
  AtmSellDownReport,
  isAtmProgramSize,
} from '../../../../../shared/components/atm/sell-down/AtmSellDownReport';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { cardHeight } from '../../shared/OfferingProfileCardItem';
import { useGetAtmStructurePricingSectionData } from '../hooks/useGetAtmStructurePricing';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

export const AtmStructurePricingWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const title = 'Structure/Pricing';
  const canReadAtmSelldown = useCheckPermissions([permissionsByEntity.ATMSelldown.READ]);

  const { offeringById } = offeringProfile;
  const { structurePricingRows } = useGetAtmStructurePricingSectionData(offeringProfile);
  const { structurePricingCol1, structurePricingCol2, structurePricingCol3 } = structurePricingRows;

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          <Stack rowGap={2} display="flex">
            <TableContainer>
              {offeringById?.atmAttributes &&
                (canReadAtmSelldown ? (
                  <AtmSellDownReport
                    {...offeringById?.atmAttributes}
                    shrink
                    pricingCurrency={offeringById?.attributes?.pricingCurrency}
                    isAtmProgramSize={isAtmProgramSize(offeringById?.terms)}
                    structure={offeringById?.atmAttributes?.structure ?? []}
                  />
                ) : (
                  <AtmBlurTable
                    shrink
                    showAlert
                    showAttributes
                    {...offeringById?.atmAttributes}
                    pricingCurrency={offeringById?.attributes?.pricingCurrency}
                    isAtmProgramSize={isAtmProgramSize(offeringById?.terms)}
                    publicFilingDate={offeringById?.publicFilingDate}
                    terms={offeringById.terms ?? []}
                    showRevisions
                  />
                ))}
            </TableContainer>
            <Grid container columnSpacing={2} display="flex" alignItems="flex-start">
              <Grid
                container
                item
                columnSpacing={1}
                display="flex"
                flex={1}
                alignItems="flex-start"
              >
                <Grid item minWidth={theme => theme.spacing(30)} flex={1} key="col1">
                  {structurePricingCol1.map(({ name, value }) => {
                    return <KeyValuePair key={name} minWidth={165} label={name} value={value} />;
                  })}
                </Grid>
                {structurePricingCol2.length > 0 && (
                  <Grid item minWidth={theme => theme.spacing(30)} flex={1} key="col2">
                    {structurePricingCol2.map(({ name, value }) => {
                      return <KeyValuePair key={name} minWidth={165} label={name} value={value} />;
                    })}
                  </Grid>
                )}
                {structurePricingCol3.length > 0 && (
                  <Grid item minWidth={theme => theme.spacing(30)} flex={1} key="col3">
                    {structurePricingCol3.map(({ name, value }) => {
                      return <KeyValuePair key={name} minWidth={165} label={name} value={value} />;
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Section>
      </Card>
    </Grid>
  );
};
