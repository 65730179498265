import { numericUtil } from '@cmg/common';
import {
  Divider,
  Grid,
  KeyValuePair,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@cmg/design-system';
import React from 'react';

import {
  gridStyle,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHeader,
  StyledTableRow,
  totalRowStyle,
} from '../../../../../common/components/table/table.styles';
import {
  Atm_Attributes_FieldsFragment,
  Atm_Selldown_FieldsFragment,
  Atm_Selldown_Term_FieldsFragment,
} from '../../../../../common/graphql/__generated__/commonFragments';
import { AtmStructureType, Currency, TermType } from '../../../../../graphql/__generated__';
import { dtc, TableKeys, useGetAtmSellDownTableModel } from './AtmSellDownReport.model';
import { dateTableColumn, millionLocalCurrencyTableColumn } from './utils';

type Props = Atm_Selldown_FieldsFragment &
  Atm_Attributes_FieldsFragment & {
    pricingCurrency?: Currency | null;
    isAtmProgramSize: boolean;
    shrink?: boolean;
  };

export const isAtmProgramSize = (terms?: Atm_Selldown_Term_FieldsFragment[]) => {
  const initial = terms?.filter(t => t.type === TermType.Initial) ?? [];
  if (initial.length > 0) {
    return !!initial[0].atmProgramSize;
  }
  return false;
};

const totalStyle = { bgcolor: theme => '#EDF1F9' };

export const AtmSellDownReport: React.FC<Props> = ({
  shrink,
  structure,
  isAtmProgramSize,
  pricingCurrency,
  totalSales,
  totalSalesInSecurities,
  totalProgramRemaining,
  totalProgramRemainingInSecurities,
  latestAsOfDate,
}) => {
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down(1201));
  const tableModel = useGetAtmSellDownTableModel({ isAtmProgramSize });

  return shrink && isSmallScreen ? (
    <Stack divider={<Divider />}>
      {structure.map(row => {
        return (
          <Stack paddingX={2} paddingY={1} key={row.selldownId}>
            <Typography variant="highlight1" paddingBottom={0.5}>
              {tableModel[0].render(row, {})}
            </Typography>
            {row.type === AtmStructureType.Selldown
              ? tableModel
                  .slice(1)
                  .map((column, index) => (
                    <KeyValuePair
                      key={index}
                      minWidth="xlarge"
                      label={column.label}
                      value={column.render(row, { pricingCurrency })}
                    />
                  ))
              : [
                  dtc[TableKeys.announcementDate],
                  dtc[TableKeys.programSize],
                  dtc[TableKeys.programRemaining],
                ].map((column, index) => (
                  <KeyValuePair
                    key={index}
                    minWidth="xlarge"
                    label={column.label}
                    value={column.render(row, { pricingCurrency })}
                  />
                ))}
          </Stack>
        );
      })}
      {!structure.length && (
        <Stack paddingX={2} paddingY={1}>
          <Typography variant="highlight1" paddingBottom={0.5}>
            Announced
          </Typography>
          <KeyValuePair minWidth="xlarge" label="Announcement Date" value="-" />
          <KeyValuePair minWidth="xlarge" label="ATM Program Size" value="-" />
          <KeyValuePair minWidth="xlarge" label="ATM Program Remaining" value="-" />
        </Stack>
      )}
      <Stack paddingX={2} paddingY={1} sx={totalStyle}>
        <Typography variant="highlight1" paddingBottom={0.5}>
          Total
        </Typography>
        <KeyValuePair
          minWidth="xlarge"
          label="Announcement Date"
          value={latestAsOfDate ? `As of ${dateTableColumn(latestAsOfDate)}` : '-'}
        />
        <KeyValuePair
          minWidth="xlarge"
          label="ATM Sales"
          value={totalSales ? millionLocalCurrencyTableColumn(totalSales, pricingCurrency) : '-'}
        />
        <KeyValuePair
          minWidth="xlarge"
          label="ATM Sales In Securities"
          value={totalSalesInSecurities ? numericUtil.formatInteger(totalSalesInSecurities) : '-'}
        />
        <KeyValuePair minWidth="xlarge" label="ATM Program Size" value="" />
        {isAtmProgramSize && (
          <KeyValuePair
            minWidth="xlarge"
            label="ATM Program Remaining"
            value={
              totalProgramRemaining
                ? millionLocalCurrencyTableColumn(totalProgramRemaining, pricingCurrency)
                : '-'
            }
          />
        )}
        {!isAtmProgramSize && (
          <KeyValuePair
            minWidth="xlarge"
            label="ATM Program Remaining Securities"
            value={
              totalProgramRemainingInSecurities
                ? millionLocalCurrencyTableColumn(
                    totalProgramRemainingInSecurities,
                    pricingCurrency
                  )
                : '-'
            }
          />
        )}
      </Stack>
    </Stack>
  ) : (
    <StyledTableContainer>
      <Grid sx={gridStyle}>
        <Table size="medium" data-testid="selldown-table">
          <TableHead>
            <TableRow color="text.secondary">
              {tableModel.map((column, index) => (
                <StyledTableHeader key={index} {...column.headerStyles}>
                  {column.label}
                </StyledTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {structure.map((row, rowIdx) => {
              return (
                <StyledTableRow key={rowIdx} topDivider={row.type === AtmStructureType.Revised}>
                  {tableModel.map((column, modelIdx) => (
                    <StyledTableCell key={`${rowIdx}_${modelIdx}`} {...column.contentStyles}>
                      {column.render(row, { pricingCurrency })}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              );
            })}
            {!structure.length && (
              <StyledTableRow>
                <StyledTableCell align="left">Announced</StyledTableCell>
                <StyledTableCell align="left">-</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
                <StyledTableCell>
                  {millionLocalCurrencyTableColumn(totalSales, pricingCurrency)}
                </StyledTableCell>
                <StyledTableCell>
                  {numericUtil.formatInteger(totalSalesInSecurities)}
                </StyledTableCell>
                <StyledTableCell rightBorder />
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
              </StyledTableRow>
            )}

            <StyledTableRow topDivider isTotals sx={totalRowStyle}>
              <StyledTableCell align="left">Total</StyledTableCell>
              <StyledTableCell align="left">-</StyledTableCell>
              <StyledTableCell align="left">
                {latestAsOfDate ? `As of ${dateTableColumn(latestAsOfDate)}` : '-'}
              </StyledTableCell>
              <StyledTableCell>
                {totalSales ? millionLocalCurrencyTableColumn(totalSales, pricingCurrency) : '-'}
              </StyledTableCell>
              <StyledTableCell>
                {totalSalesInSecurities ? numericUtil.formatInteger(totalSalesInSecurities) : '-'}
              </StyledTableCell>
              <StyledTableCell rightBorder>-</StyledTableCell>
              <StyledTableCell />
              {isAtmProgramSize && (
                <StyledTableCell>
                  {totalProgramRemaining
                    ? millionLocalCurrencyTableColumn(totalProgramRemaining, pricingCurrency)
                    : '-'}
                </StyledTableCell>
              )}
              {!isAtmProgramSize && (
                <StyledTableCell>
                  {totalProgramRemainingInSecurities
                    ? numericUtil.formatInteger(totalProgramRemainingInSecurities)
                    : '-'}
                </StyledTableCell>
              )}
            </StyledTableRow>
          </TableBody>
        </Table>
      </Grid>
    </StyledTableContainer>
  );
};
