import { typeUtil } from '@cmg/common';
import React from 'react';

export type Value = { gteq?: any; lteq?: any } | null;
type Props = {
  value?: Value;
  onChange: (value: Value | null) => void;
};
type Control = (InputControl: any) => React.FC<Props>;

export const createBetweenControl: Control =
  InputControl =>
  ({ onChange, value }) => {
    const handleChange = React.useCallback(
      (newValue, field: 'lteq' | 'gteq') => {
        if (!value || newValue !== value[field]) {
          const newObject: Value = { ...value, [field]: newValue };
          if (typeUtil.isDefined(newObject.lteq) || typeUtil.isDefined(newObject.gteq)) {
            onChange(newObject);
          } else if (value) {
            onChange(null);
          }
        }
      },
      [value, onChange]
    );

    // useCallback for handleChange gteq and lteq
    const handleChangeGteq = React.useCallback(
      newValue => handleChange(newValue, 'gteq'),
      [handleChange]
    );

    const handleChangeLteq = React.useCallback(
      newValue => handleChange(newValue, 'lteq'),
      [handleChange]
    );

    return (
      <div style={{ display: 'flex', minWidth: 300 }}>
        <InputControl onChange={handleChangeGteq} value={value?.gteq || null} />
        <div style={{ padding: '5px 5px 0' }}>and</div>
        <InputControl onChange={handleChangeLteq} value={value?.lteq || null} />
      </div>
    );
  };
