import {
  DLGWCornerstoneInvestmentRowItem,
  DLGWDataLabTableRow,
} from '@capital-markets-gateway/api-client-datalab-gateway';
import React from 'react';

import { CornerstoneInvestorType } from '../../../../../types/domain/offering/constants';
import { calculateTotalInvestors } from '../../../../calendar/components/CornerstoneInvestorsRenderer.model';
import { getFormattedCurrencyInMillion } from '../../../model/utils';
import {
  HeaderLabel,
  SFooter,
  SLabel,
  STooltipContent,
  StyledPopover,
} from './CornerstoneTooltip.styles';
import CornerstoneTooltipTableContent from './CornerstoneTooltipTableContent';

const mapToFirmName = (investment: DLGWCornerstoneInvestmentRowItem): string[] =>
  (investment.investors ?? [])
    .flatMap(investor => investor.shareholder?.firm_name ?? '')
    .filter(name => name.length > 0);

export const formatInvestorNames = (
  cornerstone_investments: DLGWCornerstoneInvestmentRowItem[] | null | undefined
) => {
  const offeringParticipants =
    cornerstone_investments?.filter(c => c.type === CornerstoneInvestorType.OFFERING_PARTICIPANT) ??
    [];
  const privatePlacement =
    cornerstone_investments?.filter(c => c.type === CornerstoneInvestorType.PRIVATE_PLACEMENT) ??
    [];
  const offeringParticipantsInvestors = offeringParticipants.flatMap(mapToFirmName);
  const privatePlacementInvestors = privatePlacement.flatMap(mapToFirmName);

  if (offeringParticipantsInvestors.length === 0) {
    return privatePlacementInvestors.join(', ');
  }
  if (privatePlacementInvestors.length === 0) {
    return offeringParticipantsInvestors.join(', ');
  }
  return `${offeringParticipantsInvestors.join(', ')} / ${privatePlacementInvestors.join(', ')}`;
};

export const CornerstoneTooltip: React.FC<DLGWDataLabTableRow> = ({
  cornerstone_total_amount,
  cornerstone_investors_total_count,
  cornerstone_investors_private_placement_count,
  cornerstone_private_placement_amount,
  cornerstone_investments,
  cornerstone_offering_participants_amount,
  cornerstone_investors_offering_participants_count,
  has_cornerstone_investors,
  pricing_currency_code,
  ...props
}) => {
  const investmentNames = formatInvestorNames(cornerstone_investments);
  if (investmentNames.length === 0) {
    return <span>-</span>;
  }

  const offeringParticipants =
    cornerstone_investments?.filter(c => c.type === CornerstoneInvestorType.OFFERING_PARTICIPANT) ??
    [];

  const privatePlacement =
    cornerstone_investments?.filter(c => c.type === CornerstoneInvestorType.PRIVATE_PLACEMENT) ??
    [];

  const offeringParticipantsTotalInvestors = calculateTotalInvestors(offeringParticipants);
  const privatePlacementTotalInvestors = calculateTotalInvestors(privatePlacement);
  const investorsTotalNumber = offeringParticipantsTotalInvestors + privatePlacementTotalInvestors;

  return (
    <StyledPopover
      content={
        <React.Fragment>
          <STooltipContent>
            <thead>
              <tr>
                <HeaderLabel className="cornerstone-header">
                  {`Cornerstone Investors (${investorsTotalNumber})`}
                  <div className="subtitle">
                    Cornerstone Investment Total
                    <span>
                      {getFormattedCurrencyInMillion(
                        pricing_currency_code,
                        cornerstone_total_amount
                      )}
                    </span>
                  </div>
                </HeaderLabel>
              </tr>
            </thead>
            <CornerstoneTooltipTableContent
              {...props}
              cornerstone_investments={offeringParticipants}
              cornerstone_investors_offering_participants_count={offeringParticipantsTotalInvestors}
              cornerstone_offering_participants_amount={cornerstone_offering_participants_amount}
              investment_type={CornerstoneInvestorType.OFFERING_PARTICIPANT}
              pricing_currency_code={pricing_currency_code}
            />
            <CornerstoneTooltipTableContent
              {...props}
              cornerstone_investments={privatePlacement}
              cornerstone_investors_offering_participants_count={privatePlacementTotalInvestors}
              cornerstone_offering_participants_amount={cornerstone_private_placement_amount}
              investment_type={CornerstoneInvestorType.PRIVATE_PLACEMENT}
              pricing_currency_code={pricing_currency_code}
            />
          </STooltipContent>
          <SFooter>
            Offering Participant Cornerstone investments are included in deal metrics and Gross
            Proceeds calculations. Private Placement Cornerstone investoments are in addition to the
            offering and not included in Gross Proceeds calculations
          </SFooter>
        </React.Fragment>
      }
    >
      <SLabel>{investmentNames}</SLabel>
    </StyledPopover>
  );
};
