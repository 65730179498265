import { Grid, InfoOutlinedIcon, Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

const minHeight = 24;

const labelStyles = (label: string) => {
  let spacingValue = 0.25; // Default padding
  if (label === 'Underlying Exchange Country / Region') {
    spacingValue = 1;
  } else if (label === 'Offering Status') {
    spacingValue = 0;
  }
  return {
    padding: (theme: any) => `${theme.spacing(spacingValue)} 0`,
  };
};

export type Props = {
  value: React.ReactNode;
  label: string;
  info?: string;
  height?: number;
};

const ValueHighlight: React.FC<Props> = ({ value, label, info, height }) => (
  <Grid
    container
    display="flex"
    minHeight={minHeight}
    height={height}
    alignItems="center"
    columnGap={1}
    paddingLeft={4}
    paddingRight={2}
  >
    <Grid
      item
      display="flex"
      alignSelf="start"
      minHeight={minHeight}
      sx={{ padding: theme => `${theme.spacing(0.5)} 0` }}
      alignItems="center"
      width={theme => theme.spacing(17.875)}
      role="rowheader"
    >
      <Typography
        variant="specialLabel"
        color="text.secondary"
        paddingTop="1px"
        lineHeight={theme => theme.spacing(1.75)}
      >
        {label}
      </Typography>
      {info && (
        <Tooltip
          title={
            <Grid maxWidth={theme => theme.spacing(19.75)}>
              <Typography variant="tooltip">{info}</Typography>
            </Grid>
          }
          placement="top-start"
          variant="info"
        >
          <Typography color="text.secondary" paddingLeft={0.5} fontSize={12} display="flex">
            <InfoOutlinedIcon fontSize="inherit" />
          </Typography>
        </Tooltip>
      )}
    </Grid>
    <Grid item flex={1} role="cell" alignSelf="start" minHeight={minHeight} sx={labelStyles(label)}>
      {typeof value === 'string' ? (
        <Grid>
          <Typography variant="highlight2">{value}</Typography>
        </Grid>
      ) : (
        value
      )}
    </Grid>
  </Grid>
);

export default ValueHighlight;
