import { Box, Grid, Tooltip, Typography } from '@cmg/design-system';
import React from 'react';

import BooleanIcon from './BooleanIcon';

export type Props = {
  value?: boolean | null;
  tooltip?: string | null;
};

const iconBoxStyle = { height: 19 };
const iconGridStyle = { display: 'flex', alignItems: 'center' };

const overflowStyles = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textDecoration: 'underline dotted',
};

const BooleanIconWithTooltip: React.FC<Props> = ({ value, tooltip }) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item sx={iconGridStyle}>
        <Box sx={iconBoxStyle}>
          <BooleanIcon value={value} />
        </Box>
        {tooltip && tooltip.length > 20 ? (
          <Tooltip title={tooltip} arrow variant="info">
            <Grid sx={overflowStyles}>
              <Typography marginLeft={1}>{tooltip}</Typography>
            </Grid>
          </Tooltip>
        ) : (
          <Typography display="inline" noWrap marginLeft={1}>
            {tooltip}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default BooleanIconWithTooltip;
