import { UnpackPromise, urlUtil } from '@cmg/common';

import { ApiResponse } from '../../../types/api/ApiResponse';
import { PaginatedResponse } from '../../../types/api/pagination';
import { ISODateTime } from '../../../types/common';
import { NotificationActionType } from '../../../types/domain/notifications/constants';
import {
  Notification,
  NotificationSummary,
} from '../../../types/domain/notifications/notification';
import { identityApi } from '../apiClient';

/*
 * Fetch list of Notifications with cursor based pagination
 */
export type GetNotificationsParams = {
  before?: ISODateTime;
  actionTypes?: NotificationActionType[];
  isRead?: boolean;
  includeTotals?: boolean;
};
export const fetchNotifications = (params: GetNotificationsParams) => {
  return identityApi.get<
    PaginatedResponse<Notification[]>,
    ApiResponse<PaginatedResponse<Notification[]>>
  >(`/self/notifications${urlUtil.queryStringify(params)}`);
};
export type GetNotificationsResponse = UnpackPromise<ReturnType<typeof fetchNotifications>>;

/*
 * Fetch Summary of Notifications including total unread and by actionType
 */
export const fetchNotificationSummary = () => {
  return identityApi.get<NotificationSummary>(`/self/notifications/summary`) as Promise<
    ApiResponse<NotificationSummary>
  >;
};
export type GetNotificationSummaryResponse = UnpackPromise<
  ReturnType<typeof fetchNotificationSummary>
>;

/*
 * Update isRead status on a Notification
 */
export type MarkAsReadParams = {
  isRead: boolean;
  notification: Notification;
};
export const markAsRead = async (params: MarkAsReadParams) => {
  const { notification, isRead } = params;
  return identityApi.put<{ isRead: typeof isRead }, ApiResponse<Notification>>(
    `/self/notifications/${notification.id}/readStatus`,
    {
      isRead,
    }
  );
};
export type PutMarkAsReadResponse = UnpackPromise<ReturnType<typeof markAsRead>>;

/*
 * Update ALL notification isRead status as read
 */
export const markAllAsRead = async () =>
  identityApi.post<{ isRead: boolean }, ApiResponse<void>>(`/self/notifications/readStatus`, {
    isRead: true,
  });
export type MarkAllAsReadResponse = UnpackPromise<ReturnType<typeof markAllAsRead>>;

const apiCalls = {
  fetchNotifications,
  fetchNotificationSummary,
  markAsRead,
  markAllAsRead,
};

export default apiCalls;
