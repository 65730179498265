import React from 'react';

import { SSpinElement } from './SpinElement.styles';

type Props = {
  size?: 'small' | 'default';
  testId?: string;
};

export default class SpinElement extends React.PureComponent<Props> {
  render() {
    return (
      <SSpinElement
        size={this.props.size}
        data-testid={this.props.testId}
        data-test-id={this.props.testId}
      >
        <div className="sk-circle1 sk-circle" />
        <div className="sk-circle2 sk-circle" />
        <div className="sk-circle3 sk-circle" />
        <div className="sk-circle4 sk-circle" />
        <div className="sk-circle5 sk-circle" />
        <div className="sk-circle6 sk-circle" />
        <div className="sk-circle7 sk-circle" />
        <div className="sk-circle8 sk-circle" />
        <div className="sk-circle9 sk-circle" />
        <div className="sk-circle10 sk-circle" />
        <div className="sk-circle11 sk-circle" />
        <div className="sk-circle12 sk-circle" />
      </SSpinElement>
    );
  }
}
