import {
  OfferingNote,
  OfferingNoteCreate,
  OfferingNoteUpdate,
} from 'src/types/domain/offering/offering-note';

import {
  makeCreateEntityRequest,
  makeDeleteEntityRequestWithResponse,
  makeFetchEntityRequest,
  makeUpdateEntityRequest,
} from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';

export const fetchIoiNotes = makeFetchEntityRequest<OfferingNote[], { offeringId: string }>(
  dlgwApiClient,
  '/offerings/:offeringId/ioiNotes'
);

export const createIoiNote = makeCreateEntityRequest<
  OfferingNoteCreate,
  OfferingNote[],
  { offeringId: string }
>(dlgwApiClient, '/offerings/:offeringId/ioiNotes');

export const updateIoiNote = makeUpdateEntityRequest<
  OfferingNoteUpdate,
  OfferingNote[],
  { offeringId: string; noteId: string }
>(dlgwApiClient, '/offerings/:offeringId/ioiNotes/:noteId');

export const deleteIoiNote = makeDeleteEntityRequestWithResponse<{
  offeringId: string;
  noteId: string;
}>(dlgwApiClient, '/offerings/:offeringId/ioiNotes/:noteId');
