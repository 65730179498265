import { makeFetchEntityRequest, makeUpdateEntityRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import {
  FundIoiTrackerPayload,
  IoiTrackerPayload,
  IoiTrackerUpdatePayload,
} from './types/IoiTrackerIoi';

export const getFirmIoiTracker = makeFetchEntityRequest<IoiTrackerPayload>(
  dlgwApiClient,
  `/offerings/:id/ioiTracker`
);

export const submitFirmIoiTracker = makeUpdateEntityRequest<
  IoiTrackerUpdatePayload,
  IoiTrackerPayload
>(dlgwApiClient, `/offerings/:id/ioiTracker`);

export const getFundIoiTracker = makeFetchEntityRequest<FundIoiTrackerPayload>(
  dlgwApiClient,
  `/offerings/:id/fundIoiTracker`
);

export const submitFundIoiTracker = makeUpdateEntityRequest<
  FundIoiTrackerPayload,
  FundIoiTrackerPayload
>(dlgwApiClient, `/offerings/:id/fundIoiTracker`);
