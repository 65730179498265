/* eslint-disable */
// prettier-ignore
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: string;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The `Decimal` scalar type represents a decimal floating-point number. */
  Decimal: number;
  /**
   * Represents an instant on the global timeline, with nanosecond resolution.
   *
   * Allowed patterns:
   * - `YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm`
   *
   * Examples:
   * - `2000-01-01T20:00:00.999999999Z`
   */
  Instant: string;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: number;
  URL: any;
  UUID: string;
};

export type AddCustomSectorInput = {
  data: CustomSectorAddInputData;
};

export type AddCustomSectorPayload = {
  __typename?: 'AddCustomSectorPayload';
  customSector?: Maybe<CustomSector>;
};

export type Adviser = {
  __typename?: 'Adviser';
  adviserType: AdviserType;
  /** Display name for AdviserType */
  adviserTypeDisplayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  offeringCount: Scalars['Int'];
};

export type AdviserAttributes = {
  __typename?: 'AdviserAttributes';
  adviserId: Scalars['UUID'];
  name: Scalars['String'];
  offering?: Maybe<Offering>;
  offeringAdviser: OfferingAdviser;
  offeringId: Scalars['UUID'];
  role: AdviserRole;
  /** Display name for Role */
  roleDisplayName?: Maybe<Scalars['String']>;
};

export type AdviserAttributesFilterInput = {
  adviserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<AdviserAttributesFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  offeringAdviser?: InputMaybe<OfferingAdviserFilterInput>;
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AdviserAttributesFilterInput>>;
  role?: InputMaybe<AdviserRoleFilterInput>;
};

/** A segment of a collection. */
export type AdviserCollectionSegment = {
  __typename?: 'AdviserCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Adviser>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AdviserFilterInput = {
  adviserType?: InputMaybe<AdviserTypeFilterInput>;
  and?: InputMaybe<Array<AdviserFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AdviserFilterInput>>;
};

/** Denormalized adviser role. AdviserRole entity as this is directly related */
export enum AdviserRole {
  Advisory = 'ADVISORY',
  IssuerAuditor = 'ISSUER_AUDITOR',
  IssuerCounsel = 'ISSUER_COUNSEL',
  Trustee = 'TRUSTEE',
  UnderwriterCounsel = 'UNDERWRITER_COUNSEL',
}

export type AdviserRoleFilterInput = {
  eq?: InputMaybe<AdviserRole>;
  in?: InputMaybe<Array<InputMaybe<AdviserRole>>>;
  neq?: InputMaybe<AdviserRole>;
  nin?: InputMaybe<Array<InputMaybe<AdviserRole>>>;
};

/** A segment of a collection. */
export type AdviserRolesCollectionSegment = {
  __typename?: 'AdviserRolesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfAdviserRole>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Denormalized adviser role. AdviserRole entity as this is directly related */
export type AdviserRoleSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type AdviserSortInput = {
  adviserType?: InputMaybe<AdviserTypeSortInput>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum AdviserType {
  Advisor = 'ADVISOR',
  Auditor = 'AUDITOR',
  Counsel = 'COUNSEL',
}

export type AdviserTypeFilterInput = {
  eq?: InputMaybe<AdviserType>;
  in?: InputMaybe<Array<InputMaybe<AdviserType>>>;
  neq?: InputMaybe<AdviserType>;
  nin?: InputMaybe<Array<InputMaybe<AdviserType>>>;
};

/** A segment of a collection. */
export type AdviserTypesCollectionSegment = {
  __typename?: 'AdviserTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfAdviserType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AdviserTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type AfterMarketRelativeToPricingProjection = {
  __typename?: 'AfterMarketRelativeToPricingProjection';
  dealPctAtPricingMarketCap?: Maybe<TrendPerformanceKeys>;
  priceVsMidpoint?: Maybe<TrendPerformanceKeys>;
};

export type Aggregation = {
  __typename?: 'Aggregation';
  max?: Maybe<Scalars['Decimal']>;
  mean?: Maybe<Scalars['Decimal']>;
  min?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
};

export type Allocation = {
  __typename?: 'Allocation';
  /** Allocation / Indication. */
  fillPercentage?: Maybe<Scalars['Decimal']>;
  /** Unique CMG identifier for a specific allocation record. */
  id: Scalars['ID'];
  /** Final allocation in USD. Calculated as allocation shares * offer price in USD. */
  investment?: Maybe<Scalars['Decimal']>;
  /** Enum denoting the IOI type, includes: PASS, REG_M, NORMAL. */
  ioiType?: Maybe<IoiType>;
  /** Display name for IoiType */
  ioiTypeDisplayName?: Maybe<Scalars['String']>;
  lastModifiedBy?: Maybe<LastModifiedBy>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** Enum denoting your firm’s allocation ranking in this offering, includes: NOT_INDICATED, TOP_5, TOP_10, TOP_15, TOP_25, TOP_50, OVER_50. */
  ranking?: Maybe<AllocationRanking>;
  /** Display name for Ranking */
  rankingDisplayName?: Maybe<Scalars['String']>;
  /** Compensation per share received by the Underwriters for selling the offering, calculated as gross spread * 0.6. */
  salesConcession?: Maybe<Scalars['Decimal']>;
  /** Allocation in number of shares. */
  shares?: Maybe<Scalars['Long']>;
};

/** A segment of a collection. */
export type AllocationCollectionSegment = {
  __typename?: 'AllocationCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Allocation>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AllocationFilterInput = {
  and?: InputMaybe<Array<AllocationFilterInput>>;
  /** Allocation / Indication. */
  fillPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** Unique CMG identifier for a specific allocation record. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Final allocation in USD. Calculated as allocation shares * offer price in USD. */
  investment?: InputMaybe<DecimalOperationFilterInput>;
  /** Enum denoting the IOI type, includes: PASS, REG_M, NORMAL. */
  ioiType?: InputMaybe<IoiTypeFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AllocationFilterInput>>;
  /** Enum denoting your firm’s allocation ranking in this offering, includes: NOT_INDICATED, TOP_5, TOP_10, TOP_15, TOP_25, TOP_50, OVER_50. */
  ranking?: InputMaybe<AllocationRankingFilterInput>;
  /** Compensation per share received by the Underwriters for selling the offering, calculated as gross spread * 0.6. */
  salesConcession?: InputMaybe<DecimalOperationFilterInput>;
  /** Allocation in number of shares. */
  shares?: InputMaybe<LongOperationFilterInput>;
};

export enum AllocationRanking {
  NotIndicated = 'NOT_INDICATED',
  Over_50 = 'OVER_50',
  Top_10 = 'TOP_10',
  Top_15 = 'TOP_15',
  Top_25 = 'TOP_25',
  Top_5 = 'TOP_5',
  Top_50 = 'TOP_50',
}

export type AllocationRankingFilterInput = {
  eq?: InputMaybe<AllocationRanking>;
  in?: InputMaybe<Array<InputMaybe<AllocationRanking>>>;
  neq?: InputMaybe<AllocationRanking>;
  nin?: InputMaybe<Array<InputMaybe<AllocationRanking>>>;
};

/** A segment of a collection. */
export type AllocationRankingsCollectionSegment = {
  __typename?: 'AllocationRankingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfAllocationRanking>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AllocationRankingSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type AllocationSortInput = {
  /** Allocation / Indication. */
  fillPercentage?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for a specific allocation record. */
  id?: InputMaybe<SortEnumType>;
  /** Final allocation in USD. Calculated as allocation shares * offer price in USD. */
  investment?: InputMaybe<SortEnumType>;
  /** Enum denoting the IOI type, includes: PASS, REG_M, NORMAL. */
  ioiType?: InputMaybe<IoiTypeSortInput>;
  offering?: InputMaybe<OfferingSortInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<SortEnumType>;
  /** Enum denoting your firm’s allocation ranking in this offering, includes: NOT_INDICATED, TOP_5, TOP_10, TOP_15, TOP_25, TOP_50, OVER_50. */
  ranking?: InputMaybe<AllocationRankingSortInput>;
  /** Compensation per share received by the Underwriters for selling the offering, calculated as gross spread * 0.6. */
  salesConcession?: InputMaybe<SortEnumType>;
  /** Allocation in number of shares. */
  shares?: InputMaybe<SortEnumType>;
};

export type AmountAggregation = {
  __typename?: 'AmountAggregation';
  amount?: Maybe<Scalars['Decimal']>;
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION',
}

export type AtmAttributes = {
  __typename?: 'AtmAttributes';
  /** ATM Program size in notional from the initial filing. */
  announcedProgramSize?: Maybe<Scalars['Decimal']>;
  /** ATM Program size in securities from the initial filing. */
  announcedProgramSizeInSecurities?: Maybe<Scalars['Decimal']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  /** Last trade prior to ATM filing adjusted for any stock splits post filing, stated in local currency. */
  lastTradeBeforeFilingSplitAdjusted?: Maybe<Scalars['Decimal']>;
  /** Lastest As Of Date of Selldown. */
  latestAsOfDate?: Maybe<Scalars['Date']>;
  /** ATM Program size in notional from the latest filing. */
  latestProgramSize?: Maybe<Scalars['Decimal']>;
  /** ATM Program size in securities from the latest filing. */
  latestProgramSizeInSecurities?: Maybe<Scalars['Decimal']>;
  offering: Offering;
  /** Unique CMG identifier for a specific offering. */
  offeringId: Scalars['UUID'];
  /** Disclosed commission percentage (or maximum percentage) payable to the sales agents under their sales agreement. */
  pctGrossSpread?: Maybe<Scalars['Decimal']>;
  structure: Array<AtmStructure>;
  /** Total Program Remaining in notional based on the latest available quarterly data. */
  totalProgramRemaining?: Maybe<Scalars['Decimal']>;
  /** Total Program Remaining in securities based on the latest available quarterly data. */
  totalProgramRemainingInSecurities?: Maybe<Scalars['Decimal']>;
  /** Latest ATM Program (notional) - latest atm program remaining (notional). */
  totalSales?: Maybe<Scalars['Decimal']>;
  /** Latest ATM Program (in securities) - latest atm program remaining (in securities). */
  totalSalesInSecurities?: Maybe<Scalars['Decimal']>;
};

export type AtmAttributesFilterInput = {
  and?: InputMaybe<Array<AtmAttributesFilterInput>>;
  /** ATM Program size in notional from the initial filing. */
  announcedProgramSize?: InputMaybe<DecimalOperationFilterInput>;
  /** ATM Program size in securities from the initial filing. */
  announcedProgramSizeInSecurities?: InputMaybe<DecimalOperationFilterInput>;
  effectiveDate?: InputMaybe<DateOperationFilterInput>;
  /** Last trade prior to ATM filing adjusted for any stock splits post filing, stated in local currency. */
  lastTradeBeforeFilingSplitAdjusted?: InputMaybe<DecimalOperationFilterInput>;
  /** Lastest As Of Date of Selldown. */
  latestAsOfDate?: InputMaybe<DateOperationFilterInput>;
  /** ATM Program size in notional from the latest filing. */
  latestProgramSize?: InputMaybe<DecimalOperationFilterInput>;
  /** ATM Program size in securities from the latest filing. */
  latestProgramSizeInSecurities?: InputMaybe<DecimalOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AtmAttributesFilterInput>>;
  /** Disclosed commission percentage (or maximum percentage) payable to the sales agents under their sales agreement. */
  pctGrossSpread?: InputMaybe<DecimalOperationFilterInput>;
  /** Total Program Remaining in notional based on the latest available quarterly data. */
  totalProgramRemaining?: InputMaybe<DecimalOperationFilterInput>;
  /** Total Program Remaining in securities based on the latest available quarterly data. */
  totalProgramRemainingInSecurities?: InputMaybe<DecimalOperationFilterInput>;
  /** Latest ATM Program (notional) - latest atm program remaining (notional). */
  totalSales?: InputMaybe<DecimalOperationFilterInput>;
  /** Latest ATM Program (in securities) - latest atm program remaining (in securities). */
  totalSalesInSecurities?: InputMaybe<DecimalOperationFilterInput>;
};

export type AtmAttributesSortInput = {
  /** ATM Program size in notional from the initial filing. */
  announcedProgramSize?: InputMaybe<SortEnumType>;
  /** ATM Program size in securities from the initial filing. */
  announcedProgramSizeInSecurities?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  /** Last trade prior to ATM filing adjusted for any stock splits post filing, stated in local currency. */
  lastTradeBeforeFilingSplitAdjusted?: InputMaybe<SortEnumType>;
  /** Lastest As Of Date of Selldown. */
  latestAsOfDate?: InputMaybe<SortEnumType>;
  /** ATM Program size in notional from the latest filing. */
  latestProgramSize?: InputMaybe<SortEnumType>;
  /** ATM Program size in securities from the latest filing. */
  latestProgramSizeInSecurities?: InputMaybe<SortEnumType>;
  offering?: InputMaybe<OfferingSortInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<SortEnumType>;
  /** Disclosed commission percentage (or maximum percentage) payable to the sales agents under their sales agreement. */
  pctGrossSpread?: InputMaybe<SortEnumType>;
  /** Total Program Remaining in notional based on the latest available quarterly data. */
  totalProgramRemaining?: InputMaybe<SortEnumType>;
  /** Total Program Remaining in securities based on the latest available quarterly data. */
  totalProgramRemainingInSecurities?: InputMaybe<SortEnumType>;
  /** Latest ATM Program (notional) - latest atm program remaining (notional). */
  totalSales?: InputMaybe<SortEnumType>;
  /** Latest ATM Program (in securities) - latest atm program remaining (in securities). */
  totalSalesInSecurities?: InputMaybe<SortEnumType>;
};

/** Represents a view containing data from AtmSelldown and Term */
export type AtmStructure = {
  __typename?: 'AtmStructure';
  /** Announcement date of an atm selldown. */
  announcementDate?: Maybe<Scalars['Date']>;
  /** As Of Date when ATM Selldown occurs. */
  asOfDate?: Maybe<Scalars['Date']>;
  /** Average Price an ATM selldown was sold. */
  averagePrice?: Maybe<Scalars['Decimal']>;
  /** ATM Program remaining in notional as of a particular selldown. */
  programRemaining?: Maybe<Scalars['Decimal']>;
  /** ATM Program remaining in securities as of a particular selldown. */
  programRemainingInSecurities?: Maybe<Scalars['Decimal']>;
  /** ATM Program Size in notional. */
  programSize?: Maybe<Scalars['Decimal']>;
  /** ATM Program Size in securities. */
  programSizeInSecurities?: Maybe<Scalars['Decimal']>;
  /** Amount of ATM sold for a particular selldown. */
  sales?: Maybe<Scalars['Decimal']>;
  /** Securities sold for a particular ATM Selldown. */
  salesInSecurities?: Maybe<Scalars['Decimal']>;
  /** Unique identifier of the selldown record. */
  selldownId?: Maybe<Scalars['UUID']>;
  /** ATM structure type: ANNOUNCED, REVISED, SELLDOWN. */
  type: AtmStructureType;
  /** Display name for Type */
  typeDisplayName?: Maybe<Scalars['String']>;
};

export enum AtmStructureType {
  Announced = 'ANNOUNCED',
  Revised = 'REVISED',
  Selldown = 'SELLDOWN',
}

export type AtmStructureTypeFilterInput = {
  eq?: InputMaybe<AtmStructureType>;
  in?: InputMaybe<Array<InputMaybe<AtmStructureType>>>;
  neq?: InputMaybe<AtmStructureType>;
  nin?: InputMaybe<Array<InputMaybe<AtmStructureType>>>;
};

/** A segment of a collection. */
export type AtmStructureTypesCollectionSegment = {
  __typename?: 'AtmStructureTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfAtmStructureType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AtmStructureTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type CapitalRaisedMarketCapTrends = {
  __typename?: 'CapitalRaisedMarketCapTrends';
  capitalRaisedUsd?: Maybe<ExtendedAggregation>;
  capitalTotalUsd?: Maybe<ExtendedAggregation>;
  marketCapAtPricingUsd?: Maybe<ExtendedAggregation>;
};

export type CollectionLabelAggregationOfAmountAggregation = {
  __typename?: 'CollectionLabelAggregationOfAmountAggregation';
  colorCode?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<AmountAggregation>;
  label: Scalars['String'];
};

export type CollectionLabelAggregationOfCapitalRaisedMarketCapTrends = {
  __typename?: 'CollectionLabelAggregationOfCapitalRaisedMarketCapTrends';
  colorCode?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<CapitalRaisedMarketCapTrends>;
  label: Scalars['String'];
};

export type CollectionLabelAggregationOfCountAggregationByOfferingType = {
  __typename?: 'CollectionLabelAggregationOfCountAggregationByOfferingType';
  colorCode?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<CountAggregationByOfferingType>;
  label: Scalars['String'];
};

export type CollectionLabelAggregationOfExtendedAggregation = {
  __typename?: 'CollectionLabelAggregationOfExtendedAggregation';
  colorCode?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<ExtendedAggregation>;
  label: Scalars['String'];
};

export type CollectionLabelAggregationOfOfferingAggregation = {
  __typename?: 'CollectionLabelAggregationOfOfferingAggregation';
  colorCode?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<OfferingAggregation>;
  label: Scalars['String'];
};

export type CollectionLabelAggregationOfOfferingsByGrossProceedsRange = {
  __typename?: 'CollectionLabelAggregationOfOfferingsByGrossProceedsRange';
  colorCode?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<OfferingsByGrossProceedsRange>;
  label: Scalars['String'];
};

export type CollectionLabelAggregationOfRangeCategorySummary = {
  __typename?: 'CollectionLabelAggregationOfRangeCategorySummary';
  colorCode?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<RangeCategorySummary>;
  label: Scalars['String'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** Indicates the start of the requested page. This is either the request 'skip' argument or a server configured default if no argument is provided. */
  offset: Scalars['Int'];
  /** Indicates the size of the requested page. This is either the request 'take' argument or a server configured default if no argument is provided. */
  pageSize: Scalars['Int'];
};

export type ConvertibleAttributes = {
  __typename?: 'ConvertibleAttributes';
  /** First call date for "hard calls". Soft/provisional calls are listed under "Provisional Call" and "Provisional Call Note". */
  callableDate?: Maybe<Scalars['Date']>;
  /** Call price associated with the "Callable Date". Expressed relative to 100. */
  callPrice?: Maybe<Scalars['Decimal']>;
  /** Free text describing the takeover protection clause in the offering. */
  changeOfControl?: Maybe<Scalars['String']>;
  /** Free text describing the threshold for the underlying stock triggering conversion. */
  contingentConversionNote?: Maybe<Scalars['String']>;
  /** Free text describing the threshold triggering the contingent payment. */
  contingentPaymentNote?: Maybe<Scalars['String']>;
  /** Free Text describing dividend protection provisions. */
  dividendProtectionNote?: Maybe<Scalars['String']>;
  /** Yes/No as to whether there is a change of control note. */
  hasChangeOfControl?: Maybe<Scalars['Boolean']>;
  /** Yes/No as to whether there is a contingent conversion feature prompting conversion into common stock. */
  hasContingentConversion?: Maybe<Scalars['Boolean']>;
  /** Yes/No as to whether there is a contingent payment feature. */
  hasContingentPayment?: Maybe<Scalars['Boolean']>;
  /** Yes/No as to whether the offering has dividend protection provisions. */
  hasDividendProtection?: Maybe<Scalars['Boolean']>;
  /** Yes/No as to whether the offering includes a provisional or "soft" Call. */
  hasProvisionalCall?: Maybe<Scalars['Boolean']>;
  /** Free text describing the hedging transactions to reduce dilution. */
  hedgingNote?: Maybe<Scalars['String']>;
  /** Yes/No as to whether the offering is callable (redeemable) by the issuer prior to maturity. */
  isCallable?: Maybe<Scalars['Boolean']>;
  /** Yes/No as to whether the offering includes a hedging transactions to reduce dilution. */
  isHedging?: Maybe<Scalars['Boolean']>;
  /** Yes/No as to whether maturity is perpetual. */
  isPerpetual?: Maybe<Scalars['Boolean']>;
  /** Yes/No as to whether the offering includes the right to redeem prior to maturity. */
  isPuttable?: Maybe<Scalars['Boolean']>;
  offering?: Maybe<Offering>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** Gross spread per note/share represented as a % of Offer Price ($) (includes any discount/premium). */
  pctGrossSpread?: Maybe<Scalars['Decimal']>;
  /** Free text stating details of any provisional call. */
  provisionalCallNote?: Maybe<Scalars['String']>;
  /** Free text describing the date (dates) in which the holder can redeem prior to maturity. */
  putNote?: Maybe<Scalars['String']>;
  /** Capital structure rank, includes: PREFERRED, SENIOR, SENIOR_SECURE, SENIOR_SUBORDINATED, SUBORDINATED. */
  rank?: Maybe<Rank>;
  /** Display name for Rank */
  rankDisplayName?: Maybe<Scalars['String']>;
  /** Free text describing security being issued (i.e. Cumulative convertible perpetual preferred). */
  securityNote?: Maybe<Scalars['String']>;
  /** Tenor of the security in years, rounded to the nearest 0.25 year. Calculated as maturity date minus settlement date or maturity date minus pricing date if the settlement date is not available. Blank for perpetuals. */
  tenor?: Maybe<Scalars['Decimal']>;
  /** Ticker for underlying stock convertible into. */
  underlyingSymbol?: Maybe<Scalars['String']>;
};

export type ConvertibleAttributesFilterInput = {
  and?: InputMaybe<Array<ConvertibleAttributesFilterInput>>;
  /** First call date for "hard calls". Soft/provisional calls are listed under "Provisional Call" and "Provisional Call Note". */
  callableDate?: InputMaybe<DateOperationFilterInput>;
  /** Call price associated with the "Callable Date". Expressed relative to 100. */
  callPrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Free text describing the takeover protection clause in the offering. */
  changeOfControl?: InputMaybe<StringOperationFilterInput>;
  /** Free text describing the threshold for the underlying stock triggering conversion. */
  contingentConversionNote?: InputMaybe<StringOperationFilterInput>;
  /** Free text describing the threshold triggering the contingent payment. */
  contingentPaymentNote?: InputMaybe<StringOperationFilterInput>;
  /** Free Text describing dividend protection provisions. */
  dividendProtectionNote?: InputMaybe<StringOperationFilterInput>;
  /** Yes/No as to whether there is a change of control note. */
  hasChangeOfControl?: InputMaybe<BooleanOperationFilterInput>;
  /** Yes/No as to whether there is a contingent conversion feature prompting conversion into common stock. */
  hasContingentConversion?: InputMaybe<BooleanOperationFilterInput>;
  /** Yes/No as to whether there is a contingent payment feature. */
  hasContingentPayment?: InputMaybe<BooleanOperationFilterInput>;
  /** Yes/No as to whether the offering has dividend protection provisions. */
  hasDividendProtection?: InputMaybe<BooleanOperationFilterInput>;
  /** Yes/No as to whether the offering includes a provisional or "soft" Call. */
  hasProvisionalCall?: InputMaybe<BooleanOperationFilterInput>;
  /** Free text describing the hedging transactions to reduce dilution. */
  hedgingNote?: InputMaybe<StringOperationFilterInput>;
  /** Yes/No as to whether the offering is callable (redeemable) by the issuer prior to maturity. */
  isCallable?: InputMaybe<BooleanOperationFilterInput>;
  /** Yes/No as to whether the offering includes a hedging transactions to reduce dilution. */
  isHedging?: InputMaybe<BooleanOperationFilterInput>;
  /** Yes/No as to whether maturity is perpetual. */
  isPerpetual?: InputMaybe<BooleanOperationFilterInput>;
  /** Yes/No as to whether the offering includes the right to redeem prior to maturity. */
  isPuttable?: InputMaybe<BooleanOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ConvertibleAttributesFilterInput>>;
  /** Gross spread per note/share represented as a % of Offer Price ($) (includes any discount/premium). */
  pctGrossSpread?: InputMaybe<DecimalOperationFilterInput>;
  /** Free text stating details of any provisional call. */
  provisionalCallNote?: InputMaybe<StringOperationFilterInput>;
  /** Free text describing the date (dates) in which the holder can redeem prior to maturity. */
  putNote?: InputMaybe<StringOperationFilterInput>;
  /** Capital structure rank, includes: PREFERRED, SENIOR, SENIOR_SECURE, SENIOR_SUBORDINATED, SUBORDINATED. */
  rank?: InputMaybe<RankFilterInput>;
  /** Free text describing security being issued (i.e. Cumulative convertible perpetual preferred). */
  securityNote?: InputMaybe<StringOperationFilterInput>;
  /** Tenor of the security in years, rounded to the nearest 0.25 year. Calculated as maturity date minus settlement date or maturity date minus pricing date if the settlement date is not available. Blank for perpetuals. */
  tenor?: InputMaybe<DecimalOperationFilterInput>;
  /** Ticker for underlying stock convertible into. */
  underlyingSymbol?: InputMaybe<StringOperationFilterInput>;
};

export type ConvertibleAttributesSortInput = {
  /** First call date for "hard calls". Soft/provisional calls are listed under "Provisional Call" and "Provisional Call Note". */
  callableDate?: InputMaybe<SortEnumType>;
  /** Call price associated with the "Callable Date". Expressed relative to 100. */
  callPrice?: InputMaybe<SortEnumType>;
  /** Free text describing the takeover protection clause in the offering. */
  changeOfControl?: InputMaybe<SortEnumType>;
  /** Free text describing the threshold for the underlying stock triggering conversion. */
  contingentConversionNote?: InputMaybe<SortEnumType>;
  /** Free text describing the threshold triggering the contingent payment. */
  contingentPaymentNote?: InputMaybe<SortEnumType>;
  /** Free Text describing dividend protection provisions. */
  dividendProtectionNote?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether there is a change of control note. */
  hasChangeOfControl?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether there is a contingent conversion feature prompting conversion into common stock. */
  hasContingentConversion?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether there is a contingent payment feature. */
  hasContingentPayment?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether the offering has dividend protection provisions. */
  hasDividendProtection?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether the offering includes a provisional or "soft" Call. */
  hasProvisionalCall?: InputMaybe<SortEnumType>;
  /** Free text describing the hedging transactions to reduce dilution. */
  hedgingNote?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether the offering is callable (redeemable) by the issuer prior to maturity. */
  isCallable?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether the offering includes a hedging transactions to reduce dilution. */
  isHedging?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether maturity is perpetual. */
  isPerpetual?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether the offering includes the right to redeem prior to maturity. */
  isPuttable?: InputMaybe<SortEnumType>;
  offering?: InputMaybe<OfferingSortInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<SortEnumType>;
  /** Gross spread per note/share represented as a % of Offer Price ($) (includes any discount/premium). */
  pctGrossSpread?: InputMaybe<SortEnumType>;
  /** Free text stating details of any provisional call. */
  provisionalCallNote?: InputMaybe<SortEnumType>;
  /** Free text describing the date (dates) in which the holder can redeem prior to maturity. */
  putNote?: InputMaybe<SortEnumType>;
  /** Capital structure rank, includes: PREFERRED, SENIOR, SENIOR_SECURE, SENIOR_SUBORDINATED, SUBORDINATED. */
  rank?: InputMaybe<RankSortInput>;
  /** Free text describing security being issued (i.e. Cumulative convertible perpetual preferred). */
  securityNote?: InputMaybe<SortEnumType>;
  /** Tenor of the security in years, rounded to the nearest 0.25 year. Calculated as maturity date minus settlement date or maturity date minus pricing date if the settlement date is not available. Blank for perpetuals. */
  tenor?: InputMaybe<SortEnumType>;
  /** Ticker for underlying stock convertible into. */
  underlyingSymbol?: InputMaybe<SortEnumType>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  x?: Maybe<Scalars['Decimal']>;
  y?: Maybe<Scalars['Decimal']>;
};

export type CornerstoneInvestment = {
  __typename?: 'CornerstoneInvestment';
  createdAt: Scalars['Instant'];
  id: Scalars['ID'];
  /** Cornerstone investment per individual or group. Represented in local currency. */
  individualAmount?: Maybe<Scalars['Decimal']>;
  investors: Array<CornerstoneInvestor>;
  modifiedAt: Scalars['Instant'];
  offering: Offering;
  /** Unique CMG identifier for a specific offering. */
  offeringId: Scalars['UUID'];
  /** Number of total cornerstone investors for the offering. */
  totalInvestors: Scalars['Int'];
  /** Enum of cornerstone investment: OFFERING_PARTICIPANT, PRIVATE_PLACEMENT. */
  type: CornerstoneInvestmentType;
  /** Display name for Type */
  typeDisplayName?: Maybe<Scalars['String']>;
};

export type CornerstoneInvestmentFilterInput = {
  and?: InputMaybe<Array<CornerstoneInvestmentFilterInput>>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Cornerstone investment per individual or group. Represented in local currency. */
  individualAmount?: InputMaybe<DecimalOperationFilterInput>;
  investors?: InputMaybe<ListFilterInputTypeOfCornerstoneInvestorFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CornerstoneInvestmentFilterInput>>;
  /** Number of total cornerstone investors for the offering. */
  totalInvestors?: InputMaybe<IntOperationFilterInput>;
  /** Enum of cornerstone investment: OFFERING_PARTICIPANT, PRIVATE_PLACEMENT. */
  type?: InputMaybe<CornerstoneInvestmentTypeFilterInput>;
};

export enum CornerstoneInvestmentType {
  OfferingParticipant = 'OFFERING_PARTICIPANT',
  PrivatePlacement = 'PRIVATE_PLACEMENT',
}

export type CornerstoneInvestmentTypeFilterInput = {
  eq?: InputMaybe<CornerstoneInvestmentType>;
  in?: InputMaybe<Array<InputMaybe<CornerstoneInvestmentType>>>;
  neq?: InputMaybe<CornerstoneInvestmentType>;
  nin?: InputMaybe<Array<InputMaybe<CornerstoneInvestmentType>>>;
};

/** A segment of a collection. */
export type CornerstoneInvestmentTypesCollectionSegment = {
  __typename?: 'CornerstoneInvestmentTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfCornerstoneInvestmentType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type CornerstoneInvestmentTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type CornerstoneInvestor = {
  __typename?: 'CornerstoneInvestor';
  cornerstoneInvestment: CornerstoneInvestment;
  cornerstoneInvestmentId: Scalars['UUID'];
  createdAt: Scalars['Instant'];
  id: Scalars['ID'];
  /** Returns Yes if an existing investor with this issuer. Returns No if not an existing investor with this issuer. Returns NULL if unknown. */
  isExistingCornerstoneInvestor: Scalars['Boolean'];
  modifiedAt: Scalars['Instant'];
  shareholder: Shareholder;
  /** Unique CMG identifier for the cornerstone investor. */
  shareholderId: Scalars['UUID'];
};

export type CornerstoneInvestorFilterInput = {
  and?: InputMaybe<Array<CornerstoneInvestorFilterInput>>;
  cornerstoneInvestment?: InputMaybe<CornerstoneInvestmentFilterInput>;
  cornerstoneInvestmentId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Returns Yes if an existing investor with this issuer. Returns No if not an existing investor with this issuer. Returns NULL if unknown. */
  isExistingCornerstoneInvestor?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  or?: InputMaybe<Array<CornerstoneInvestorFilterInput>>;
  shareholder?: InputMaybe<ShareholderFilterInput>;
  /** Unique CMG identifier for the cornerstone investor. */
  shareholderId?: InputMaybe<UuidOperationFilterInput>;
};

export type CountAggregation = {
  __typename?: 'CountAggregation';
  count?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Decimal']>;
};

export type CountAggregationByOfferingType = {
  __typename?: 'CountAggregationByOfferingType';
  ipo?: Maybe<CountAggregation>;
  marketedFo?: Maybe<CountAggregation>;
  overnightFo?: Maybe<CountAggregation>;
  registeredBlock?: Maybe<CountAggregation>;
  rights?: Maybe<CountAggregation>;
  unregisteredBlock?: Maybe<CountAggregation>;
};

/** A segment of a collection. */
export type CountriesCollectionSegment = {
  __typename?: 'CountriesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfCountry>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export enum Country {
  Ae = 'AE',
  Ar = 'AR',
  At = 'AT',
  Au = 'AU',
  Bd = 'BD',
  Be = 'BE',
  Bh = 'BH',
  Br = 'BR',
  Ca = 'CA',
  Ch = 'CH',
  Ci = 'CI',
  Cl = 'CL',
  Cn = 'CN',
  Co = 'CO',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dk = 'DK',
  Ee = 'EE',
  Eg = 'EG',
  Es = 'ES',
  Fi = 'FI',
  Fr = 'FR',
  Gb = 'GB',
  Gr = 'GR',
  Hk = 'HK',
  Hr = 'HR',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  Is = 'IS',
  It = 'IT',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kr = 'KR',
  Kw = 'KW',
  Lk = 'LK',
  Lu = 'LU',
  Ma = 'MA',
  Mx = 'MX',
  My = 'MY',
  Ng = 'NG',
  Nl = 'NL',
  No = 'NO',
  Nz = 'NZ',
  Om = 'OM',
  Pe = 'PE',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pt = 'PT',
  Qa = 'QA',
  Ro = 'RO',
  Ru = 'RU',
  Sa = 'SA',
  Se = 'SE',
  Sg = 'SG',
  Si = 'SI',
  Sy = 'SY',
  Th = 'TH',
  Tr = 'TR',
  Tw = 'TW',
  Us = 'US',
  Vn = 'VN',
  Za = 'ZA',
}

export type CountryFilterInput = {
  eq?: InputMaybe<Country>;
  in?: InputMaybe<Array<InputMaybe<Country>>>;
  neq?: InputMaybe<Country>;
  nin?: InputMaybe<Array<InputMaybe<Country>>>;
};

export type CountrySortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type CurrenciesCollectionSegment = {
  __typename?: 'CurrenciesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfCurrency>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sle = 'SLE',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ved = 'VED',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpf = 'XPF',
  Xsu = 'XSU',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL',
}

export type CurrencyFilterInput = {
  eq?: InputMaybe<Currency>;
  in?: InputMaybe<Array<InputMaybe<Currency>>>;
  neq?: InputMaybe<Currency>;
  nin?: InputMaybe<Array<InputMaybe<Currency>>>;
};

export type CurrencySortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type CustomSector = {
  __typename?: 'CustomSector';
  children: Array<CustomSector>;
  colorCode?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Instant'];
  id: Scalars['ID'];
  modifiedAt: Scalars['Instant'];
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<CustomSector>;
  parentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<CustomSectorType>;
  /** Display name for Type */
  typeDisplayName?: Maybe<Scalars['String']>;
};

export type CustomSectorAddInputData = {
  colorCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<CustomSectorType>;
};

/** A segment of a collection. */
export type CustomSectorCollectionSegment = {
  __typename?: 'CustomSectorCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<CustomSector>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type CustomSectorFilterInput = {
  and?: InputMaybe<Array<CustomSectorFilterInput>>;
  children?: InputMaybe<ListFilterInputTypeOfCustomSectorFilterInput>;
  colorCode?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomSectorFilterInput>>;
  parent?: InputMaybe<CustomSectorFilterInput>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  type?: InputMaybe<CustomSectorTypeFilterInput>;
};

export type CustomSectorIssuer = {
  __typename?: 'CustomSectorIssuer';
  customSector?: Maybe<CustomSector>;
  customSectorId?: Maybe<Scalars['UUID']>;
  customSubSector?: Maybe<CustomSector>;
  customSubsectorId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  issuerId?: Maybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type CustomSectorIssuerCollectionSegment = {
  __typename?: 'CustomSectorIssuerCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<CustomSectorIssuer>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type CustomSectorIssuerFilterInput = {
  and?: InputMaybe<Array<CustomSectorIssuerFilterInput>>;
  customSector?: InputMaybe<CustomSectorFilterInput>;
  customSectorId?: InputMaybe<UuidOperationFilterInput>;
  customSubSector?: InputMaybe<CustomSectorFilterInput>;
  customSubsectorId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  issuerId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CustomSectorIssuerFilterInput>>;
};

export type CustomSectorIssuerSortInput = {
  customSector?: InputMaybe<CustomSectorSortInput>;
  customSectorId?: InputMaybe<SortEnumType>;
  customSubSector?: InputMaybe<CustomSectorSortInput>;
  customSubsectorId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  issuerId?: InputMaybe<SortEnumType>;
};

export type CustomSectorSortInput = {
  colorCode?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parent?: InputMaybe<CustomSectorSortInput>;
  parentId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<CustomSectorTypeSortInput>;
};

export enum CustomSectorType {
  Sector = 'SECTOR',
  SubSector = 'SUB_SECTOR',
}

export type CustomSectorTypeFilterInput = {
  eq?: InputMaybe<CustomSectorType>;
  in?: InputMaybe<Array<InputMaybe<CustomSectorType>>>;
  neq?: InputMaybe<CustomSectorType>;
  nin?: InputMaybe<Array<InputMaybe<CustomSectorType>>>;
};

/** A segment of a collection. */
export type CustomSectorTypesCollectionSegment = {
  __typename?: 'CustomSectorTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfCustomSectorType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type CustomSectorTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type CustomSectorUpdateInputData = {
  colorCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<CustomSectorType>;
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  neq?: InputMaybe<Scalars['Date']>;
  ngt?: InputMaybe<Scalars['Date']>;
  ngte?: InputMaybe<Scalars['Date']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  nlt?: InputMaybe<Scalars['Date']>;
  nlte?: InputMaybe<Scalars['Date']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type DeleteCustomSectorInput = {
  id: Scalars['ID'];
};

export type DeleteCustomSectorPayload = {
  __typename?: 'DeleteCustomSectorPayload';
  customSector?: Maybe<CustomSector>;
};

export type Document = {
  __typename?: 'Document';
  /** Link to the document. */
  link: Scalars['URL'];
  /** Document name. */
  name?: Maybe<Scalars['String']>;
  offering?: Maybe<Offering>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** Publication Date of the document. */
  publicationDate?: Maybe<Scalars['Date']>;
  /** Include: SOURCE_DOCUMENT, REGISTRATION_INITIAL, REGISTRATION_REVISION, REGISTRATION_FINAL. */
  type: DocumentType;
  /** Display name for Type */
  typeDisplayName?: Maybe<Scalars['String']>;
};

export type DocumentFilterInput = {
  and?: InputMaybe<Array<DocumentFilterInput>>;
  /** Link to the document. */
  link?: InputMaybe<UrlOperationFilterInput>;
  /** Document name. */
  name?: InputMaybe<StringOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<DocumentFilterInput>>;
  /** Publication Date of the document. */
  publicationDate?: InputMaybe<DateOperationFilterInput>;
  /** Include: SOURCE_DOCUMENT, REGISTRATION_INITIAL, REGISTRATION_REVISION, REGISTRATION_FINAL. */
  type?: InputMaybe<DocumentTypeFilterInput>;
};

export enum DocumentType {
  RegistrationFinal = 'REGISTRATION_FINAL',
  RegistrationInitial = 'REGISTRATION_INITIAL',
  RegistrationRevision = 'REGISTRATION_REVISION',
  SourceDocument = 'SOURCE_DOCUMENT',
}

export type DocumentTypeFilterInput = {
  eq?: InputMaybe<DocumentType>;
  in?: InputMaybe<Array<InputMaybe<DocumentType>>>;
  neq?: InputMaybe<DocumentType>;
  nin?: InputMaybe<Array<InputMaybe<DocumentType>>>;
};

/** A segment of a collection. */
export type DocumentTypesCollectionSegment = {
  __typename?: 'DocumentTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfDocumentType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DocumentTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum EgcStatus {
  No = 'NO',
  NotIndicated = 'NOT_INDICATED',
  Yes = 'YES',
}

/** A segment of a collection. */
export type EgcStatusesCollectionSegment = {
  __typename?: 'EgcStatusesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfEgcStatus>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type EgcStatusFilterInput = {
  eq?: InputMaybe<EgcStatus>;
  in?: InputMaybe<Array<InputMaybe<EgcStatus>>>;
  neq?: InputMaybe<EgcStatus>;
  nin?: InputMaybe<Array<InputMaybe<EgcStatus>>>;
};

export type EgcStatusSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum EntityType {
  Bdc = 'BDC',
  Lp = 'LP',
  Reit = 'REIT',
  Spac = 'SPAC',
}

export type EntityTypeFilterInput = {
  eq?: InputMaybe<EntityType>;
  in?: InputMaybe<Array<InputMaybe<EntityType>>>;
  neq?: InputMaybe<EntityType>;
  nin?: InputMaybe<Array<InputMaybe<EntityType>>>;
};

export type EntityTypeListFilterInput = {
  all?: InputMaybe<EntityTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EntityTypeFilterInput>;
  some?: InputMaybe<EntityTypeFilterInput>;
};

/** A segment of a collection. */
export type EntityTypesCollectionSegment = {
  __typename?: 'EntityTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfEntityType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type EntityTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfAdviserRole = {
  __typename?: 'EnumLabelOfAdviserRole';
  displayName: Scalars['String'];
  id: AdviserRole;
  value: Scalars['Int'];
};

export type EnumLabelOfAdviserRoleFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfAdviserRoleFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<AdviserRoleFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfAdviserRoleFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfAdviserRoleSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<AdviserRoleSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfAdviserType = {
  __typename?: 'EnumLabelOfAdviserType';
  displayName: Scalars['String'];
  id: AdviserType;
  value: Scalars['Int'];
};

export type EnumLabelOfAdviserTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfAdviserTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<AdviserTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfAdviserTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfAdviserTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<AdviserTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfAllocationRanking = {
  __typename?: 'EnumLabelOfAllocationRanking';
  displayName: Scalars['String'];
  id: AllocationRanking;
  value: Scalars['Int'];
};

export type EnumLabelOfAllocationRankingFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfAllocationRankingFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<AllocationRankingFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfAllocationRankingFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfAllocationRankingSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<AllocationRankingSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfAtmStructureType = {
  __typename?: 'EnumLabelOfAtmStructureType';
  displayName: Scalars['String'];
  id: AtmStructureType;
  value: Scalars['Int'];
};

export type EnumLabelOfAtmStructureTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfAtmStructureTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<AtmStructureTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfAtmStructureTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfAtmStructureTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<AtmStructureTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfCornerstoneInvestmentType = {
  __typename?: 'EnumLabelOfCornerstoneInvestmentType';
  displayName: Scalars['String'];
  id: CornerstoneInvestmentType;
  value: Scalars['Int'];
};

export type EnumLabelOfCornerstoneInvestmentTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfCornerstoneInvestmentTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<CornerstoneInvestmentTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfCornerstoneInvestmentTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfCornerstoneInvestmentTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<CornerstoneInvestmentTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfCountry = {
  __typename?: 'EnumLabelOfCountry';
  displayName: Scalars['String'];
  id: Country;
  region?: Maybe<EnumLabelOfRegion>;
  value: Scalars['Int'];
};

export type EnumLabelOfCountryFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfCountryFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<CountryFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfCountryFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfCountrySortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<CountrySortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfCurrency = {
  __typename?: 'EnumLabelOfCurrency';
  displayName: Scalars['String'];
  id: Currency;
  value: Scalars['Int'];
};

export type EnumLabelOfCurrencyFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfCurrencyFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<CurrencyFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfCurrencyFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfCurrencySortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<CurrencySortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfCustomSectorType = {
  __typename?: 'EnumLabelOfCustomSectorType';
  displayName: Scalars['String'];
  id: CustomSectorType;
  value: Scalars['Int'];
};

export type EnumLabelOfCustomSectorTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfCustomSectorTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<CustomSectorTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfCustomSectorTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfCustomSectorTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<CustomSectorTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfDocumentType = {
  __typename?: 'EnumLabelOfDocumentType';
  displayName: Scalars['String'];
  id: DocumentType;
  value: Scalars['Int'];
};

export type EnumLabelOfDocumentTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfDocumentTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<DocumentTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfDocumentTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfDocumentTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<DocumentTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfEgcStatus = {
  __typename?: 'EnumLabelOfEgcStatus';
  displayName: Scalars['String'];
  id: EgcStatus;
  value: Scalars['Int'];
};

export type EnumLabelOfEgcStatusFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfEgcStatusFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<EgcStatusFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfEgcStatusFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfEgcStatusSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<EgcStatusSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfEntityType = {
  __typename?: 'EnumLabelOfEntityType';
  displayName: Scalars['String'];
  id: EntityType;
  value: Scalars['Int'];
};

export type EnumLabelOfEntityTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfEntityTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<EntityTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfEntityTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfEntityTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<EntityTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfIoiType = {
  __typename?: 'EnumLabelOfIoiType';
  displayName: Scalars['String'];
  id: IoiType;
  value: Scalars['Int'];
};

export type EnumLabelOfIoiTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfIoiTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IoiTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfIoiTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfIoiTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<IoiTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfLockUpBenchmarkReference = {
  __typename?: 'EnumLabelOfLockUpBenchmarkReference';
  displayName: Scalars['String'];
  id: LockUpBenchmarkReference;
  value: Scalars['Int'];
};

export type EnumLabelOfLockUpBenchmarkReferenceFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfLockUpBenchmarkReferenceFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<LockUpBenchmarkReferenceFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfLockUpBenchmarkReferenceFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfLockUpBenchmarkReferenceSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<LockUpBenchmarkReferenceSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfLockUpPartyType = {
  __typename?: 'EnumLabelOfLockUpPartyType';
  displayName: Scalars['String'];
  id: LockUpPartyType;
  value: Scalars['Int'];
};

export type EnumLabelOfLockUpPartyTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfLockUpPartyTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<LockUpPartyTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfLockUpPartyTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfLockUpPartyTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<LockUpPartyTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfLockUpUnit = {
  __typename?: 'EnumLabelOfLockUpUnit';
  displayName: Scalars['String'];
  id: LockUpUnit;
  value: Scalars['Int'];
};

export type EnumLabelOfLockUpUnitFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfLockUpUnitFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<LockUpUnitFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfLockUpUnitFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfLockUpUnitSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<LockUpUnitSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfMarketTiming = {
  __typename?: 'EnumLabelOfMarketTiming';
  displayName: Scalars['String'];
  id: MarketTiming;
  value: Scalars['Int'];
};

export type EnumLabelOfMarketTimingFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfMarketTimingFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<MarketTimingFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfMarketTimingFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfMarketTimingSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<MarketTimingSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfOfferingDateType = {
  __typename?: 'EnumLabelOfOfferingDateType';
  displayName: Scalars['String'];
  id: OfferingDateType;
  value: Scalars['Int'];
};

export type EnumLabelOfOfferingDateTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfOfferingDateTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<OfferingDateTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfOfferingDateTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfOfferingDateTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<OfferingDateTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfOfferingManagerRole = {
  __typename?: 'EnumLabelOfOfferingManagerRole';
  displayName: Scalars['String'];
  id: OfferingManagerRole;
  value: Scalars['Int'];
};

export type EnumLabelOfOfferingManagerRoleFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfOfferingManagerRoleFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<OfferingManagerRoleFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfOfferingManagerRoleFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfOfferingManagerRoleSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<OfferingManagerRoleSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfOfferingSetupExchangeMic = {
  __typename?: 'EnumLabelOfOfferingSetupExchangeMic';
  displayName: Scalars['String'];
  id: OfferingSetupExchangeMic;
  value: Scalars['Int'];
};

export type EnumLabelOfOfferingSetupExchangeMicFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfOfferingSetupExchangeMicFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<OfferingSetupExchangeMicFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfOfferingSetupExchangeMicFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfOfferingSetupExchangeMicSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<OfferingSetupExchangeMicSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfOfferingStatus = {
  __typename?: 'EnumLabelOfOfferingStatus';
  displayName: Scalars['String'];
  id: OfferingStatus;
  value: Scalars['Int'];
};

export type EnumLabelOfOfferingStatusFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfOfferingStatusFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<OfferingStatusFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfOfferingStatusFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfOfferingStatusSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<OfferingStatusSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfOfferingType = {
  __typename?: 'EnumLabelOfOfferingType';
  displayName: Scalars['String'];
  id: OfferingType;
  value: Scalars['Int'];
};

export type EnumLabelOfOfferingTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfOfferingTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<OfferingTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfOfferingTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfOfferingTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<OfferingTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfPriceVsRange = {
  __typename?: 'EnumLabelOfPriceVsRange';
  displayName: Scalars['String'];
  id: PriceVsRange;
  value: Scalars['Int'];
};

export type EnumLabelOfPriceVsRangeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfPriceVsRangeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<PriceVsRangeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfPriceVsRangeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfPriceVsRangeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<PriceVsRangeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfRank = {
  __typename?: 'EnumLabelOfRank';
  displayName: Scalars['String'];
  id: Rank;
  value: Scalars['Int'];
};

export type EnumLabelOfRankFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfRankFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<RankFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfRankFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfRankSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<RankSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfRegion = {
  __typename?: 'EnumLabelOfRegion';
  displayName: Scalars['String'];
  id: Region;
  value: Scalars['Int'];
};

export type EnumLabelOfSector = {
  __typename?: 'EnumLabelOfSector';
  displayName: Scalars['String'];
  id: Sector;
  value: Scalars['Int'];
};

export type EnumLabelOfSectorFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfSectorFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<SectorFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfSectorFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfSectorSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SectorSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfSecurityType = {
  __typename?: 'EnumLabelOfSecurityType';
  displayName: Scalars['String'];
  id: SecurityType;
  value: Scalars['Int'];
};

export type EnumLabelOfSecurityTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfSecurityTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<SecurityTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfSecurityTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfSecurityTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SecurityTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfShareClass = {
  __typename?: 'EnumLabelOfShareClass';
  displayName: Scalars['String'];
  id: ShareClass;
  value: Scalars['Int'];
};

export type EnumLabelOfShareClassFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfShareClassFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ShareClassFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfShareClassFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfShareClassSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<ShareClassSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfShareholderType = {
  __typename?: 'EnumLabelOfShareholderType';
  displayName: Scalars['String'];
  id: ShareholderType;
  value: Scalars['Int'];
};

export type EnumLabelOfShareholderTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfShareholderTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ShareholderTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfShareholderTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfShareholderTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<ShareholderTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfSubSector = {
  __typename?: 'EnumLabelOfSubSector';
  displayName: Scalars['String'];
  id: SubSector;
  value: Scalars['Int'];
};

export type EnumLabelOfSubSectorFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfSubSectorFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<SubSectorFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfSubSectorFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfSubSectorSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SubSectorSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfTermType = {
  __typename?: 'EnumLabelOfTermType';
  displayName: Scalars['String'];
  id: TermType;
  value: Scalars['Int'];
};

export type EnumLabelOfTermTypeFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfTermTypeFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<TermTypeFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfTermTypeFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfTermTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<TermTypeSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfUseOfProceeds = {
  __typename?: 'EnumLabelOfUseOfProceeds';
  displayName: Scalars['String'];
  id: UseOfProceeds;
  value: Scalars['Int'];
};

export type EnumLabelOfUseOfProceedsFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfUseOfProceedsFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UseOfProceedsFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfUseOfProceedsFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfUseOfProceedsSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<UseOfProceedsSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type EnumLabelOfVersusTalk = {
  __typename?: 'EnumLabelOfVersusTalk';
  displayName: Scalars['String'];
  id: VersusTalk;
  value: Scalars['Int'];
};

export type EnumLabelOfVersusTalkFilterInput = {
  and?: InputMaybe<Array<EnumLabelOfVersusTalkFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<VersusTalkFilterInput>;
  or?: InputMaybe<Array<EnumLabelOfVersusTalkFilterInput>>;
  value?: InputMaybe<IntOperationFilterInput>;
};

export type EnumLabelOfVersusTalkSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<VersusTalkSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export enum ExchangeCode {
  Adf = 'ADF',
  Adrk = 'ADRK',
  Ads = 'ADS',
  Alg = 'ALG',
  Amm = 'AMM',
  Ams = 'AMS',
  Apxl = 'APXL',
  Aqse = 'AQSE',
  Arcx = 'ARCX',
  Arm = 'ARM',
  Ase = 'ASE',
  Asx = 'ASX',
  Ath = 'ATH',
  Ats = 'ATS',
  Atx = 'ATX',
  Aus = 'AUS',
  Baa = 'BAA',
  Bab = 'BAB',
  Bah = 'BAH',
  Bar = 'BAR',
  Bate = 'BATE',
  Bats = 'BATS',
  Baty = 'BATY',
  Bda = 'BDA',
  Bekb = 'BEKB',
  Bel = 'BEL',
  Ber = 'BER',
  Bey = 'BEY',
  Bil = 'BIL',
  Bjb = 'BJB',
  Bjse = 'BJSE',
  Bkk = 'BKK',
  Blb = 'BLB',
  Blnk = 'BLNK',
  Blox = 'BLOX',
  Bnp = 'BNP',
  Bnv = 'BNV',
  Boat = 'BOAT',
  Bog = 'BOG',
  Bol = 'BOL',
  Bom = 'BOM',
  Bos = 'BOS',
  Bot = 'BOT',
  Bra = 'BRA',
  Brn = 'BRN',
  Bru = 'BRU',
  Brv = 'BRV',
  Bsar = 'BSAR',
  Bse = 'BSE',
  Bsp = 'BSP',
  Btsr = 'BTSR',
  Btsy = 'BTSY',
  Bud = 'BUD',
  Bue = 'BUE',
  Bul = 'BUL',
  Burg = 'BURG',
  Bvmf = 'BVMF',
  Bvr = 'BVR',
  Bzx = 'BZX',
  Cai = 'CAI',
  Can = 'CAN',
  Capa = 'CAPA',
  Car = 'CAR',
  Cas = 'CAS',
  Cay = 'CAY',
  Cbo = 'CBO',
  Ccrm = 'CCRM',
  Ccxe = 'CCXE',
  Chi = 'CHI',
  Chia = 'CHIA',
  Chic = 'CHIC',
  Chix = 'CHIX',
  Cie = 'CIE',
  Cis = 'CIS',
  Cnq = 'CNQ',
  Col = 'COL',
  Cotc = 'COTC',
  Cse = 'CSE',
  Cse_2 = 'CSE_2',
  Csfb = 'CSFB',
  Csx = 'CSX',
  Cx2 = 'CX2',
  Cxd = 'CXD',
  Cys = 'CYS',
  Damp = 'DAMP',
  Dar = 'DAR',
  Dbxm = 'DBXM',
  Deu = 'DEU',
  Dfm = 'DFM',
  Dha = 'DHA',
  Difx = 'DIFX',
  Dktc = 'DKTC',
  Dotc = 'DOTC',
  Dse = 'DSE',
  Dsmd = 'DSMD',
  Dub = 'DUB',
  Dus = 'DUS',
  Dxe = 'DXE',
  Dzcd = 'DZCD',
  Edar = 'EDAR',
  Edga = 'EDGA',
  Edgx = 'EDGX',
  Edxr = 'EDXR',
  Eea = 'EEA',
  Emtf = 'EMTF',
  Enx = 'ENX',
  Eprl = 'EPRL',
  Eti = 'ETI',
  Etlx = 'ETLX',
  Etr = 'ETR',
  Euct = 'EUCT',
  Eur = 'EUR',
  Fka = 'FKA',
  Fra = 'FRA',
  Gha = 'GHA',
  Gotc = 'GOTC',
  Grey = 'GREY',
  Gse = 'GSE',
  Gsil = 'GSIL',
  Gsxl = 'GSXL',
  Gua = 'GUA',
  Ham = 'HAM',
  Han = 'HAN',
  Hel = 'HEL',
  Hkg = 'HKG',
  Hmtf = 'HMTF',
  Hotc = 'HOTC',
  Hsc = 'HSC',
  Hstc = 'HSTC',
  Ice = 'ICE',
  Icx = 'ICX',
  Iexg = 'IEXG',
  Ind = 'IND',
  Iotc = 'IOTC',
  Iqs = 'IQS',
  Ise = 'ISE',
  Isef = 'ISEF',
  Ist = 'IST',
  Jam = 'JAM',
  Jas = 'JAS',
  Jkt = 'JKT',
  Jse = 'JSE',
  Kar = 'KAR',
  Kaz = 'KAZ',
  Kcge = 'KCGE',
  Kha = 'KHA',
  Kls = 'KLS',
  Kon = 'KON',
  Kotc = 'KOTC',
  Krx = 'KRX',
  Kuw = 'KUW',
  Lao = 'LAO',
  Lasz = 'LASZ',
  Ldn = 'LDN',
  Lica = 'LICA',
  Lim = 'LIM',
  Lipa = 'LIPA',
  Liqu = 'LIQU',
  Lis = 'LIS',
  Lit = 'LIT',
  Lju = 'LJU',
  Lon = 'LON',
  Lotc = 'LOTC',
  Lsm = 'LSM',
  Lts = 'LTS',
  Ltse = 'LTSE',
  Lus = 'LUS',
  Lux = 'LUX',
  Lynx = 'LYNX',
  Mad = 'MAD',
  Mae = 'MAE',
  Mal = 'MAL',
  Matn = 'MATN',
  Mau = 'MAU',
  Mce = 'MCE',
  Memx = 'MEMX',
  Mex = 'MEX',
  Mic = 'MIC',
  Mil = 'MIL',
  Misx = 'MISX',
  Mnt = 'MNT',
  Mnx = 'MNX',
  Mod = 'MOD',
  Motc = 'MOTC',
  Msta = 'MSTA',
  Mstb = 'MSTB',
  Mstc = 'MSTC',
  Mste = 'MSTE',
  Msts = 'MSTS',
  Mstu = 'MSTU',
  Mstz = 'MSTZ',
  Msw = 'MSW',
  Mtah = 'MTAH',
  Mun = 'MUN',
  Munc = 'MUNC',
  Mus = 'MUS',
  Nai = 'NAI',
  Nam = 'NAM',
  Nas = 'NAS',
  Nasc = 'NASC',
  Nasq = 'NASQ',
  Nec = 'NEC',
  Neod = 'NEOD',
  Neoe = 'NEOE',
  Neol = 'NEOL',
  Nep = 'NEP',
  Ngm = 'NGM',
  Ngo = 'NGO',
  Notc = 'NOTC',
  Nsa = 'NSA',
  Nse = 'NSE',
  Nwtc = 'NWTC',
  Nys = 'NYS',
  Nze = 'NZE',
  Ome = 'OME',
  Omga = 'OMGA',
  One = 'ONE',
  Ose = 'OSE',
  Osl = 'OSL',
  Otc = 'OTC',
  Pae = 'PAE',
  Par = 'PAR',
  Pfts = 'PFTS',
  Phl = 'PHL',
  Phs = 'PHS',
  Pinx = 'PINX',
  Placeholder = 'PLACEHOLDER',
  Plu = 'PLU',
  Pom = 'POM',
  Pra = 'PRA',
  Pse = 'PSE',
  Pty = 'PTY',
  Pure = 'PURE',
  Qmh = 'QMH',
  Qtx = 'QTX',
  Qui = 'QUI',
  Ras = 'RAS',
  Rio = 'RIO',
  Ris = 'RIS',
  Roco = 'ROCO',
  Rotc = 'ROTC',
  Rsex = 'RSEX',
  Rus = 'RUS',
  Sap = 'SAP',
  Sara = 'SARA',
  Sat = 'SAT',
  Sau = 'SAU',
  Sbil = 'SBIL',
  Sec = 'SEC',
  Sedx = 'SEDX',
  Ses = 'SES',
  Sfd = 'SFD',
  Sgo = 'SGO',
  She = 'SHE',
  Shg = 'SHG',
  Sotc = 'SOTC',
  Ssc = 'SSC',
  Sse = 'SSE',
  Stc = 'STC',
  Stu = 'STU',
  Sva = 'SVA',
  Swa = 'SWA',
  Swx = 'SWX',
  Szsc = 'SZSC',
  Tae = 'TAE',
  Tai = 'TAI',
  Tal = 'TAL',
  Teh = 'TEH',
  Tgat = 'TGAT',
  Tks = 'TKS',
  Tmsx = 'TMSX',
  Tqex = 'TQEX',
  Trn = 'TRN',
  Trqx = 'TRQX',
  Tse = 'TSE',
  Tsx = 'TSX',
  Tun = 'TUN',
  Tvtc = 'TVTC',
  Uax = 'UAX',
  Ubs = 'UBS',
  Ubsw = 'UBSW',
  Uga = 'UGA',
  Ula = 'ULA',
  Uotc = 'UOTC',
  Usa = 'USA',
  Val = 'VAL',
  Vonb = 'VONB',
  Vtx = 'VTX',
  War = 'WAR',
  Wbo = 'WBO',
  Wotc = 'WOTC',
  Xex = 'XEX',
  Xkaz = 'XKAZ',
  Xnec = 'XNEC',
  Xpos = 'XPOS',
  Xtxi = 'XTXI',
  Xtxn = 'XTXN',
  Xtxp = 'XTXP',
  Xubs = 'XUBS',
  Zag = 'ZAG',
  Zim = 'ZIM',
  Zkbx = 'ZKBX',
  Zotc = 'ZOTC',
  Zzad = 'ZZAD',
  Zzae = 'ZZAE',
  Zzag = 'ZZAG',
  Zzai = 'ZZAI',
  Zzan = 'ZZAN',
  Zzap = 'ZZAP',
  Zzat = 'ZZAT',
  Zzau = 'ZZAU',
  Zzaw = 'ZZAW',
  Zzbb = 'ZZBB',
  Zzbe = 'ZZBE',
  Zzbh = 'ZZBH',
  Zzbm = 'ZZBM',
  Zzbr = 'ZZBR',
  Zzbs = 'ZZBS',
  Zzbw = 'ZZBW',
  Zzbz = 'ZZBZ',
  Zzca = 'ZZCA',
  Zzch = 'ZZCH',
  Zzck = 'ZZCK',
  Zzcl = 'ZZCL',
  Zzcn = 'ZZCN',
  Zzcr = 'ZZCR',
  Zzcw = 'ZZCW',
  Zzcz = 'ZZCZ',
  Zzde = 'ZZDE',
  Zzdk = 'ZZDK',
  Zzee = 'ZZEE',
  Zzes = 'ZZES',
  Zzeu = 'ZZEU',
  Zzfi = 'ZZFI',
  Zzfr = 'ZZFR',
  Zzgb = 'ZZGB',
  Zzgg = 'ZZGG',
  Zzgr = 'ZZGR',
  Zzhk = 'ZZHK',
  Zzhr = 'ZZHR',
  Zzhu = 'ZZHU',
  Zzid = 'ZZID',
  Zzie = 'ZZIE',
  Zzim = 'ZZIM',
  Zzin = 'ZZIN',
  Zzis = 'ZZIS',
  Zzit = 'ZZIT',
  Zzje = 'ZZJE',
  Zzjp = 'ZZJP',
  Zzkr = 'ZZKR',
  Zzkw = 'ZZKW',
  Zzky = 'ZZKY',
  Zzlb = 'ZZLB',
  Zzlc = 'ZZLC',
  Zzli = 'ZZLI',
  Zzlu = 'ZZLU',
  Zzmc = 'ZZMC',
  Zzmh = 'ZZMH',
  Zzmt = 'ZZMT',
  Zzmu = 'ZZMU',
  Zzmy = 'ZZMY',
  Zznl = 'ZZNL',
  Zzno = 'ZZNO',
  Zznz = 'ZZNZ',
  Zzom = 'ZZOM',
  Zzph = 'ZZPH',
  Zzpk = 'ZZPK',
  Zzpl = 'ZZPL',
  Zzpr = 'ZZPR',
  Zzpt = 'ZZPT',
  Zzru = 'ZZRU',
  Zzsa = 'ZZSA',
  Zzsc = 'ZZSC',
  Zzse = 'ZZSE',
  Zzsg = 'ZZSG',
  Zzth = 'ZZTH',
  Zztr = 'ZZTR',
  Zztw = 'ZZTW',
  Zzua = 'ZZUA',
  Zzus = 'ZZUS',
  Zzvc = 'ZZVC',
  Zzvg = 'ZZVG',
  Zzvi = 'ZZVI',
  Zzza = 'ZZZA',
}

export type ExtendedAggregation = {
  __typename?: 'ExtendedAggregation';
  count: Scalars['Int'];
  investment?: Maybe<Scalars['Decimal']>;
  investmentCount?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Decimal']>;
  mean?: Maybe<Scalars['Decimal']>;
  median?: Maybe<Scalars['Decimal']>;
  min?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
  value?: Maybe<Scalars['Decimal']>;
  weight?: Maybe<Scalars['Decimal']>;
};

export type FirmMetric = {
  __typename?: 'FirmMetric';
  allocation?: Maybe<Scalars['Decimal']>;
  fillRate?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  ioiShares?: Maybe<Scalars['Decimal']>;
  offering?: Maybe<Offering>;
};

export type FirmMetricFilterInput = {
  allocation?: InputMaybe<DecimalOperationFilterInput>;
  allocationInvestment?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<FirmMetricFilterInput>>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  customSector?: InputMaybe<StringOperationFilterInput>;
  customSectorColorCode?: InputMaybe<IntOperationFilterInput>;
  customSectorId?: InputMaybe<UuidOperationFilterInput>;
  customSubsector?: InputMaybe<StringOperationFilterInput>;
  customSubsectorId?: InputMaybe<UuidOperationFilterInput>;
  fillRate?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  ioiShares?: InputMaybe<DecimalOperationFilterInput>;
  ioiType?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<FirmMetricFilterInput>>;
  pctAllocation?: InputMaybe<DecimalOperationFilterInput>;
  returnCurrent?: InputMaybe<DecimalOperationFilterInput>;
  returnDay1?: InputMaybe<DecimalOperationFilterInput>;
  returnDay3?: InputMaybe<DecimalOperationFilterInput>;
  returnDay7?: InputMaybe<DecimalOperationFilterInput>;
  returnOpen?: InputMaybe<DecimalOperationFilterInput>;
  returnPriorQuarter?: InputMaybe<DecimalOperationFilterInput>;
  salesConcession?: InputMaybe<DecimalOperationFilterInput>;
};

export type FirmMetricSortInput = {
  allocation?: InputMaybe<SortEnumType>;
  allocationInvestment?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customSector?: InputMaybe<SortEnumType>;
  customSectorColorCode?: InputMaybe<SortEnumType>;
  customSectorId?: InputMaybe<SortEnumType>;
  customSubsector?: InputMaybe<SortEnumType>;
  customSubsectorId?: InputMaybe<SortEnumType>;
  fillRate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  ioiShares?: InputMaybe<SortEnumType>;
  ioiType?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  offering?: InputMaybe<OfferingSortInput>;
  offeringId?: InputMaybe<SortEnumType>;
  pctAllocation?: InputMaybe<SortEnumType>;
  returnCurrent?: InputMaybe<SortEnumType>;
  returnDay1?: InputMaybe<SortEnumType>;
  returnDay3?: InputMaybe<SortEnumType>;
  returnDay7?: InputMaybe<SortEnumType>;
  returnOpen?: InputMaybe<SortEnumType>;
  returnPriorQuarter?: InputMaybe<SortEnumType>;
  salesConcession?: InputMaybe<SortEnumType>;
};

export type FollowOfferingInput = {
  isFollowing: Scalars['Boolean'];
  offeringId: Scalars['UUID'];
};

export type FollowOfferingPayload = {
  __typename?: 'FollowOfferingPayload';
  offeringId?: Maybe<Scalars['UUID']>;
};

export type FollowOnAggregation = {
  __typename?: 'FollowOnAggregation';
  allocationInvestment?: Maybe<Scalars['Decimal']>;
  capitalRaisedUsd?: Maybe<Scalars['Decimal']>;
  count?: Maybe<Scalars['Long']>;
  pctFileToOfferMean?: Maybe<Scalars['Decimal']>;
  pctOfferTo1DayMean?: Maybe<Scalars['Decimal']>;
  pctOfferTo30DayMean?: Maybe<Scalars['Decimal']>;
  pctOfferToCurrentMean?: Maybe<Scalars['Decimal']>;
  pctToLastTradeMean?: Maybe<Scalars['Decimal']>;
};

export type Fund = {
  __typename?: 'Fund';
  /** Timestamp when the fund was created at. */
  createdAt: Scalars['Instant'];
  /** Unique CMG identifier for a specific fund record. */
  id: Scalars['ID'];
  /** Timestamp when the fund was last modified at. */
  modifiedAt: Scalars['Instant'];
  /** Name of the fund. */
  name: Scalars['String'];
};

export type FundAllocation = {
  __typename?: 'FundAllocation';
  /** Fund Allocation / Fund Indication. */
  fillPercentage?: Maybe<Scalars['Decimal']>;
  fund?: Maybe<Fund>;
  fundAttributes?: Maybe<FundAttributes>;
  /** Unique CMG identifier for a specific fund record. */
  fundId?: Maybe<Scalars['UUID']>;
  /** Unique CMG identifier for a specific allocation record. */
  id: Scalars['ID'];
  lastModifiedBy?: Maybe<LastModifiedBy>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** Fund Allocation / Firm Allocation. */
  percentOfFirmAllocation?: Maybe<Scalars['Decimal']>;
  /** Allocation in number of shares. */
  shares?: Maybe<Scalars['Long']>;
};

export type FundAllocationFilterInput = {
  and?: InputMaybe<Array<FundAllocationFilterInput>>;
  /** Fund Allocation / Fund Indication. */
  fillPercentage?: InputMaybe<DecimalOperationFilterInput>;
  fund?: InputMaybe<FundFilterInput>;
  fundAttributes?: InputMaybe<FundAttributesFilterInput>;
  /** Unique CMG identifier for a specific fund record. */
  fundId?: InputMaybe<UuidOperationFilterInput>;
  /** Unique CMG identifier for a specific allocation record. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<FundAllocationFilterInput>>;
  /** Fund Allocation / Firm Allocation. */
  percentOfFirmAllocation?: InputMaybe<DecimalOperationFilterInput>;
  /** Allocation in number of shares. */
  shares?: InputMaybe<LongOperationFilterInput>;
};

export type FundAttributes = {
  __typename?: 'FundAttributes';
  allocation?: Maybe<Scalars['Decimal']>;
  allocationInvestment?: Maybe<Scalars['Decimal']>;
  fillRate?: Maybe<Scalars['Decimal']>;
  ioiShares?: Maybe<Scalars['Decimal']>;
  pctAllocation?: Maybe<Scalars['Decimal']>;
  returnCurrent?: Maybe<Scalars['Decimal']>;
  returnDay1?: Maybe<Scalars['Decimal']>;
  returnDay3?: Maybe<Scalars['Decimal']>;
  returnDay7?: Maybe<Scalars['Decimal']>;
  returnOpen?: Maybe<Scalars['Decimal']>;
  returnPriorQuarter?: Maybe<Scalars['Decimal']>;
};

export type FundAttributesFilterInput = {
  allocation?: InputMaybe<DecimalOperationFilterInput>;
  allocationInvestment?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<FundAttributesFilterInput>>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  fillRate?: InputMaybe<DecimalOperationFilterInput>;
  fundAllocation?: InputMaybe<FundAllocationFilterInput>;
  fundAllocationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  ioiShares?: InputMaybe<DecimalOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  or?: InputMaybe<Array<FundAttributesFilterInput>>;
  pctAllocation?: InputMaybe<DecimalOperationFilterInput>;
  returnCurrent?: InputMaybe<DecimalOperationFilterInput>;
  returnDay1?: InputMaybe<DecimalOperationFilterInput>;
  returnDay3?: InputMaybe<DecimalOperationFilterInput>;
  returnDay7?: InputMaybe<DecimalOperationFilterInput>;
  returnOpen?: InputMaybe<DecimalOperationFilterInput>;
  returnPriorQuarter?: InputMaybe<DecimalOperationFilterInput>;
};

export type FundFilterInput = {
  allocations?: InputMaybe<ListFilterInputTypeOfFundAllocationFilterInput>;
  and?: InputMaybe<Array<FundFilterInput>>;
  /** Timestamp when the fund was created at. */
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  /** Unique CMG identifier for a specific fund record. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Timestamp when the fund was last modified at. */
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  /** Name of the fund. */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FundFilterInput>>;
};

export type FundIndicationOfInterest = {
  __typename?: 'FundIndicationOfInterest';
  /** This field converts an indication entered as percentage or dollars to shares. */
  calculateShares?: Maybe<Scalars['Long']>;
  createdAt: Scalars['Instant'];
  /** The demand in dollars, given that unitType is dollars. */
  dollars?: Maybe<Scalars['Decimal']>;
  fund?: Maybe<Fund>;
  /** Unique CMG identifier for a specific fund. */
  fundId?: Maybe<Scalars['UUID']>;
  /** Unique CMG identifier for a specific indication record. */
  id: Scalars['ID'];
  /** limitPercentage is used when the limit order is at a discount or premium. */
  limitPercentage?: Maybe<Scalars['Decimal']>;
  /** limitPriceUsd is used when the limit order is denoted in US dollars. */
  limitPriceUsd?: Maybe<Scalars['Decimal']>;
  /** Enum denoting the type of the limit order, includes: PRICE, DISCOUNT, PREMIUM. */
  limitType?: Maybe<LimitType>;
  modifiedAt: Scalars['Instant'];
  offering?: Maybe<Offering>;
  /** Unique CMG identifier for a specific offering, against which this indication should be submitted. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** The demand in percentage, given that unitType is percentage. */
  percentage?: Maybe<Scalars['Decimal']>;
  /** Enum denoting whether an indication of interest is placed as an at market or limit price, includes: MARKET, LIMIT. */
  pricingType?: Maybe<PricingType>;
  /** The demand in shares, given that unitType is shares. */
  shares?: Maybe<Scalars['Long']>;
  /** Enum denoting whether an indication of interest is placed as a $USD amount, % of deal or number of shares, includes: SHARES, PERCENTAGE, DOLLARS. */
  unitType?: Maybe<UnitType>;
};

export type FundIndicationOfInterestFilterInput = {
  and?: InputMaybe<Array<FundIndicationOfInterestFilterInput>>;
  /** This field converts an indication entered as percentage or dollars to shares. */
  calculateShares?: InputMaybe<LongOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  /** The demand in dollars, given that unitType is dollars. */
  dollars?: InputMaybe<DecimalOperationFilterInput>;
  fund?: InputMaybe<FundFilterInput>;
  /** Unique CMG identifier for a specific fund. */
  fundId?: InputMaybe<UuidOperationFilterInput>;
  /** Unique CMG identifier for a specific indication record. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** limitPercentage is used when the limit order is at a discount or premium. */
  limitPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** limitPriceUsd is used when the limit order is denoted in US dollars. */
  limitPriceUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Enum denoting the type of the limit order, includes: PRICE, DISCOUNT, PREMIUM. */
  limitType?: InputMaybe<LimitTypeFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering, against which this indication should be submitted. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<FundIndicationOfInterestFilterInput>>;
  /** The demand in percentage, given that unitType is percentage. */
  percentage?: InputMaybe<DecimalOperationFilterInput>;
  /** Enum denoting whether an indication of interest is placed as an at market or limit price, includes: MARKET, LIMIT. */
  pricingType?: InputMaybe<PricingTypeFilterInput>;
  /** The firm-level internal demand in dollars, given that unitType is dollars. */
  realDemandDollars?: InputMaybe<DecimalOperationFilterInput>;
  /** The firm-level internal demand in percentage, given that unitType is percentage. */
  realDemandPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** The firm-level internal demand in shares, given that unitType is shares. */
  realDemandShares?: InputMaybe<LongOperationFilterInput>;
  /** The demand in shares, given that unitType is shares. */
  shares?: InputMaybe<LongOperationFilterInput>;
  /** Enum denoting whether an indication of interest is placed as a $USD amount, % of deal or number of shares, includes: SHARES, PERCENTAGE, DOLLARS. */
  unitType?: InputMaybe<UnitTypeFilterInput>;
};

export type GlobalLeagueTableRow = {
  __typename?: 'GlobalLeagueTableRow';
  /** Total dollar value (in millions) of proceeds raised in all offerings where a specific underwriter acted as a manager. */
  dollarVolumeUsd?: Maybe<Scalars['Decimal']>;
  /** Unique CMG identifier for a specific offering manager. */
  managerId: Scalars['UUID'];
  /** Name of the manager. */
  managerName?: Maybe<Scalars['String']>;
  /** Number of offerings in which a specific underwriter acted as a bookrunner. */
  numberOfBook: Scalars['Int'];
  /** Number of offerings in which a specific underwriter played a role other than that of bookrunner. */
  numberOfNonBook: Scalars['Int'];
  /** Total number of offerings a specific underwriter participated in. */
  offeringCount: Scalars['Int'];
};

/** A segment of a collection. */
export type GlobalLeagueTableRowCollectionSegment = {
  __typename?: 'GlobalLeagueTableRowCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<GlobalLeagueTableRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type GlobalLeagueTableRowSortInput = {
  /** Total dollar value (in millions) of proceeds raised in all offerings where a specific underwriter acted as a manager. */
  dollarVolumeUsd?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for a specific offering manager. */
  managerId?: InputMaybe<SortEnumType>;
  /** Name of the manager. */
  managerName?: InputMaybe<SortEnumType>;
  /** Number of offerings in which a specific underwriter acted as a bookrunner. */
  numberOfBook?: InputMaybe<SortEnumType>;
  /** Number of offerings in which a specific underwriter played a role other than that of bookrunner. */
  numberOfNonBook?: InputMaybe<SortEnumType>;
  /** Total number of offerings a specific underwriter participated in. */
  offeringCount?: InputMaybe<SortEnumType>;
};

export type HeatMapOfPerformanceLabelAggregation = {
  __typename?: 'HeatMapOfPerformanceLabelAggregation';
  items?: Maybe<Array<PerformanceLabelAggregation>>;
  max?: Maybe<Scalars['Decimal']>;
  min?: Maybe<Scalars['Decimal']>;
};

export type IndicationOfInterest = {
  __typename?: 'IndicationOfInterest';
  /** This field converts an indication entered as percentage or dollars to shares. */
  calculateShares?: Maybe<Scalars['Decimal']>;
  /** The demand in dollars, given that unitType is dollars. */
  dollars?: Maybe<Scalars['Decimal']>;
  /** Unique CMG identifier for a specific indication record. */
  id: Scalars['ID'];
  /** limitPercentage is used when the limit order is at a discount or premium. */
  limitPercentage?: Maybe<Scalars['Decimal']>;
  /** limitPriceUsd is used when the limit order is denoted in US dollars. */
  limitPriceUsd?: Maybe<Scalars['Decimal']>;
  /** Enum denoting the type of the limit order, includes: PRICE, DISCOUNT, PREMIUM. */
  limitType?: Maybe<LimitType>;
  /** Unique CMG identifier for a specific offering, against which this indication should be submitted. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** The demand in percentage, given that unitType is percentage. */
  percentage?: Maybe<Scalars['Decimal']>;
  /** Enum denoting whether an indication of interest is placed as an at market or limit price, includes: MARKET, LIMIT. */
  pricingType?: Maybe<PricingType>;
  /** The firm-level internal demand in dollars, given that unitType is dollars. */
  realDemandDollars?: Maybe<Scalars['Decimal']>;
  /** The firm-level internal demand in percentage, given that unitType is percentage. */
  realDemandPercentage?: Maybe<Scalars['Decimal']>;
  /** The firm-level internal demand in shares, given that unitType is shares. */
  realDemandShares?: Maybe<Scalars['Long']>;
  /** The demand in shares, given that unitType is shares. */
  shares?: Maybe<Scalars['Long']>;
  /** Enum denoting whether an indication of interest is placed as a $USD amount, % of deal or number of shares, includes: SHARES, PERCENTAGE, DOLLARS. */
  unitType?: Maybe<UnitType>;
};

/** A segment of a collection. */
export type IndicationOfInterestCollectionSegment = {
  __typename?: 'IndicationOfInterestCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<IndicationOfInterest>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type IndicationOfInterestFilterInput = {
  and?: InputMaybe<Array<IndicationOfInterestFilterInput>>;
  /** This field converts an indication entered as percentage or dollars to shares. */
  calculateShares?: InputMaybe<LongOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  /** The demand in dollars, given that unitType is dollars. */
  dollars?: InputMaybe<DecimalOperationFilterInput>;
  /** Unique CMG identifier for a specific indication record. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** limitPercentage is used when the limit order is at a discount or premium. */
  limitPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** limitPriceUsd is used when the limit order is denoted in US dollars. */
  limitPriceUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Enum denoting the type of the limit order, includes: PRICE, DISCOUNT, PREMIUM. */
  limitType?: InputMaybe<LimitTypeFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering, against which this indication should be submitted. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<IndicationOfInterestFilterInput>>;
  /** The demand in percentage, given that unitType is percentage. */
  percentage?: InputMaybe<DecimalOperationFilterInput>;
  /** Enum denoting whether an indication of interest is placed as an at market or limit price, includes: MARKET, LIMIT. */
  pricingType?: InputMaybe<PricingTypeFilterInput>;
  /** The firm-level internal demand in dollars, given that unitType is dollars. */
  realDemandDollars?: InputMaybe<DecimalOperationFilterInput>;
  /** The firm-level internal demand in percentage, given that unitType is percentage. */
  realDemandPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** The firm-level internal demand in shares, given that unitType is shares. */
  realDemandShares?: InputMaybe<LongOperationFilterInput>;
  /** The demand in shares, given that unitType is shares. */
  shares?: InputMaybe<LongOperationFilterInput>;
  /** Enum denoting whether an indication of interest is placed as a $USD amount, % of deal or number of shares, includes: SHARES, PERCENTAGE, DOLLARS. */
  unitType?: InputMaybe<UnitTypeFilterInput>;
};

export type IndicationOfInterestSortInput = {
  /** This field converts an indication entered as percentage or dollars to shares. */
  calculateShares?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  /** The demand in dollars, given that unitType is dollars. */
  dollars?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for a specific indication record. */
  id?: InputMaybe<SortEnumType>;
  /** limitPercentage is used when the limit order is at a discount or premium. */
  limitPercentage?: InputMaybe<SortEnumType>;
  /** limitPriceUsd is used when the limit order is denoted in US dollars. */
  limitPriceUsd?: InputMaybe<SortEnumType>;
  /** Enum denoting the type of the limit order, includes: PRICE, DISCOUNT, PREMIUM. */
  limitType?: InputMaybe<LimitTypeSortInput>;
  modifiedAt?: InputMaybe<SortEnumType>;
  offering?: InputMaybe<OfferingSortInput>;
  /** Unique CMG identifier for a specific offering, against which this indication should be submitted. */
  offeringId?: InputMaybe<SortEnumType>;
  /** The demand in percentage, given that unitType is percentage. */
  percentage?: InputMaybe<SortEnumType>;
  /** Enum denoting whether an indication of interest is placed as an at market or limit price, includes: MARKET, LIMIT. */
  pricingType?: InputMaybe<PricingTypeSortInput>;
  /** The firm-level internal demand in dollars, given that unitType is dollars. */
  realDemandDollars?: InputMaybe<SortEnumType>;
  /** The firm-level internal demand in percentage, given that unitType is percentage. */
  realDemandPercentage?: InputMaybe<SortEnumType>;
  /** The firm-level internal demand in shares, given that unitType is shares. */
  realDemandShares?: InputMaybe<SortEnumType>;
  /** The demand in shares, given that unitType is shares. */
  shares?: InputMaybe<SortEnumType>;
  /** Enum denoting whether an indication of interest is placed as a $USD amount, % of deal or number of shares, includes: SHARES, PERCENTAGE, DOLLARS. */
  unitType?: InputMaybe<UnitTypeSortInput>;
};

export type InstantOperationFilterInput = {
  eq?: InputMaybe<Scalars['Instant']>;
  gt?: InputMaybe<Scalars['Instant']>;
  gte?: InputMaybe<Scalars['Instant']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Instant']>>>;
  lt?: InputMaybe<Scalars['Instant']>;
  lte?: InputMaybe<Scalars['Instant']>;
  neq?: InputMaybe<Scalars['Instant']>;
  ngt?: InputMaybe<Scalars['Instant']>;
  ngte?: InputMaybe<Scalars['Instant']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Instant']>>>;
  nlt?: InputMaybe<Scalars['Instant']>;
  nlte?: InputMaybe<Scalars['Instant']>;
};

export type Instrument = {
  __typename?: 'Instrument';
  /** The conversionMultiplier is used to convert the derived instrument to the underlying security. */
  conversionMultiplier?: Maybe<Scalars['Decimal']>;
  /** Trading currency code of the instrument. */
  currency?: Maybe<Currency>;
  /** Display name for Currency */
  currencyDisplayName?: Maybe<Scalars['String']>;
  /** Security identifier used in U.S. and Canadian markets. Customers need to hold valid CUSIP license in order to receive values in this attribute. */
  cusip?: Maybe<Scalars['String']>;
  /** An integer value for the denominator in depositary receipt conversion ratio. The conversion ratio is specified as converting from depositary receipt(s) to underlying securities. */
  depositaryReceiptConversionDenominator?: Maybe<Scalars['Int']>;
  /** An integer value for the numerator in depositary receipt conversion ratio. The conversion ratio is specified as converting from depositary receipt(s) to underlying securities. */
  depositaryReceiptConversionNumerator?: Maybe<Scalars['Int']>;
  /** Country code of the primary listing exchange of the instrument. */
  exchangeCountry?: Maybe<Country>;
  /** Display name for ExchangeCountry */
  exchangeCountryDisplayName?: Maybe<Scalars['String']>;
  /** Exchange Market Identifier Code in which the instrument is primarily listed. */
  exchangeMic?: Maybe<OfferingSetupExchangeMic>;
  /** Display name for ExchangeMic */
  exchangeMicDisplayName?: Maybe<Scalars['String']>;
  /** Region code of the primary listing exchange of the instrument, includes: APAC, EMEA, LAMR, NAMR. */
  exchangeRegion?: Maybe<Region>;
  /** Display name for ExchangeRegion */
  exchangeRegionDisplayName?: Maybe<Scalars['String']>;
  /** Boolean indicating whether the instrument is a depositary receipt. */
  isDepositaryReceipt?: Maybe<Scalars['Boolean']>;
  /** Security identifier used globally. Customers need to hold valid CUSIP license in order to receive values in this attribute for US and Canadian instruments. */
  isin?: Maybe<Scalars['String']>;
  /** Boolean indicating whether the instrument is the pricing instrument for the offering. */
  isPricingInstrument: Scalars['Boolean'];
  /** Exchange trading symbol / ticker for the instrument. */
  symbol?: Maybe<Scalars['String']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type IoiNote = {
  __typename?: 'IoiNote';
  createdAt: Scalars['Instant'];
  id: Scalars['ID'];
  modifiedAt: Scalars['Instant'];
  note?: Maybe<Scalars['String']>;
  offering?: Maybe<Offering>;
  offeringId?: Maybe<Scalars['UUID']>;
  subject?: Maybe<Scalars['String']>;
};

export type IoiNoteFilterInput = {
  and?: InputMaybe<Array<IoiNoteFilterInput>>;
  note?: InputMaybe<StringOperationFilterInput>;
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<IoiNoteFilterInput>>;
};

export enum IoiType {
  Normal = 'NORMAL',
  Pass = 'PASS',
  RegM = 'REG_M',
}

export type IoiTypeFilterInput = {
  eq?: InputMaybe<IoiType>;
  in?: InputMaybe<Array<InputMaybe<IoiType>>>;
  neq?: InputMaybe<IoiType>;
  nin?: InputMaybe<Array<InputMaybe<IoiType>>>;
};

/** A segment of a collection. */
export type IoiTypesCollectionSegment = {
  __typename?: 'IoiTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfIoiType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type IoiTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type IpoAggregation = {
  __typename?: 'IpoAggregation';
  allocationInvestment?: Maybe<Scalars['Decimal']>;
  capitalRaisedUsd?: Maybe<Scalars['Decimal']>;
  count?: Maybe<Scalars['Long']>;
  pctAbove?: Maybe<Scalars['Decimal']>;
  pctBelow?: Maybe<Scalars['Decimal']>;
  pctInRange?: Maybe<Scalars['Decimal']>;
  pctOfferTo1DayMean?: Maybe<Scalars['Decimal']>;
  pctOfferTo30DayMean?: Maybe<Scalars['Decimal']>;
  pctOfferToCurrentMean?: Maybe<Scalars['Decimal']>;
};

export type Issuer = {
  __typename?: 'Issuer';
  /** Total dollar value of capital raised in all priced offerings CMG has a record of the Issuer completing, excluding ATM offerings. Accounts for any shares sold in connection with the Over-Allotment Exercised. Stated in local currency. This value is only available if the local currency is the same across the underlying offerings. */
  capitalRaised?: Maybe<Scalars['Decimal']>;
  /** Total dollar value of capital raised in all priced offerings CMG has a record of the Issuer completing, excluding ATM offerings. Accounts for any shares sold in connection with the Over-Allotment Exercised. Stated in USD. */
  capitalRaisedUsd?: Maybe<Scalars['Decimal']>;
  /** Central Index Key of the issuer. */
  cik?: Maybe<Scalars['String']>;
  customSectors?: Maybe<CustomSectorIssuer>;
  /** Business description of the issuer company. */
  description?: Maybe<Scalars['String']>;
  /** Entity classification of issuer:  BDC (Business Development Company), LP (Limited Partnership), REIT (Real Estate Investment Trust), or SPAC (Special Purpose Acquisition Company) */
  entityTypes?: Maybe<Array<EntityType>>;
  /** Display name for EntityTypes */
  entityTypesDisplayNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Boolean indicating whether the issuer has stock splits. */
  hasSplits?: Maybe<Scalars['Boolean']>;
  headquarters?: Maybe<Scalars['String']>;
  /** Unique CMG identifier for the issuer. */
  id?: Maybe<Scalars['ID']>;
  /** Boolean indicating whether the issuer company is defunct. */
  isDefunct?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the issuer is delisted. */
  isDelisted?: Maybe<Scalars['Boolean']>;
  naics?: Maybe<Scalars['Int']>;
  /** Name of the issuer. */
  name?: Maybe<Scalars['String']>;
  /** Total number of offerings CMG has a record for the issuer, including ATM offerings. */
  offerings: Array<Offering>;
  /** Total number of offerings CMG has a record for the issuer, including ATM offerings. */
  offeringsCount?: Maybe<Scalars['Int']>;
  /** Cusip for the primary instrument of the issuer. */
  primaryCusip?: Maybe<Scalars['String']>;
  /** Isin for the primary instrument of the issuer. */
  primaryIsin?: Maybe<Scalars['String']>;
  /** Exchange trading symbol / ticker for the primary instrument of the issuer. */
  primarySymbol?: Maybe<Scalars['String']>;
  /** Retrieves the latest morning star profile based on the PullTimeStamp column. */
  profile?: Maybe<IssuerProfile>;
  /** Industry sector code of the issuer. */
  sector?: Maybe<Sector>;
  /** Display name for Sector */
  sectorDisplayName?: Maybe<Scalars['String']>;
  stockProfile?: Maybe<StockProfile>;
  /** Industry sub sector code of the issuer. */
  subSector?: Maybe<SubSector>;
  /** Display name for SubSector */
  subSectorDisplayName?: Maybe<Scalars['String']>;
};

/** A segment of a collection. */
export type IssuerCollectionSegment = {
  __typename?: 'IssuerCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Issuer>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type IssuerFilterInput = {
  and?: InputMaybe<Array<IssuerFilterInput>>;
  /** Central Index Key of the issuer. */
  cik?: InputMaybe<StringOperationFilterInput>;
  customSectors?: InputMaybe<CustomSectorIssuerFilterInput>;
  /** Business description of the issuer company. */
  description?: InputMaybe<StringOperationFilterInput>;
  /** Entity classification of issuer:  BDC (Business Development Company), LP (Limited Partnership), REIT (Real Estate Investment Trust), or SPAC (Special Purpose Acquisition Company) */
  entityTypes?: InputMaybe<EntityTypeListFilterInput>;
  headquarters?: InputMaybe<StringOperationFilterInput>;
  /** Unique CMG identifier for the issuer. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Boolean indicating whether the issuer company is defunct. */
  isDefunct?: InputMaybe<BooleanOperationFilterInput>;
  naics?: InputMaybe<IntOperationFilterInput>;
  /** Name of the issuer. */
  name?: InputMaybe<StringOperationFilterInput>;
  offerings?: InputMaybe<ListFilterInputTypeOfOfferingFilterInput>;
  or?: InputMaybe<Array<IssuerFilterInput>>;
  /** Cusip for the primary instrument of the issuer. */
  primaryCusip?: InputMaybe<StringOperationFilterInput>;
  /** Isin for the primary instrument of the issuer. */
  primaryIsin?: InputMaybe<StringOperationFilterInput>;
  /** Exchange trading symbol / ticker for the primary instrument of the issuer. */
  primarySymbol?: InputMaybe<StringOperationFilterInput>;
  profiles?: InputMaybe<ListFilterInputTypeOfIssuerProfileFilterInput>;
  /** Industry sector code of the issuer. */
  sector?: InputMaybe<SectorFilterInput>;
  stockProfile?: InputMaybe<StockProfileFilterInput>;
  /** Industry sub sector code of the issuer. */
  subSector?: InputMaybe<SubSectorFilterInput>;
};

export type IssuerProfile = {
  __typename?: 'IssuerProfile';
  auditor?: Maybe<Scalars['String']>;
  cik?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exchangeCode?: Maybe<ExchangeCode>;
  /** Display name for ExchangeCode */
  exchangeCodeDisplayName?: Maybe<Scalars['String']>;
  fiscalYearEnd?: Maybe<Month>;
  headquarters?: Maybe<Scalars['String']>;
  hqCity?: Maybe<Scalars['String']>;
  hqHomepage?: Maybe<Scalars['String']>;
  hqPhone?: Maybe<Scalars['String']>;
  hqProvince?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  industryGroupName?: Maybe<Scalars['String']>;
  industryName?: Maybe<Scalars['String']>;
  issuer?: Maybe<Issuer>;
  legalName?: Maybe<Scalars['String']>;
  mic?: Maybe<Scalars['String']>;
  naicsCode?: Maybe<NaicsCode>;
  sectorName?: Maybe<Scalars['String']>;
  sicCode?: Maybe<SicCode>;
  standardName?: Maybe<Scalars['String']>;
  yearOfEstablishment?: Maybe<Scalars['Short']>;
};

export type IssuerProfileExchangeCodeArgs = {
  where?: InputMaybe<IssuerProfileFilterInput>;
};

export type IssuerProfileFilterInput = {
  advisor?: InputMaybe<StringOperationFilterInput>;
  advisorLanguageCode?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<IssuerProfileFilterInput>>;
  auditor?: InputMaybe<StringOperationFilterInput>;
  auditorLanguageCode?: InputMaybe<StringOperationFilterInput>;
  averageEmployeeNumber?: InputMaybe<IntOperationFilterInput>;
  businessCountryId?: InputMaybe<StringOperationFilterInput>;
  cannaics?: InputMaybe<IntOperationFilterInput>;
  cik?: InputMaybe<StringOperationFilterInput>;
  commonShareSubType?: InputMaybe<StringOperationFilterInput>;
  contactEmail?: InputMaybe<StringOperationFilterInput>;
  conversionRatio?: InputMaybe<DecimalOperationFilterInput>;
  countryId?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  currencyId?: InputMaybe<StringOperationFilterInput>;
  cusip?: InputMaybe<StringOperationFilterInput>;
  delistingDate?: InputMaybe<DateOperationFilterInput>;
  delistingReason?: InputMaybe<StringOperationFilterInput>;
  depositaryReceiptRatio?: InputMaybe<DecimalOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  economySphereCode?: InputMaybe<IntOperationFilterInput>;
  economySphereName?: InputMaybe<StringOperationFilterInput>;
  exchangeSubMarketGlobalId?: InputMaybe<StringOperationFilterInput>;
  expectedFiscalYearEnd?: InputMaybe<DateOperationFilterInput>;
  financialHealthGrade?: InputMaybe<StringOperationFilterInput>;
  fiscalYearEnd?: InputMaybe<NullableOfMonthOperationFilterInput>;
  growthGrade?: InputMaybe<StringOperationFilterInput>;
  growthScore?: InputMaybe<DecimalOperationFilterInput>;
  headquarters?: InputMaybe<StringOperationFilterInput>;
  hqAddress1?: InputMaybe<StringOperationFilterInput>;
  hqAddress2?: InputMaybe<StringOperationFilterInput>;
  hqAddress3?: InputMaybe<StringOperationFilterInput>;
  hqAddress4?: InputMaybe<StringOperationFilterInput>;
  hqAddress5?: InputMaybe<StringOperationFilterInput>;
  hqCity?: InputMaybe<StringOperationFilterInput>;
  hqCountry?: InputMaybe<StringOperationFilterInput>;
  hqFax?: InputMaybe<StringOperationFilterInput>;
  hqHomepage?: InputMaybe<StringOperationFilterInput>;
  hqPhone?: InputMaybe<StringOperationFilterInput>;
  hqPostalCode?: InputMaybe<StringOperationFilterInput>;
  hqProvince?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  industryCode?: InputMaybe<IntOperationFilterInput>;
  industryGroupCode?: InputMaybe<IntOperationFilterInput>;
  industryGroupName?: InputMaybe<StringOperationFilterInput>;
  industryName?: InputMaybe<StringOperationFilterInput>;
  industryTemplateCode?: InputMaybe<ShortOperationFilterInput>;
  investmentId?: InputMaybe<StringOperationFilterInput>;
  ipoDate?: InputMaybe<DateOperationFilterInput>;
  ipoOfferPrice?: InputMaybe<DecimalOperationFilterInput>;
  ipoOfferPriceRange?: InputMaybe<StringOperationFilterInput>;
  isBusinessDevelopmentCompany?: InputMaybe<BooleanOperationFilterInput>;
  isDepositaryReceipt?: InputMaybe<BooleanOperationFilterInput>;
  isDirectInvest?: InputMaybe<BooleanOperationFilterInput>;
  isDividendReinvest?: InputMaybe<BooleanOperationFilterInput>;
  isHqSameAddress?: InputMaybe<BooleanOperationFilterInput>;
  isin?: InputMaybe<StringOperationFilterInput>;
  isLimitedLiabilityCompany?: InputMaybe<BooleanOperationFilterInput>;
  isLimitedPartnership?: InputMaybe<BooleanOperationFilterInput>;
  isMasterLimitedPartnership?: InputMaybe<BooleanOperationFilterInput>;
  isPrimaryShare?: InputMaybe<BooleanOperationFilterInput>;
  isReit?: InputMaybe<BooleanOperationFilterInput>;
  isSpecialPurposeAcquisitionCompany?: InputMaybe<BooleanOperationFilterInput>;
  issuer?: InputMaybe<IssuerFilterInput>;
  issuerId?: InputMaybe<UuidOperationFilterInput>;
  issuerStatus?: InputMaybe<StringOperationFilterInput>;
  isTradingSuspended?: InputMaybe<BooleanOperationFilterInput>;
  legalName?: InputMaybe<StringOperationFilterInput>;
  legalNameLanguageCode?: InputMaybe<StringOperationFilterInput>;
  marketDataId?: InputMaybe<StringOperationFilterInput>;
  mic?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  msIssuerId?: InputMaybe<StringOperationFilterInput>;
  nace?: InputMaybe<DecimalOperationFilterInput>;
  naics?: InputMaybe<IntOperationFilterInput>;
  naicsCode?: InputMaybe<NaicsCodeFilterInput>;
  or?: InputMaybe<Array<IssuerProfileFilterInput>>;
  parValue?: InputMaybe<DecimalOperationFilterInput>;
  profitabilityGrade?: InputMaybe<StringOperationFilterInput>;
  pullTimestamp?: InputMaybe<InstantOperationFilterInput>;
  registeredAddress1?: InputMaybe<StringOperationFilterInput>;
  registeredAddress2?: InputMaybe<StringOperationFilterInput>;
  registeredAddress3?: InputMaybe<StringOperationFilterInput>;
  registeredAddress4?: InputMaybe<StringOperationFilterInput>;
  registeredCity?: InputMaybe<StringOperationFilterInput>;
  registeredCountry?: InputMaybe<StringOperationFilterInput>;
  registeredFax?: InputMaybe<StringOperationFilterInput>;
  registeredPhone?: InputMaybe<StringOperationFilterInput>;
  registeredPostalCode?: InputMaybe<StringOperationFilterInput>;
  registeredProvince?: InputMaybe<StringOperationFilterInput>;
  reportStyle?: InputMaybe<ShortOperationFilterInput>;
  sectorCode?: InputMaybe<IntOperationFilterInput>;
  sectorName?: InputMaybe<StringOperationFilterInput>;
  securityType?: InputMaybe<StringOperationFilterInput>;
  sedol?: InputMaybe<StringOperationFilterInput>;
  shareClassDescription?: InputMaybe<StringOperationFilterInput>;
  shareClassId?: InputMaybe<StringOperationFilterInput>;
  shareClassStatus?: InputMaybe<StringOperationFilterInput>;
  shortName?: InputMaybe<StringOperationFilterInput>;
  sic?: InputMaybe<IntOperationFilterInput>;
  sicCode?: InputMaybe<SicCodeFilterInput>;
  sizeScore?: InputMaybe<DecimalOperationFilterInput>;
  standardName?: InputMaybe<StringOperationFilterInput>;
  stockType?: InputMaybe<ShortOperationFilterInput>;
  stockTypeName?: InputMaybe<StringOperationFilterInput>;
  styleBox?: InputMaybe<ShortOperationFilterInput>;
  styleBoxName?: InputMaybe<StringOperationFilterInput>;
  styleScore?: InputMaybe<DecimalOperationFilterInput>;
  symbol?: InputMaybe<StringOperationFilterInput>;
  totalEmployeeNumber?: InputMaybe<IntOperationFilterInput>;
  valueScore?: InputMaybe<DecimalOperationFilterInput>;
  yearOfEstablishment?: InputMaybe<ShortOperationFilterInput>;
};

export type IssuerSortInput = {
  /** Central Index Key of the issuer. */
  cik?: InputMaybe<SortEnumType>;
  customSectors?: InputMaybe<CustomSectorIssuerSortInput>;
  /** Business description of the issuer company. */
  description?: InputMaybe<SortEnumType>;
  headquarters?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for the issuer. */
  id?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the issuer company is defunct. */
  isDefunct?: InputMaybe<SortEnumType>;
  naics?: InputMaybe<SortEnumType>;
  /** Name of the issuer. */
  name?: InputMaybe<SortEnumType>;
  /** Cusip for the primary instrument of the issuer. */
  primaryCusip?: InputMaybe<SortEnumType>;
  /** Isin for the primary instrument of the issuer. */
  primaryIsin?: InputMaybe<SortEnumType>;
  /** Exchange trading symbol / ticker for the primary instrument of the issuer. */
  primarySymbol?: InputMaybe<SortEnumType>;
  /** Industry sector code of the issuer. */
  sector?: InputMaybe<SectorSortInput>;
  stockProfile?: InputMaybe<StockProfileSortInput>;
  /** Industry sub sector code of the issuer. */
  subSector?: InputMaybe<SubSectorSortInput>;
};

export type LabelAggregationOfStringAndNullableOfDecimal = {
  __typename?: 'LabelAggregationOfStringAndNullableOfDecimal';
  colorCode?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['Decimal']>;
  label: Scalars['String'];
};

export type LabeledCoordinate = {
  __typename?: 'LabeledCoordinate';
  label?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Decimal']>;
  y?: Maybe<Scalars['Decimal']>;
};

export type LastModifiedBy = {
  __typename?: 'LastModifiedBy';
  /** Timestamp when the record was last modified at. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The email address of the user who last modified the record. */
  email?: Maybe<Scalars['String']>;
  /** The name of the user who last modified the record. */
  name?: Maybe<Scalars['String']>;
  /** The user Id who last modified the record. */
  userId?: Maybe<Scalars['UUID']>;
};

export enum LimitType {
  Discount = 'DISCOUNT',
  Premium = 'PREMIUM',
  Price = 'PRICE',
}

export type LimitTypeFilterInput = {
  eq?: InputMaybe<LimitType>;
  in?: InputMaybe<Array<InputMaybe<LimitType>>>;
  neq?: InputMaybe<LimitType>;
  nin?: InputMaybe<Array<InputMaybe<LimitType>>>;
};

export type LimitTypeSortInput = {
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type ListFilterInputTypeOfAdviserAttributesFilterInput = {
  all?: InputMaybe<AdviserAttributesFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AdviserAttributesFilterInput>;
  some?: InputMaybe<AdviserAttributesFilterInput>;
};

export type ListFilterInputTypeOfAllocationFilterInput = {
  all?: InputMaybe<AllocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AllocationFilterInput>;
  some?: InputMaybe<AllocationFilterInput>;
};

export type ListFilterInputTypeOfCornerstoneInvestmentFilterInput = {
  all?: InputMaybe<CornerstoneInvestmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CornerstoneInvestmentFilterInput>;
  some?: InputMaybe<CornerstoneInvestmentFilterInput>;
};

export type ListFilterInputTypeOfCornerstoneInvestorFilterInput = {
  all?: InputMaybe<CornerstoneInvestorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CornerstoneInvestorFilterInput>;
  some?: InputMaybe<CornerstoneInvestorFilterInput>;
};

export type ListFilterInputTypeOfCustomSectorFilterInput = {
  all?: InputMaybe<CustomSectorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomSectorFilterInput>;
  some?: InputMaybe<CustomSectorFilterInput>;
};

export type ListFilterInputTypeOfDocumentFilterInput = {
  all?: InputMaybe<DocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DocumentFilterInput>;
  some?: InputMaybe<DocumentFilterInput>;
};

export type ListFilterInputTypeOfFundAllocationFilterInput = {
  all?: InputMaybe<FundAllocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FundAllocationFilterInput>;
  some?: InputMaybe<FundAllocationFilterInput>;
};

export type ListFilterInputTypeOfFundIndicationOfInterestFilterInput = {
  all?: InputMaybe<FundIndicationOfInterestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FundIndicationOfInterestFilterInput>;
  some?: InputMaybe<FundIndicationOfInterestFilterInput>;
};

export type ListFilterInputTypeOfIndicationOfInterestFilterInput = {
  all?: InputMaybe<IndicationOfInterestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<IndicationOfInterestFilterInput>;
  some?: InputMaybe<IndicationOfInterestFilterInput>;
};

export type ListFilterInputTypeOfIoiNoteFilterInput = {
  all?: InputMaybe<IoiNoteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<IoiNoteFilterInput>;
  some?: InputMaybe<IoiNoteFilterInput>;
};

export type ListFilterInputTypeOfIssuerProfileFilterInput = {
  all?: InputMaybe<IssuerProfileFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<IssuerProfileFilterInput>;
  some?: InputMaybe<IssuerProfileFilterInput>;
};

export type ListFilterInputTypeOfLockUpFilterInput = {
  all?: InputMaybe<LockUpFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LockUpFilterInput>;
  some?: InputMaybe<LockUpFilterInput>;
};

export type ListFilterInputTypeOfLockUpPartyFilterInput = {
  all?: InputMaybe<LockUpPartyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LockUpPartyFilterInput>;
  some?: InputMaybe<LockUpPartyFilterInput>;
};

export type ListFilterInputTypeOfManagerAttributesFilterInput = {
  all?: InputMaybe<ManagerAttributesFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ManagerAttributesFilterInput>;
  some?: InputMaybe<ManagerAttributesFilterInput>;
};

export type ListFilterInputTypeOfOfferingAdviserFilterInput = {
  all?: InputMaybe<OfferingAdviserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OfferingAdviserFilterInput>;
  some?: InputMaybe<OfferingAdviserFilterInput>;
};

export type ListFilterInputTypeOfOfferingFilterInput = {
  all?: InputMaybe<OfferingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OfferingFilterInput>;
  some?: InputMaybe<OfferingFilterInput>;
};

export type ListFilterInputTypeOfOfferingManagerFilterInput = {
  all?: InputMaybe<OfferingManagerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OfferingManagerFilterInput>;
  some?: InputMaybe<OfferingManagerFilterInput>;
};

export type ListFilterInputTypeOfOfferingNoteFilterInput = {
  all?: InputMaybe<OfferingNoteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OfferingNoteFilterInput>;
  some?: InputMaybe<OfferingNoteFilterInput>;
};

export type ListFilterInputTypeOfOfferingShareholderFilterInput = {
  all?: InputMaybe<OfferingShareholderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OfferingShareholderFilterInput>;
  some?: InputMaybe<OfferingShareholderFilterInput>;
};

export type ListFilterInputTypeOfShareholderAttributesFilterInput = {
  all?: InputMaybe<ShareholderAttributesFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShareholderAttributesFilterInput>;
  some?: InputMaybe<ShareholderAttributesFilterInput>;
};

export type ListFilterInputTypeOfTermFilterInput = {
  all?: InputMaybe<TermFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TermFilterInput>;
  some?: InputMaybe<TermFilterInput>;
};

export type ListFilterInputTypeOfUserOfferingFilterInput = {
  all?: InputMaybe<UserOfferingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserOfferingFilterInput>;
  some?: InputMaybe<UserOfferingFilterInput>;
};

export type LockUp = {
  __typename?: 'LockUp';
  /** The reference starting date based on which to calculate the lock-up expiration date. */
  benchMarkDate?: Maybe<Scalars['Date']>;
  conditionalLockUpNote?: Maybe<Scalars['String']>;
  /** The date upon which an existing lock-up agreement is released implicitly as a result of a follow-on offering filing/launching within an existing lock-up period OR explicitly as a result of conditions being met that result in some/all of previously locked up shares being released. */
  earlyReleaseDate?: Maybe<Scalars['Date']>;
  /** The date upon which the contractual agreement will expire. Parties subject to the lock-up are no longer restricted on the day following the Lock-Up Expiration. */
  expirationDate?: Maybe<Scalars['Date']>;
  offering: Offering;
  /** Unique CMG identifier for a specific offering. */
  offeringId: Scalars['UUID'];
  parties: Array<LockUpParty>;
  /** Periods from the benchmark date during which one or more parties are prevented from disposing or hedging positions due to a contractual obligation with the underwriters. */
  period: Scalars['Int'];
  /** Unit of Period: MONTHS, DAYS. */
  unit: LockUpUnit;
  /** Display name for Unit */
  unitDisplayName?: Maybe<Scalars['String']>;
};

export enum LockUpBenchmarkReference {
  FirstTradeDate = 'FIRST_TRADE_DATE',
  PricingDate = 'PRICING_DATE',
  SettlementDate = 'SETTLEMENT_DATE',
}

export type LockUpBenchmarkReferenceFilterInput = {
  eq?: InputMaybe<LockUpBenchmarkReference>;
  in?: InputMaybe<Array<InputMaybe<LockUpBenchmarkReference>>>;
  neq?: InputMaybe<LockUpBenchmarkReference>;
  nin?: InputMaybe<Array<InputMaybe<LockUpBenchmarkReference>>>;
};

/** A segment of a collection. */
export type LockUpBenchmarkReferencesCollectionSegment = {
  __typename?: 'LockUpBenchmarkReferencesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfLockUpBenchmarkReference>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type LockUpBenchmarkReferenceSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type LockUpFilterInput = {
  and?: InputMaybe<Array<LockUpFilterInput>>;
  /** The reference starting date based on which to calculate the lock-up expiration date. */
  benchMarkDate?: InputMaybe<DateOperationFilterInput>;
  conditionalLockUpNote?: InputMaybe<StringOperationFilterInput>;
  /** The date upon which an existing lock-up agreement is released implicitly as a result of a follow-on offering filing/launching within an existing lock-up period OR explicitly as a result of conditions being met that result in some/all of previously locked up shares being released. */
  earlyReleaseDate?: InputMaybe<DateOperationFilterInput>;
  /** The date upon which the contractual agreement will expire. Parties subject to the lock-up are no longer restricted on the day following the Lock-Up Expiration. */
  expirationDate?: InputMaybe<DateOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<LockUpFilterInput>>;
  parties?: InputMaybe<ListFilterInputTypeOfLockUpPartyFilterInput>;
  /** Periods from the benchmark date during which one or more parties are prevented from disposing or hedging positions due to a contractual obligation with the underwriters. */
  period?: InputMaybe<IntOperationFilterInput>;
  /** Unit of Period: MONTHS, DAYS. */
  unit?: InputMaybe<LockUpUnitFilterInput>;
};

export type LockUpParty = {
  __typename?: 'LockUpParty';
  createdAt: Scalars['Instant'];
  id: Scalars['UUID'];
  lockUp: LockUp;
  lockUpId: Scalars['UUID'];
  modifiedAt: Scalars['Instant'];
  partyType: LockUpPartyType;
  /** Display name for PartyType */
  partyTypeDisplayName?: Maybe<Scalars['String']>;
};

export type LockUpPartyFilterInput = {
  and?: InputMaybe<Array<LockUpPartyFilterInput>>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lockUp?: InputMaybe<LockUpFilterInput>;
  lockUpId?: InputMaybe<UuidOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  or?: InputMaybe<Array<LockUpPartyFilterInput>>;
  partyType?: InputMaybe<LockUpPartyTypeFilterInput>;
};

export enum LockUpPartyType {
  Insiders = 'INSIDERS',
  Issuer = 'ISSUER',
}

export type LockUpPartyTypeFilterInput = {
  eq?: InputMaybe<LockUpPartyType>;
  in?: InputMaybe<Array<InputMaybe<LockUpPartyType>>>;
  neq?: InputMaybe<LockUpPartyType>;
  nin?: InputMaybe<Array<InputMaybe<LockUpPartyType>>>;
};

/** A segment of a collection. */
export type LockUpPartyTypesCollectionSegment = {
  __typename?: 'LockUpPartyTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfLockUpPartyType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type LockUpPartyTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum LockUpUnit {
  Days = 'DAYS',
  Months = 'MONTHS',
}

export type LockUpUnitFilterInput = {
  eq?: InputMaybe<LockUpUnit>;
  in?: InputMaybe<Array<InputMaybe<LockUpUnit>>>;
  neq?: InputMaybe<LockUpUnit>;
  nin?: InputMaybe<Array<InputMaybe<LockUpUnit>>>;
};

/** A segment of a collection. */
export type LockUpUnitsCollectionSegment = {
  __typename?: 'LockUpUnitsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfLockUpUnit>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type LockUpUnitSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']>;
  gt?: InputMaybe<Scalars['Long']>;
  gte?: InputMaybe<Scalars['Long']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  lt?: InputMaybe<Scalars['Long']>;
  lte?: InputMaybe<Scalars['Long']>;
  neq?: InputMaybe<Scalars['Long']>;
  ngt?: InputMaybe<Scalars['Long']>;
  ngte?: InputMaybe<Scalars['Long']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  nlt?: InputMaybe<Scalars['Long']>;
  nlte?: InputMaybe<Scalars['Long']>;
};

export type Manager = {
  __typename?: 'Manager';
  cmgEntityKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['Instant'];
  id: Scalars['ID'];
  modifiedAt: Scalars['Instant'];
  name?: Maybe<Scalars['String']>;
  offeringCount: Scalars['Int'];
};

export type ManagerAttributes = {
  __typename?: 'ManagerAttributes';
  estimatedFee?: Maybe<Scalars['Decimal']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isLeftLead?: Maybe<Scalars['Boolean']>;
  isSettlementAgent?: Maybe<Scalars['Boolean']>;
  isStabilizationAgent?: Maybe<Scalars['Boolean']>;
  managerId: Scalars['UUID'];
  name: Scalars['String'];
  offering?: Maybe<Offering>;
  offeringId?: Maybe<Scalars['UUID']>;
  offeringManager?: Maybe<OfferingManager>;
  pctUnderwritingBaseShares?: Maybe<Scalars['Decimal']>;
  role: OfferingManagerRole;
  /** Display name for Role */
  roleDisplayName?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  underwritingBaseShares?: Maybe<Scalars['Decimal']>;
};

export type ManagerAttributesFilterInput = {
  and?: InputMaybe<Array<ManagerAttributesFilterInput>>;
  estimatedFee?: InputMaybe<DecimalOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isLeftLead?: InputMaybe<BooleanOperationFilterInput>;
  isSettlementAgent?: InputMaybe<BooleanOperationFilterInput>;
  isStabilizationAgent?: InputMaybe<BooleanOperationFilterInput>;
  managerId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  offeringManager?: InputMaybe<OfferingManagerFilterInput>;
  or?: InputMaybe<Array<ManagerAttributesFilterInput>>;
  pctUnderwritingBaseShares?: InputMaybe<DecimalOperationFilterInput>;
  role?: InputMaybe<OfferingManagerRoleFilterInput>;
  sequence?: InputMaybe<IntOperationFilterInput>;
  underwritingBaseShares?: InputMaybe<DecimalOperationFilterInput>;
};

/** A segment of a collection. */
export type ManagerCollectionSegment = {
  __typename?: 'ManagerCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Manager>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ManagerFilterInput = {
  and?: InputMaybe<Array<ManagerFilterInput>>;
  cmgEntityKey?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  offeringManagers?: InputMaybe<ListFilterInputTypeOfOfferingManagerFilterInput>;
  or?: InputMaybe<Array<ManagerFilterInput>>;
};

export type ManagerSortInput = {
  cmgEntityKey?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum MarketTiming {
  IntraDay = 'INTRA_DAY',
  PostClose = 'POST_CLOSE',
  PreOpen = 'PRE_OPEN',
}

export type MarketTimingFilterInput = {
  eq?: InputMaybe<MarketTiming>;
  in?: InputMaybe<Array<InputMaybe<MarketTiming>>>;
  neq?: InputMaybe<MarketTiming>;
  nin?: InputMaybe<Array<InputMaybe<MarketTiming>>>;
};

/** A segment of a collection. */
export type MarketTimingsCollectionSegment = {
  __typename?: 'MarketTimingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfMarketTiming>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type MarketTimingSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

/** Calendar Months Enum for display */
export enum Month {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER',
}

export type Mutation = {
  __typename?: 'Mutation';
  addCustomSector?: Maybe<AddCustomSectorPayload>;
  deleteCustomSector?: Maybe<DeleteCustomSectorPayload>;
  followOffering: FollowOfferingPayload;
  updateCustomSector?: Maybe<UpdateCustomSectorPayload>;
  upsertCustomSectorIssuers?: Maybe<UpsertCustomSectorIssuersPayload>;
  upsertOfferingNote: UpsertOfferingNotePayload;
};

export type MutationAddCustomSectorArgs = {
  input: AddCustomSectorInput;
};

export type MutationDeleteCustomSectorArgs = {
  input: DeleteCustomSectorInput;
};

export type MutationFollowOfferingArgs = {
  input: FollowOfferingInput;
};

export type MutationUpdateCustomSectorArgs = {
  input: UpdateCustomSectorInput;
};

export type MutationUpsertCustomSectorIssuersArgs = {
  input: UpsertCustomSectorIssuersInput;
};

export type MutationUpsertOfferingNoteArgs = {
  input: UpsertOfferingNoteInput;
};

export type NaicsCode = {
  __typename?: 'NaicsCode';
  code?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Instant'];
  exchangeGroupId?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  issuerProfiles: Array<IssuerProfile>;
  modifiedAt: Scalars['Instant'];
  name?: Maybe<Scalars['String']>;
};

export type NaicsCodeFilterInput = {
  and?: InputMaybe<Array<NaicsCodeFilterInput>>;
  code?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  exchangeGroupId?: InputMaybe<StringOperationFilterInput>;
  globalId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  issuerProfiles?: InputMaybe<ListFilterInputTypeOfIssuerProfileFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NaicsCodeFilterInput>>;
};

export type Notice = {
  __typename?: 'Notice';
  /** A read-only disclaimer for U.S. equity offerings that are managed on the CMG platform. */
  disclaimers?: Maybe<Scalars['String']>;
  /** Intended to capture any free text additional disclaimers. */
  footnotes?: Maybe<Scalars['String']>;
  offering?: Maybe<Offering>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** Geographic and/or Investor restrictions that specify investor participation in a particular offering. */
  restrictions?: Maybe<Scalars['String']>;
};

export type NoticeFilterInput = {
  and?: InputMaybe<Array<NoticeFilterInput>>;
  /** A read-only disclaimer for U.S. equity offerings that are managed on the CMG platform. */
  disclaimers?: InputMaybe<StringOperationFilterInput>;
  /** Intended to capture any free text additional disclaimers. */
  footnotes?: InputMaybe<StringOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<NoticeFilterInput>>;
  /** Geographic and/or Investor restrictions that specify investor participation in a particular offering. */
  restrictions?: InputMaybe<StringOperationFilterInput>;
};

export type NoticeSortInput = {
  /** A read-only disclaimer for U.S. equity offerings that are managed on the CMG platform. */
  disclaimers?: InputMaybe<SortEnumType>;
  /** Intended to capture any free text additional disclaimers. */
  footnotes?: InputMaybe<SortEnumType>;
  offering?: InputMaybe<OfferingSortInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<SortEnumType>;
  /** Geographic and/or Investor restrictions that specify investor participation in a particular offering. */
  restrictions?: InputMaybe<SortEnumType>;
};

export type NullableOfMonthOperationFilterInput = {
  eq?: InputMaybe<Month>;
  in?: InputMaybe<Array<InputMaybe<Month>>>;
  neq?: InputMaybe<Month>;
  nin?: InputMaybe<Array<InputMaybe<Month>>>;
};

export type Offering = {
  __typename?: 'Offering';
  advisers?: Maybe<Array<OfferingAdviser>>;
  /** Allocation data in the My DataLab IOI Tracker. */
  allocations?: Maybe<Array<Allocation>>;
  atmAttributes?: Maybe<AtmAttributes>;
  attributes?: Maybe<OfferingAttributes>;
  booksCloseAt?: Maybe<Scalars['Instant']>;
  convertibleAttributes?: Maybe<ConvertibleAttributes>;
  cornerstoneInvestments?: Maybe<Array<CornerstoneInvestment>>;
  documents: Array<Document>;
  exchange?: Maybe<Scalars['String']>;
  exchangeCountry?: Maybe<Country>;
  /** Display name for ExchangeCountry */
  exchangeCountryDisplayName?: Maybe<Scalars['String']>;
  exchangeMic?: Maybe<OfferingSetupExchangeMic>;
  /** Display name for ExchangeMic */
  exchangeMicDisplayName?: Maybe<Scalars['String']>;
  exchangeRegion?: Maybe<Region>;
  /** Display name for ExchangeRegion */
  exchangeRegionDisplayName?: Maybe<Scalars['String']>;
  finalTerm?: Maybe<Term>;
  finalTermId?: Maybe<Scalars['UUID']>;
  firmMetric?: Maybe<FirmMetric>;
  firstTradeDate?: Maybe<Scalars['Date']>;
  /** Fund-level allocation data in the My DataLab IOI Tracker. */
  fundAllocations?: Maybe<Array<FundAllocation>>;
  /** Fund-level indication data in the My DataLab IOI Tracker. */
  fundIndicationsOfInterest?: Maybe<Array<FundIndicationOfInterest>>;
  /** Underwriters' gross compensation in local currency, per share. */
  grossSpreadTotal?: Maybe<Scalars['Decimal']>;
  hasForwardAgreement?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** Indication data in the My DataLab IOI Tracker. */
  indicationsOfInterest?: Maybe<Array<IndicationOfInterest>>;
  initialTerm?: Maybe<Term>;
  initialTermId?: Maybe<Scalars['UUID']>;
  instruments: Array<Instrument>;
  ioiNotes?: Maybe<Array<IoiNote>>;
  /** Boolean indicating whether the offering is managed on the CMG XC platform. */
  isExecutingOnPlatform?: Maybe<Scalars['Boolean']>;
  isRule144A?: Maybe<Scalars['Boolean']>;
  issuer?: Maybe<Issuer>;
  issuerId?: Maybe<Scalars['UUID']>;
  lastAllocation?: Maybe<Allocation>;
  lastTradeBeforeFiling?: Maybe<Scalars['Decimal']>;
  lastTradeBeforeLaunch?: Maybe<Scalars['Decimal']>;
  lastTradeBeforeOffer?: Maybe<Scalars['Decimal']>;
  latestRevisedTerm?: Maybe<Term>;
  latestRevisedTermId?: Maybe<Scalars['UUID']>;
  launchDate?: Maybe<Scalars['Date']>;
  lockUps?: Maybe<Array<LockUp>>;
  managers: Array<OfferingManager>;
  marketTiming?: Maybe<MarketTiming>;
  /** Display name for MarketTiming */
  marketTimingDisplayName?: Maybe<Scalars['String']>;
  maturityDate?: Maybe<Scalars['Date']>;
  news?: Maybe<Array<SecFiling>>;
  notice?: Maybe<Notice>;
  offeringNotes?: Maybe<Array<Maybe<OfferingNote>>>;
  pctOfDeal?: Maybe<Scalars['Decimal']>;
  postponedDate?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Decimal']>;
  /** Currency code of the pricing instrument of the offering. */
  pricingCurrency?: Maybe<Currency>;
  /** Display name for PricingCurrency */
  pricingCurrencyDisplayName?: Maybe<Scalars['String']>;
  pricingDate?: Maybe<Scalars['Date']>;
  publicFilingDate?: Maybe<Scalars['Date']>;
  relevantTermId?: Maybe<Scalars['UUID']>;
  securityType?: Maybe<SecurityType>;
  /** Display name for SecurityType */
  securityTypeDisplayName?: Maybe<Scalars['String']>;
  settlementDate?: Maybe<Scalars['Date']>;
  shareholders?: Maybe<Array<OfferingShareholder>>;
  status: OfferingStatus;
  /** Display name for Status */
  statusDisplayName?: Maybe<Scalars['String']>;
  statusRelevantDate?: Maybe<Scalars['Date']>;
  /** Exchange trading symbol / ticker for the offering. */
  symbol?: Maybe<Scalars['String']>;
  terminatedDate?: Maybe<Scalars['Date']>;
  terms: Array<Term>;
  type: OfferingType;
  /** Display name for Type */
  typeDisplayName?: Maybe<Scalars['String']>;
  useOfProceeds?: Maybe<Array<Maybe<UseOfProceeds>>>;
  /** Display name for UseOfProceeds */
  useOfProceedsDisplayNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  userOfferings?: Maybe<Array<Maybe<UserOffering>>>;
  withdrawnDate?: Maybe<Scalars['Date']>;
};

export type OfferingAdviser = {
  __typename?: 'OfferingAdviser';
  adviser?: Maybe<Adviser>;
  /** Unique CMG identifier for the adviser. */
  adviserId?: Maybe<Scalars['UUID']>;
  /** Name of the adviser. */
  name?: Maybe<Scalars['String']>;
  offering?: Maybe<Offering>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** Role of the adviser on the offering, includes: ADVISORY, ISSUER_AUDITOR, ISSUER_COUNSEL, TRUSTEE, UNDERWRITER_COUNSEL. */
  role?: Maybe<AdviserRole>;
  /** Display name for Role */
  roleDisplayName?: Maybe<Scalars['String']>;
};

export type OfferingAdviserFilterInput = {
  adviser?: InputMaybe<AdviserFilterInput>;
  /** Unique CMG identifier for the adviser. */
  adviserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<OfferingAdviserFilterInput>>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<OfferingAdviserFilterInput>>;
  /** Role of the adviser on the offering, includes: ADVISORY, ISSUER_AUDITOR, ISSUER_COUNSEL, TRUSTEE, UNDERWRITER_COUNSEL. */
  role?: InputMaybe<AdviserRoleFilterInput>;
};

export type OfferingAggregation = {
  __typename?: 'OfferingAggregation';
  capitalRaisedUsd?: Maybe<Scalars['Decimal']>;
  count: Scalars['Int'];
  underwrittenCapitalRaisedUsd?: Maybe<Scalars['Decimal']>;
};

export type OfferingAggregations = {
  __typename?: 'OfferingAggregations';
  aggregatePrincipalAmount?: Maybe<Aggregation>;
  aggregatePrincipalInclOverallotmentExercised?: Maybe<Aggregation>;
  allInCost?: Maybe<Aggregation>;
  callPrice?: Maybe<Aggregation>;
  conversionPrice?: Maybe<Aggregation>;
  conversionRatio?: Maybe<Aggregation>;
  conversionReferencePrice?: Maybe<Aggregation>;
  couponPercentage?: Maybe<Aggregation>;
  estimatedFeeUsd?: Maybe<Aggregation>;
  fiftyTwoWeekHigh?: Maybe<Aggregation>;
  firstDayTurnover?: Maybe<Aggregation>;
  grossSpreadTotalUsd?: Maybe<Aggregation>;
  initialIndicativeGrossProceedsUsd?: Maybe<Aggregation>;
  latestGrossProceedsBaseUsd?: Maybe<Aggregation>;
  latestGrossProceedsTotalUsd?: Maybe<Aggregation>;
  latestPctSecondaryShares?: Maybe<Aggregation>;
  latestPostOfferingShares?: Maybe<Aggregation>;
  latestSizeInSecuritiesBase?: Maybe<Aggregation>;
  lockUpPeriod?: Maybe<Aggregation>;
  marketCapAtPricingUsd?: Maybe<Aggregation>;
  marketCapPreOfferingUsd?: Maybe<Aggregation>;
  netPrice?: Maybe<Aggregation>;
  offerToVwap1Day?: Maybe<Aggregation>;
  pctChangeInSize?: Maybe<Aggregation>;
  pctFileToOffer?: Maybe<Aggregation>;
  pctGrossSpread?: Maybe<Aggregation>;
  pctMarketCapAtPricing?: Maybe<Aggregation>;
  pctMarketCapPreOffering?: Maybe<Aggregation>;
  pctOfferPrice?: Maybe<Aggregation>;
  pctOfferTo14Day?: Maybe<Aggregation>;
  pctOfferTo180Day?: Maybe<Aggregation>;
  pctOfferTo1Day?: Maybe<Aggregation>;
  pctOfferTo30Day?: Maybe<Aggregation>;
  pctOfferTo3Day?: Maybe<Aggregation>;
  pctOfferTo7Day?: Maybe<Aggregation>;
  pctOfferTo90Day?: Maybe<Aggregation>;
  pctOfferToCurrent?: Maybe<Aggregation>;
  pctOfferToOneYear?: Maybe<Aggregation>;
  pctOfferToOpen?: Maybe<Aggregation>;
  pctOfferToPriorQuarter?: Maybe<Aggregation>;
  pctReOfferHigh?: Maybe<Aggregation>;
  pctReOfferLow?: Maybe<Aggregation>;
  pctTo52WeekHigh?: Maybe<Aggregation>;
  pctToLastTrade?: Maybe<Aggregation>;
  pctToVwap?: Maybe<Aggregation>;
  pctVsMidpoint?: Maybe<Aggregation>;
  premiumPercentage?: Maybe<Aggregation>;
  preOfferingShares?: Maybe<Aggregation>;
  priceUsd?: Maybe<Aggregation>;
  principalAmountPerNote?: Maybe<Aggregation>;
  reOfferHighUsd?: Maybe<Aggregation>;
  reOfferLowUsd?: Maybe<Aggregation>;
  sizeAsMultipleOfAdtv?: Maybe<Aggregation>;
  tenor?: Maybe<Aggregation>;
  totalBookrunners?: Maybe<Aggregation>;
  totalManagers?: Maybe<Aggregation>;
  totalNonBookrunners?: Maybe<Aggregation>;
  totalPctToBookrunners?: Maybe<Aggregation>;
  totalPctToLeftLead?: Maybe<Aggregation>;
  totalPctToNonBookrunners?: Maybe<Aggregation>;
};

/** A entity representing metrics, derived, and entered properties from multiple source entities */
export type OfferingAttributes = {
  __typename?: 'OfferingAttributes';
  /** The date with the highest Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  adtvRangeHighDate?: Maybe<Scalars['Date']>;
  /** The date with the lowest Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  adtvRangeLowDate?: Maybe<Scalars['Date']>;
  advisers?: Maybe<Array<AdviserAttributes>>;
  /** netPrice-lastTradeBeforeFiling. */
  allInCost?: Maybe<Scalars['Decimal']>;
  /** Time when order book is closed. */
  booksCloseAt?: Maybe<Scalars['Instant']>;
  /** Filing date of initial confidential 'Draft Registration Statement'. Made public upon the release of the initial public filing of the prospectus. */
  confidentialFilingDate?: Maybe<Scalars['Date']>;
  /** Number of offering participants cornerstone investors for the deal. */
  cornerstoneInvestorsOfferingParticipantsCount?: Maybe<Scalars['Int']>;
  /** Number of Private Placement cornerstone investors for the deal. */
  cornerstoneInvestorsPrivatePlacementCount?: Maybe<Scalars['Int']>;
  /** Number of total cornerstone investors for the offering. */
  cornerstoneInvestorsTotalCount?: Maybe<Scalars['Int']>;
  /** Investment sum of all cornerstone investors that are participating in the in the base offering. Represented in local currency. */
  cornerstoneOfferingParticipantsAmount?: Maybe<Scalars['Decimal']>;
  /** Number of Private Placement cornerstone investors for the deal. */
  cornerstonePrivatePlacementAmount?: Maybe<Scalars['Decimal']>;
  /** Investment sum of all cornerstone investors for this offering. Represented in local currency. */
  cornerstoneTotalAmount?: Maybe<Scalars['Decimal']>;
  /** Share price as of the most recent close. */
  currentSharePrice?: Maybe<Scalars['Decimal']>;
  /** Closing price 14 days post the first trade date. */
  day14SharePrice?: Maybe<Scalars['Decimal']>;
  /** Closing price 180 days post the first trade date. */
  day180SharePrice?: Maybe<Scalars['Decimal']>;
  /** Closing price on the first trade date. */
  day1SharePrice?: Maybe<Scalars['Decimal']>;
  /** Volume Weighted Average Price on the first trade date. */
  day1VwapPrice?: Maybe<Scalars['Decimal']>;
  /** Closing price 30 days post the first trade date. */
  day30SharePrice?: Maybe<Scalars['Decimal']>;
  /** Closing price 3 days post the first trade date. */
  day3SharePrice?: Maybe<Scalars['Decimal']>;
  /** Closing price 7 days post the first trade date. */
  day7SharePrice?: Maybe<Scalars['Decimal']>;
  /** Closing price 90 days post the first trade date. */
  day90SharePrice?: Maybe<Scalars['Decimal']>;
  /** Total costs and fees associated with the offering and payable to the Underwriters. Takes into account any shares sold in accordance with the Over-allotment Authorized. Typically paid by the Issuer. */
  estimatedFeeUsd?: Maybe<Scalars['Decimal']>;
  /** Country code of the pricing instrument of the offering. */
  exchangeCountry?: Maybe<Country>;
  /** Display name for ExchangeCountry */
  exchangeCountryDisplayName?: Maybe<Scalars['String']>;
  /** Exchange Market Identifier Code in which the pricing instrument is primarily listed. */
  exchangeMic?: Maybe<OfferingSetupExchangeMic>;
  /** Display name for ExchangeMic */
  exchangeMicDisplayName?: Maybe<Scalars['String']>;
  /** Region code of the pricing instrument of the offering, includes: APAC, EMEA, LAMR, NAMR. */
  exchangeRegion?: Maybe<Region>;
  /** Display name for ExchangeRegion */
  exchangeRegionDisplayName?: Maybe<Scalars['String']>;
  /** Highest share price in the 52 weeks preceding the pricing date of a Follow-On or Block offering. Highest share price in the 52 weeks preceding the announcement date of an ATM offering. */
  fiftyTwoWeekHigh?: Maybe<Scalars['Decimal']>;
  /** The date with the highest share price in the 52 weeks preceding the pricing date of a Follow-On or Block offering, or the announcement date of an ATM offering. */
  fiftyTwoWeekHighDate?: Maybe<Scalars['Date']>;
  /** Multiple of trade volume on first trade date relative to total deal size. */
  firstDayTurnover?: Maybe<Scalars['Decimal']>;
  /** Trading volume on first trade date. */
  firstDayVolume?: Maybe<Scalars['Long']>;
  /** Date the offering begins trading post pricing. */
  firstTradeDate?: Maybe<Scalars['Date']>;
  /** Underwriters' gross compensation in local currency, per share. */
  grossSpreadTotal?: Maybe<Scalars['Decimal']>;
  /** Underwriters' gross compensation in $USD, per share. */
  grossSpreadTotalUsd?: Maybe<Scalars['Decimal']>;
  /** Boolean indicating whether the offering has cornerstone investors. */
  hasCornerstoneInvestors?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the issuer entered into a Forward Sale Agreement in which shares sold in the offering were borrowed from a counter party and is expected to settle with the counter party at a future date by issuing shares or in some cases settling in cash. */
  hasForwardAgreement?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether multiple lock ups are stated for different parties in the contractual agreement. */
  hasMultipleLockUps?: Maybe<Scalars['Boolean']>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the initial offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  initialGrossProceedsBase?: Maybe<Scalars['Decimal']>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the initial offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  initialGrossProceedsBaseUsd?: Maybe<Scalars['Decimal']>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the initial offering terms. The amount is used for the purposes of estimating filing fees. */
  initialIndicativeGrossProceeds?: Maybe<Scalars['Decimal']>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the initial offering terms, stated in USD. The amount is used for the purposes of estimating filing fees. */
  initialIndicativeGrossProceedsUsd?: Maybe<Scalars['Decimal']>;
  /** High end of IPO price range as of the initial offering terms, stated in local currency. */
  initialIpoRangeHigh?: Maybe<Scalars['Decimal']>;
  /** High end of IPO price range as of the initial offering terms, stated in USD. */
  initialIpoRangeHighUsd?: Maybe<Scalars['Decimal']>;
  /** Low end of IPO price range as of the initial offering terms, stated in local currency. */
  initialIpoRangeLow?: Maybe<Scalars['Decimal']>;
  /** Low end of IPO price range as of the initial offering terms, stated in USD. */
  initialIpoRangeLowUsd?: Maybe<Scalars['Decimal']>;
  /** Total shares being offered as of the initial offering terms. */
  initialSizeInSecuritiesBase?: Maybe<Scalars['Long']>;
  /** Total shares being offered as of the initial offering terms. */
  initialSizeInSecuritiesTotal?: Maybe<Scalars['Long']>;
  /** Boolean indicating whether the pricing date of the offering has been accelerated. */
  isAccelerated?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the offering is a carve out offering. */
  isCarveOut?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the offering is a clean up trade. */
  isCleanUpTrade?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether an issuer repurchases shares from a selling shareholder in a separate transaction completed concurrently with a public offering (deal size does not include portion to be repurchased). */
  isCompanyRepurchaseAdditional?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether an issuer repurchases shares from a selling shareholder as part of an offering (deal size includes portion to be repurchased). */
  isCompanyRepurchaseIncluded?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether an IPO or follow-on offering occurs in parallel to a converts offering. */
  isConcurrentOffering?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether a lock-up has conditional criteria stated in the contractual agreement. */
  isConditionalLockUp?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the offering is dual listed. */
  isDualListed?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the issuer is classified as an emerging growth company. */
  isEgc?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the offering is managed on the CMG XC platform. */
  isExecutingOnPlatform?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the security is registered with the FDIC. */
  isFdicRegistered?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the offering is the first follow-on offering for the issuer after their IPO. */
  isFirstFollowOn?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the security is a Reg S offering. */
  isRegS?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the security is a Rule 144A offering. */
  isRule144A?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the security is registered with the SEC. */
  isSecRegistered?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the issuer company is classified as SPAC. */
  isSpac: Scalars['Boolean'];
  /** When this attribute is populated, it means the offering has been replaced by another offering. This attribute holds the offering ID of the surviving offering. */
  isSupersededBy?: Maybe<Scalars['UUID']>;
  /** Boolean indicating whether the offering is classified as a synthetic secondary. */
  isSyntheticSecondary?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the offering is classified as an uplisting. */
  isUpListing?: Maybe<Scalars['Boolean']>;
  /** The closing price prior to the initial filing of the offering, stated in local currency. */
  lastTradeBeforeFiling?: Maybe<Scalars['Decimal']>;
  /** The closing price prior to the initial filing of the offering, stated in USD. */
  lastTradeBeforeFilingUsd?: Maybe<Scalars['Decimal']>;
  /** The closing price prior to launch of the offering, stated in local currency. */
  lastTradeBeforeLaunch?: Maybe<Scalars['Decimal']>;
  /** The closing price prior to launch of the offering, stated in USD. */
  lastTradeBeforeLaunchUsd?: Maybe<Scalars['Decimal']>;
  /** The closing price prior to pricing of the offering, stated in local currency. */
  lastTradeBeforeOffer?: Maybe<Scalars['Decimal']>;
  /** The closing price prior to pricing of the offering, stated in USD. */
  lastTradeBeforeOfferUsd?: Maybe<Scalars['Decimal']>;
  latestCouponTalkPercentageHigh?: Maybe<Scalars['Decimal']>;
  latestCouponTalkPercentageLow?: Maybe<Scalars['Decimal']>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the latest offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  latestGrossProceedsBase?: Maybe<Scalars['Decimal']>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the latest offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  latestGrossProceedsBaseUsd?: Maybe<Scalars['Decimal']>;
  /** Total capital raised, including the exercise of any over-allotments, as of the latest offering terms. Equals [(sizeInSecuritiesBase + overAllotmentExercised) * offerPrice], stated in local currency. */
  latestGrossProceedsTotal?: Maybe<Scalars['Decimal']>;
  /** Total capital raised, including the exercise of any over-allotments, as of the latest offering terms. Equals [(sizeInSecuritiesBase + overAllotmentExercised) * offerPrice], stated in USD. */
  latestGrossProceedsTotalUsd?: Maybe<Scalars['Decimal']>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the latest offering terms. The amount is used for the purposes of estimating filing fees. */
  latestIndicativeGrossProceeds?: Maybe<Scalars['Decimal']>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the latest offering terms, stated in USD. The amount is used for the purposes of estimating filing fees. */
  latestIndicativeGrossProceedsUsd?: Maybe<Scalars['Decimal']>;
  /** High end of IPO price range as of the latest offering terms, stated in USD. */
  latestIpoRangeHighUsd?: Maybe<Scalars['Decimal']>;
  /** Low end of IPO price range as of the latest offering terms, stated in USD. */
  latestIpoRangeLowUsd?: Maybe<Scalars['Decimal']>;
  latestOfferPrice?: Maybe<Scalars['Decimal']>;
  /** Final price of the offering, stated in USD. */
  latestOfferPriceUsd?: Maybe<Scalars['Decimal']>;
  /** Number of shares the underwriters are authorized to sell in addition to the total base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of total base share size. */
  latestOverAllotmentAuthorized?: Maybe<Scalars['Long']>;
  /** Number of primary shares the underwriters are authorized to sell in addition to the primary base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of primary base share size. */
  latestOverAllotmentAuthorizedPrimary?: Maybe<Scalars['Long']>;
  /** Offering size expressed as a notional amount (currency) the underwriters are authorized to sell in addition to the total base offering size, as of the latest offering terms. */
  latestOverAllotmentAuthorizedProceeds?: Maybe<Scalars['Decimal']>;
  /** Number of secondary shares the underwriters are authorized to sell in addition to the secondary base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of secondary base share size. */
  latestOverAllotmentAuthorizedSecondary?: Maybe<Scalars['Long']>;
  /** Secondary base shares offered (i.e. existing shareholders) as a % of total base shares offered, as of the latest offering terms. */
  latestPctSecondaryShares?: Maybe<Scalars['Decimal']>;
  /** Total shares outstanding after the offering as of the latest offering terms. */
  latestPostOfferingShares?: Maybe<Scalars['Long']>;
  latestPremiumTalkHighPercentage?: Maybe<Scalars['Decimal']>;
  latestPremiumTalkLowPercentage?: Maybe<Scalars['Decimal']>;
  /** Total shares being offered as of the latest offering terms. */
  latestSizeInSecuritiesBase?: Maybe<Scalars['Long']>;
  /** Number of shares being issued by the company as of the latest offering terms. Issuance of primary shares will increase total shares outstanding. */
  latestSizeInSecuritiesBasePrimary?: Maybe<Scalars['Long']>;
  /** Number of shares being offered from existing shareholders as of the latest offering terms. */
  latestSizeInSecuritiesBaseSecondary?: Maybe<Scalars['Long']>;
  /** latestSizeInSecuritiesTotal = latestSizeInSecuritiesBasePrimary + latestSizeInSecuritiesBaseSecondary+ overAllotmentExercised. */
  latestSizeInSecuritiesTotal?: Maybe<Scalars['Long']>;
  /** latestSizeInSecuritiesTotalPrimary = latestSizeInSecuritiesBasePrimary + overAllotmentExercisedPrimary. */
  latestSizeInSecuritiesTotalPrimary?: Maybe<Scalars['Long']>;
  /** latestSizeInSecuritiesTotalSecondary = latestSizeInSecuritiesBaseSecondary + overAllotmentExercisedSecondary. */
  latestSizeInSecuritiesTotalSecondary?: Maybe<Scalars['Long']>;
  /** This date represents the day the offering went "live", aka began its marketing period. */
  launchDate?: Maybe<Scalars['Date']>;
  /** Unique CMG identifier for the first underwriting bookrunner listed on the prospectus. */
  leftLeadId?: Maybe<Scalars['UUID']>;
  /** The first underwriting bookrunner listed on the prospectus (top left of the cover page). Typically indicative of the most senior underwriting role in the transaction. */
  leftLeadName?: Maybe<Scalars['String']>;
  /** The date upon which an existing lock-up agreement is released implicitly as a result of a follow-on offering filing/launching within an existing lock-up period OR explicitly as a result of conditions being met that result in some/all of previously locked up shares being released. */
  lockUpEarlyReleaseDate?: Maybe<Scalars['Date']>;
  /** The date upon which the contractual agreement will expire. Parties subject to the lock-up are no longer restricted on the day following the Lock-Up Expiration. */
  lockUpExpirationDate?: Maybe<Scalars['Date']>;
  /** Number of days from pricing during which one or more parties are prevented from disposing or hedging positions due to a contractual obligation with the underwriters. */
  lockUpPeriod?: Maybe<Scalars['Int']>;
  /** Compensation received by the Underwriters for acting in a management role. */
  managementFeeUsd?: Maybe<Scalars['Decimal']>;
  managers: Array<ManagerAttributes>;
  /** Issuer's market capitalization at the time of pricing. Does not include the exercise of any over-allotments. Stated in local currency. */
  marketCapAtPricing?: Maybe<Scalars['Decimal']>;
  /** Issuer's market capitalization at the time of pricing. Does not include the exercise of any over-allotments. Stated in USD. */
  marketCapAtPricingUsd?: Maybe<Scalars['Decimal']>;
  /** Issuer's market capitalization before the offering. Stated in local currency. */
  marketCapPreOffering?: Maybe<Scalars['Decimal']>;
  /** Issuer's market capitalization before the offering. Stated in USD. */
  marketCapPreOfferingUsd?: Maybe<Scalars['Decimal']>;
  /** Time during the day the deal is launched, includes: PRE_OPEN, INTRA_DAY, POST_CLOSE. */
  marketTiming?: Maybe<MarketTiming>;
  /** Display name for MarketTiming */
  marketTimingDisplayName?: Maybe<Scalars['String']>;
  /** Timestamp of the most recent change to the offering. */
  modifiedAt: Scalars['Instant'];
  /** Equals (offerPrice - grossSpreadTotal). */
  netPrice?: Maybe<Scalars['Decimal']>;
  offering?: Maybe<Offering>;
  /** Timestamp when the offering is created at. */
  offeringCreatedAt?: Maybe<Scalars['Instant']>;
  /** Unique CMG identifier for a specific offering. */
  offeringId: Scalars['UUID'];
  /** Timestamp of the most recent definitional change to the offering, not including performance data updates. */
  offeringModifiedAt?: Maybe<Scalars['Instant']>;
  /** % change in share price from the offering price to the VWAP price on the first trade date. */
  offerToVwap1Day?: Maybe<Scalars['Decimal']>;
  /** Closing price 1 year post the first trade date. */
  oneYearSharePrice?: Maybe<Scalars['Decimal']>;
  /** Opening price on the first trade date. */
  openingSharePrice?: Maybe<Scalars['Decimal']>;
  /** Number of shares sold in addition to the total base shares in accordance with the total over-allotment authorized. */
  overAllotmentExercised?: Maybe<Scalars['Long']>;
  /** Number of primary shares sold in addition to the primary base shares in accordance with the primary shares over-allotment authorized. */
  overAllotmentExercisedPrimary?: Maybe<Scalars['Long']>;
  /** Offering size expressed as a notional amount (currency value) sold in addition to the total base offering size in accordance with the total over-allotment authorized. */
  overAllotmentExercisedProceeds?: Maybe<Scalars['Decimal']>;
  overAllotmentExercisedProceedsNotional?: Maybe<Scalars['Decimal']>;
  /** Number of secondary shares sold in addition to the secondary base shares in accordance with the secondary shares over-allotment authorized. */
  overAllotmentExercisedSecondary?: Maybe<Scalars['Long']>;
  /** Represents the total cost of the offering to the Issuer when stock price decline and gross spread are factored in as costs and measured as a % of stock price prior to filing the offering. Effectively the % change from the closing price prior to filing vs. the final per share proceeds to the Issuer. */
  pctAllInCost?: Maybe<Scalars['Decimal']>;
  /** A measure of the change in final offering proceeds from initial expected proceeds calculated as the % change from the initial offering Size ($M) in "Filed" status relative to final offering Size ($M) in "Priced" status and not taking into account any increase in size resulting from the exercise of the Over-Allotment. *Will only appear for IPO's. */
  pctChangeInSize?: Maybe<Scalars['Decimal']>;
  /** % change in share price from last trade prior to filing relative to offering price. */
  pctFileToOffer?: Maybe<Scalars['Decimal']>;
  /** Calculated value based on disclosed or implied commission payable to the manager(s) divided by the offering price. */
  pctGrossSpread?: Maybe<Scalars['Decimal']>;
  /** The closing price prior to pricing of the offering, stated in local currency. */
  pctLastTradeBeforeLaunch?: Maybe<Scalars['Decimal']>;
  /** % of total base offering shares relative to shares outstanding post-offering. */
  pctMarketCapAtPricing?: Maybe<Scalars['Decimal']>;
  /** % of total base offering shares relative to shares outstanding pre-offering. */
  pctMarketCapPreOffering?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to closing price 14 days post offering. */
  pctOfferTo14Day?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to closing price 180 days post offering. */
  pctOfferTo180Day?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to closing price on the First Trade Date. */
  pctOfferTo1Day?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to closing price 30 days post offering. */
  pctOfferTo30Day?: Maybe<Scalars['Decimal']>;
  pctOfferTo3Day?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to closing price 7 days post offering. */
  pctOfferTo7Day?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to closing price 90 days post offering. */
  pctOfferTo90Day?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to the most recent closing price. */
  pctOfferToCurrent?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to closing price 365 days post offering. */
  pctOfferToOneYear?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to opening trade on first trade date. */
  pctOfferToOpen?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the offering price to the final trade in the preceding quarter. */
  pctOfferToPriorQuarter?: Maybe<Scalars['Decimal']>;
  /** The Re Offer High stated as % of the Last Trade Before Offer. Representative of the expected discount To Last Trade %. */
  pctReOfferHigh?: Maybe<Scalars['Decimal']>;
  /** The Re Offer Low stated as % of the Last Trade Before Offer. Representative of the expected discount To Last Trade %. */
  pctReOfferLow?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the highest share price in the 52 weeks prior to pricing relative to offering price. */
  pctTo52WeekHigh?: Maybe<Scalars['Decimal']>;
  /** % change in share price from last trade prior to pricing relative to the offering price. */
  pctToLastTrade?: Maybe<Scalars['Decimal']>;
  /** % change in share price from the highest share price in the 52 weeks prior to pricing relative to offering price. */
  pctToVwap?: Maybe<Scalars['Decimal']>;
  /** % change from IPO offering price relative to the initial IPO price range. */
  pctVsMidpoint?: Maybe<Scalars['Decimal']>;
  /** Date when the offering is postponed. */
  postponedDate?: Maybe<Scalars['Date']>;
  /** Average Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  preOfferingAdtv?: Maybe<Scalars['Decimal']>;
  /** Total shares outstanding before the offering. */
  preOfferingShares?: Maybe<Scalars['Long']>;
  /** Final price of the offering, stated in local currency. */
  price?: Maybe<Scalars['Decimal']>;
  priceRangeHigh?: Maybe<Scalars['Decimal']>;
  priceRangeLow?: Maybe<Scalars['Decimal']>;
  /** Final price of the offering, stated in USD. */
  priceUsd?: Maybe<Scalars['Decimal']>;
  /** IPO offering price relative to stated initial IPO price range, includes: BELOW, WITHIN and ABOVE. */
  priceVsRange?: Maybe<PriceVsRange>;
  /** Display name for PriceVsRange */
  priceVsRangeDisplayName?: Maybe<Scalars['String']>;
  /** Currency code of the pricing instrument of the offering. */
  pricingCurrency?: Maybe<Currency>;
  /** Display name for PricingCurrency */
  pricingCurrencyDisplayName?: Maybe<Scalars['String']>;
  /** Date when the offering is priced. */
  pricingDate?: Maybe<Scalars['Date']>;
  /** Unique CMG identifier for the primary shareholder. */
  primaryShareholderFirmId?: Maybe<Scalars['UUID']>;
  /** Legal name of the shareholder with the most shares pre-offering. */
  primaryShareholderName?: Maybe<Scalars['String']>;
  /** Closing price on the last day of the preceding quarter. */
  priorQuarterSharePrice?: Maybe<Scalars['Decimal']>;
  /** Public filing date of the initial prospectus. */
  publicFilingDate?: Maybe<Scalars['Date']>;
  /** The high end of the re-offer range, stated in local currency. */
  reOfferHigh?: Maybe<Scalars['Decimal']>;
  /** The high end of the re-offer range, stated in USD. */
  reOfferHighUsd?: Maybe<Scalars['Decimal']>;
  /** The low end of the re-offer range, stated in local currency. */
  reOfferLow?: Maybe<Scalars['Decimal']>;
  /** The low end of the re-offer range, stated in USD. */
  reOfferLowUsd?: Maybe<Scalars['Decimal']>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the revised offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  revisedGrossProceedsBase?: Maybe<Scalars['Decimal']>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the revised offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  revisedGrossProceedsBaseUsd?: Maybe<Scalars['Decimal']>;
  /** High end of IPO price range as of the revised offering terms, stated in local currency. */
  revisedIpoRangeHigh?: Maybe<Scalars['Decimal']>;
  /** High end of IPO price range as of the revised offering terms, stated in USD. */
  revisedIpoRangeHighUsd?: Maybe<Scalars['Decimal']>;
  /** Low end of IPO price range as of the revised offering terms, stated in local currency. */
  revisedIpoRangeLow?: Maybe<Scalars['Decimal']>;
  /** Low end of IPO price range as of the revised offering terms, stated in USD. */
  revisedIpoRangeLowUsd?: Maybe<Scalars['Decimal']>;
  /** Share Class of the security, designated by letter or by name. Different classes of security often carries different privileges, such as voting rights. */
  securityDescription?: Maybe<ShareClass>;
  /** Display name for SecurityDescription */
  securityDescriptionDisplayName?: Maybe<Scalars['String']>;
  /** Type of security being offered, includes: COMMON_ORDINARY, GDR_ADR_ADS, UNIT, COMMON_WARRANTS, CONVERTIBLE_NOTE, PREFERRED_SHARE */
  securityType: SecurityType;
  /** Display name for SecurityType */
  securityTypeDisplayName?: Maybe<Scalars['String']>;
  /** Compensation per share received by the Underwriters for selling the offering. */
  sellingConcessionUsd?: Maybe<Scalars['Decimal']>;
  /** The manager that is responsible for syndicate settlement. */
  settlementAgentName?: Maybe<Scalars['String']>;
  /** Transaction settlement date as disclosed on final prospectus (typically T+2). */
  settlementDate?: Maybe<Scalars['Date']>;
  shareholders?: Maybe<Array<ShareholderAttributes>>;
  /** Total base offering shares (excluding any over-allotments) as a multiple of the Average Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  sizeAsMultipleOfAdtv?: Maybe<Scalars['Decimal']>;
  sizeUsd?: Maybe<Scalars['Decimal']>;
  /** Split Adjusted Offer Price. */
  splitAdjustedOfferPrice?: Maybe<Scalars['Decimal']>;
  /** The manager that is responsible for executing stabilization trades. */
  stabilizationAgentName?: Maybe<Scalars['String']>;
  /** Offering status, includes: FILED, LIVE, PRICED, POSTPONED, WITHDRAWN, TERMINATED, DRAFT. */
  status: OfferingStatus;
  /** Display name for Status */
  statusDisplayName?: Maybe<Scalars['String']>;
  /** When this attribute is populated, it means this offering has replaced another offering. This attribute holds the offering ID of the other offering that has been replaced. */
  supersedes?: Maybe<Scalars['UUID']>;
  /** Exchange trading symbol for the pricing instrument. */
  symbol?: Maybe<Scalars['String']>;
  /** Date when the offering is terminated. */
  terminatedDate?: Maybe<Scalars['Date']>;
  /** Time zone used for timestamps in the offering. */
  timeZone?: Maybe<Scalars['String']>;
  /** Total # of managers acting in a bookrunner role on the offering. */
  totalBookrunners?: Maybe<Scalars['Int']>;
  /** Total # of managers acting in a co-manager role on the offering. */
  totalCoManagers?: Maybe<Scalars['Int']>;
  /** Total # of managers underwriting the offering. */
  totalManagers?: Maybe<Scalars['Int']>;
  /** Total # of managers acting in non-bookrunner roles on the offering. */
  totalNonBookrunners?: Maybe<Scalars['Int']>;
  /** % of of shares owned by all shareholders tracked by CMG post-offering relative to total shares outstanding (totalPostOfferingOwnership/postOfferingShares ) */
  totalPctPostOfferingOwnership?: Maybe<Scalars['Decimal']>;
  /** % of of shares owned by all shareholders tracked by CMG pre-offering relative to total shares outstanding (totalPreOfferingOwnership/preOfferingShares ) */
  totalPctPreOfferingOwnership?: Maybe<Scalars['Decimal']>;
  /** Economic % of the total sum of shares attributable to managers in an active bookrunner or global coordinator role. */
  totalPctToActiveManagers?: Maybe<Scalars['Decimal']>;
  /** Economic % of the total sum of shares attributable to managers in a bookrunner role. */
  totalPctToBookrunners?: Maybe<Scalars['Decimal']>;
  /** Economic % of the total sum of shares attributable to managers in a co-manager role. */
  totalPctToCoManagers?: Maybe<Scalars['Decimal']>;
  /** Economic % of shares attributable to the left lead bank. */
  totalPctToLeftLead?: Maybe<Scalars['Decimal']>;
  /** Economic % of the total sum of shares attributable to managers in non bookrunner roles. */
  totalPctToNonBookrunners?: Maybe<Scalars['Decimal']>;
  /** Number of shares owned by all shareholders tracked by CMG post-offering (totalPreOfferingOwnership - total shares sold by the shareholders in the offering) */
  totalPostOfferingOwnership?: Maybe<Scalars['Long']>;
  /** Number of shares owned by all shareholders tracked by CMG pre-offering */
  totalPreOfferingOwnership?: Maybe<Scalars['Long']>;
  /** This is the sum of all the manager's total underwritten shares / securities in the offering, excluding over allotment. */
  totalUnderwritingSecurities?: Maybe<Scalars['Long']>;
  /** Legally binding date that drives trade booking. It’s the date when the banks execute the trades with the institutional investors. Depending on the time of pricing, banks will declare trade date same day or next day of pricing date. */
  tradeDate?: Maybe<Scalars['Date']>;
  /** Offering type, includes: IPO, FOLLOW_ON, MARKETED_FO, OVERNIGHT_FO, REGISTERED_BLOCK, UNREGISTERED_BLOCK, ATM, CONVERTIBLE, DIRECT_LISTING, REGISTERED_DIRECT, RIGHTS. */
  type: OfferingType;
  /** Display name for Type */
  typeDisplayName?: Maybe<Scalars['String']>;
  /** Compensation received by the Underwriters for underwriting services. */
  underwritingFeeUsd?: Maybe<Scalars['Decimal']>;
  /** How issuer plans to use the capital raised from the offering. */
  useOfProceeds?: Maybe<Array<UseOfProceeds>>;
  /** Display name for UseOfProceeds */
  useOfProceedsDisplayNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Additional information on the use of proceeds included in the prospectus. */
  useOfProceedsNote?: Maybe<Scalars['String']>;
  /** Volume Weighted Average Price on the day preceding first trade date. */
  vwapPrice?: Maybe<Scalars['Decimal']>;
  /** Date when the offering is withdrawn. */
  withdrawnDate?: Maybe<Scalars['Date']>;
};

/** A segment of a collection. */
export type OfferingAttributesCollectionSegment = {
  __typename?: 'OfferingAttributesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OfferingAttributes>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A entity representing metrics, derived, and entered properties from multiple source entities */
export type OfferingAttributesFilterInput = {
  /** The date with the highest Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  adtvRangeHighDate?: InputMaybe<DateOperationFilterInput>;
  /** The date with the lowest Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  adtvRangeLowDate?: InputMaybe<DateOperationFilterInput>;
  advisers?: InputMaybe<ListFilterInputTypeOfAdviserAttributesFilterInput>;
  /** netPrice-lastTradeBeforeFiling. */
  allInCost?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<OfferingAttributesFilterInput>>;
  /** Time when order book is closed. */
  booksCloseAt?: InputMaybe<InstantOperationFilterInput>;
  /** Filing date of initial confidential 'Draft Registration Statement'. Made public upon the release of the initial public filing of the prospectus. */
  confidentialFilingDate?: InputMaybe<DateOperationFilterInput>;
  /** Number of offering participants cornerstone investors for the deal. */
  cornerstoneInvestorsOfferingParticipantsCount?: InputMaybe<IntOperationFilterInput>;
  /** Number of Private Placement cornerstone investors for the deal. */
  cornerstoneInvestorsPrivatePlacementCount?: InputMaybe<IntOperationFilterInput>;
  /** Number of total cornerstone investors for the offering. */
  cornerstoneInvestorsTotalCount?: InputMaybe<IntOperationFilterInput>;
  /** Investment sum of all cornerstone investors that are participating in the in the base offering. Represented in local currency. */
  cornerstoneOfferingParticipantsAmount?: InputMaybe<DecimalOperationFilterInput>;
  /** Number of Private Placement cornerstone investors for the deal. */
  cornerstonePrivatePlacementAmount?: InputMaybe<DecimalOperationFilterInput>;
  /** Investment sum of all cornerstone investors for this offering. Represented in local currency. */
  cornerstoneTotalAmount?: InputMaybe<DecimalOperationFilterInput>;
  /** Share price as of the most recent close. */
  currentSharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Closing price 14 days post the first trade date. */
  day14SharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Closing price 180 days post the first trade date. */
  day180SharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Closing price on the first trade date. */
  day1SharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Volume Weighted Average Price on the first trade date. */
  day1VwapPrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Closing price 30 days post the first trade date. */
  day30SharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Closing price 3 days post the first trade date. */
  day3SharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Closing price 7 days post the first trade date. */
  day7SharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Closing price 90 days post the first trade date. */
  day90SharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Total costs and fees associated with the offering and payable to the Underwriters. Takes into account any shares sold in accordance with the Over-allotment Authorized. Typically paid by the Issuer. */
  estimatedFeeUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Country code of the pricing instrument of the offering. */
  exchangeCountry?: InputMaybe<CountryFilterInput>;
  /** Exchange Market Identifier Code in which the pricing instrument is primarily listed. */
  exchangeMic?: InputMaybe<OfferingSetupExchangeMicFilterInput>;
  /** Region code of the pricing instrument of the offering, includes: APAC, EMEA, LAMR, NAMR. */
  exchangeRegion?: InputMaybe<RegionFilterInput>;
  /** Highest share price in the 52 weeks preceding the pricing date of a Follow-On or Block offering. Highest share price in the 52 weeks preceding the announcement date of an ATM offering. */
  fiftyTwoWeekHigh?: InputMaybe<DecimalOperationFilterInput>;
  /** The date with the highest share price in the 52 weeks preceding the pricing date of a Follow-On or Block offering, or the announcement date of an ATM offering. */
  fiftyTwoWeekHighDate?: InputMaybe<DateOperationFilterInput>;
  /** Multiple of trade volume on first trade date relative to total deal size. */
  firstDayTurnover?: InputMaybe<DecimalOperationFilterInput>;
  /** Trading volume on first trade date. */
  firstDayVolume?: InputMaybe<LongOperationFilterInput>;
  /** Date the offering begins trading post pricing. */
  firstTradeDate?: InputMaybe<DateOperationFilterInput>;
  /** Underwriters' gross compensation in local currency, per share. */
  grossSpreadTotal?: InputMaybe<DecimalOperationFilterInput>;
  /** Underwriters' gross compensation in $USD, per share. */
  grossSpreadTotalUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Boolean indicating whether the offering has cornerstone investors. */
  hasCornerstoneInvestors?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the issuer entered into a Forward Sale Agreement in which shares sold in the offering were borrowed from a counter party and is expected to settle with the counter party at a future date by issuing shares or in some cases settling in cash. */
  hasForwardAgreement?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether multiple lock ups are stated for different parties in the contractual agreement. */
  hasMultipleLockUps?: InputMaybe<BooleanOperationFilterInput>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the initial offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  initialGrossProceedsBase?: InputMaybe<DecimalOperationFilterInput>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the initial offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  initialGrossProceedsBaseUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the initial offering terms. The amount is used for the purposes of estimating filing fees. */
  initialIndicativeGrossProceeds?: InputMaybe<DecimalOperationFilterInput>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the initial offering terms, stated in USD. The amount is used for the purposes of estimating filing fees. */
  initialIndicativeGrossProceedsUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** High end of IPO price range as of the initial offering terms, stated in local currency. */
  initialIpoRangeHigh?: InputMaybe<DecimalOperationFilterInput>;
  /** High end of IPO price range as of the initial offering terms, stated in USD. */
  initialIpoRangeHighUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Low end of IPO price range as of the initial offering terms, stated in local currency. */
  initialIpoRangeLow?: InputMaybe<DecimalOperationFilterInput>;
  /** Low end of IPO price range as of the initial offering terms, stated in USD. */
  initialIpoRangeLowUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Total shares being offered as of the initial offering terms. */
  initialSizeInSecuritiesBase?: InputMaybe<LongOperationFilterInput>;
  /** Total shares being offered as of the initial offering terms. */
  initialSizeInSecuritiesTotal?: InputMaybe<LongOperationFilterInput>;
  /** Boolean indicating whether the pricing date of the offering has been accelerated. */
  isAccelerated?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the offering is a carve out offering. */
  isCarveOut?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the offering is a clean up trade. */
  isCleanUpTrade?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether an issuer repurchases shares from a selling shareholder in a separate transaction completed concurrently with a public offering (deal size does not include portion to be repurchased). */
  isCompanyRepurchaseAdditional?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether an issuer repurchases shares from a selling shareholder as part of an offering (deal size includes portion to be repurchased). */
  isCompanyRepurchaseIncluded?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether an IPO or follow-on offering occurs in parallel to a converts offering. */
  isConcurrentOffering?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether a lock-up has conditional criteria stated in the contractual agreement. */
  isConditionalLockUp?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the offering is dual listed. */
  isDualListed?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the issuer is classified as an emerging growth company. */
  isEgc?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the offering is managed on the CMG XC platform. */
  isExecutingOnPlatform?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the security is registered with the FDIC. */
  isFdicRegistered?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the offering is the first follow-on offering for the issuer after their IPO. */
  isFirstFollowOn?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the security is a Reg S offering. */
  isRegS?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the security is a Rule 144A offering. */
  isRule144A?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the security is registered with the SEC. */
  isSecRegistered?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the issuer company is classified as SPAC. */
  isSpac?: InputMaybe<BooleanOperationFilterInput>;
  /** When this attribute is populated, it means the offering has been replaced by another offering. This attribute holds the offering ID of the surviving offering. */
  isSupersededBy?: InputMaybe<UuidOperationFilterInput>;
  /** Boolean indicating whether the offering is classified as a synthetic secondary. */
  isSyntheticSecondary?: InputMaybe<BooleanOperationFilterInput>;
  /** Boolean indicating whether the offering is classified as an uplisting. */
  isUpListing?: InputMaybe<BooleanOperationFilterInput>;
  /** The closing price prior to the initial filing of the offering, stated in local currency. */
  lastTradeBeforeFiling?: InputMaybe<DecimalOperationFilterInput>;
  /** The closing price prior to the initial filing of the offering, stated in USD. */
  lastTradeBeforeFilingUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** The closing price prior to launch of the offering, stated in local currency. */
  lastTradeBeforeLaunch?: InputMaybe<DecimalOperationFilterInput>;
  /** The closing price prior to launch of the offering, stated in USD. */
  lastTradeBeforeLaunchUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** The closing price prior to pricing of the offering, stated in local currency. */
  lastTradeBeforeOffer?: InputMaybe<DecimalOperationFilterInput>;
  /** The closing price prior to pricing of the offering, stated in USD. */
  lastTradeBeforeOfferUsd?: InputMaybe<DecimalOperationFilterInput>;
  latestCouponTalkPercentageHigh?: InputMaybe<DecimalOperationFilterInput>;
  latestCouponTalkPercentageLow?: InputMaybe<DecimalOperationFilterInput>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the latest offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  latestGrossProceedsBase?: InputMaybe<DecimalOperationFilterInput>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the latest offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  latestGrossProceedsBaseUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Total capital raised, including the exercise of any over-allotments, as of the latest offering terms. Equals [(sizeInSecuritiesBase + overAllotmentExercised) * offerPrice], stated in local currency. */
  latestGrossProceedsTotal?: InputMaybe<DecimalOperationFilterInput>;
  /** Total capital raised, including the exercise of any over-allotments, as of the latest offering terms. Equals [(sizeInSecuritiesBase + overAllotmentExercised) * offerPrice], stated in USD. */
  latestGrossProceedsTotalUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the latest offering terms. The amount is used for the purposes of estimating filing fees. */
  latestIndicativeGrossProceeds?: InputMaybe<DecimalOperationFilterInput>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the latest offering terms, stated in USD. The amount is used for the purposes of estimating filing fees. */
  latestIndicativeGrossProceedsUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** High end of IPO price range as of the latest offering terms, stated in USD. */
  latestIpoRangeHighUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Low end of IPO price range as of the latest offering terms, stated in USD. */
  latestIpoRangeLowUsd?: InputMaybe<DecimalOperationFilterInput>;
  latestOfferPrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Final price of the offering, stated in USD. */
  latestOfferPriceUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Number of shares the underwriters are authorized to sell in addition to the total base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of total base share size. */
  latestOverAllotmentAuthorized?: InputMaybe<LongOperationFilterInput>;
  /** Number of primary shares the underwriters are authorized to sell in addition to the primary base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of primary base share size. */
  latestOverAllotmentAuthorizedPrimary?: InputMaybe<LongOperationFilterInput>;
  /** Offering size expressed as a notional amount (currency) the underwriters are authorized to sell in addition to the total base offering size, as of the latest offering terms. */
  latestOverAllotmentAuthorizedProceeds?: InputMaybe<DecimalOperationFilterInput>;
  /** Number of secondary shares the underwriters are authorized to sell in addition to the secondary base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of secondary base share size. */
  latestOverAllotmentAuthorizedSecondary?: InputMaybe<LongOperationFilterInput>;
  /** Secondary base shares offered (i.e. existing shareholders) as a % of total base shares offered, as of the latest offering terms. */
  latestPctSecondaryShares?: InputMaybe<DecimalOperationFilterInput>;
  /** Total shares outstanding after the offering as of the latest offering terms. */
  latestPostOfferingShares?: InputMaybe<LongOperationFilterInput>;
  latestPremiumTalkHighPercentage?: InputMaybe<DecimalOperationFilterInput>;
  latestPremiumTalkLowPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** Total shares being offered as of the latest offering terms. */
  latestSizeInSecuritiesBase?: InputMaybe<LongOperationFilterInput>;
  /** Number of shares being issued by the company as of the latest offering terms. Issuance of primary shares will increase total shares outstanding. */
  latestSizeInSecuritiesBasePrimary?: InputMaybe<LongOperationFilterInput>;
  /** Number of shares being offered from existing shareholders as of the latest offering terms. */
  latestSizeInSecuritiesBaseSecondary?: InputMaybe<LongOperationFilterInput>;
  /** latestSizeInSecuritiesTotal = latestSizeInSecuritiesBasePrimary + latestSizeInSecuritiesBaseSecondary+ overAllotmentExercised. */
  latestSizeInSecuritiesTotal?: InputMaybe<LongOperationFilterInput>;
  /** latestSizeInSecuritiesTotalPrimary = latestSizeInSecuritiesBasePrimary + overAllotmentExercisedPrimary. */
  latestSizeInSecuritiesTotalPrimary?: InputMaybe<LongOperationFilterInput>;
  /** latestSizeInSecuritiesTotalSecondary = latestSizeInSecuritiesBaseSecondary + overAllotmentExercisedSecondary. */
  latestSizeInSecuritiesTotalSecondary?: InputMaybe<LongOperationFilterInput>;
  /** This date represents the day the offering went "live", aka began its marketing period. */
  launchDate?: InputMaybe<DateOperationFilterInput>;
  /** Unique CMG identifier for the first underwriting bookrunner listed on the prospectus. */
  leftLeadId?: InputMaybe<UuidOperationFilterInput>;
  /** The first underwriting bookrunner listed on the prospectus (top left of the cover page). Typically indicative of the most senior underwriting role in the transaction. */
  leftLeadName?: InputMaybe<StringOperationFilterInput>;
  /** The date upon which an existing lock-up agreement is released implicitly as a result of a follow-on offering filing/launching within an existing lock-up period OR explicitly as a result of conditions being met that result in some/all of previously locked up shares being released. */
  lockUpEarlyReleaseDate?: InputMaybe<DateOperationFilterInput>;
  /** The date upon which the contractual agreement will expire. Parties subject to the lock-up are no longer restricted on the day following the Lock-Up Expiration. */
  lockUpExpirationDate?: InputMaybe<DateOperationFilterInput>;
  /** Number of days from pricing during which one or more parties are prevented from disposing or hedging positions due to a contractual obligation with the underwriters. */
  lockUpPeriod?: InputMaybe<IntOperationFilterInput>;
  /** Compensation received by the Underwriters for acting in a management role. */
  managementFeeUsd?: InputMaybe<DecimalOperationFilterInput>;
  managers?: InputMaybe<ListFilterInputTypeOfManagerAttributesFilterInput>;
  /** Issuer's market capitalization at the time of pricing. Does not include the exercise of any over-allotments. Stated in local currency. */
  marketCapAtPricing?: InputMaybe<DecimalOperationFilterInput>;
  /** Issuer's market capitalization at the time of pricing. Does not include the exercise of any over-allotments. Stated in USD. */
  marketCapAtPricingUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Issuer's market capitalization before the offering. Stated in local currency. */
  marketCapPreOffering?: InputMaybe<DecimalOperationFilterInput>;
  /** Issuer's market capitalization before the offering. Stated in USD. */
  marketCapPreOfferingUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Time during the day the deal is launched, includes: PRE_OPEN, INTRA_DAY, POST_CLOSE. */
  marketTiming?: InputMaybe<MarketTimingFilterInput>;
  /** Timestamp of the most recent change to the offering. */
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  /** Equals (offerPrice - grossSpreadTotal). */
  netPrice?: InputMaybe<DecimalOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Timestamp when the offering is created at. */
  offeringCreatedAt?: InputMaybe<InstantOperationFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  /** Timestamp of the most recent definitional change to the offering, not including performance data updates. */
  offeringModifiedAt?: InputMaybe<InstantOperationFilterInput>;
  /** % change in share price from the offering price to the VWAP price on the first trade date. */
  offerToVwap1Day?: InputMaybe<DecimalOperationFilterInput>;
  /** Closing price 1 year post the first trade date. */
  oneYearSharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Opening price on the first trade date. */
  openingSharePrice?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<OfferingAttributesFilterInput>>;
  /** Number of shares sold in addition to the total base shares in accordance with the total over-allotment authorized. */
  overAllotmentExercised?: InputMaybe<LongOperationFilterInput>;
  /** Number of primary shares sold in addition to the primary base shares in accordance with the primary shares over-allotment authorized. */
  overAllotmentExercisedPrimary?: InputMaybe<LongOperationFilterInput>;
  /** Offering size expressed as a notional amount (currency value) sold in addition to the total base offering size in accordance with the total over-allotment authorized. */
  overAllotmentExercisedProceeds?: InputMaybe<DecimalOperationFilterInput>;
  overAllotmentExercisedProceedsNotional?: InputMaybe<DecimalOperationFilterInput>;
  /** Number of secondary shares sold in addition to the secondary base shares in accordance with the secondary shares over-allotment authorized. */
  overAllotmentExercisedSecondary?: InputMaybe<LongOperationFilterInput>;
  /** Represents the total cost of the offering to the Issuer when stock price decline and gross spread are factored in as costs and measured as a % of stock price prior to filing the offering. Effectively the % change from the closing price prior to filing vs. the final per share proceeds to the Issuer. */
  pctAllInCost?: InputMaybe<DecimalOperationFilterInput>;
  /** A measure of the change in final offering proceeds from initial expected proceeds calculated as the % change from the initial offering Size ($M) in "Filed" status relative to final offering Size ($M) in "Priced" status and not taking into account any increase in size resulting from the exercise of the Over-Allotment. *Will only appear for IPO's. */
  pctChangeInSize?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from last trade prior to filing relative to offering price. */
  pctFileToOffer?: InputMaybe<DecimalOperationFilterInput>;
  /** Calculated value based on disclosed or implied commission payable to the manager(s) divided by the offering price. */
  pctGrossSpread?: InputMaybe<DecimalOperationFilterInput>;
  /** The closing price prior to pricing of the offering, stated in local currency. */
  pctLastTradeBeforeLaunch?: InputMaybe<DecimalOperationFilterInput>;
  /** % of total base offering shares relative to shares outstanding post-offering. */
  pctMarketCapAtPricing?: InputMaybe<DecimalOperationFilterInput>;
  /** % of total base offering shares relative to shares outstanding pre-offering. */
  pctMarketCapPreOffering?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to closing price 14 days post offering. */
  pctOfferTo14Day?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to closing price 180 days post offering. */
  pctOfferTo180Day?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to closing price on the First Trade Date. */
  pctOfferTo1Day?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to closing price 30 days post offering. */
  pctOfferTo30Day?: InputMaybe<DecimalOperationFilterInput>;
  pctOfferTo3Day?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to closing price 7 days post offering. */
  pctOfferTo7Day?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to closing price 90 days post offering. */
  pctOfferTo90Day?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to the most recent closing price. */
  pctOfferToCurrent?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to closing price 365 days post offering. */
  pctOfferToOneYear?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to opening trade on first trade date. */
  pctOfferToOpen?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the offering price to the final trade in the preceding quarter. */
  pctOfferToPriorQuarter?: InputMaybe<DecimalOperationFilterInput>;
  /** The Re Offer High stated as % of the Last Trade Before Offer. Representative of the expected discount To Last Trade %. */
  pctReOfferHigh?: InputMaybe<DecimalOperationFilterInput>;
  /** The Re Offer Low stated as % of the Last Trade Before Offer. Representative of the expected discount To Last Trade %. */
  pctReOfferLow?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the highest share price in the 52 weeks prior to pricing relative to offering price. */
  pctTo52WeekHigh?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from last trade prior to pricing relative to the offering price. */
  pctToLastTrade?: InputMaybe<DecimalOperationFilterInput>;
  /** % change in share price from the highest share price in the 52 weeks prior to pricing relative to offering price. */
  pctToVwap?: InputMaybe<DecimalOperationFilterInput>;
  /** % change from IPO offering price relative to the initial IPO price range. */
  pctVsMidpoint?: InputMaybe<DecimalOperationFilterInput>;
  /** Date when the offering is postponed. */
  postponedDate?: InputMaybe<DateOperationFilterInput>;
  /** Average Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  preOfferingAdtv?: InputMaybe<DecimalOperationFilterInput>;
  /** Total shares outstanding before the offering. */
  preOfferingShares?: InputMaybe<LongOperationFilterInput>;
  /** Final price of the offering, stated in local currency. */
  price?: InputMaybe<DecimalOperationFilterInput>;
  priceRangeHigh?: InputMaybe<DecimalOperationFilterInput>;
  priceRangeLow?: InputMaybe<DecimalOperationFilterInput>;
  /** Final price of the offering, stated in USD. */
  priceUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** IPO offering price relative to stated initial IPO price range, includes: BELOW, WITHIN and ABOVE. */
  priceVsRange?: InputMaybe<PriceVsRangeFilterInput>;
  /** Currency code of the pricing instrument of the offering. */
  pricingCurrency?: InputMaybe<CurrencyFilterInput>;
  /** Date when the offering is priced. */
  pricingDate?: InputMaybe<DateOperationFilterInput>;
  /** Unique CMG identifier for the primary shareholder. */
  primaryShareholderFirmId?: InputMaybe<UuidOperationFilterInput>;
  /** Legal name of the shareholder with the most shares pre-offering. */
  primaryShareholderName?: InputMaybe<StringOperationFilterInput>;
  /** Closing price on the last day of the preceding quarter. */
  priorQuarterSharePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Public filing date of the initial prospectus. */
  publicFilingDate?: InputMaybe<DateOperationFilterInput>;
  /** The high end of the re-offer range, stated in local currency. */
  reOfferHigh?: InputMaybe<DecimalOperationFilterInput>;
  /** The high end of the re-offer range, stated in USD. */
  reOfferHighUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** The low end of the re-offer range, stated in local currency. */
  reOfferLow?: InputMaybe<DecimalOperationFilterInput>;
  /** The low end of the re-offer range, stated in USD. */
  reOfferLowUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the revised offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  revisedGrossProceedsBase?: InputMaybe<DecimalOperationFilterInput>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the revised offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  revisedGrossProceedsBaseUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** High end of IPO price range as of the revised offering terms, stated in local currency. */
  revisedIpoRangeHigh?: InputMaybe<DecimalOperationFilterInput>;
  /** High end of IPO price range as of the revised offering terms, stated in USD. */
  revisedIpoRangeHighUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Low end of IPO price range as of the revised offering terms, stated in local currency. */
  revisedIpoRangeLow?: InputMaybe<DecimalOperationFilterInput>;
  /** Low end of IPO price range as of the revised offering terms, stated in USD. */
  revisedIpoRangeLowUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Share Class of the security, designated by letter or by name. Different classes of security often carries different privileges, such as voting rights. */
  securityDescription?: InputMaybe<ShareClassFilterInput>;
  /** Type of security being offered, includes: COMMON_ORDINARY, GDR_ADR_ADS, UNIT, COMMON_WARRANTS, CONVERTIBLE_NOTE, PREFERRED_SHARE */
  securityType?: InputMaybe<SecurityTypeFilterInput>;
  /** Compensation per share received by the Underwriters for selling the offering. */
  sellingConcessionUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** The manager that is responsible for syndicate settlement. */
  settlementAgentName?: InputMaybe<StringOperationFilterInput>;
  /** Transaction settlement date as disclosed on final prospectus (typically T+2). */
  settlementDate?: InputMaybe<DateOperationFilterInput>;
  shareholders?: InputMaybe<ListFilterInputTypeOfShareholderAttributesFilterInput>;
  /** Total base offering shares (excluding any over-allotments) as a multiple of the Average Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  sizeAsMultipleOfAdtv?: InputMaybe<DecimalOperationFilterInput>;
  sizeUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** Split Adjusted Offer Price. */
  splitAdjustedOfferPrice?: InputMaybe<DecimalOperationFilterInput>;
  /** The manager that is responsible for executing stabilization trades. */
  stabilizationAgentName?: InputMaybe<StringOperationFilterInput>;
  /** Offering status, includes: FILED, LIVE, PRICED, POSTPONED, WITHDRAWN, TERMINATED, DRAFT. */
  status?: InputMaybe<OfferingStatusFilterInput>;
  /** When this attribute is populated, it means this offering has replaced another offering. This attribute holds the offering ID of the other offering that has been replaced. */
  supersedes?: InputMaybe<UuidOperationFilterInput>;
  /** Exchange trading symbol for the pricing instrument. */
  symbol?: InputMaybe<StringOperationFilterInput>;
  /** Date when the offering is terminated. */
  terminatedDate?: InputMaybe<DateOperationFilterInput>;
  /** Time zone used for timestamps in the offering. */
  timeZone?: InputMaybe<StringOperationFilterInput>;
  /** Total # of managers acting in a bookrunner role on the offering. */
  totalBookrunners?: InputMaybe<IntOperationFilterInput>;
  /** Total # of managers acting in a co-manager role on the offering. */
  totalCoManagers?: InputMaybe<IntOperationFilterInput>;
  /** Total # of managers underwriting the offering. */
  totalManagers?: InputMaybe<IntOperationFilterInput>;
  /** Total # of managers acting in non-bookrunner roles on the offering. */
  totalNonBookrunners?: InputMaybe<IntOperationFilterInput>;
  /** % of of shares owned by all shareholders tracked by CMG post-offering relative to total shares outstanding (totalPostOfferingOwnership/postOfferingShares ) */
  totalPctPostOfferingOwnership?: InputMaybe<DecimalOperationFilterInput>;
  /** % of of shares owned by all shareholders tracked by CMG pre-offering relative to total shares outstanding (totalPreOfferingOwnership/preOfferingShares ) */
  totalPctPreOfferingOwnership?: InputMaybe<DecimalOperationFilterInput>;
  /** Economic % of the total sum of shares attributable to managers in an active bookrunner or global coordinator role. */
  totalPctToActiveManagers?: InputMaybe<DecimalOperationFilterInput>;
  /** Economic % of the total sum of shares attributable to managers in a bookrunner role. */
  totalPctToBookrunners?: InputMaybe<DecimalOperationFilterInput>;
  /** Economic % of the total sum of shares attributable to managers in a co-manager role. */
  totalPctToCoManagers?: InputMaybe<DecimalOperationFilterInput>;
  /** Economic % of shares attributable to the left lead bank. */
  totalPctToLeftLead?: InputMaybe<DecimalOperationFilterInput>;
  /** Economic % of the total sum of shares attributable to managers in non bookrunner roles. */
  totalPctToNonBookrunners?: InputMaybe<DecimalOperationFilterInput>;
  /** Number of shares owned by all shareholders tracked by CMG post-offering (totalPreOfferingOwnership - total shares sold by the shareholders in the offering) */
  totalPostOfferingOwnership?: InputMaybe<LongOperationFilterInput>;
  /** Number of shares owned by all shareholders tracked by CMG pre-offering */
  totalPreOfferingOwnership?: InputMaybe<LongOperationFilterInput>;
  /** This is the sum of all the manager's total underwritten shares / securities in the offering, excluding over allotment. */
  totalUnderwritingSecurities?: InputMaybe<LongOperationFilterInput>;
  /** Legally binding date that drives trade booking. It’s the date when the banks execute the trades with the institutional investors. Depending on the time of pricing, banks will declare trade date same day or next day of pricing date. */
  tradeDate?: InputMaybe<DateOperationFilterInput>;
  /** Offering type, includes: IPO, FOLLOW_ON, MARKETED_FO, OVERNIGHT_FO, REGISTERED_BLOCK, UNREGISTERED_BLOCK, ATM, CONVERTIBLE, DIRECT_LISTING, REGISTERED_DIRECT, RIGHTS. */
  type?: InputMaybe<OfferingTypeFilterInput>;
  /** Compensation received by the Underwriters for underwriting services. */
  underwritingFeeUsd?: InputMaybe<DecimalOperationFilterInput>;
  /** How issuer plans to use the capital raised from the offering. */
  useOfProceeds?: InputMaybe<UseOfProceedsListFilterInput>;
  /** Additional information on the use of proceeds included in the prospectus. */
  useOfProceedsNote?: InputMaybe<StringOperationFilterInput>;
  /** Volume Weighted Average Price on the day preceding first trade date. */
  vwapPrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Date when the offering is withdrawn. */
  withdrawnDate?: InputMaybe<DateOperationFilterInput>;
};

/** A entity representing metrics, derived, and entered properties from multiple source entities */
export type OfferingAttributesSortInput = {
  /** The date with the highest Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  adtvRangeHighDate?: InputMaybe<SortEnumType>;
  /** The date with the lowest Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  adtvRangeLowDate?: InputMaybe<SortEnumType>;
  /** netPrice-lastTradeBeforeFiling. */
  allInCost?: InputMaybe<SortEnumType>;
  /** Time when order book is closed. */
  booksCloseAt?: InputMaybe<SortEnumType>;
  /** Filing date of initial confidential 'Draft Registration Statement'. Made public upon the release of the initial public filing of the prospectus. */
  confidentialFilingDate?: InputMaybe<SortEnumType>;
  /** Number of offering participants cornerstone investors for the deal. */
  cornerstoneInvestorsOfferingParticipantsCount?: InputMaybe<SortEnumType>;
  /** Number of Private Placement cornerstone investors for the deal. */
  cornerstoneInvestorsPrivatePlacementCount?: InputMaybe<SortEnumType>;
  /** Number of total cornerstone investors for the offering. */
  cornerstoneInvestorsTotalCount?: InputMaybe<SortEnumType>;
  /** Investment sum of all cornerstone investors that are participating in the in the base offering. Represented in local currency. */
  cornerstoneOfferingParticipantsAmount?: InputMaybe<SortEnumType>;
  /** Number of Private Placement cornerstone investors for the deal. */
  cornerstonePrivatePlacementAmount?: InputMaybe<SortEnumType>;
  /** Investment sum of all cornerstone investors for this offering. Represented in local currency. */
  cornerstoneTotalAmount?: InputMaybe<SortEnumType>;
  /** Share price as of the most recent close. */
  currentSharePrice?: InputMaybe<SortEnumType>;
  /** Closing price 14 days post the first trade date. */
  day14SharePrice?: InputMaybe<SortEnumType>;
  /** Closing price 180 days post the first trade date. */
  day180SharePrice?: InputMaybe<SortEnumType>;
  /** Closing price on the first trade date. */
  day1SharePrice?: InputMaybe<SortEnumType>;
  /** Volume Weighted Average Price on the first trade date. */
  day1VwapPrice?: InputMaybe<SortEnumType>;
  /** Closing price 30 days post the first trade date. */
  day30SharePrice?: InputMaybe<SortEnumType>;
  /** Closing price 3 days post the first trade date. */
  day3SharePrice?: InputMaybe<SortEnumType>;
  /** Closing price 7 days post the first trade date. */
  day7SharePrice?: InputMaybe<SortEnumType>;
  /** Closing price 90 days post the first trade date. */
  day90SharePrice?: InputMaybe<SortEnumType>;
  /** Total costs and fees associated with the offering and payable to the Underwriters. Takes into account any shares sold in accordance with the Over-allotment Authorized. Typically paid by the Issuer. */
  estimatedFeeUsd?: InputMaybe<SortEnumType>;
  /** Country code of the pricing instrument of the offering. */
  exchangeCountry?: InputMaybe<CountrySortInput>;
  /** Exchange Market Identifier Code in which the pricing instrument is primarily listed. */
  exchangeMic?: InputMaybe<OfferingSetupExchangeMicSortInput>;
  /** Region code of the pricing instrument of the offering, includes: APAC, EMEA, LAMR, NAMR. */
  exchangeRegion?: InputMaybe<RegionSortInput>;
  /** Highest share price in the 52 weeks preceding the pricing date of a Follow-On or Block offering. Highest share price in the 52 weeks preceding the announcement date of an ATM offering. */
  fiftyTwoWeekHigh?: InputMaybe<SortEnumType>;
  /** The date with the highest share price in the 52 weeks preceding the pricing date of a Follow-On or Block offering, or the announcement date of an ATM offering. */
  fiftyTwoWeekHighDate?: InputMaybe<SortEnumType>;
  /** Multiple of trade volume on first trade date relative to total deal size. */
  firstDayTurnover?: InputMaybe<SortEnumType>;
  /** Trading volume on first trade date. */
  firstDayVolume?: InputMaybe<SortEnumType>;
  /** Date the offering begins trading post pricing. */
  firstTradeDate?: InputMaybe<SortEnumType>;
  /** Underwriters' gross compensation in local currency, per share. */
  grossSpreadTotal?: InputMaybe<SortEnumType>;
  /** Underwriters' gross compensation in $USD, per share. */
  grossSpreadTotalUsd?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering has cornerstone investors. */
  hasCornerstoneInvestors?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the issuer entered into a Forward Sale Agreement in which shares sold in the offering were borrowed from a counter party and is expected to settle with the counter party at a future date by issuing shares or in some cases settling in cash. */
  hasForwardAgreement?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether multiple lock ups are stated for different parties in the contractual agreement. */
  hasMultipleLockUps?: InputMaybe<SortEnumType>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the initial offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  initialGrossProceedsBase?: InputMaybe<SortEnumType>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the initial offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  initialGrossProceedsBaseUsd?: InputMaybe<SortEnumType>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the initial offering terms. The amount is used for the purposes of estimating filing fees. */
  initialIndicativeGrossProceeds?: InputMaybe<SortEnumType>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the initial offering terms, stated in USD. The amount is used for the purposes of estimating filing fees. */
  initialIndicativeGrossProceedsUsd?: InputMaybe<SortEnumType>;
  /** High end of IPO price range as of the initial offering terms, stated in local currency. */
  initialIpoRangeHigh?: InputMaybe<SortEnumType>;
  /** High end of IPO price range as of the initial offering terms, stated in USD. */
  initialIpoRangeHighUsd?: InputMaybe<SortEnumType>;
  /** Low end of IPO price range as of the initial offering terms, stated in local currency. */
  initialIpoRangeLow?: InputMaybe<SortEnumType>;
  /** Low end of IPO price range as of the initial offering terms, stated in USD. */
  initialIpoRangeLowUsd?: InputMaybe<SortEnumType>;
  /** Total shares being offered as of the initial offering terms. */
  initialSizeInSecuritiesBase?: InputMaybe<SortEnumType>;
  /** Total shares being offered as of the initial offering terms. */
  initialSizeInSecuritiesTotal?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the pricing date of the offering has been accelerated. */
  isAccelerated?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering is a carve out offering. */
  isCarveOut?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering is a clean up trade. */
  isCleanUpTrade?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether an issuer repurchases shares from a selling shareholder in a separate transaction completed concurrently with a public offering (deal size does not include portion to be repurchased). */
  isCompanyRepurchaseAdditional?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether an issuer repurchases shares from a selling shareholder as part of an offering (deal size includes portion to be repurchased). */
  isCompanyRepurchaseIncluded?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether an IPO or follow-on offering occurs in parallel to a converts offering. */
  isConcurrentOffering?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether a lock-up has conditional criteria stated in the contractual agreement. */
  isConditionalLockUp?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering is dual listed. */
  isDualListed?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the issuer is classified as an emerging growth company. */
  isEgc?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering is managed on the CMG XC platform. */
  isExecutingOnPlatform?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the security is registered with the FDIC. */
  isFdicRegistered?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering is the first follow-on offering for the issuer after their IPO. */
  isFirstFollowOn?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the security is a Reg S offering. */
  isRegS?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the security is a Rule 144A offering. */
  isRule144A?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the security is registered with the SEC. */
  isSecRegistered?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the issuer company is classified as SPAC. */
  isSpac?: InputMaybe<SortEnumType>;
  /** When this attribute is populated, it means the offering has been replaced by another offering. This attribute holds the offering ID of the surviving offering. */
  isSupersededBy?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering is classified as a synthetic secondary. */
  isSyntheticSecondary?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering is classified as an uplisting. */
  isUpListing?: InputMaybe<SortEnumType>;
  /** The closing price prior to the initial filing of the offering, stated in local currency. */
  lastTradeBeforeFiling?: InputMaybe<SortEnumType>;
  /** The closing price prior to the initial filing of the offering, stated in USD. */
  lastTradeBeforeFilingUsd?: InputMaybe<SortEnumType>;
  /** The closing price prior to launch of the offering, stated in local currency. */
  lastTradeBeforeLaunch?: InputMaybe<SortEnumType>;
  /** The closing price prior to launch of the offering, stated in USD. */
  lastTradeBeforeLaunchUsd?: InputMaybe<SortEnumType>;
  /** The closing price prior to pricing of the offering, stated in local currency. */
  lastTradeBeforeOffer?: InputMaybe<SortEnumType>;
  /** The closing price prior to pricing of the offering, stated in USD. */
  lastTradeBeforeOfferUsd?: InputMaybe<SortEnumType>;
  latestCouponTalkPercentageHigh?: InputMaybe<SortEnumType>;
  latestCouponTalkPercentageLow?: InputMaybe<SortEnumType>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the latest offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  latestGrossProceedsBase?: InputMaybe<SortEnumType>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the latest offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  latestGrossProceedsBaseUsd?: InputMaybe<SortEnumType>;
  /** Total capital raised, including the exercise of any over-allotments, as of the latest offering terms. Equals [(sizeInSecuritiesBase + overAllotmentExercised) * offerPrice], stated in local currency. */
  latestGrossProceedsTotal?: InputMaybe<SortEnumType>;
  /** Total capital raised, including the exercise of any over-allotments, as of the latest offering terms. Equals [(sizeInSecuritiesBase + overAllotmentExercised) * offerPrice], stated in USD. */
  latestGrossProceedsTotalUsd?: InputMaybe<SortEnumType>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the latest offering terms. The amount is used for the purposes of estimating filing fees. */
  latestIndicativeGrossProceeds?: InputMaybe<SortEnumType>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC as of the latest offering terms, stated in USD. The amount is used for the purposes of estimating filing fees. */
  latestIndicativeGrossProceedsUsd?: InputMaybe<SortEnumType>;
  /** High end of IPO price range as of the latest offering terms, stated in USD. */
  latestIpoRangeHighUsd?: InputMaybe<SortEnumType>;
  /** Low end of IPO price range as of the latest offering terms, stated in USD. */
  latestIpoRangeLowUsd?: InputMaybe<SortEnumType>;
  latestOfferPrice?: InputMaybe<SortEnumType>;
  /** Final price of the offering, stated in USD. */
  latestOfferPriceUsd?: InputMaybe<SortEnumType>;
  /** Number of shares the underwriters are authorized to sell in addition to the total base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of total base share size. */
  latestOverAllotmentAuthorized?: InputMaybe<SortEnumType>;
  /** Number of primary shares the underwriters are authorized to sell in addition to the primary base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of primary base share size. */
  latestOverAllotmentAuthorizedPrimary?: InputMaybe<SortEnumType>;
  /** Offering size expressed as a notional amount (currency) the underwriters are authorized to sell in addition to the total base offering size, as of the latest offering terms. */
  latestOverAllotmentAuthorizedProceeds?: InputMaybe<SortEnumType>;
  /** Number of secondary shares the underwriters are authorized to sell in addition to the secondary base shares as of the latest offering terms. Also referred to as the 'greenshoe' option. Typically 15% of secondary base share size. */
  latestOverAllotmentAuthorizedSecondary?: InputMaybe<SortEnumType>;
  /** Secondary base shares offered (i.e. existing shareholders) as a % of total base shares offered, as of the latest offering terms. */
  latestPctSecondaryShares?: InputMaybe<SortEnumType>;
  /** Total shares outstanding after the offering as of the latest offering terms. */
  latestPostOfferingShares?: InputMaybe<SortEnumType>;
  latestPremiumTalkHighPercentage?: InputMaybe<SortEnumType>;
  latestPremiumTalkLowPercentage?: InputMaybe<SortEnumType>;
  /** Total shares being offered as of the latest offering terms. */
  latestSizeInSecuritiesBase?: InputMaybe<SortEnumType>;
  /** Number of shares being issued by the company as of the latest offering terms. Issuance of primary shares will increase total shares outstanding. */
  latestSizeInSecuritiesBasePrimary?: InputMaybe<SortEnumType>;
  /** Number of shares being offered from existing shareholders as of the latest offering terms. */
  latestSizeInSecuritiesBaseSecondary?: InputMaybe<SortEnumType>;
  /** latestSizeInSecuritiesTotal = latestSizeInSecuritiesBasePrimary + latestSizeInSecuritiesBaseSecondary+ overAllotmentExercised. */
  latestSizeInSecuritiesTotal?: InputMaybe<SortEnumType>;
  /** latestSizeInSecuritiesTotalPrimary = latestSizeInSecuritiesBasePrimary + overAllotmentExercisedPrimary. */
  latestSizeInSecuritiesTotalPrimary?: InputMaybe<SortEnumType>;
  /** latestSizeInSecuritiesTotalSecondary = latestSizeInSecuritiesBaseSecondary + overAllotmentExercisedSecondary. */
  latestSizeInSecuritiesTotalSecondary?: InputMaybe<SortEnumType>;
  /** This date represents the day the offering went "live", aka began its marketing period. */
  launchDate?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for the first underwriting bookrunner listed on the prospectus. */
  leftLeadId?: InputMaybe<SortEnumType>;
  /** The first underwriting bookrunner listed on the prospectus (top left of the cover page). Typically indicative of the most senior underwriting role in the transaction. */
  leftLeadName?: InputMaybe<SortEnumType>;
  /** The date upon which an existing lock-up agreement is released implicitly as a result of a follow-on offering filing/launching within an existing lock-up period OR explicitly as a result of conditions being met that result in some/all of previously locked up shares being released. */
  lockUpEarlyReleaseDate?: InputMaybe<SortEnumType>;
  /** The date upon which the contractual agreement will expire. Parties subject to the lock-up are no longer restricted on the day following the Lock-Up Expiration. */
  lockUpExpirationDate?: InputMaybe<SortEnumType>;
  /** Number of days from pricing during which one or more parties are prevented from disposing or hedging positions due to a contractual obligation with the underwriters. */
  lockUpPeriod?: InputMaybe<SortEnumType>;
  /** Compensation received by the Underwriters for acting in a management role. */
  managementFeeUsd?: InputMaybe<SortEnumType>;
  /** Issuer's market capitalization at the time of pricing. Does not include the exercise of any over-allotments. Stated in local currency. */
  marketCapAtPricing?: InputMaybe<SortEnumType>;
  /** Issuer's market capitalization at the time of pricing. Does not include the exercise of any over-allotments. Stated in USD. */
  marketCapAtPricingUsd?: InputMaybe<SortEnumType>;
  /** Issuer's market capitalization before the offering. Stated in local currency. */
  marketCapPreOffering?: InputMaybe<SortEnumType>;
  /** Issuer's market capitalization before the offering. Stated in USD. */
  marketCapPreOfferingUsd?: InputMaybe<SortEnumType>;
  /** Time during the day the deal is launched, includes: PRE_OPEN, INTRA_DAY, POST_CLOSE. */
  marketTiming?: InputMaybe<MarketTimingSortInput>;
  /** Timestamp of the most recent change to the offering. */
  modifiedAt?: InputMaybe<SortEnumType>;
  naics?: InputMaybe<SortEnumType>;
  /** Equals (offerPrice - grossSpreadTotal). */
  netPrice?: InputMaybe<SortEnumType>;
  offering?: InputMaybe<OfferingSortInput>;
  /** Timestamp when the offering is created at. */
  offeringCreatedAt?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<SortEnumType>;
  /** Timestamp of the most recent definitional change to the offering, not including performance data updates. */
  offeringModifiedAt?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to the VWAP price on the first trade date. */
  offerToVwap1Day?: InputMaybe<SortEnumType>;
  /** Closing price 1 year post the first trade date. */
  oneYearSharePrice?: InputMaybe<SortEnumType>;
  /** Opening price on the first trade date. */
  openingSharePrice?: InputMaybe<SortEnumType>;
  /** Number of shares sold in addition to the total base shares in accordance with the total over-allotment authorized. */
  overAllotmentExercised?: InputMaybe<SortEnumType>;
  /** Number of primary shares sold in addition to the primary base shares in accordance with the primary shares over-allotment authorized. */
  overAllotmentExercisedPrimary?: InputMaybe<SortEnumType>;
  /** Offering size expressed as a notional amount (currency value) sold in addition to the total base offering size in accordance with the total over-allotment authorized. */
  overAllotmentExercisedProceeds?: InputMaybe<SortEnumType>;
  overAllotmentExercisedProceedsNotional?: InputMaybe<SortEnumType>;
  /** Number of secondary shares sold in addition to the secondary base shares in accordance with the secondary shares over-allotment authorized. */
  overAllotmentExercisedSecondary?: InputMaybe<SortEnumType>;
  /** Represents the total cost of the offering to the Issuer when stock price decline and gross spread are factored in as costs and measured as a % of stock price prior to filing the offering. Effectively the % change from the closing price prior to filing vs. the final per share proceeds to the Issuer. */
  pctAllInCost?: InputMaybe<SortEnumType>;
  /** A measure of the change in final offering proceeds from initial expected proceeds calculated as the % change from the initial offering Size ($M) in "Filed" status relative to final offering Size ($M) in "Priced" status and not taking into account any increase in size resulting from the exercise of the Over-Allotment. *Will only appear for IPO's. */
  pctChangeInSize?: InputMaybe<SortEnumType>;
  /** % change in share price from last trade prior to filing relative to offering price. */
  pctFileToOffer?: InputMaybe<SortEnumType>;
  /** Calculated value based on disclosed or implied commission payable to the manager(s) divided by the offering price. */
  pctGrossSpread?: InputMaybe<SortEnumType>;
  /** The closing price prior to pricing of the offering, stated in local currency. */
  pctLastTradeBeforeLaunch?: InputMaybe<SortEnumType>;
  /** % of total base offering shares relative to shares outstanding post-offering. */
  pctMarketCapAtPricing?: InputMaybe<SortEnumType>;
  /** % of total base offering shares relative to shares outstanding pre-offering. */
  pctMarketCapPreOffering?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to closing price 14 days post offering. */
  pctOfferTo14Day?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to closing price 180 days post offering. */
  pctOfferTo180Day?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to closing price on the First Trade Date. */
  pctOfferTo1Day?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to closing price 30 days post offering. */
  pctOfferTo30Day?: InputMaybe<SortEnumType>;
  pctOfferTo3Day?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to closing price 7 days post offering. */
  pctOfferTo7Day?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to closing price 90 days post offering. */
  pctOfferTo90Day?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to the most recent closing price. */
  pctOfferToCurrent?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to closing price 365 days post offering. */
  pctOfferToOneYear?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to opening trade on first trade date. */
  pctOfferToOpen?: InputMaybe<SortEnumType>;
  /** % change in share price from the offering price to the final trade in the preceding quarter. */
  pctOfferToPriorQuarter?: InputMaybe<SortEnumType>;
  /** The Re Offer High stated as % of the Last Trade Before Offer. Representative of the expected discount To Last Trade %. */
  pctReOfferHigh?: InputMaybe<SortEnumType>;
  /** The Re Offer Low stated as % of the Last Trade Before Offer. Representative of the expected discount To Last Trade %. */
  pctReOfferLow?: InputMaybe<SortEnumType>;
  /** % change in share price from the highest share price in the 52 weeks prior to pricing relative to offering price. */
  pctTo52WeekHigh?: InputMaybe<SortEnumType>;
  /** % change in share price from last trade prior to pricing relative to the offering price. */
  pctToLastTrade?: InputMaybe<SortEnumType>;
  /** % change in share price from the highest share price in the 52 weeks prior to pricing relative to offering price. */
  pctToVwap?: InputMaybe<SortEnumType>;
  /** % change from IPO offering price relative to the initial IPO price range. */
  pctVsMidpoint?: InputMaybe<SortEnumType>;
  /** Date when the offering is postponed. */
  postponedDate?: InputMaybe<SortEnumType>;
  /** Average Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  preOfferingAdtv?: InputMaybe<SortEnumType>;
  /** Total shares outstanding before the offering. */
  preOfferingShares?: InputMaybe<SortEnumType>;
  /** Final price of the offering, stated in local currency. */
  price?: InputMaybe<SortEnumType>;
  priceRangeHigh?: InputMaybe<SortEnumType>;
  priceRangeLow?: InputMaybe<SortEnumType>;
  /** Final price of the offering, stated in USD. */
  priceUsd?: InputMaybe<SortEnumType>;
  /** IPO offering price relative to stated initial IPO price range, includes: BELOW, WITHIN and ABOVE. */
  priceVsRange?: InputMaybe<PriceVsRangeSortInput>;
  /** Currency code of the pricing instrument of the offering. */
  pricingCurrency?: InputMaybe<CurrencySortInput>;
  /** Date when the offering is priced. */
  pricingDate?: InputMaybe<SortEnumType>;
  pricingDateNonNull?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for the primary shareholder. */
  primaryShareholderFirmId?: InputMaybe<SortEnumType>;
  /** Legal name of the shareholder with the most shares pre-offering. */
  primaryShareholderName?: InputMaybe<SortEnumType>;
  /** Closing price on the last day of the preceding quarter. */
  priorQuarterSharePrice?: InputMaybe<SortEnumType>;
  /** Public filing date of the initial prospectus. */
  publicFilingDate?: InputMaybe<SortEnumType>;
  /** The high end of the re-offer range, stated in local currency. */
  reOfferHigh?: InputMaybe<SortEnumType>;
  /** The high end of the re-offer range, stated in USD. */
  reOfferHighUsd?: InputMaybe<SortEnumType>;
  /** The low end of the re-offer range, stated in local currency. */
  reOfferLow?: InputMaybe<SortEnumType>;
  /** The low end of the re-offer range, stated in USD. */
  reOfferLowUsd?: InputMaybe<SortEnumType>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the revised offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in local currency. */
  revisedGrossProceedsBase?: InputMaybe<SortEnumType>;
  /** Total capital raised, excluding the exercise of any over-allotments, as of the revised offering terms. Equals (sizeInSecuritiesBase * offerPrice), stated in USD. */
  revisedGrossProceedsBaseUsd?: InputMaybe<SortEnumType>;
  /** High end of IPO price range as of the revised offering terms, stated in local currency. */
  revisedIpoRangeHigh?: InputMaybe<SortEnumType>;
  /** High end of IPO price range as of the revised offering terms, stated in USD. */
  revisedIpoRangeHighUsd?: InputMaybe<SortEnumType>;
  /** Low end of IPO price range as of the revised offering terms, stated in local currency. */
  revisedIpoRangeLow?: InputMaybe<SortEnumType>;
  /** Low end of IPO price range as of the revised offering terms, stated in USD. */
  revisedIpoRangeLowUsd?: InputMaybe<SortEnumType>;
  /** Share Class of the security, designated by letter or by name. Different classes of security often carries different privileges, such as voting rights. */
  securityDescription?: InputMaybe<ShareClassSortInput>;
  /** Type of security being offered, includes: COMMON_ORDINARY, GDR_ADR_ADS, UNIT, COMMON_WARRANTS, CONVERTIBLE_NOTE, PREFERRED_SHARE */
  securityType?: InputMaybe<SecurityTypeSortInput>;
  /** Compensation per share received by the Underwriters for selling the offering. */
  sellingConcessionUsd?: InputMaybe<SortEnumType>;
  /** The manager that is responsible for syndicate settlement. */
  settlementAgentName?: InputMaybe<SortEnumType>;
  /** Transaction settlement date as disclosed on final prospectus (typically T+2). */
  settlementDate?: InputMaybe<SortEnumType>;
  /** Total base offering shares (excluding any over-allotments) as a multiple of the Average Daily Trading Volume in the four weeks preceding the initial public filing of the offering. */
  sizeAsMultipleOfAdtv?: InputMaybe<SortEnumType>;
  sizeUsd?: InputMaybe<SortEnumType>;
  /** Split Adjusted Offer Price. */
  splitAdjustedOfferPrice?: InputMaybe<SortEnumType>;
  /** The manager that is responsible for executing stabilization trades. */
  stabilizationAgentName?: InputMaybe<SortEnumType>;
  /** Offering status, includes: FILED, LIVE, PRICED, POSTPONED, WITHDRAWN, TERMINATED, DRAFT. */
  status?: InputMaybe<OfferingStatusSortInput>;
  /** When this attribute is populated, it means this offering has replaced another offering. This attribute holds the offering ID of the other offering that has been replaced. */
  supersedes?: InputMaybe<SortEnumType>;
  /** Exchange trading symbol for the pricing instrument. */
  symbol?: InputMaybe<SortEnumType>;
  /** Date when the offering is terminated. */
  terminatedDate?: InputMaybe<SortEnumType>;
  /** Time zone used for timestamps in the offering. */
  timeZone?: InputMaybe<SortEnumType>;
  /** Total # of managers acting in a bookrunner role on the offering. */
  totalBookrunners?: InputMaybe<SortEnumType>;
  /** Total # of managers acting in a co-manager role on the offering. */
  totalCoManagers?: InputMaybe<SortEnumType>;
  /** Total # of managers underwriting the offering. */
  totalManagers?: InputMaybe<SortEnumType>;
  /** Total # of managers acting in non-bookrunner roles on the offering. */
  totalNonBookrunners?: InputMaybe<SortEnumType>;
  /** % of of shares owned by all shareholders tracked by CMG post-offering relative to total shares outstanding (totalPostOfferingOwnership/postOfferingShares ) */
  totalPctPostOfferingOwnership?: InputMaybe<SortEnumType>;
  /** % of of shares owned by all shareholders tracked by CMG pre-offering relative to total shares outstanding (totalPreOfferingOwnership/preOfferingShares ) */
  totalPctPreOfferingOwnership?: InputMaybe<SortEnumType>;
  /** Economic % of the total sum of shares attributable to managers in an active bookrunner or global coordinator role. */
  totalPctToActiveManagers?: InputMaybe<SortEnumType>;
  /** Economic % of the total sum of shares attributable to managers in a bookrunner role. */
  totalPctToBookrunners?: InputMaybe<SortEnumType>;
  /** Economic % of the total sum of shares attributable to managers in a co-manager role. */
  totalPctToCoManagers?: InputMaybe<SortEnumType>;
  /** Economic % of shares attributable to the left lead bank. */
  totalPctToLeftLead?: InputMaybe<SortEnumType>;
  /** Economic % of the total sum of shares attributable to managers in non bookrunner roles. */
  totalPctToNonBookrunners?: InputMaybe<SortEnumType>;
  /** Number of shares owned by all shareholders tracked by CMG post-offering (totalPreOfferingOwnership - total shares sold by the shareholders in the offering) */
  totalPostOfferingOwnership?: InputMaybe<SortEnumType>;
  /** Number of shares owned by all shareholders tracked by CMG pre-offering */
  totalPreOfferingOwnership?: InputMaybe<SortEnumType>;
  /** This is the sum of all the manager's total underwritten shares / securities in the offering, excluding over allotment. */
  totalUnderwritingSecurities?: InputMaybe<SortEnumType>;
  /** Legally binding date that drives trade booking. It’s the date when the banks execute the trades with the institutional investors. Depending on the time of pricing, banks will declare trade date same day or next day of pricing date. */
  tradeDate?: InputMaybe<SortEnumType>;
  /** Offering type, includes: IPO, FOLLOW_ON, MARKETED_FO, OVERNIGHT_FO, REGISTERED_BLOCK, UNREGISTERED_BLOCK, ATM, CONVERTIBLE, DIRECT_LISTING, REGISTERED_DIRECT, RIGHTS. */
  type?: InputMaybe<OfferingTypeSortInput>;
  /** Compensation received by the Underwriters for underwriting services. */
  underwritingFeeUsd?: InputMaybe<SortEnumType>;
  /** Additional information on the use of proceeds included in the prospectus. */
  useOfProceedsNote?: InputMaybe<SortEnumType>;
  /** Volume Weighted Average Price on the day preceding first trade date. */
  vwapPrice?: InputMaybe<SortEnumType>;
  /** Date when the offering is withdrawn. */
  withdrawnDate?: InputMaybe<SortEnumType>;
};

export type OfferingCharts = {
  __typename?: 'OfferingCharts';
  aftermarketPerformanceHeatMapBySector?: Maybe<HeatMapOfPerformanceLabelAggregation>;
  aftermarketPerformanceHeatMapByType?: Maybe<HeatMapOfPerformanceLabelAggregation>;
  aggregateAfterMarketRelativeToPricing?: Maybe<AfterMarketRelativeToPricingProjection>;
  averageEconomicsByRole: Array<PerformanceLabelAggregationForManagerRole>;
  averageEconomicsBySector: Array<CollectionLabelAggregationOfExtendedAggregation>;
  averageFeeByRoleAndSector: Array<PerformanceLabelAggregationForSectorAndManager>;
  capitalRaisedMarketCapTrends: Array<CollectionLabelAggregationOfCapitalRaisedMarketCapTrends>;
  capitalRaisedUsd?: Maybe<Scalars['Decimal']>;
  daysFromConfidentialFilingToPublicFiling: Array<CollectionLabelAggregationOfExtendedAggregation>;
  feesBySector: Array<CollectionLabelAggregationOfAmountAggregation>;
  feesByType: Array<CollectionLabelAggregationOfAmountAggregation>;
  firstDayTurnoverBySector: Array<LabelAggregationOfStringAndNullableOfDecimal>;
  followOn?: Maybe<FollowOnAggregation>;
  ipo?: Maybe<IpoAggregation>;
  offeringByGrossProceedsRange: Array<CollectionLabelAggregationOfOfferingsByGrossProceedsRange>;
  offeringVolume: Array<CollectionLabelAggregationOfCountAggregationByOfferingType>;
  offeringVolumeBySector: Array<CollectionLabelAggregationOfOfferingAggregation>;
  offeringVolumeByType: Array<CollectionLabelAggregationOfOfferingAggregation>;
  pctOfOfferingsTradingAboveOfferBySector: Array<LabelAggregationOfStringAndNullableOfDecimal>;
  pctVsMidpoint: Array<CollectionLabelAggregationOfExtendedAggregation>;
  performanceBySector: Array<CollectionLabelAggregationOfExtendedAggregation>;
  priceVsRange: Array<CollectionLabelAggregationOfRangeCategorySummary>;
  turnOverHeatMapBySector?: Maybe<HeatMapOfPerformanceLabelAggregation>;
};

/** A segment of a collection. */
export type OfferingCollectionSegment = {
  __typename?: 'OfferingCollectionSegment';
  charts: OfferingCharts;
  /** A flattened list of the items. */
  items?: Maybe<Array<Offering>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  summaries?: Maybe<OfferingListSummary>;
  totalCount: Scalars['Int'];
};

export enum OfferingDateType {
  Launch = 'LAUNCH',
  Pricing = 'PRICING',
  PublicFiling = 'PUBLIC_FILING',
}

export type OfferingDateTypeFilterInput = {
  eq?: InputMaybe<OfferingDateType>;
  in?: InputMaybe<Array<InputMaybe<OfferingDateType>>>;
  neq?: InputMaybe<OfferingDateType>;
  nin?: InputMaybe<Array<InputMaybe<OfferingDateType>>>;
};

/** A segment of a collection. */
export type OfferingDateTypesCollectionSegment = {
  __typename?: 'OfferingDateTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfOfferingDateType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type OfferingDateTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type OfferingFilterInput = {
  advisers?: InputMaybe<ListFilterInputTypeOfOfferingAdviserFilterInput>;
  /** Allocation data in the My DataLab IOI Tracker. */
  allocations?: InputMaybe<ListFilterInputTypeOfAllocationFilterInput>;
  and?: InputMaybe<Array<OfferingFilterInput>>;
  atmAttributes?: InputMaybe<AtmAttributesFilterInput>;
  attributes?: InputMaybe<OfferingAttributesFilterInput>;
  booksCloseAt?: InputMaybe<InstantOperationFilterInput>;
  convertibleAttributes?: InputMaybe<ConvertibleAttributesFilterInput>;
  cornerstoneInvestments?: InputMaybe<ListFilterInputTypeOfCornerstoneInvestmentFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  exchange?: InputMaybe<StringOperationFilterInput>;
  exchangeCountry?: InputMaybe<CountryFilterInput>;
  exchangeMic?: InputMaybe<OfferingSetupExchangeMicFilterInput>;
  exchangeRegion?: InputMaybe<RegionFilterInput>;
  finalTerm?: InputMaybe<TermFilterInput>;
  finalTermId?: InputMaybe<UuidOperationFilterInput>;
  firmMetric?: InputMaybe<FirmMetricFilterInput>;
  firstTradeDate?: InputMaybe<DateOperationFilterInput>;
  /** Fund-level allocation data in the My DataLab IOI Tracker. */
  fundAllocations?: InputMaybe<ListFilterInputTypeOfFundAllocationFilterInput>;
  /** Fund-level indication data in the My DataLab IOI Tracker. */
  fundIndicationsOfInterest?: InputMaybe<ListFilterInputTypeOfFundIndicationOfInterestFilterInput>;
  /** Underwriters' gross compensation in local currency, per share. */
  grossSpreadTotal?: InputMaybe<DecimalOperationFilterInput>;
  hasForwardAgreement?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Indication data in the My DataLab IOI Tracker. */
  indicationsOfInterest?: InputMaybe<ListFilterInputTypeOfIndicationOfInterestFilterInput>;
  initialTerm?: InputMaybe<TermFilterInput>;
  initialTermId?: InputMaybe<UuidOperationFilterInput>;
  ioiNotes?: InputMaybe<ListFilterInputTypeOfIoiNoteFilterInput>;
  /** Boolean indicating whether the offering is managed on the CMG XC platform. */
  isExecutingOnPlatform?: InputMaybe<BooleanOperationFilterInput>;
  isRule144A?: InputMaybe<BooleanOperationFilterInput>;
  issuer?: InputMaybe<IssuerFilterInput>;
  issuerId?: InputMaybe<UuidOperationFilterInput>;
  lastAllocation?: InputMaybe<AllocationFilterInput>;
  lastTradeBeforeFiling?: InputMaybe<DecimalOperationFilterInput>;
  lastTradeBeforeLaunch?: InputMaybe<DecimalOperationFilterInput>;
  lastTradeBeforeOffer?: InputMaybe<DecimalOperationFilterInput>;
  latestRevisedTerm?: InputMaybe<TermFilterInput>;
  latestRevisedTermId?: InputMaybe<UuidOperationFilterInput>;
  launchDate?: InputMaybe<DateOperationFilterInput>;
  lockUps?: InputMaybe<ListFilterInputTypeOfLockUpFilterInput>;
  managers?: InputMaybe<ListFilterInputTypeOfOfferingManagerFilterInput>;
  marketTiming?: InputMaybe<MarketTimingFilterInput>;
  maturityDate?: InputMaybe<DateOperationFilterInput>;
  notice?: InputMaybe<NoticeFilterInput>;
  offeringNotes?: InputMaybe<ListFilterInputTypeOfOfferingNoteFilterInput>;
  or?: InputMaybe<Array<OfferingFilterInput>>;
  pctOfDeal?: InputMaybe<DecimalOperationFilterInput>;
  postponedDate?: InputMaybe<DateOperationFilterInput>;
  price?: InputMaybe<DecimalOperationFilterInput>;
  /** Currency code of the pricing instrument of the offering. */
  pricingCurrency?: InputMaybe<CurrencyFilterInput>;
  pricingDate?: InputMaybe<DateOperationFilterInput>;
  publicFilingDate?: InputMaybe<DateOperationFilterInput>;
  relevantTermId?: InputMaybe<UuidOperationFilterInput>;
  securityType?: InputMaybe<SecurityTypeFilterInput>;
  settlementDate?: InputMaybe<DateOperationFilterInput>;
  shareholders?: InputMaybe<ListFilterInputTypeOfOfferingShareholderFilterInput>;
  status?: InputMaybe<OfferingStatusFilterInput>;
  statusRelevantDate?: InputMaybe<DateOperationFilterInput>;
  /** Exchange trading symbol / ticker for the offering. */
  symbol?: InputMaybe<StringOperationFilterInput>;
  terminatedDate?: InputMaybe<DateOperationFilterInput>;
  terms?: InputMaybe<ListFilterInputTypeOfTermFilterInput>;
  type?: InputMaybe<OfferingTypeFilterInput>;
  useOfProceeds?: InputMaybe<UseOfProceedsListFilterInput>;
  userOfferings?: InputMaybe<ListFilterInputTypeOfUserOfferingFilterInput>;
  withdrawnDate?: InputMaybe<DateOperationFilterInput>;
};

export type OfferingListSummary = {
  __typename?: 'OfferingListSummary';
  /** Gets aggregations (min, max, and mean) for a select set of fields. */
  aggregations?: Maybe<OfferingAggregations>;
};

export type OfferingManager = {
  __typename?: 'OfferingManager';
  /** Total costs and fees associated with the offering and payable to the Underwriter. (pctUnderwritingBaseShares * grossSpreadTotal*latestSizeInSecuritiesTotal). */
  estimatedFee?: Maybe<Scalars['Decimal']>;
  /** Boolean indicating whether the manager is an Active Bookrunner on the offering. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the manager is the Left Lead on the offering. */
  isLeftLead?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the manager is the Settlement Agent on the offering. */
  isSettlementAgent?: Maybe<Scalars['Boolean']>;
  /** Boolean indicating whether the manager is the Stabilization Agent on the offering. */
  isStabilizationAgent?: Maybe<Scalars['Boolean']>;
  manager?: Maybe<Manager>;
  /** Unique CMG identifier for a specific offering manager. */
  managerId?: Maybe<Scalars['UUID']>;
  /** Name of the manager. */
  name?: Maybe<Scalars['String']>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** A manager's percentage allocation of total underwritten shares in the offering excluding over allotment. */
  pctUnderwritingBaseShares?: Maybe<Scalars['Decimal']>;
  /** Role of the manager on the offering, includes: ACTIVE_BOOKRUNNER, BOOKRUNNER, CO_LEAD, CO_MANAGER, UNDERWRITER, GLOBAL_COORDINATOR, SELLING_GROUP_MEMBER. */
  role?: Maybe<OfferingManagerRole>;
  /** Display name for Role */
  roleDisplayName?: Maybe<Scalars['String']>;
  /** The order of managers in the syndicate line up as shown in the prospectus. */
  sequence?: Maybe<Scalars['Int']>;
  /** A manager's total underwritten shares in the offering excluding over allotment. */
  underwritingBaseShares?: Maybe<Scalars['Decimal']>;
};

/** A segment of a collection. */
export type OfferingManagerCollectionSegment = {
  __typename?: 'OfferingManagerCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<OfferingManager>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type OfferingManagerFilterInput = {
  and?: InputMaybe<Array<OfferingManagerFilterInput>>;
  /** Boolean indicating whether the manager is the Left Lead on the offering. */
  isLeftLead?: InputMaybe<BooleanOperationFilterInput>;
  manager?: InputMaybe<ManagerFilterInput>;
  /** Unique CMG identifier for a specific offering manager. */
  managerId?: InputMaybe<UuidOperationFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<OfferingManagerFilterInput>>;
  /** Role of the manager on the offering, includes: ACTIVE_BOOKRUNNER, BOOKRUNNER, CO_LEAD, CO_MANAGER, UNDERWRITER, GLOBAL_COORDINATOR, SELLING_GROUP_MEMBER. */
  role?: InputMaybe<OfferingManagerRoleFilterInput>;
  /** The order of managers in the syndicate line up as shown in the prospectus. */
  sequence?: InputMaybe<IntOperationFilterInput>;
  /** A manager's total underwritten shares in the offering excluding over allotment. */
  underwritingBaseShares?: InputMaybe<DecimalOperationFilterInput>;
};

export enum OfferingManagerRole {
  ActiveBookrunner = 'ACTIVE_BOOKRUNNER',
  Bookrunner = 'BOOKRUNNER',
  CoLead = 'CO_LEAD',
  CoManager = 'CO_MANAGER',
  GlobalCoordinator = 'GLOBAL_COORDINATOR',
  SellingGroupMember = 'SELLING_GROUP_MEMBER',
  Underwriter = 'UNDERWRITER',
}

export type OfferingManagerRoleFilterInput = {
  eq?: InputMaybe<OfferingManagerRole>;
  in?: InputMaybe<Array<InputMaybe<OfferingManagerRole>>>;
  neq?: InputMaybe<OfferingManagerRole>;
  nin?: InputMaybe<Array<InputMaybe<OfferingManagerRole>>>;
};

/** A segment of a collection. */
export type OfferingManagerRolesCollectionSegment = {
  __typename?: 'OfferingManagerRolesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfOfferingManagerRole>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type OfferingManagerRoleSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type OfferingManagerSortInput = {
  /** Boolean indicating whether the manager is the Left Lead on the offering. */
  isLeftLead?: InputMaybe<SortEnumType>;
  manager?: InputMaybe<ManagerSortInput>;
  /** Unique CMG identifier for a specific offering manager. */
  managerId?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<SortEnumType>;
  /** Role of the manager on the offering, includes: ACTIVE_BOOKRUNNER, BOOKRUNNER, CO_LEAD, CO_MANAGER, UNDERWRITER, GLOBAL_COORDINATOR, SELLING_GROUP_MEMBER. */
  role?: InputMaybe<OfferingManagerRoleSortInput>;
  /** The order of managers in the syndicate line up as shown in the prospectus. */
  sequence?: InputMaybe<SortEnumType>;
  /** A manager's total underwritten shares in the offering excluding over allotment. */
  underwritingBaseShares?: InputMaybe<SortEnumType>;
};

export type OfferingNote = {
  __typename?: 'OfferingNote';
  note?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
};

export type OfferingNoteFilterInput = {
  and?: InputMaybe<Array<OfferingNoteFilterInput>>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<OfferingNoteFilterInput>>;
  userId?: InputMaybe<UuidOperationFilterInput>;
};

export type OfferingsByGrossProceedsRange = {
  __typename?: 'OfferingsByGrossProceedsRange';
  allInCost: ExtendedAggregation;
  grossSpread: ExtendedAggregation;
  pctMarketCapAtPricing: ExtendedAggregation;
  pctMarketCapPreOffering: ExtendedAggregation;
  pctWithSecondaryOffered: ExtendedAggregation;
  totalBookrunners: ExtendedAggregation;
  totalManagers: ExtendedAggregation;
  totalNonBookManagers: ExtendedAggregation;
  totalPctToBookrunners: ExtendedAggregation;
  totalPctToLeftLead: ExtendedAggregation;
  totalPctToNonBookrunners: ExtendedAggregation;
};

export enum OfferingSetupExchangeMic {
  Aqse = 'AQSE',
  Bats = 'BATS',
  Bvmf = 'BVMF',
  Difx = 'DIFX',
  Dsmd = 'DSMD',
  Hstc = 'HSTC',
  Misx = 'MISX',
  Neoe = 'NEOE',
  Notc = 'NOTC',
  Xads = 'XADS',
  Xamm = 'XAMM',
  Xams = 'XAMS',
  Xasx = 'XASX',
  Xath = 'XATH',
  Xbah = 'XBAH',
  Xbkk = 'XBKK',
  Xbog = 'XBOG',
  Xbom = 'XBOM',
  Xbru = 'XBRU',
  Xbrv = 'XBRV',
  Xbse = 'XBSE',
  Xbud = 'XBUD',
  Xbue = 'XBUE',
  Xcai = 'XCAI',
  Xcas = 'XCAS',
  Xcnq = 'XCNQ',
  Xcol = 'XCOL',
  Xcse = 'XCSE',
  Xcys = 'XCYS',
  Xdfm = 'XDFM',
  Xdha = 'XDHA',
  Xdse = 'XDSE',
  Xdub = 'XDUB',
  Xfra = 'XFRA',
  Xhel = 'XHEL',
  Xhkg = 'XHKG',
  Xice = 'XICE',
  Xidx = 'XIDX',
  Xist = 'XIST',
  Xjse = 'XJSE',
  Xkar = 'XKAR',
  Xkls = 'XKLS',
  Xkrx = 'XKRX',
  Xkuw = 'XKUW',
  Xlim = 'XLIM',
  Xlis = 'XLIS',
  Xlju = 'XLJU',
  Xlon = 'XLON',
  Xlux = 'XLUX',
  Xmad = 'XMAD',
  Xmex = 'XMEX',
  Xmil = 'XMIL',
  Xmus = 'XMUS',
  Xnai = 'XNAI',
  Xnas = 'XNAS',
  Xnec = 'XNEC',
  Xngm = 'XNGM',
  Xnsa = 'XNSA',
  Xnse = 'XNSE',
  Xnys = 'XNYS',
  Xnze = 'XNZE',
  Xosl = 'XOSL',
  Xpar = 'XPAR',
  Xphs = 'XPHS',
  Xpra = 'XPRA',
  Xsau = 'XSAU',
  Xses = 'XSES',
  Xsgo = 'XSGO',
  Xshe = 'XSHE',
  Xshg = 'XSHG',
  Xstc = 'XSTC',
  Xsto = 'XSTO',
  Xswx = 'XSWX',
  Xtae = 'XTAE',
  Xtai = 'XTAI',
  Xtal = 'XTAL',
  Xtks = 'XTKS',
  Xtse = 'XTSE',
  Xtsx = 'XTSX',
  Xwar = 'XWAR',
  Xwbo = 'XWBO',
  Xzag = 'XZAG',
}

export type OfferingSetupExchangeMicFilterInput = {
  eq?: InputMaybe<OfferingSetupExchangeMic>;
  in?: InputMaybe<Array<InputMaybe<OfferingSetupExchangeMic>>>;
  neq?: InputMaybe<OfferingSetupExchangeMic>;
  nin?: InputMaybe<Array<InputMaybe<OfferingSetupExchangeMic>>>;
};

/** A segment of a collection. */
export type OfferingSetupExchangeMicsCollectionSegment = {
  __typename?: 'OfferingSetupExchangeMicsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfOfferingSetupExchangeMic>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type OfferingSetupExchangeMicSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type OfferingShareholder = {
  __typename?: 'OfferingShareholder';
  /** Boolean indicating whether the shareholder is the parent of the issuer of a carve-out offering. */
  isCarveOutParent?: Maybe<Scalars['Boolean']>;
  /** Name of the shareholder. */
  name?: Maybe<Scalars['String']>;
  offering?: Maybe<Offering>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** % of shares owned by a shareholder post-offering relative to total shares outstanding (postOfferingOwnership/postOfferingShares). */
  pctPostOfferingOwnership?: Maybe<Scalars['Decimal']>;
  /** % of shares owned by a shareholder post-offering if the over-allotment is exercised in full relative to total shares outstanding (postOfferingOwnershipIncludingShoe/postOfferingShares). */
  pctPostOfferingOwnershipIncludingShoe?: Maybe<Scalars['Decimal']>;
  /** % of shares owned by a shareholder pre-offering relative to total shares outstanding (preOfferingOwnership/preOfferingShares). */
  pctPreOfferingOwnership?: Maybe<Scalars['Decimal']>;
  /** Number of shares owned by a shareholder post-offering (preOfferingOwnership - shares sold in the offering). */
  postOfferingOwnership?: Maybe<Scalars['Long']>;
  /** Number of shares owned by a shareholder post-offering if the over-allotment is exercised in full. */
  postOfferingOwnershipIncludingShoe?: Maybe<Scalars['Long']>;
  /** Number of shares owned by a shareholder pre-offering. */
  preOfferingOwnership?: Maybe<Scalars['Long']>;
  shareholder?: Maybe<Shareholder>;
  /** Unique CMG identifier for the shareholder. */
  shareholderId?: Maybe<Scalars['UUID']>;
  /** The type of the shareholder: CORPORATE, INSIDER, NON_INSIDER, PRIVATE_EQUITY, VENTURE_CAPITAL, OTHER_INSTITUIONAL. */
  shareholderType?: Maybe<ShareholderType>;
  /** Display name for ShareholderType */
  shareholderTypeDisplayName?: Maybe<Scalars['String']>;
};

export type OfferingShareholderFilterInput = {
  and?: InputMaybe<Array<OfferingShareholderFilterInput>>;
  /** Boolean indicating whether the shareholder is the parent of the issuer of a carve-out offering. */
  isCarveOutParent?: InputMaybe<BooleanOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<OfferingShareholderFilterInput>>;
  /** Number of shares owned by a shareholder post-offering (preOfferingOwnership - shares sold in the offering). */
  postOfferingOwnership?: InputMaybe<LongOperationFilterInput>;
  /** Number of shares owned by a shareholder post-offering if the over-allotment is exercised in full. */
  postOfferingOwnershipIncludingShoe?: InputMaybe<LongOperationFilterInput>;
  /** Number of shares owned by a shareholder pre-offering. */
  preOfferingOwnership?: InputMaybe<LongOperationFilterInput>;
  shareholder?: InputMaybe<ShareholderFilterInput>;
  /** Unique CMG identifier for the shareholder. */
  shareholderId?: InputMaybe<UuidOperationFilterInput>;
};

export type OfferingSortInput = {
  atmAttributes?: InputMaybe<AtmAttributesSortInput>;
  attributes?: InputMaybe<OfferingAttributesSortInput>;
  booksCloseAt?: InputMaybe<SortEnumType>;
  convertibleAttributes?: InputMaybe<ConvertibleAttributesSortInput>;
  exchange?: InputMaybe<SortEnumType>;
  exchangeCountry?: InputMaybe<CountrySortInput>;
  exchangeMic?: InputMaybe<OfferingSetupExchangeMicSortInput>;
  exchangeRegion?: InputMaybe<RegionSortInput>;
  finalTerm?: InputMaybe<TermSortInput>;
  finalTermId?: InputMaybe<SortEnumType>;
  firmMetric?: InputMaybe<FirmMetricSortInput>;
  firstTradeDate?: InputMaybe<SortEnumType>;
  /** Underwriters' gross compensation in local currency, per share. */
  grossSpreadTotal?: InputMaybe<SortEnumType>;
  hasForwardAgreement?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  initialTerm?: InputMaybe<TermSortInput>;
  initialTermId?: InputMaybe<SortEnumType>;
  /** Boolean indicating whether the offering is managed on the CMG XC platform. */
  isExecutingOnPlatform?: InputMaybe<SortEnumType>;
  isRule144A?: InputMaybe<SortEnumType>;
  issuer?: InputMaybe<IssuerSortInput>;
  issuerId?: InputMaybe<SortEnumType>;
  lastAllocation?: InputMaybe<AllocationSortInput>;
  lastTradeBeforeFiling?: InputMaybe<SortEnumType>;
  lastTradeBeforeLaunch?: InputMaybe<SortEnumType>;
  lastTradeBeforeOffer?: InputMaybe<SortEnumType>;
  latestRevisedTerm?: InputMaybe<TermSortInput>;
  latestRevisedTermId?: InputMaybe<SortEnumType>;
  launchDate?: InputMaybe<SortEnumType>;
  marketTiming?: InputMaybe<MarketTimingSortInput>;
  maturityDate?: InputMaybe<SortEnumType>;
  notice?: InputMaybe<NoticeSortInput>;
  pctOfDeal?: InputMaybe<SortEnumType>;
  postponedDate?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  /** Currency code of the pricing instrument of the offering. */
  pricingCurrency?: InputMaybe<CurrencySortInput>;
  pricingDate?: InputMaybe<SortEnumType>;
  pricingDateNonNull?: InputMaybe<SortEnumType>;
  publicFilingDate?: InputMaybe<SortEnumType>;
  relevantTermId?: InputMaybe<SortEnumType>;
  securityType?: InputMaybe<SecurityTypeSortInput>;
  settlementDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<OfferingStatusSortInput>;
  statusRelevantDate?: InputMaybe<SortEnumType>;
  supersedes?: InputMaybe<OfferingSupersedesSortInput>;
  /** Exchange trading symbol / ticker for the offering. */
  symbol?: InputMaybe<SortEnumType>;
  terminatedDate?: InputMaybe<SortEnumType>;
  type?: InputMaybe<OfferingTypeSortInput>;
  withdrawnDate?: InputMaybe<SortEnumType>;
};

export enum OfferingStatus {
  Draft = 'DRAFT',
  Filed = 'FILED',
  Live = 'LIVE',
  Postponed = 'POSTPONED',
  Priced = 'PRICED',
  Terminated = 'TERMINATED',
  Withdrawn = 'WITHDRAWN',
}

/** A segment of a collection. */
export type OfferingStatusesCollectionSegment = {
  __typename?: 'OfferingStatusesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfOfferingStatus>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type OfferingStatusFilterInput = {
  eq?: InputMaybe<OfferingStatus>;
  in?: InputMaybe<Array<InputMaybe<OfferingStatus>>>;
  neq?: InputMaybe<OfferingStatus>;
  nin?: InputMaybe<Array<InputMaybe<OfferingStatus>>>;
};

export type OfferingStatusSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type OfferingSupersedesSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isSupersededBy?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  offering?: InputMaybe<OfferingSortInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<SortEnumType>;
  supersedes?: InputMaybe<SortEnumType>;
};

export enum OfferingType {
  Atm = 'ATM',
  Convertible = 'CONVERTIBLE',
  DirectListing = 'DIRECT_LISTING',
  FollowOn = 'FOLLOW_ON',
  Ipo = 'IPO',
  MarketedFo = 'MARKETED_FO',
  OvernightFo = 'OVERNIGHT_FO',
  RegisteredBlock = 'REGISTERED_BLOCK',
  RegisteredDirect = 'REGISTERED_DIRECT',
  Rights = 'RIGHTS',
  UnregisteredBlock = 'UNREGISTERED_BLOCK',
}

export type OfferingTypeFilterInput = {
  eq?: InputMaybe<OfferingType>;
  in?: InputMaybe<Array<InputMaybe<OfferingType>>>;
  neq?: InputMaybe<OfferingType>;
  nin?: InputMaybe<Array<InputMaybe<OfferingType>>>;
};

/** A segment of a collection. */
export type OfferingTypesCollectionSegment = {
  __typename?: 'OfferingTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfOfferingType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type OfferingTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type PerformanceLabelAggregation = {
  __typename?: 'PerformanceLabelAggregation';
  count?: Maybe<Scalars['Int']>;
  day10Turnover?: Maybe<ExtendedAggregation>;
  day2Turnover?: Maybe<ExtendedAggregation>;
  day3Turnover?: Maybe<ExtendedAggregation>;
  day4Turnover?: Maybe<ExtendedAggregation>;
  day5Turnover?: Maybe<ExtendedAggregation>;
  day6Turnover?: Maybe<ExtendedAggregation>;
  day7Turnover?: Maybe<ExtendedAggregation>;
  day8Turnover?: Maybe<ExtendedAggregation>;
  day9Turnover?: Maybe<ExtendedAggregation>;
  economics?: Maybe<ExtendedAggregation>;
  firstDayTurnover?: Maybe<ExtendedAggregation>;
  label: Scalars['String'];
  offeringFee?: Maybe<AmountAggregation>;
  pctOfferTo14Day?: Maybe<ExtendedAggregation>;
  pctOfferTo180Day?: Maybe<ExtendedAggregation>;
  pctOfferTo1Day?: Maybe<ExtendedAggregation>;
  pctOfferTo30Day?: Maybe<ExtendedAggregation>;
  pctOfferTo3Day?: Maybe<ExtendedAggregation>;
  pctOfferTo7Day?: Maybe<ExtendedAggregation>;
  pctOfferTo90Day?: Maybe<ExtendedAggregation>;
  pctOfferToCurrent?: Maybe<ExtendedAggregation>;
  pctOfferToCurrentAboveOffer?: Maybe<ExtendedAggregation>;
  pctOfferToOpen?: Maybe<ExtendedAggregation>;
};

export type PerformanceLabelAggregationForManagerRole = {
  __typename?: 'PerformanceLabelAggregationForManagerRole';
  economicsRole?: Maybe<ExtendedAggregation>;
  label: Scalars['String'];
};

export type PerformanceLabelAggregationForSectorAndManager = {
  __typename?: 'PerformanceLabelAggregationForSectorAndManager';
  activeBookrunnerFee?: Maybe<ExtendedAggregation>;
  bookrunnerFee?: Maybe<ExtendedAggregation>;
  coLeadFee?: Maybe<ExtendedAggregation>;
  colorCode?: Maybe<Scalars['Int']>;
  coManagerFee?: Maybe<ExtendedAggregation>;
  label: Scalars['String'];
};

export enum PriceVsRange {
  Above = 'ABOVE',
  Below = 'BELOW',
  Unspecified = 'UNSPECIFIED',
  Within = 'WITHIN',
}

export type PriceVsRangeFilterInput = {
  eq?: InputMaybe<PriceVsRange>;
  in?: InputMaybe<Array<InputMaybe<PriceVsRange>>>;
  neq?: InputMaybe<PriceVsRange>;
  nin?: InputMaybe<Array<InputMaybe<PriceVsRange>>>;
};

/** A segment of a collection. */
export type PriceVsRangesCollectionSegment = {
  __typename?: 'PriceVsRangesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfPriceVsRange>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type PriceVsRangeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum PricingType {
  Limit = 'LIMIT',
  Market = 'MARKET',
}

export type PricingTypeFilterInput = {
  eq?: InputMaybe<PricingType>;
  in?: InputMaybe<Array<InputMaybe<PricingType>>>;
  neq?: InputMaybe<PricingType>;
  nin?: InputMaybe<Array<InputMaybe<PricingType>>>;
};

export type PricingTypeSortInput = {
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type Query = {
  __typename?: 'Query';
  /** Adviser Role types */
  adviserRoles?: Maybe<AdviserRolesCollectionSegment>;
  advisers?: Maybe<AdviserCollectionSegment>;
  /** Adviser Type types */
  adviserTypes?: Maybe<AdviserTypesCollectionSegment>;
  allocationById?: Maybe<Allocation>;
  /** Allocation Ranking types */
  allocationRankings?: Maybe<AllocationRankingsCollectionSegment>;
  allocations?: Maybe<AllocationCollectionSegment>;
  /** Atm Structure Type types */
  atmStructureTypes?: Maybe<AtmStructureTypesCollectionSegment>;
  /** Cornerstone Investment Type types */
  cornerstoneInvestmentTypes?: Maybe<CornerstoneInvestmentTypesCollectionSegment>;
  /** Country types */
  countries?: Maybe<CountriesCollectionSegment>;
  /** Currency types */
  currencies?: Maybe<CurrenciesCollectionSegment>;
  customSectorById?: Maybe<CustomSector>;
  customSectorIssuerById?: Maybe<CustomSectorIssuer>;
  customSectorIssuers?: Maybe<CustomSectorIssuerCollectionSegment>;
  customSectors?: Maybe<CustomSectorCollectionSegment>;
  /** Custom Sector Type types */
  customSectorTypes?: Maybe<CustomSectorTypesCollectionSegment>;
  /** Document Type types */
  documentTypes?: Maybe<DocumentTypesCollectionSegment>;
  /** Egc Status types */
  egcStatuses?: Maybe<EgcStatusesCollectionSegment>;
  /** Entity Type types */
  entityTypes?: Maybe<EntityTypesCollectionSegment>;
  globalLeagueTable?: Maybe<GlobalLeagueTableRowCollectionSegment>;
  indicationOfInterestById?: Maybe<IndicationOfInterest>;
  indicationOfInterests?: Maybe<IndicationOfInterestCollectionSegment>;
  /** Ioi Type types */
  ioiTypes?: Maybe<IoiTypesCollectionSegment>;
  issuerById?: Maybe<Issuer>;
  issuers?: Maybe<IssuerCollectionSegment>;
  /** Lock up Benchmark Reference types */
  lockUpBenchmarkReferences?: Maybe<LockUpBenchmarkReferencesCollectionSegment>;
  /** Lock up Party Type types */
  lockUpPartyTypes?: Maybe<LockUpPartyTypesCollectionSegment>;
  /** Lock up Unit types */
  lockUpUnits?: Maybe<LockUpUnitsCollectionSegment>;
  managers?: Maybe<ManagerCollectionSegment>;
  /** Market Timing types */
  marketTimings?: Maybe<MarketTimingsCollectionSegment>;
  offeringAttributes?: Maybe<OfferingAttributesCollectionSegment>;
  offeringById?: Maybe<Offering>;
  /** Offering Date Type types */
  offeringDateTypes?: Maybe<OfferingDateTypesCollectionSegment>;
  /** Offering Manager Role types */
  offeringManagerRoles?: Maybe<OfferingManagerRolesCollectionSegment>;
  offeringManagers?: Maybe<OfferingManagerCollectionSegment>;
  offerings?: Maybe<OfferingCollectionSegment>;
  /** Offering Setup Exchange Mic types */
  offeringSetupExchangeMics?: Maybe<OfferingSetupExchangeMicsCollectionSegment>;
  /** Offering Status types */
  offeringStatuses?: Maybe<OfferingStatusesCollectionSegment>;
  /** Offering Type types */
  offeringTypes?: Maybe<OfferingTypesCollectionSegment>;
  /** Price Vs Range types */
  priceVsRanges?: Maybe<PriceVsRangesCollectionSegment>;
  /** Rank types */
  ranks?: Maybe<RanksCollectionSegment>;
  /** Sector types */
  sectors?: Maybe<SectorsCollectionSegment>;
  /** Security Type types */
  securityTypes?: Maybe<SecurityTypesCollectionSegment>;
  /** Share Class types */
  shareClasses?: Maybe<ShareClassesCollectionSegment>;
  shareholders?: Maybe<ShareholderCollectionSegment>;
  /** Shareholder Type types */
  shareholderTypes?: Maybe<ShareholderTypesCollectionSegment>;
  /** Sub Sector types */
  subSectors?: Maybe<SubSectorsCollectionSegment>;
  /** Term Type types */
  termTypes?: Maybe<TermTypesCollectionSegment>;
  /** Use of Proceed types */
  useOfProceeds?: Maybe<UseOfProceedsCollectionSegment>;
  /** Versus Talk types */
  versusTalks?: Maybe<VersusTalksCollectionSegment>;
};

export type QueryAdviserRolesArgs = {
  order?: InputMaybe<Array<EnumLabelOfAdviserRoleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfAdviserRoleFilterInput>;
};

export type QueryAdvisersArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  order?: InputMaybe<Array<AdviserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdviserFilterInput>;
};

export type QueryAdviserTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfAdviserTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfAdviserTypeFilterInput>;
};

export type QueryAllocationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryAllocationRankingsArgs = {
  order?: InputMaybe<Array<EnumLabelOfAllocationRankingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfAllocationRankingFilterInput>;
};

export type QueryAllocationsArgs = {
  order?: InputMaybe<Array<AllocationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AllocationFilterInput>;
};

export type QueryAtmStructureTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfAtmStructureTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfAtmStructureTypeFilterInput>;
};

export type QueryCornerstoneInvestmentTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfCornerstoneInvestmentTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfCornerstoneInvestmentTypeFilterInput>;
};

export type QueryCountriesArgs = {
  order?: InputMaybe<Array<EnumLabelOfCountrySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfCountryFilterInput>;
};

export type QueryCurrenciesArgs = {
  order?: InputMaybe<Array<EnumLabelOfCurrencySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfCurrencyFilterInput>;
};

export type QueryCustomSectorByIdArgs = {
  id: Scalars['ID'];
};

export type QueryCustomSectorIssuerByIdArgs = {
  id: Scalars['ID'];
};

export type QueryCustomSectorIssuersArgs = {
  order?: InputMaybe<Array<CustomSectorIssuerSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomSectorIssuerFilterInput>;
};

export type QueryCustomSectorsArgs = {
  order?: InputMaybe<Array<CustomSectorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomSectorFilterInput>;
};

export type QueryCustomSectorTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfCustomSectorTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfCustomSectorTypeFilterInput>;
};

export type QueryDocumentTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfDocumentTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfDocumentTypeFilterInput>;
};

export type QueryEgcStatusesArgs = {
  order?: InputMaybe<Array<EnumLabelOfEgcStatusSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfEgcStatusFilterInput>;
};

export type QueryEntityTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfEntityTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfEntityTypeFilterInput>;
};

export type QueryGlobalLeagueTableArgs = {
  order?: InputMaybe<Array<GlobalLeagueTableRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingFilterInput>;
};

export type QueryIndicationOfInterestByIdArgs = {
  id: Scalars['ID'];
};

export type QueryIndicationOfInterestsArgs = {
  order?: InputMaybe<Array<IndicationOfInterestSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IndicationOfInterestFilterInput>;
};

export type QueryIoiTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfIoiTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfIoiTypeFilterInput>;
};

export type QueryIssuerByIdArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  id: Scalars['ID'];
};

export type QueryIssuersArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  order?: InputMaybe<Array<IssuerSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IssuerFilterInput>;
};

export type QueryLockUpBenchmarkReferencesArgs = {
  order?: InputMaybe<Array<EnumLabelOfLockUpBenchmarkReferenceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfLockUpBenchmarkReferenceFilterInput>;
};

export type QueryLockUpPartyTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfLockUpPartyTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfLockUpPartyTypeFilterInput>;
};

export type QueryLockUpUnitsArgs = {
  order?: InputMaybe<Array<EnumLabelOfLockUpUnitSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfLockUpUnitFilterInput>;
};

export type QueryManagersArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  order?: InputMaybe<Array<ManagerSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManagerFilterInput>;
};

export type QueryMarketTimingsArgs = {
  order?: InputMaybe<Array<EnumLabelOfMarketTimingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfMarketTimingFilterInput>;
};

export type QueryOfferingAttributesArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  order?: InputMaybe<Array<OfferingAttributesSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingAttributesFilterInput>;
};

export type QueryOfferingByIdArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  id: Scalars['ID'];
};

export type QueryOfferingDateTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfOfferingDateTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfOfferingDateTypeFilterInput>;
};

export type QueryOfferingManagerRolesArgs = {
  order?: InputMaybe<Array<EnumLabelOfOfferingManagerRoleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfOfferingManagerRoleFilterInput>;
};

export type QueryOfferingManagersArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  order?: InputMaybe<Array<OfferingManagerSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingManagerFilterInput>;
};

export type QueryOfferingsArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  legacyFilter?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<OfferingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingFilterInput>;
};

export type QueryOfferingSetupExchangeMicsArgs = {
  order?: InputMaybe<Array<EnumLabelOfOfferingSetupExchangeMicSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfOfferingSetupExchangeMicFilterInput>;
};

export type QueryOfferingStatusesArgs = {
  order?: InputMaybe<Array<EnumLabelOfOfferingStatusSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfOfferingStatusFilterInput>;
};

export type QueryOfferingTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfOfferingTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfOfferingTypeFilterInput>;
};

export type QueryPriceVsRangesArgs = {
  order?: InputMaybe<Array<EnumLabelOfPriceVsRangeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfPriceVsRangeFilterInput>;
};

export type QueryRanksArgs = {
  order?: InputMaybe<Array<EnumLabelOfRankSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfRankFilterInput>;
};

export type QuerySectorsArgs = {
  order?: InputMaybe<Array<EnumLabelOfSectorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfSectorFilterInput>;
};

export type QuerySecurityTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfSecurityTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfSecurityTypeFilterInput>;
};

export type QueryShareClassesArgs = {
  order?: InputMaybe<Array<EnumLabelOfShareClassSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfShareClassFilterInput>;
};

export type QueryShareholdersArgs = {
  asOf?: InputMaybe<Scalars['Instant']>;
  order?: InputMaybe<Array<ShareholderSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ShareholderFilterInput>;
};

export type QueryShareholderTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfShareholderTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfShareholderTypeFilterInput>;
};

export type QuerySubSectorsArgs = {
  order?: InputMaybe<Array<EnumLabelOfSubSectorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfSubSectorFilterInput>;
};

export type QueryTermTypesArgs = {
  order?: InputMaybe<Array<EnumLabelOfTermTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfTermTypeFilterInput>;
};

export type QueryUseOfProceedsArgs = {
  order?: InputMaybe<Array<EnumLabelOfUseOfProceedsSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfUseOfProceedsFilterInput>;
};

export type QueryVersusTalksArgs = {
  order?: InputMaybe<Array<EnumLabelOfVersusTalkSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EnumLabelOfVersusTalkFilterInput>;
};

export type RangeCategorySummary = {
  __typename?: 'RangeCategorySummary';
  above?: Maybe<Scalars['Decimal']>;
  aboveCount: Scalars['Int'];
  below?: Maybe<Scalars['Decimal']>;
  belowCount: Scalars['Int'];
  inRange?: Maybe<Scalars['Decimal']>;
  inRangeCount: Scalars['Int'];
};

export enum Rank {
  Preferred = 'PREFERRED',
  Senior = 'SENIOR',
  SeniorSecure = 'SENIOR_SECURE',
  SeniorSubordinated = 'SENIOR_SUBORDINATED',
  Subordinated = 'SUBORDINATED',
}

export type RankFilterInput = {
  eq?: InputMaybe<Rank>;
  in?: InputMaybe<Array<InputMaybe<Rank>>>;
  neq?: InputMaybe<Rank>;
  nin?: InputMaybe<Array<InputMaybe<Rank>>>;
};

/** A segment of a collection. */
export type RanksCollectionSegment = {
  __typename?: 'RanksCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfRank>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type RankSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum Region {
  Apac = 'APAC',
  Emea = 'EMEA',
  Lamr = 'LAMR',
  Namr = 'NAMR',
}

export type RegionFilterInput = {
  eq?: InputMaybe<Region>;
  in?: InputMaybe<Array<InputMaybe<Region>>>;
  neq?: InputMaybe<Region>;
  nin?: InputMaybe<Array<InputMaybe<Region>>>;
};

export type RegionSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type SecFiling = {
  __typename?: 'SecFiling';
  cik?: Maybe<Scalars['String']>;
  createdAt: Scalars['Instant'];
  description?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  filingDate?: Maybe<Scalars['Date']>;
  filingType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modifiedAt: Scalars['Instant'];
  pullTimestamp?: Maybe<Scalars['DateTime']>;
};

export enum Sector {
  BasicMaterials = 'BASIC_MATERIALS',
  CommunicationServices = 'COMMUNICATION_SERVICES',
  ConsumerCyclical = 'CONSUMER_CYCLICAL',
  ConsumerDefensive = 'CONSUMER_DEFENSIVE',
  Energy = 'ENERGY',
  FinancialServices = 'FINANCIAL_SERVICES',
  Healthcare = 'HEALTHCARE',
  Industrials = 'INDUSTRIALS',
  Other = 'OTHER',
  RealEstate = 'REAL_ESTATE',
  Technology = 'TECHNOLOGY',
  Utilities = 'UTILITIES',
}

export type SectorFilterInput = {
  eq?: InputMaybe<Sector>;
  in?: InputMaybe<Array<InputMaybe<Sector>>>;
  neq?: InputMaybe<Sector>;
  nin?: InputMaybe<Array<InputMaybe<Sector>>>;
};

/** A segment of a collection. */
export type SectorsCollectionSegment = {
  __typename?: 'SectorsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfSector>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type SectorSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum SecurityType {
  CommonOrdinary = 'COMMON_ORDINARY',
  CommonWarrants = 'COMMON_WARRANTS',
  ConvertibleNote = 'CONVERTIBLE_NOTE',
  GdrAdrAds = 'GDR_ADR_ADS',
  PreferredShare = 'PREFERRED_SHARE',
  Unit = 'UNIT',
}

export type SecurityTypeFilterInput = {
  eq?: InputMaybe<SecurityType>;
  in?: InputMaybe<Array<InputMaybe<SecurityType>>>;
  neq?: InputMaybe<SecurityType>;
  nin?: InputMaybe<Array<InputMaybe<SecurityType>>>;
};

/** A segment of a collection. */
export type SecurityTypesCollectionSegment = {
  __typename?: 'SecurityTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfSecurityType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type SecurityTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export enum ShareClass {
  A = 'A',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B = 'B',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  C = 'C',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  D = 'D',
  E = 'E',
  Eight = 'EIGHT',
  F = 'F',
  Five = 'FIVE',
  Four = 'FOUR',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  Nine = 'NINE',
  O = 'O',
  One = 'ONE',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  Seven = 'SEVEN',
  Six = 'SIX',
  T = 'T',
  Three = 'THREE',
  Two = 'TWO',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

/** A segment of a collection. */
export type ShareClassesCollectionSegment = {
  __typename?: 'ShareClassesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfShareClass>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ShareClassFilterInput = {
  eq?: InputMaybe<ShareClass>;
  in?: InputMaybe<Array<InputMaybe<ShareClass>>>;
  neq?: InputMaybe<ShareClass>;
  nin?: InputMaybe<Array<InputMaybe<ShareClass>>>;
};

export type ShareClassSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type Shareholder = {
  __typename?: 'Shareholder';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  offeringCount: Scalars['Int'];
  shareholderType?: Maybe<ShareholderType>;
  /** Display name for ShareholderType */
  shareholderTypeDisplayName?: Maybe<Scalars['String']>;
};

export type ShareholderAttributes = {
  __typename?: 'ShareholderAttributes';
  isCarveOutParent?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offering?: Maybe<Offering>;
  offeringId: Scalars['UUID'];
  offeringShareholder: OfferingShareholder;
  pctPostOfferingOwnership?: Maybe<Scalars['Decimal']>;
  pctPostOfferingOwnershipIncludingShoe?: Maybe<Scalars['Decimal']>;
  pctPreOfferingOwnership?: Maybe<Scalars['Decimal']>;
  postOfferingOwnership?: Maybe<Scalars['Long']>;
  postOfferingOwnershipIncludingShoe?: Maybe<Scalars['Long']>;
  preOfferingOwnership?: Maybe<Scalars['Long']>;
  shareholderId: Scalars['UUID'];
  shareholderType?: Maybe<ShareholderType>;
  /** Display name for ShareholderType */
  shareholderTypeDisplayName?: Maybe<Scalars['String']>;
};

export type ShareholderAttributesFilterInput = {
  and?: InputMaybe<Array<ShareholderAttributesFilterInput>>;
  isCarveOutParent?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  offeringShareholder?: InputMaybe<OfferingShareholderFilterInput>;
  or?: InputMaybe<Array<ShareholderAttributesFilterInput>>;
  pctPostOfferingOwnership?: InputMaybe<DecimalOperationFilterInput>;
  pctPostOfferingOwnershipIncludingShoe?: InputMaybe<DecimalOperationFilterInput>;
  pctPreOfferingOwnership?: InputMaybe<DecimalOperationFilterInput>;
  postOfferingOwnership?: InputMaybe<LongOperationFilterInput>;
  postOfferingOwnershipIncludingShoe?: InputMaybe<LongOperationFilterInput>;
  preOfferingOwnership?: InputMaybe<LongOperationFilterInput>;
  shareholderId?: InputMaybe<UuidOperationFilterInput>;
  shareholderType?: InputMaybe<ShareholderTypeFilterInput>;
};

/** A segment of a collection. */
export type ShareholderCollectionSegment = {
  __typename?: 'ShareholderCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Shareholder>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ShareholderFilterInput = {
  and?: InputMaybe<Array<ShareholderFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ShareholderFilterInput>>;
  shareholderType?: InputMaybe<ShareholderTypeFilterInput>;
};

export type ShareholderSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  shareholderType?: InputMaybe<ShareholderTypeSortInput>;
};

export enum ShareholderType {
  Corporate = 'CORPORATE',
  Insider = 'INSIDER',
  NonInsider = 'NON_INSIDER',
  OtherInstitutional = 'OTHER_INSTITUTIONAL',
  PrivateEquity = 'PRIVATE_EQUITY',
  VentureCapital = 'VENTURE_CAPITAL',
}

export type ShareholderTypeFilterInput = {
  eq?: InputMaybe<ShareholderType>;
  in?: InputMaybe<Array<InputMaybe<ShareholderType>>>;
  neq?: InputMaybe<ShareholderType>;
  nin?: InputMaybe<Array<InputMaybe<ShareholderType>>>;
};

/** A segment of a collection. */
export type ShareholderTypesCollectionSegment = {
  __typename?: 'ShareholderTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfShareholderType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ShareholderTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type ShortOperationFilterInput = {
  eq?: InputMaybe<Scalars['Short']>;
  gt?: InputMaybe<Scalars['Short']>;
  gte?: InputMaybe<Scalars['Short']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Short']>>>;
  lt?: InputMaybe<Scalars['Short']>;
  lte?: InputMaybe<Scalars['Short']>;
  neq?: InputMaybe<Scalars['Short']>;
  ngt?: InputMaybe<Scalars['Short']>;
  ngte?: InputMaybe<Scalars['Short']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Short']>>>;
  nlt?: InputMaybe<Scalars['Short']>;
  nlte?: InputMaybe<Scalars['Short']>;
};

export type SicCode = {
  __typename?: 'SicCode';
  code?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Instant'];
  id: Scalars['UUID'];
  issuerProfiles: Array<IssuerProfile>;
  modifiedAt: Scalars['Instant'];
  name?: Maybe<Scalars['String']>;
};

export type SicCodeFilterInput = {
  and?: InputMaybe<Array<SicCodeFilterInput>>;
  code?: InputMaybe<IntOperationFilterInput>;
  countryId?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  issuerProfiles?: InputMaybe<ListFilterInputTypeOfIssuerProfileFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SicCodeFilterInput>>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StockProfile = {
  __typename?: 'StockProfile';
  averageDailyVolume4Weeks?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['Instant'];
  currency: Currency;
  /** Display name for Currency */
  currencyDisplayName?: Maybe<Scalars['String']>;
  enterpriseValue?: Maybe<Scalars['Long']>;
  fiftyTwoWeekHigh?: Maybe<Scalars['Decimal']>;
  fiftyTwoWeekLow?: Maybe<Scalars['Decimal']>;
  id: Scalars['UUID'];
  issuer?: Maybe<Issuer>;
  lastClose?: Maybe<Scalars['Decimal']>;
  marketCap?: Maybe<Scalars['Long']>;
  modifiedAt: Scalars['Instant'];
  pctPriceChange1Week?: Maybe<Scalars['Decimal']>;
  pctPriceChange52Weeks?: Maybe<Scalars['Decimal']>;
  pctPriceChangeMtd?: Maybe<Scalars['Decimal']>;
  pctPriceChangeYtd?: Maybe<Scalars['Decimal']>;
  pctTo52WeekHigh?: Maybe<Scalars['Decimal']>;
  pctTo52WeekLow?: Maybe<Scalars['Decimal']>;
  sharesOutstanding?: Maybe<Scalars['Long']>;
};

export type StockProfileFilterInput = {
  and?: InputMaybe<Array<StockProfileFilterInput>>;
  averageDailyVolume4Weeks?: InputMaybe<DecimalOperationFilterInput>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  currency?: InputMaybe<CurrencyFilterInput>;
  enterpriseValue?: InputMaybe<LongOperationFilterInput>;
  fiftyTwoWeekHigh?: InputMaybe<DecimalOperationFilterInput>;
  fiftyTwoWeekLow?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  issuer?: InputMaybe<IssuerFilterInput>;
  lastClose?: InputMaybe<DecimalOperationFilterInput>;
  marketCap?: InputMaybe<LongOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  or?: InputMaybe<Array<StockProfileFilterInput>>;
  pctPriceChange1Week?: InputMaybe<DecimalOperationFilterInput>;
  pctPriceChange52Weeks?: InputMaybe<DecimalOperationFilterInput>;
  pctPriceChangeMtd?: InputMaybe<DecimalOperationFilterInput>;
  pctPriceChangeYtd?: InputMaybe<DecimalOperationFilterInput>;
  pctTo52WeekHigh?: InputMaybe<DecimalOperationFilterInput>;
  pctTo52WeekLow?: InputMaybe<DecimalOperationFilterInput>;
  sharesOutstanding?: InputMaybe<LongOperationFilterInput>;
};

export type StockProfileSortInput = {
  averageDailyVolume4Weeks?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  currency?: InputMaybe<CurrencySortInput>;
  enterpriseValue?: InputMaybe<SortEnumType>;
  fiftyTwoWeekHigh?: InputMaybe<SortEnumType>;
  fiftyTwoWeekLow?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  issuer?: InputMaybe<IssuerSortInput>;
  lastClose?: InputMaybe<SortEnumType>;
  marketCap?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  pctPriceChange1Week?: InputMaybe<SortEnumType>;
  pctPriceChange52Weeks?: InputMaybe<SortEnumType>;
  pctPriceChangeMtd?: InputMaybe<SortEnumType>;
  pctPriceChangeYtd?: InputMaybe<SortEnumType>;
  pctTo52WeekHigh?: InputMaybe<SortEnumType>;
  pctTo52WeekLow?: InputMaybe<SortEnumType>;
  sharesOutstanding?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  like?: InputMaybe<Scalars['String']>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  offeringChanged?: Maybe<Offering>;
};

export enum SubSector {
  AerospaceAndDefense = 'AEROSPACE_AND_DEFENSE',
  Agriculture = 'AGRICULTURE',
  AssetManagement = 'ASSET_MANAGEMENT',
  Banks = 'BANKS',
  BeveragesAlcoholic = 'BEVERAGES_ALCOHOLIC',
  BeveragesNonAlcoholic = 'BEVERAGES_NON_ALCOHOLIC',
  Biotechnology = 'BIOTECHNOLOGY',
  BuildingMaterials = 'BUILDING_MATERIALS',
  CapitalMarkets = 'CAPITAL_MARKETS',
  Chemicals = 'CHEMICALS',
  Conglomerates = 'CONGLOMERATES',
  Construction = 'CONSTRUCTION',
  ConsultingServices = 'CONSULTING_SERVICES',
  ConsumerPackagedGoods = 'CONSUMER_PACKAGED_GOODS',
  CreditServices = 'CREDIT_SERVICES',
  DrugManufacturers = 'DRUG_MANUFACTURERS',
  Education = 'EDUCATION',
  ElectronicGamingAndMultimedia = 'ELECTRONIC_GAMING_AND_MULTIMEDIA',
  FarmAndHeavyConstructionMachinery = 'FARM_AND_HEAVY_CONSTRUCTION_MACHINERY',
  FinancialConglomerates = 'FINANCIAL_CONGLOMERATES',
  ForestProducts = 'FOREST_PRODUCTS',
  FurnishingsFixturesAndAppliances = 'FURNISHINGS_FIXTURES_AND_APPLIANCES',
  Hardware = 'HARDWARE',
  HealthInformationServices = 'HEALTH_INFORMATION_SERVICES',
  HealthcarePlans = 'HEALTHCARE_PLANS',
  HealthcareProvidersAndServices = 'HEALTHCARE_PROVIDERS_AND_SERVICES',
  HomebuildingAndConstruction = 'HOMEBUILDING_AND_CONSTRUCTION',
  IndustrialDistribution = 'INDUSTRIAL_DISTRIBUTION',
  IndustrialProducts = 'INDUSTRIAL_PRODUCTS',
  InformationTechnologyServices = 'INFORMATION_TECHNOLOGY_SERVICES',
  Insurance = 'INSURANCE',
  InternetContentAndInformation = 'INTERNET_CONTENT_AND_INFORMATION',
  InternetRetail = 'INTERNET_RETAIL',
  ManufacturingApparelAndAccessories = 'MANUFACTURING_APPAREL_AND_ACCESSORIES',
  MediaDiversified = 'MEDIA_DIVERSIFIED',
  MedicalDevicesAndInstruments = 'MEDICAL_DEVICES_AND_INSTRUMENTS',
  MedicalDiagnosticsAndResearch = 'MEDICAL_DIAGNOSTICS_AND_RESEARCH',
  MedicalDistribution = 'MEDICAL_DISTRIBUTION',
  MetalsAndMining = 'METALS_AND_MINING',
  OilAndGasDrilling = 'OIL_AND_GAS_DRILLING',
  OilAndGasEandp = 'OIL_AND_GAS_EANDP',
  OilAndGasEquipmentAndServices = 'OIL_AND_GAS_EQUIPMENT_AND_SERVICES',
  OilAndGasIntegrated = 'OIL_AND_GAS_INTEGRATED',
  OilAndGasMidstream = 'OIL_AND_GAS_MIDSTREAM',
  OilAndGasRefiningAndMarketing = 'OIL_AND_GAS_REFINING_AND_MARKETING',
  OtherEnergySources = 'OTHER_ENERGY_SOURCES',
  PackagingAndContainers = 'PACKAGING_AND_CONTAINERS',
  PersonalServices = 'PERSONAL_SERVICES',
  RealEstateDevelopment = 'REAL_ESTATE_DEVELOPMENT',
  RealEstateDiversified = 'REAL_ESTATE_DIVERSIFIED',
  RealEstateServices = 'REAL_ESTATE_SERVICES',
  ReitDiversified = 'REIT_DIVERSIFIED',
  ReitHealthcareFacilities = 'REIT_HEALTHCARE_FACILITIES',
  ReitHotelAndMotel = 'REIT_HOTEL_AND_MOTEL',
  ReitIndustrial = 'REIT_INDUSTRIAL',
  ReitMortgage = 'REIT_MORTGAGE',
  ReitOffice = 'REIT_OFFICE',
  ReitResidential = 'REIT_RESIDENTIAL',
  ReitRetail = 'REIT_RETAIL',
  ReitSpecialty = 'REIT_SPECIALTY',
  RentalAndLeasingServices = 'RENTAL_AND_LEASING_SERVICES',
  Restaurants = 'RESTAURANTS',
  RetailCyclical = 'RETAIL_CYCLICAL',
  RetailDefensive = 'RETAIL_DEFENSIVE',
  SecurityAndProtectionServices = 'SECURITY_AND_PROTECTION_SERVICES',
  Semiconductors = 'SEMICONDUCTORS',
  ShellCompanies = 'SHELL_COMPANIES',
  SoftwareApplication = 'SOFTWARE_APPLICATION',
  SoftwareInfrastructure = 'SOFTWARE_INFRASTRUCTURE',
  Solar = 'SOLAR',
  SpecialtyBusinessServices = 'SPECIALTY_BUSINESS_SERVICES',
  StaffingAndEmploymentServices = 'STAFFING_AND_EMPLOYMENT_SERVICES',
  Steel = 'STEEL',
  TelecomServices = 'TELECOM_SERVICES',
  TobaccoProducts = 'TOBACCO_PRODUCTS',
  Transportation = 'TRANSPORTATION',
  TravelAndLeisure = 'TRAVEL_AND_LEISURE',
  UtilitiesIndependentPowerProducers = 'UTILITIES_INDEPENDENT_POWER_PRODUCERS',
  UtilitiesRegulated = 'UTILITIES_REGULATED',
  VehiclesAndParts = 'VEHICLES_AND_PARTS',
  WasteManagement = 'WASTE_MANAGEMENT',
}

export type SubSectorFilterInput = {
  eq?: InputMaybe<SubSector>;
  in?: InputMaybe<Array<InputMaybe<SubSector>>>;
  neq?: InputMaybe<SubSector>;
  nin?: InputMaybe<Array<InputMaybe<SubSector>>>;
};

/** A segment of a collection. */
export type SubSectorsCollectionSegment = {
  __typename?: 'SubSectorsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfSubSector>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type SubSectorSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type Term = {
  __typename?: 'Term';
  /** Headline principal amount of the offering. */
  aggregatePrincipalAmount?: Maybe<Scalars['Decimal']>;
  /** Aggregate principal amount inclusive of any overallotment exercised. */
  aggregatePrincipalInclOverallotmentExercised?: Maybe<Scalars['Decimal']>;
  /** ATM Program Size in notional. */
  atmProgramSize?: Maybe<Scalars['Decimal']>;
  /** ATM Program Size In securities. */
  atmProgramSizeInSecurities?: Maybe<Scalars['Decimal']>;
  /** Price at which the offering converts into common stock. Not updated for changes after issuance. */
  conversionPrice?: Maybe<Scalars['Decimal']>;
  /** The rate or ratio at which each convertible security is convertible in common stock.  Not updated for changes after issuance. */
  conversionRatio?: Maybe<Scalars['Decimal']>;
  /** Reference price of underlying stock. Either the closing level or the price of shares sold in a concurrent stock offering. */
  conversionReferencePrice?: Maybe<Scalars['Decimal']>;
  /** The coupon of the security or, for preferred stock and preference shares, the dividend. Expressed as a percentage to three decimals or text with index plus spread in basis points for floating-rate securities. */
  couponPercentage?: Maybe<Scalars['Decimal']>;
  /** High end of expected coupon of the offering, expressed as a percentage. */
  couponTalkPercentageHigh?: Maybe<Scalars['Decimal']>;
  /** Low end of expected coupon of the offering, expressed as a percentage. */
  couponTalkPercentageLow?: Maybe<Scalars['Decimal']>;
  /** Timestamp when the terms record is created. */
  createdAt: Scalars['Instant'];
  /** Premium of the Capped call price. The initial conversion premium from the issuer’s perspective after taking into account hedging arrangements. */
  effectiveConversionPremium?: Maybe<Scalars['Decimal']>;
  /** Capped Price of the capped call hedge. The conversion price from the issuer’s perspective after taking into account hedging arrangements. */
  effectiveConversionPrice?: Maybe<Scalars['Decimal']>;
  grossProceedsBase?: Maybe<Scalars['Decimal']>;
  /** Yes/No as to whether the offering has a coupon of 0%. */
  hasZeroCoupon?: Maybe<Scalars['Boolean']>;
  /** Unique CMG identifier for a specific term record. */
  id?: Maybe<Scalars['ID']>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC. The amount is used for the purposes of estimating filing fees. */
  indicativeGrossProceeds?: Maybe<Scalars['Decimal']>;
  /** High end of IPO price range, stated in local currency. */
  ipoRangeHigh?: Maybe<Scalars['Decimal']>;
  /** Low end of IPO price range, stated in local currency. */
  ipoRangeLow?: Maybe<Scalars['Decimal']>;
  /** Timestamp of the most recent change on the term. */
  modifiedAt: Scalars['Instant'];
  offering?: Maybe<Offering>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: Maybe<Scalars['UUID']>;
  /** Final price of the offering, stated in local currency. */
  offerPrice?: Maybe<Scalars['Decimal']>;
  /** overAllotmentAuthorized = overAllotmentAuthorizedPrimary + overAllotmentAuthorizedSecondary. */
  overAllotmentAuthorized?: Maybe<Scalars['Long']>;
  /** Number of primary shares the underwriters are authorized to sell in addition to the primary base shares. Also referred to as the 'greenshoe' option. Typically 15% of primary base share size. */
  overAllotmentAuthorizedPrimary?: Maybe<Scalars['Long']>;
  /** Offering size expressed as a notional amount (currency) the underwriters are authorized to sell in addition to the total base offering size. */
  overAllotmentAuthorizedProceeds?: Maybe<Scalars['Decimal']>;
  /** Number of secondary shares the underwriters are authorized to sell in addition to the secondary base shares. Also referred to as the 'greenshoe' option. Typically 15% of secondary base share size. */
  overAllotmentAuthorizedSecondary?: Maybe<Scalars['Long']>;
  overAllotmentExercised?: Maybe<Scalars['Long']>;
  overAllotmentExercisedPrimary?: Maybe<Scalars['Long']>;
  overAllotmentExercisedSecondary?: Maybe<Scalars['Long']>;
  /** Offer price stated as % of principal amount paid by investors. */
  pctOfferPrice?: Maybe<Scalars['Decimal']>;
  /** Total shares outstanding after the offering. */
  postOfferingShares?: Maybe<Scalars['Long']>;
  /** Initial conversion premium of the securities. Not updated for changes after issuance.  Stated as a %. */
  premiumPercentage?: Maybe<Scalars['Decimal']>;
  /** High end of the expected initial conversion premium of the securities. */
  premiumTalkHighPercentage?: Maybe<Scalars['Decimal']>;
  /** Low end of the expected initial conversion premium of the securities. */
  premiumTalkLowPercentage?: Maybe<Scalars['Decimal']>;
  /** Principal amount of over-allotment authorized. */
  principalAmountOverallotmentAuthorized?: Maybe<Scalars['Decimal']>;
  /** Principal amount of over-allotment exercised. */
  principalAmountOverallotmentExercised?: Maybe<Scalars['Decimal']>;
  /** Principal amount per note. */
  principalAmountPerNote?: Maybe<Scalars['Decimal']>;
  /** Date when the term is revised. */
  revisedDate?: Maybe<Scalars['Date']>;
  /** Total shares being offered. sizeInSecuritiesBase = sizeInSecuritiesBasePrimary + sizeInSecuritiesBaseSecondary. */
  sizeInSecuritiesBase?: Maybe<Scalars['Long']>;
  /** Number of shares being issued by the company. Issuance of primary shares will increase total shares outstanding. */
  sizeInSecuritiesBasePrimary?: Maybe<Scalars['Long']>;
  /** Number of shares being offered from existing shareholders. */
  sizeInSecuritiesBaseSecondary?: Maybe<Scalars['Long']>;
  /** sizeInSecuritiesTotal = sizeInSecuritiesBasePrimary + sizeInSecuritiesBaseSecondary+ overAllotmentExercised. */
  sizeInSecuritiesTotal?: Maybe<Scalars['Long']>;
  /** Type of the term, includes: INITIAL, REVISED, FINAL. */
  type?: Maybe<TermType>;
  /** Display name for Type */
  typeDisplayName?: Maybe<Scalars['String']>;
  /** Comparison of actual terms with price talk for the offer, includes: RICHER, RICH_END, WITHIN, CHEAP_END, CHEAPER, OTHER. */
  versusTalk?: Maybe<VersusTalk>;
  /** Display name for VersusTalk */
  versusTalkDisplayName?: Maybe<Scalars['String']>;
};

export type TermFilterInput = {
  /** Headline principal amount of the offering. */
  aggregatePrincipalAmount?: InputMaybe<DecimalOperationFilterInput>;
  /** Aggregate principal amount inclusive of any overallotment exercised. */
  aggregatePrincipalInclOverallotmentExercised?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<TermFilterInput>>;
  /** ATM Program Size in notional. */
  atmProgramSize?: InputMaybe<DecimalOperationFilterInput>;
  /** ATM Program Size In securities. */
  atmProgramSizeInSecurities?: InputMaybe<DecimalOperationFilterInput>;
  /** Price at which the offering converts into common stock. Not updated for changes after issuance. */
  conversionPrice?: InputMaybe<DecimalOperationFilterInput>;
  /** The rate or ratio at which each convertible security is convertible in common stock.  Not updated for changes after issuance. */
  conversionRatio?: InputMaybe<DecimalOperationFilterInput>;
  /** Reference price of underlying stock. Either the closing level or the price of shares sold in a concurrent stock offering. */
  conversionReferencePrice?: InputMaybe<DecimalOperationFilterInput>;
  /** The coupon of the security or, for preferred stock and preference shares, the dividend. Expressed as a percentage to three decimals or text with index plus spread in basis points for floating-rate securities. */
  couponPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** High end of expected coupon of the offering, expressed as a percentage. */
  couponTalkPercentageHigh?: InputMaybe<DecimalOperationFilterInput>;
  /** Low end of expected coupon of the offering, expressed as a percentage. */
  couponTalkPercentageLow?: InputMaybe<DecimalOperationFilterInput>;
  /** Timestamp when the terms record is created. */
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  /** Premium of the Capped call price. The initial conversion premium from the issuer’s perspective after taking into account hedging arrangements. */
  effectiveConversionPremium?: InputMaybe<DecimalOperationFilterInput>;
  /** Capped Price of the capped call hedge. The conversion price from the issuer’s perspective after taking into account hedging arrangements. */
  effectiveConversionPrice?: InputMaybe<DecimalOperationFilterInput>;
  grossProceedsBase?: InputMaybe<DecimalOperationFilterInput>;
  /** Yes/No as to whether the offering has a coupon of 0%. */
  hasZeroCoupon?: InputMaybe<BooleanOperationFilterInput>;
  /** Unique CMG identifier for a specific term record. */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC. The amount is used for the purposes of estimating filing fees. */
  indicativeGrossProceeds?: InputMaybe<DecimalOperationFilterInput>;
  /** High end of IPO price range, stated in local currency. */
  ipoRangeHigh?: InputMaybe<DecimalOperationFilterInput>;
  /** Low end of IPO price range, stated in local currency. */
  ipoRangeLow?: InputMaybe<DecimalOperationFilterInput>;
  /** Timestamp of the most recent change on the term. */
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  /** Final price of the offering, stated in local currency. */
  offerPrice?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<TermFilterInput>>;
  /** overAllotmentAuthorized = overAllotmentAuthorizedPrimary + overAllotmentAuthorizedSecondary. */
  overAllotmentAuthorized?: InputMaybe<LongOperationFilterInput>;
  /** Number of primary shares the underwriters are authorized to sell in addition to the primary base shares. Also referred to as the 'greenshoe' option. Typically 15% of primary base share size. */
  overAllotmentAuthorizedPrimary?: InputMaybe<LongOperationFilterInput>;
  /** Offering size expressed as a notional amount (currency) the underwriters are authorized to sell in addition to the total base offering size. */
  overAllotmentAuthorizedProceeds?: InputMaybe<DecimalOperationFilterInput>;
  /** Number of secondary shares the underwriters are authorized to sell in addition to the secondary base shares. Also referred to as the 'greenshoe' option. Typically 15% of secondary base share size. */
  overAllotmentAuthorizedSecondary?: InputMaybe<LongOperationFilterInput>;
  overAllotmentExercised?: InputMaybe<LongOperationFilterInput>;
  overAllotmentExercisedPrimary?: InputMaybe<LongOperationFilterInput>;
  overAllotmentExercisedSecondary?: InputMaybe<LongOperationFilterInput>;
  /** Offer price stated as % of principal amount paid by investors. */
  pctOfferPrice?: InputMaybe<DecimalOperationFilterInput>;
  /** Total shares outstanding after the offering. */
  postOfferingShares?: InputMaybe<LongOperationFilterInput>;
  /** Initial conversion premium of the securities. Not updated for changes after issuance.  Stated as a %. */
  premiumPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** High end of the expected initial conversion premium of the securities. */
  premiumTalkHighPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** Low end of the expected initial conversion premium of the securities. */
  premiumTalkLowPercentage?: InputMaybe<DecimalOperationFilterInput>;
  /** Principal amount of over-allotment authorized. */
  principalAmountOverallotmentAuthorized?: InputMaybe<DecimalOperationFilterInput>;
  /** Principal amount of over-allotment exercised. */
  principalAmountOverallotmentExercised?: InputMaybe<DecimalOperationFilterInput>;
  /** Principal amount per note. */
  principalAmountPerNote?: InputMaybe<DecimalOperationFilterInput>;
  /** Date when the term is revised. */
  revisedDate?: InputMaybe<DateOperationFilterInput>;
  /** Total shares being offered. sizeInSecuritiesBase = sizeInSecuritiesBasePrimary + sizeInSecuritiesBaseSecondary. */
  sizeInSecuritiesBase?: InputMaybe<LongOperationFilterInput>;
  /** Number of shares being issued by the company. Issuance of primary shares will increase total shares outstanding. */
  sizeInSecuritiesBasePrimary?: InputMaybe<LongOperationFilterInput>;
  /** Number of shares being offered from existing shareholders. */
  sizeInSecuritiesBaseSecondary?: InputMaybe<LongOperationFilterInput>;
  /** sizeInSecuritiesTotal = sizeInSecuritiesBasePrimary + sizeInSecuritiesBaseSecondary+ overAllotmentExercised. */
  sizeInSecuritiesTotal?: InputMaybe<LongOperationFilterInput>;
  /** Type of the term, includes: INITIAL, REVISED, FINAL. */
  type?: InputMaybe<TermTypeFilterInput>;
  /** Comparison of actual terms with price talk for the offer, includes: RICHER, RICH_END, WITHIN, CHEAP_END, CHEAPER, OTHER. */
  versusTalk?: InputMaybe<VersusTalkFilterInput>;
};

export type TermSortInput = {
  /** Headline principal amount of the offering. */
  aggregatePrincipalAmount?: InputMaybe<SortEnumType>;
  /** Aggregate principal amount inclusive of any overallotment exercised. */
  aggregatePrincipalInclOverallotmentExercised?: InputMaybe<SortEnumType>;
  /** ATM Program Size in notional. */
  atmProgramSize?: InputMaybe<SortEnumType>;
  /** ATM Program Size In securities. */
  atmProgramSizeInSecurities?: InputMaybe<SortEnumType>;
  /** Price at which the offering converts into common stock. Not updated for changes after issuance. */
  conversionPrice?: InputMaybe<SortEnumType>;
  /** The rate or ratio at which each convertible security is convertible in common stock.  Not updated for changes after issuance. */
  conversionRatio?: InputMaybe<SortEnumType>;
  /** Reference price of underlying stock. Either the closing level or the price of shares sold in a concurrent stock offering. */
  conversionReferencePrice?: InputMaybe<SortEnumType>;
  /** The coupon of the security or, for preferred stock and preference shares, the dividend. Expressed as a percentage to three decimals or text with index plus spread in basis points for floating-rate securities. */
  couponPercentage?: InputMaybe<SortEnumType>;
  /** High end of expected coupon of the offering, expressed as a percentage. */
  couponTalkPercentageHigh?: InputMaybe<SortEnumType>;
  /** Low end of expected coupon of the offering, expressed as a percentage. */
  couponTalkPercentageLow?: InputMaybe<SortEnumType>;
  /** Timestamp when the terms record is created. */
  createdAt?: InputMaybe<SortEnumType>;
  /** Premium of the Capped call price. The initial conversion premium from the issuer’s perspective after taking into account hedging arrangements. */
  effectiveConversionPremium?: InputMaybe<SortEnumType>;
  /** Capped Price of the capped call hedge. The conversion price from the issuer’s perspective after taking into account hedging arrangements. */
  effectiveConversionPrice?: InputMaybe<SortEnumType>;
  grossProceedsBase?: InputMaybe<SortEnumType>;
  /** Yes/No as to whether the offering has a coupon of 0%. */
  hasZeroCoupon?: InputMaybe<SortEnumType>;
  /** Unique CMG identifier for a specific term record. */
  id?: InputMaybe<SortEnumType>;
  /** Indicative gross proceeds with the initial prospectus filing submitted with the SEC. The amount is used for the purposes of estimating filing fees. */
  indicativeGrossProceeds?: InputMaybe<SortEnumType>;
  /** High end of IPO price range, stated in local currency. */
  ipoRangeHigh?: InputMaybe<SortEnumType>;
  /** Low end of IPO price range, stated in local currency. */
  ipoRangeLow?: InputMaybe<SortEnumType>;
  /** Timestamp of the most recent change on the term. */
  modifiedAt?: InputMaybe<SortEnumType>;
  offering?: InputMaybe<OfferingSortInput>;
  /** Unique CMG identifier for a specific offering. */
  offeringId?: InputMaybe<SortEnumType>;
  /** Final price of the offering, stated in local currency. */
  offerPrice?: InputMaybe<SortEnumType>;
  /** overAllotmentAuthorized = overAllotmentAuthorizedPrimary + overAllotmentAuthorizedSecondary. */
  overAllotmentAuthorized?: InputMaybe<SortEnumType>;
  /** Number of primary shares the underwriters are authorized to sell in addition to the primary base shares. Also referred to as the 'greenshoe' option. Typically 15% of primary base share size. */
  overAllotmentAuthorizedPrimary?: InputMaybe<SortEnumType>;
  /** Offering size expressed as a notional amount (currency) the underwriters are authorized to sell in addition to the total base offering size. */
  overAllotmentAuthorizedProceeds?: InputMaybe<SortEnumType>;
  /** Number of secondary shares the underwriters are authorized to sell in addition to the secondary base shares. Also referred to as the 'greenshoe' option. Typically 15% of secondary base share size. */
  overAllotmentAuthorizedSecondary?: InputMaybe<SortEnumType>;
  overAllotmentExercised?: InputMaybe<SortEnumType>;
  overAllotmentExercisedPrimary?: InputMaybe<SortEnumType>;
  overAllotmentExercisedSecondary?: InputMaybe<SortEnumType>;
  /** Offer price stated as % of principal amount paid by investors. */
  pctOfferPrice?: InputMaybe<SortEnumType>;
  /** Total shares outstanding after the offering. */
  postOfferingShares?: InputMaybe<SortEnumType>;
  /** Initial conversion premium of the securities. Not updated for changes after issuance.  Stated as a %. */
  premiumPercentage?: InputMaybe<SortEnumType>;
  /** High end of the expected initial conversion premium of the securities. */
  premiumTalkHighPercentage?: InputMaybe<SortEnumType>;
  /** Low end of the expected initial conversion premium of the securities. */
  premiumTalkLowPercentage?: InputMaybe<SortEnumType>;
  /** Principal amount of over-allotment authorized. */
  principalAmountOverallotmentAuthorized?: InputMaybe<SortEnumType>;
  /** Principal amount of over-allotment exercised. */
  principalAmountOverallotmentExercised?: InputMaybe<SortEnumType>;
  /** Principal amount per note. */
  principalAmountPerNote?: InputMaybe<SortEnumType>;
  /** Date when the term is revised. */
  revisedDate?: InputMaybe<SortEnumType>;
  /** Total shares being offered. sizeInSecuritiesBase = sizeInSecuritiesBasePrimary + sizeInSecuritiesBaseSecondary. */
  sizeInSecuritiesBase?: InputMaybe<SortEnumType>;
  /** Number of shares being issued by the company. Issuance of primary shares will increase total shares outstanding. */
  sizeInSecuritiesBasePrimary?: InputMaybe<SortEnumType>;
  /** Number of shares being offered from existing shareholders. */
  sizeInSecuritiesBaseSecondary?: InputMaybe<SortEnumType>;
  /** sizeInSecuritiesTotal = sizeInSecuritiesBasePrimary + sizeInSecuritiesBaseSecondary+ overAllotmentExercised. */
  sizeInSecuritiesTotal?: InputMaybe<SortEnumType>;
  /** Type of the term, includes: INITIAL, REVISED, FINAL. */
  type?: InputMaybe<TermTypeSortInput>;
  /** Comparison of actual terms with price talk for the offer, includes: RICHER, RICH_END, WITHIN, CHEAP_END, CHEAPER, OTHER. */
  versusTalk?: InputMaybe<VersusTalkSortInput>;
};

export enum TermType {
  Final = 'FINAL',
  Initial = 'INITIAL',
  Revised = 'REVISED',
}

export type TermTypeFilterInput = {
  eq?: InputMaybe<TermType>;
  in?: InputMaybe<Array<InputMaybe<TermType>>>;
  neq?: InputMaybe<TermType>;
  nin?: InputMaybe<Array<InputMaybe<TermType>>>;
};

/** A segment of a collection. */
export type TermTypesCollectionSegment = {
  __typename?: 'TermTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfTermType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type TermTypeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type TrendPerformanceCategory = {
  __typename?: 'TrendPerformanceCategory';
  line?: Maybe<Array<Coordinate>>;
  points?: Maybe<Array<LabeledCoordinate>>;
};

export type TrendPerformanceKeys = {
  __typename?: 'TrendPerformanceKeys';
  offerTo180Day?: Maybe<TrendPerformanceCategory>;
  offerTo1Day?: Maybe<TrendPerformanceCategory>;
  offerTo30Day?: Maybe<TrendPerformanceCategory>;
  offerTo90Day?: Maybe<TrendPerformanceCategory>;
  offerToCurrent?: Maybe<TrendPerformanceCategory>;
  offerToOpen?: Maybe<TrendPerformanceCategory>;
};

export enum UnitType {
  Dollars = 'DOLLARS',
  Percentage = 'PERCENTAGE',
  Shares = 'SHARES',
}

export type UnitTypeFilterInput = {
  eq?: InputMaybe<UnitType>;
  in?: InputMaybe<Array<InputMaybe<UnitType>>>;
  neq?: InputMaybe<UnitType>;
  nin?: InputMaybe<Array<InputMaybe<UnitType>>>;
};

export type UnitTypeSortInput = {
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type UpdateCustomSectorInput = {
  data: CustomSectorUpdateInputData;
  id: Scalars['ID'];
};

export type UpdateCustomSectorPayload = {
  __typename?: 'UpdateCustomSectorPayload';
  customSector?: Maybe<CustomSector>;
};

export type UpsertCustomSectorIssuersInput = {
  customSectorId: Scalars['UUID'];
  issuerIds: Array<Scalars['UUID']>;
};

export type UpsertCustomSectorIssuersPayload = {
  __typename?: 'UpsertCustomSectorIssuersPayload';
  customSectorIssuer?: Maybe<Array<CustomSectorIssuer>>;
};

export type UpsertOfferingNoteInput = {
  note?: InputMaybe<Scalars['String']>;
  offeringId: Scalars['UUID'];
};

export type UpsertOfferingNotePayload = {
  __typename?: 'UpsertOfferingNotePayload';
  offeringId?: Maybe<Scalars['UUID']>;
};

export type UrlOperationFilterInput = {
  eq?: InputMaybe<Scalars['URL']>;
  gt?: InputMaybe<Scalars['URL']>;
  gte?: InputMaybe<Scalars['URL']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['URL']>>>;
  lt?: InputMaybe<Scalars['URL']>;
  lte?: InputMaybe<Scalars['URL']>;
  neq?: InputMaybe<Scalars['URL']>;
  ngt?: InputMaybe<Scalars['URL']>;
  ngte?: InputMaybe<Scalars['URL']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['URL']>>>;
  nlt?: InputMaybe<Scalars['URL']>;
  nlte?: InputMaybe<Scalars['URL']>;
};

export enum UseOfProceeds {
  Acquisitions = 'ACQUISITIONS',
  CapitalExpenditures = 'CAPITAL_EXPENDITURES',
  DebtRepayment = 'DEBT_REPAYMENT',
  FutureAcquisitions = 'FUTURE_ACQUISITIONS',
  GeneralCorporatePurposes = 'GENERAL_CORPORATE_PURPOSES',
  GrowthCapital = 'GROWTH_CAPITAL',
  Investments = 'INVESTMENTS',
  NoProceedsToIssuer = 'NO_PROCEEDS_TO_ISSUER',
  ResearchAndDevelopment = 'RESEARCH_AND_DEVELOPMENT',
  ShareRepurchase = 'SHARE_REPURCHASE',
  ShareholderDividends = 'SHAREHOLDER_DIVIDENDS',
  WorkingCapital = 'WORKING_CAPITAL',
}

/** A segment of a collection. */
export type UseOfProceedsCollectionSegment = {
  __typename?: 'UseOfProceedsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfUseOfProceeds>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type UseOfProceedsFilterInput = {
  eq?: InputMaybe<UseOfProceeds>;
  in?: InputMaybe<Array<InputMaybe<UseOfProceeds>>>;
  neq?: InputMaybe<UseOfProceeds>;
  nin?: InputMaybe<Array<InputMaybe<UseOfProceeds>>>;
};

export type UseOfProceedsListFilterInput = {
  all?: InputMaybe<UseOfProceedsFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UseOfProceedsFilterInput>;
  some?: InputMaybe<UseOfProceedsFilterInput>;
};

export type UseOfProceedsSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type UserOffering = {
  __typename?: 'UserOffering';
  isShared: Scalars['Boolean'];
  userId?: Maybe<Scalars['UUID']>;
};

export type UserOfferingFilterInput = {
  and?: InputMaybe<Array<UserOfferingFilterInput>>;
  createdAt?: InputMaybe<InstantOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isShared?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<InstantOperationFilterInput>;
  offering?: InputMaybe<OfferingFilterInput>;
  offeringId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<UserOfferingFilterInput>>;
  userId?: InputMaybe<UuidOperationFilterInput>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export enum VersusTalk {
  CheapEnd = 'CHEAP_END',
  Cheaper = 'CHEAPER',
  Other = 'OTHER',
  RichEnd = 'RICH_END',
  Richer = 'RICHER',
  Within = 'WITHIN',
}

export type VersusTalkFilterInput = {
  eq?: InputMaybe<VersusTalk>;
  in?: InputMaybe<Array<InputMaybe<VersusTalk>>>;
  neq?: InputMaybe<VersusTalk>;
  nin?: InputMaybe<Array<InputMaybe<VersusTalk>>>;
};

/** A segment of a collection. */
export type VersusTalksCollectionSegment = {
  __typename?: 'VersusTalksCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnumLabelOfVersusTalk>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type VersusTalkSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};
