import { useAuth } from '@cmg/auth';
import { ToastManager, useHubspotForm } from '@cmg/common';
import React from 'react';

import LoadingOverlay from '../../../../../../../common/components/indicators/loading-overlay/LoadingOverlay';
import { getAppSettings } from '../../../../../../../config/appSettings';
import { SFormContainer } from './HubspotForm.styles';

const hubspotTarget = 'hubspot-support-form';

type Props = {
  category?: 'GENERAL_INQUIRY' | 'TECHNICAL_ISSUE' | 'DATA_INQUIRY' | 'API_INQUIRY' | 'FEEDBACK';
  subject?: string;
  description?: string;
};

const HubspotForm: React.FC<Props> = ({ category, subject, description }) => {
  const appSettings = getAppSettings();
  const { oidcUserEmail, oidcUserGivenName, oidcUserFamilyName } = useAuth();

  const handleSubmit = $form => {
    $form.find('input[name="firstname"]').val(oidcUserGivenName).change();
    $form.find('input[name="lastname"]').val(oidcUserFamilyName).change();
    $form.find('input[name="email"]').val(oidcUserEmail).change();
  };

  const prePopulateFields = $form => {
    category && $form.find('select[name="TICKET.hs_ticket_category"]').val(category).change();
    subject && $form.find('input[name="TICKET.subject"]').val(subject).change();
    description && $form.find('textarea[name="TICKET.content"]').val(description).change();
  };

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: appSettings.env.hubspotSupportFormPortalId || '',
    formId: appSettings.env.hubspotSupportFormId || '',
    target: `#${hubspotTarget}`,
    onFormSubmit: handleSubmit,
    onFormReady: prePopulateFields,
  });

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Failed to load support form');
    }
  }, [error]);

  return (
    <SFormContainer>
      {(!loaded || !formCreated) && <LoadingOverlay message="Loading..." />}
      <div id={hubspotTarget} />
    </SFormContainer>
  );
};

export default HubspotForm;
