import { Icon } from '@cmg/common';
import React from 'react';

import {
  SDropdownFiltersCountBadge,
  SDropdownIconWrapper,
  SDropdownText,
  SFiltersButtonWrapper,
  StyledFiltersToggleButton,
} from './FilterFormToggleButton.styles';

export type Props = {
  isFiltersExpanded: boolean;
  filterCount: number;
  onClick: () => void;
};

const FilterFormToggleButton: React.FC<Props> = ({ isFiltersExpanded, filterCount, onClick }) => (
  <StyledFiltersToggleButton
    type="button"
    testId="datalab-toggle-filters"
    isFiltersExpanded={isFiltersExpanded}
    onClick={onClick}
  >
    <SFiltersButtonWrapper hasFilters={filterCount > 0}>
      <SDropdownText>{isFiltersExpanded ? 'Hide Filters' : 'Show Filters'}</SDropdownText>
      {filterCount > 0 && <SDropdownFiltersCountBadge>{filterCount}</SDropdownFiltersCountBadge>}
      <SDropdownIconWrapper>
        <Icon size="lg" name="angle-down" />
      </SDropdownIconWrapper>
    </SFiltersButtonWrapper>
  </StyledFiltersToggleButton>
);

export default FilterFormToggleButton;
