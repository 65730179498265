import {
  Card,
  Grid,
  KeyValuePair,
  RegularBreakpoints,
  Section,
  Typography,
} from '@cmg/design-system';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetLockupsData } from '../hooks/useGetLockupsData';
import { x4_l6_m12_s12, x6_l12_m12_s12, x8_l6_m12_s12 } from '../OfferingProfile.styles';
import { cardHeight } from '../OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};
const LockupsWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const lockups = useGetLockupsData(offeringProfile);
  const title = 'Lock-ups';
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          {!lockups.length ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <Grid container columnSpacing={2}>
              <Grid container columnSpacing={2} item {...x8_l6_m12_s12}>
                <Grid item {...x6_l12_m12_s12}>
                  {lockups.slice(0, 1).map(({ name, value }) => {
                    return <KeyValuePair minWidth="small" key={name} label={name} value={value} />;
                  })}
                </Grid>
                <Grid item {...x6_l12_m12_s12}>
                  {lockups.slice(1, 3).map(({ name, value }) => {
                    return <KeyValuePair minWidth="small" key={name} label={name} value={value} />;
                  })}
                </Grid>
              </Grid>
              <Grid item {...x4_l6_m12_s12}>
                {lockups.slice(3).map(({ name, value }) => {
                  return <KeyValuePair minWidth="small" key={name} label={name} value={value} />;
                })}
              </Grid>
            </Grid>
          )}
        </Section>
      </Card>
    </Grid>
  );
};

export default LockupsWidget;
