import {
  Card,
  Divider,
  Grid,
  KeyValuePair,
  RegularBreakpoints,
  Section,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@cmg/design-system';
import React from 'react';

import { isFOOffering } from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import LabelWithTooltip from '../components/LabelWithTooltip';
import { useGetStructurePricingSectionData } from '../hooks/useGetStructurePricing';
import { cardHeight } from '../OfferingProfileCardItem';
import {
  type CardRowItem,
  StructurePricingRowLabels,
  StructurePricingTableHeader,
  StructurePricingTermName,
} from '../types';
import {
  bgColor,
  borderStyle,
  getBgColor,
  gridStyle,
  StyledTableContainer,
} from './StructurePricingWidget.styles';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

export type StructurePricingTermsProps = {
  column1: CardRowItem[];
  column2: CardRowItem[];
  column3: CardRowItem[];
  keyValueItemHeight;
};

export const StructurePricingTerms: React.FC<StructurePricingTermsProps> = ({
  column1,
  column2,
  column3,
  keyValueItemHeight,
}) => {
  return (
    <Grid container columnSpacing={2} display="flex" justifyContent="space-between">
      <Grid item flex={1}>
        {column1.map(({ name, value }) => (
          <KeyValuePair
            key={name}
            minWidth="large"
            label={name}
            value={value}
            height={keyValueItemHeight(name)}
          />
        ))}
      </Grid>

      {column2.length > 0 && (
        <Grid item flex={1}>
          {column2.map(({ name, value }) => (
            <KeyValuePair
              key={name}
              minWidth="large"
              label={name}
              value={value}
              height={keyValueItemHeight(name)}
            />
          ))}
        </Grid>
      )}

      {column3.length > 0 && (
        <Grid item flex={1}>
          {column3.map(({ name, value }) => (
            <KeyValuePair
              key={name}
              minWidth="large"
              label={name}
              value={value}
              height={keyValueItemHeight(name)}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

const StructurePricingWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
  const matchesMediumDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(1770));
  const matchesSmallDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(1200));
  const { structurePricingTable, structurePricingFOData, structurePricingRows } =
    useGetStructurePricingSectionData(offeringProfile, matchesMediumDown, matchesSmallDown);
  const { column1, column2, column3 } = structurePricingRows;

  const isFollowOn = React.useMemo(() => {
    return isFOOffering(offeringProfile?.offeringById?.type);
  }, [offeringProfile?.offeringById?.type]);

  const title = 'Structure/Pricing';

  // note the spacing need to cover item height including icons
  const keyValueItemHeight = name =>
    ![StructurePricingRowLabels.useOfProceeds, StructurePricingRowLabels.ADTVDateRange].includes(
      name
    )
      ? theme => theme.spacing(3.75)
      : undefined;

  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          <Stack rowGap={2} display="flex">
            {isSmallScreen ? (
              <Stack divider={<Divider />}>
                {structurePricingTable.map(
                  ({
                    name,
                    grossProceeds,
                    offerPrice,
                    sizeInSecurities,
                    primary,
                    secondary,
                    ovltAuth,
                  }) => {
                    return (
                      <Stack paddingX={2} paddingY={1} sx={bgColor[name]} key={name}>
                        <Typography variant="highlight1" paddingBottom={0.5}>
                          {name}
                        </Typography>
                        <KeyValuePair
                          label={
                            <LabelWithTooltip
                              label={StructurePricingTableHeader.grossProceeds}
                              tooltipText={
                                isFollowOn
                                  ? 'Gross Proceeds calculations rely on the latest available closing price, marketing range or offer price'
                                  : null
                              }
                            />
                          }
                          value={grossProceeds}
                        />
                        <KeyValuePair
                          label={StructurePricingTableHeader.offerPriceRange}
                          value={offerPrice}
                        />
                        <KeyValuePair
                          label={StructurePricingTableHeader.sizeInSecurities}
                          value={sizeInSecurities}
                        />
                        <KeyValuePair label={StructurePricingTableHeader.primary} value={primary} />
                        <KeyValuePair
                          label={StructurePricingTableHeader.secondary}
                          value={secondary}
                        />
                        {name !== StructurePricingTermName.OvltExercised &&
                          name !== StructurePricingTermName.Total && (
                            <KeyValuePair
                              label={StructurePricingTableHeader.ovltAuth}
                              value={ovltAuth}
                            />
                          )}
                      </Stack>
                    );
                  }
                )}
              </Stack>
            ) : (
              <StyledTableContainer>
                <Grid sx={gridStyle}>
                  <Table size="medium">
                    <TableHead>
                      <TableRow color="text.secondary">
                        <TableGroupHeaderCell></TableGroupHeaderCell>
                        <TableCell align="right">
                          <LabelWithTooltip
                            label={StructurePricingTableHeader.grossProceeds}
                            tooltipText={
                              isFollowOn
                                ? 'Gross Proceeds calculations rely on the latest available closing price, marketing range or offer price'
                                : null
                            }
                            justifyContent="flex-end"
                          />
                        </TableCell>
                        <TableCell align="right">
                          {StructurePricingTableHeader.offerPriceRange}
                        </TableCell>
                        <TableCell align="right" sx={borderStyle}>
                          {StructurePricingTableHeader.sizeInSecurities}
                        </TableCell>
                        <TableCell align="right">{StructurePricingTableHeader.primary}</TableCell>
                        <TableCell align="right">{StructurePricingTableHeader.secondary}</TableCell>
                        <TableCell align="right">{StructurePricingTableHeader.ovltAuth}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {structurePricingTable.map(
                        ({
                          name,
                          grossProceeds,
                          offerPrice,
                          sizeInSecurities,
                          primary,
                          secondary,
                          ovltAuth,
                        }) => {
                          return (
                            <TableRow key={name} sx={getBgColor(name)}>
                              <TableCell width="13%">{name}</TableCell>
                              <TableCell align="right" width="14%">
                                {grossProceeds}
                              </TableCell>
                              <TableCell align="right" width="20%">
                                {offerPrice}
                              </TableCell>
                              <TableCell align="right" width="14%" sx={borderStyle}>
                                {sizeInSecurities}
                              </TableCell>
                              <TableCell align="right" width="13%">
                                {primary}
                              </TableCell>
                              <TableCell align="right" width="13%">
                                {secondary}
                              </TableCell>
                              <TableCell align="right" width="13%">
                                {ovltAuth}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </StyledTableContainer>
            )}
            {structurePricingFOData.length > 0 && (
              <React.Fragment>
                {isSmallScreen ? (
                  <Stack divider={<Divider />}>
                    {structurePricingFOData.map(({ name, value, value2 }) => {
                      return (
                        <Stack marginBottom={1} marginTop={1.5} key={name}>
                          <Typography variant="highlight1">{name}</Typography>
                          <KeyValuePair label="Price" value={value} />
                          <KeyValuePair label="Discount" value={value2} />
                        </Stack>
                      );
                    })}
                  </Stack>
                ) : (
                  <Grid item minWidth={theme => theme.spacing(46)} flex={1}>
                    <TableContainer>
                      <Table size="medium">
                        <TableHead>
                          <TableRow color="text.secondary">
                            <TableCell></TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right">Discount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {structurePricingFOData.map(({ name, value, value2 }) => {
                            return (
                              <TableRow key={name}>
                                <TableCell width="34%">{name}</TableCell>
                                <TableCell align="right" width="33%">
                                  {value}
                                </TableCell>
                                <TableCell align="right" width="33%">
                                  {value2}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </React.Fragment>
            )}
            <StructurePricingTerms
              column1={column1}
              column2={column2}
              column3={column3}
              keyValueItemHeight={keyValueItemHeight}
            />
          </Stack>
        </Section>
      </Card>
    </Grid>
  );
};

export default StructurePricingWidget;
