import { styled, SxProps, TableContainer, Theme } from '@cmg/design-system';

import { StructurePricingTermName } from '../types';

export const bgColor: { [name in StructurePricingTermName]: SxProps<Theme> } = {
  Initial: { bgcolor: theme => theme.palette.background.default },
  Revised: { bgcolor: theme => theme.palette.background.default },
  Priced: { bgcolor: theme => '#F5F9FF' }, // This is temporary until design team add more color to our palette.
  'Ovlt. Exercised': { bgcolor: theme => '#F5F9FF' },
  Total: { bgcolor: theme => '#EDF1F9' },
};

export const getBgColor = (name: StructurePricingTermName): SxProps<Theme> => {
  const borderStyles = {
    borderBottom: 'none',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor:
      name === StructurePricingTermName.Priced || name === StructurePricingTermName.Total
        ? 'darkgray'
        : 'lightgray',
  };
  return {
    ...bgColor[name],
    '& td': borderStyles,
    '&:last-child td': { typography: 'highlight1' },
  };
};

export const borderStyle: SxProps<Theme> = {
  borderRight: 1,
  borderRightColor: theme => theme.palette.divider,
  ':after': {
    content: 'none',
  },
};
// these 2 make table scroll horizontally
export const gridStyle = { minWidth: theme => theme.spacing(112) };
export const tableCellStyle = { whiteSpace: 'nowrap' };

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.neutral[400]};
  }
`;
