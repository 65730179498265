import React from 'react';

import { SectionHeaderProps } from '../../features/offering-dl/offering-profile/profiles/shared/types';

const nearestElementIndex = (
  currentPosition: number,
  sections: SectionHeaderProps[]
): string | null => {
  let activeSection: string | null = null;
  sections.forEach(section => {
    const sectionElement = document.getElementById(section.id);
    if (!sectionElement) {
      return;
    }
    const { offsetTop, offsetHeight } = sectionElement;
    const offsetBottom = offsetTop + offsetHeight;

    if (currentPosition >= offsetTop && currentPosition < offsetBottom) {
      activeSection = section.id;
    }
  });
  return activeSection;
};

export type UseAciveSectionOnScrollParams = {
  sectionsConfig: SectionHeaderProps[];
  mainSectionId: string;
};
export type UseAciveSectionOnScrollResult = {
  activeSection: string | null;
  handleClick: (section: SectionHeaderProps) => void;
};
export const useActiveSectionOnScroll = ({
  sectionsConfig,
  mainSectionId,
}: UseAciveSectionOnScrollParams): UseAciveSectionOnScrollResult => {
  const [activeSection, setActiveSection] = React.useState<string | null>(null);

  const handleScroll = React.useCallback(
    e => {
      const sectionId = nearestElementIndex(
        e.currentTarget.offsetTop + e.currentTarget.scrollTop,
        sectionsConfig
      );
      sectionId && setActiveSection(sectionId);
    },
    [sectionsConfig]
  );

  React.useEffect(() => {
    if (sectionsConfig.length > 0) {
      const mainSection = document.getElementById(mainSectionId);
      setActiveSection(sectionsConfig[0].id);
      mainSection?.addEventListener('scroll', handleScroll);
      return () => {
        mainSection?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, mainSectionId, sectionsConfig]);

  const handleClick = React.useCallback(
    (section: SectionHeaderProps) => {
      const mainSection = document.getElementById(mainSectionId);
      // temporarily disable scroll listener because it override active section while scrolling
      mainSection?.removeEventListener('scroll', handleScroll);

      // scroll to selected section
      const targetSection = document.getElementById(section.id);
      targetSection?.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(section.id);

      setTimeout(() => {
        // put scroll listener back
        mainSection?.addEventListener('scroll', handleScroll);
      }, 1000);
    },
    [handleScroll, mainSectionId]
  );
  return {
    activeSection,
    handleClick,
  };
};
