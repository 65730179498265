import cloneDeep from 'lodash/cloneDeep';
import memoize from 'memoize-one';

import { TableDataManagementType } from '../../shared/constants/constants';
import { DatalabScreens } from '../constants';
import { ColumnCategoryConfig, ColumnGroupConfig } from './advanced-filters-config';
import heatMapPerformanceButtons from './buttons/heat-map-performance-buttons';
import plCreditButtons from './buttons/pl-credit-buttons';
import {
  DatalabTableColumn,
  intlOverridingProps,
  localCurrencyColumns,
} from './datalabTableColumns';
import * as aftermarketMatrixTableConfig from './table/aftermarket-matrix';
import * as aftermarketUnderwriterOfferingsTableConfig from './table/aftermarket-underwriter-offerings';
import * as brokerPlTableConfig from './table/broker-pl';
import * as capitalRaisedMatrixTableConfig from './table/capital-raised-matrix';
import * as foPricingTableConfig from './table/follow-on-pricing';
import * as foStructureTableConfig from './table/follow-on-structure';
import * as fundIoiLeagueTableTableConfig from './table/fund-ioi-league-table';
import * as fundIoiParticipationTableConfig from './table/fund-ioi-participation';
import * as ioiLeagueTableTableConfig from './table/ioi-league-table';
import * as ioiParticipationTableConfig from './table/ioi-participation';
import * as ipoBacklogTableConfig from './table/ipo-backlog';
import * as ipoPricingTableConfig from './table/ipo-pricing';
import * as ipoStructureTableConfig from './table/ipo-structure';
import * as leagueTablesTableConfig from './table/league-tables';
import * as lockupReportTableConfig from './table/lockup-report';
import * as marketPulseTableConfig from './table/market-pulse';
import * as plBrokerOfferingsTableConfig from './table/pl-broker-offerings';
import * as sponsorLeagueTableTableConfig from './table/sponsor-league-table';
import * as underwriterOfferingsTableConfig from './table/underwriter-offerings';
import * as underwritingTermsTableConfig from './table/underwriting-terms';
import { getInternationalPermission } from './utils';

function getSummaryColumns(columnsConfig) {
  const summaryColumns = {};
  columnsConfig
    .filter(col => col.summaryConfig)
    .forEach(col => {
      summaryColumns[col.field] = col.summaryConfig.map(summary => summary.field);
    });

  return summaryColumns;
}

function getVisibleColumns(columnsConfig) {
  return columnsConfig.map(config => config.field);
}

export type TableConfigType = {
  title: string;
  titleTooltip?: string;
  columnsConfig: DatalabTableColumn[];
  summaryColumns: { [key: string]: any };
  categorizedColumns?: ColumnCategoryConfig;
  groupedColumns?: ColumnGroupConfig;
  orderBy: string;
  orderByType: string;
  builtVisibleColumns: string[];
  localCurrencyColumns?: string[];
  staticColumns: string[];
  showRanking: boolean;
  quickFilterOptions: any;
  quickFilterType: any;
  paginationType: any;
};

export const defaultTableConfig: TableConfigType = {
  title: '',
  columnsConfig: [],
  orderBy: 'offerings_pricing_date',
  orderByType: 'desc',
  builtVisibleColumns: [],
  staticColumns: [],
  showRanking: false,
  quickFilterOptions: undefined,
  quickFilterType: undefined,
  paginationType: TableDataManagementType.SERVER,
  summaryColumns: {},
};

const tableConfigs: { [key: string]: TableConfigType } = {
  [DatalabScreens.IPO_STRUCTURE]: {
    ...defaultTableConfig,
    title: 'IPO Structure',
    columnsConfig: ipoStructureTableConfig.columns,
    builtVisibleColumns: ipoStructureTableConfig.visibleFields,
    staticColumns: ipoStructureTableConfig.staticFields,
    categorizedColumns: ipoStructureTableConfig.categorizedColumns,
    groupedColumns: ipoStructureTableConfig.groupedColumns,
    localCurrencyColumns: ipoStructureTableConfig.visibleLocalCurrFields,
  },
  [DatalabScreens.IPO_PRICING]: {
    ...defaultTableConfig,
    title: 'IPO Pricing',
    columnsConfig: ipoPricingTableConfig.columns,
    builtVisibleColumns: ipoPricingTableConfig.visibleFields,
    staticColumns: ipoPricingTableConfig.staticFields,
    categorizedColumns: ipoPricingTableConfig.categorizedColumns,
    groupedColumns: ipoPricingTableConfig.groupedColumns,
    localCurrencyColumns: ipoPricingTableConfig.visibleLocalCurrFields,
  },
  [DatalabScreens.FOLLOW_ON_STRUCTURE]: {
    ...defaultTableConfig,
    title: 'Follow-on Structure',
    columnsConfig: foStructureTableConfig.columns,
    builtVisibleColumns: foStructureTableConfig.visibleFields,
    staticColumns: foStructureTableConfig.staticFields,
    categorizedColumns: foStructureTableConfig.categorizedColumns,
    groupedColumns: foStructureTableConfig.groupedColumns,
    localCurrencyColumns: foStructureTableConfig.visibleLocalCurrFields,
  },
  [DatalabScreens.FOLLOW_ON_PRICING]: {
    ...defaultTableConfig,
    title: 'Follow-on Pricing',
    columnsConfig: foPricingTableConfig.columns,
    builtVisibleColumns: foPricingTableConfig.visibleFields,
    staticColumns: foPricingTableConfig.staticFields,
    categorizedColumns: foPricingTableConfig.categorizedColumns,
    groupedColumns: foPricingTableConfig.groupedColumns,
    localCurrencyColumns: foPricingTableConfig.visibleLocalCurrFields,
  },
  [DatalabScreens.UNDERWRITING_TERMS]: {
    ...defaultTableConfig,
    title: 'Underwriting Terms',
    columnsConfig: underwritingTermsTableConfig.columns,
    builtVisibleColumns: underwritingTermsTableConfig.visibleFields,
    staticColumns: underwritingTermsTableConfig.staticFields,
    categorizedColumns: underwritingTermsTableConfig.categorizedColumns,
    groupedColumns: underwritingTermsTableConfig.groupedColumns,
    localCurrencyColumns: underwritingTermsTableConfig.visibleLocalCurrFields,
  },
  [DatalabScreens.UNDERWRITER_PROFILE_SELL]: {
    ...defaultTableConfig,
    title: 'Underwriter Profile',
    columnsConfig: underwritingTermsTableConfig.columns,
    builtVisibleColumns: underwritingTermsTableConfig.visibleFields,
    staticColumns: underwritingTermsTableConfig.staticFields,
    categorizedColumns: underwritingTermsTableConfig.categorizedColumns,
    groupedColumns: underwritingTermsTableConfig.groupedColumns,
    localCurrencyColumns: underwritingTermsTableConfig.visibleLocalCurrFields,
  },
  [DatalabScreens.IPO_BACKLOG]: {
    ...defaultTableConfig,
    title: 'IPO Backlog',
    columnsConfig: ipoBacklogTableConfig.columns,
    orderBy: 'offering_public_filing_date',
    orderByType: 'desc',
    builtVisibleColumns: ipoBacklogTableConfig.visibleFields,
    staticColumns: ipoBacklogTableConfig.staticFields,
    paginationType: TableDataManagementType.SERVER,
  },
  [DatalabScreens.LOCK_UP_EXPIRATION]: {
    ...defaultTableConfig,
    title: 'Lock-up Report',
    columnsConfig: lockupReportTableConfig.columns,
    builtVisibleColumns: lockupReportTableConfig.visibleFields,
    staticColumns: lockupReportTableConfig.staticFields,
    categorizedColumns: lockupReportTableConfig.categorizedColumns,
    groupedColumns: lockupReportTableConfig.groupedColumns,
    localCurrencyColumns: lockupReportTableConfig.visibleLocalCurrFields,
    orderBy: 'lock_up_expiration',
    orderByType: 'desc',
  },
  [DatalabScreens.LEAGUE_TABLE]: {
    ...defaultTableConfig,
    title: 'League Table',
    columnsConfig: leagueTablesTableConfig.columns,
    staticColumns: leagueTablesTableConfig.staticFields,
    orderBy: 'fees',
    orderByType: 'desc',
    showRanking: true,
    paginationType: TableDataManagementType.CLIENT,
  },
  [DatalabScreens.AFTERMARKET_MATRIX]: {
    ...defaultTableConfig,
    title: 'Aftermarket Matrix',
    columnsConfig: aftermarketMatrixTableConfig.columns,
    builtVisibleColumns: aftermarketMatrixTableConfig.visibleFields,
    staticColumns: aftermarketMatrixTableConfig.staticFields,
    orderBy: 'offerings',
    orderByType: 'desc',
    quickFilterOptions: heatMapPerformanceButtons,
    quickFilterType: 'avg_method',
    paginationType: TableDataManagementType.CLIENT,
  },
  [DatalabScreens.SPONSOR_LEAGUE_TABLE]: {
    ...defaultTableConfig,
    title: 'Sponsor League Table',
    columnsConfig: sponsorLeagueTableTableConfig.columns,
    builtVisibleColumns: sponsorLeagueTableTableConfig.visibleFields,
    staticColumns: sponsorLeagueTableTableConfig.staticFields,
    orderBy: 'offerings',
    orderByType: 'desc',
    quickFilterOptions: heatMapPerformanceButtons,
    quickFilterType: 'avg_method',
    paginationType: TableDataManagementType.CLIENT,
  },
  [DatalabScreens.UNDERWRITER_OFFERINGS]: {
    ...defaultTableConfig,
    title: 'Underwriter Offerings',
    columnsConfig: underwriterOfferingsTableConfig.columns,
    builtVisibleColumns: underwriterOfferingsTableConfig.visibleFields,
    staticColumns: underwriterOfferingsTableConfig.staticFields,
    categorizedColumns: underwriterOfferingsTableConfig.categorizedColumns,
    orderBy: 'offerings_pricing_date',
    orderByType: 'desc',
    paginationType: TableDataManagementType.CLIENT,
  },
  [DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS]: {
    ...defaultTableConfig,
    title: 'Underwriter Offerings',
    columnsConfig: aftermarketUnderwriterOfferingsTableConfig.columns,
    builtVisibleColumns: aftermarketUnderwriterOfferingsTableConfig.visibleFields,
    staticColumns: aftermarketUnderwriterOfferingsTableConfig.staticFields,
    categorizedColumns: aftermarketUnderwriterOfferingsTableConfig.categorizedColumns,
    orderBy: 'offerings_pricing_date',
    orderByType: 'desc',
    paginationType: TableDataManagementType.CLIENT,
  },
  [DatalabScreens.MARKET_PULSE]: {
    ...defaultTableConfig,
    title: 'U.S. ECM',
    columnsConfig: marketPulseTableConfig.columns,
    orderBy: 'offerings_pricing_date',
    orderByType: 'desc',
    builtVisibleColumns: marketPulseTableConfig.visibleFields,
    staticColumns: marketPulseTableConfig.staticFields,
    categorizedColumns: marketPulseTableConfig.categorizedColumns,
    groupedColumns: marketPulseTableConfig.groupedColumns,
    localCurrencyColumns: marketPulseTableConfig.visibleLocalCurrFields,
  },
  [DatalabScreens.IOI_PARTICIPATION]: {
    ...defaultTableConfig,
    title: 'Participation',
    titleTooltip:
      'Return calculations in this report currently do not support international offerings.',
    columnsConfig: ioiParticipationTableConfig.columns,
    builtVisibleColumns: ioiParticipationTableConfig.visibleFields,
    staticColumns: ioiParticipationTableConfig.staticFields,
    categorizedColumns: ioiParticipationTableConfig.categorizedColumns,
    groupedColumns: ioiParticipationTableConfig.groupedColumns,
    localCurrencyColumns: ioiParticipationTableConfig.visibleLocalCurrFields,
    orderBy: 'offerings_pricing_date',
    orderByType: 'desc',
  },
  [DatalabScreens.IOI_LEAGUE_TABLE]: {
    ...defaultTableConfig,
    title: 'Rankings',
    columnsConfig: ioiLeagueTableTableConfig.columns,
    builtVisibleColumns: ioiLeagueTableTableConfig.visibleFields,
    staticColumns: ioiLeagueTableTableConfig.staticFields,
    orderBy: 'offerings',
    orderByType: 'desc',
    showRanking: true,
    paginationType: TableDataManagementType.CLIENT,
  },
  [DatalabScreens.PL_LEAGUE_TABLE]: {
    ...defaultTableConfig,
    title: 'Broker P&L',
    columnsConfig: brokerPlTableConfig.columns,
    builtVisibleColumns: brokerPlTableConfig.visibleFields,
    staticColumns: brokerPlTableConfig.staticFields,
    orderBy: 'offerings',
    orderByType: 'desc',
    showRanking: true,
    paginationType: TableDataManagementType.CLIENT,
    quickFilterOptions: plCreditButtons,
    quickFilterType: 'pl_credit',
  },
  [DatalabScreens.BROKER_PL_OFFERINGS]: {
    ...defaultTableConfig,
    title: 'Broker P&L Offerings',
    columnsConfig: plBrokerOfferingsTableConfig.columns,
    builtVisibleColumns: plBrokerOfferingsTableConfig.visibleFields,
    staticColumns: plBrokerOfferingsTableConfig.staticFields,
    categorizedColumns: plBrokerOfferingsTableConfig.categorizedColumns,
    orderBy: 'offerings_pricing_date',
    orderByType: 'desc',
    paginationType: TableDataManagementType.CLIENT,
    quickFilterOptions: plCreditButtons,
    quickFilterType: 'pl_credit',
  },
  [DatalabScreens.FUND_IOI_LEAGUE_TABLE]: {
    ...defaultTableConfig,
    title: 'Fund Rankings',
    titleTooltip:
      'Report includes offerings with both firm-level and fund-level allocations, which are required for calculations.',
    columnsConfig: fundIoiLeagueTableTableConfig.columns,
    builtVisibleColumns: fundIoiLeagueTableTableConfig.visibleFields,
    staticColumns: fundIoiLeagueTableTableConfig.staticFields,
    orderBy: 'offerings',
    orderByType: 'desc',
    showRanking: true,
    paginationType: TableDataManagementType.CLIENT,
  },
  [DatalabScreens.CAPITAL_RAISED_MATRIX]: {
    ...defaultTableConfig,
    title: 'U.S. ECM Matrix',
    columnsConfig: capitalRaisedMatrixTableConfig.columns,
    builtVisibleColumns: capitalRaisedMatrixTableConfig.visibleFields,
    staticColumns: capitalRaisedMatrixTableConfig.staticFields,
    orderBy: 'ord',
    orderByType: 'asc',
  },
  [DatalabScreens.FUND_IOI_PARTICIPATION]: {
    ...defaultTableConfig,
    title: 'Fund Participation',
    titleTooltip:
      'Return calculations in this report currently do not support international offerings.',
    columnsConfig: fundIoiParticipationTableConfig.columns,
    builtVisibleColumns: fundIoiParticipationTableConfig.visibleFields,
    staticColumns: fundIoiParticipationTableConfig.staticFields,
    categorizedColumns: fundIoiParticipationTableConfig.categorizedColumns,
    orderBy: 'offerings_pricing_date',
    orderByType: 'desc',
    paginationType: TableDataManagementType.CLIENT,
  },
};

const tableConfigsIntlOverride: { [key: string]: Partial<TableConfigType> } = {
  [DatalabScreens.MARKET_PULSE]: {
    title: 'Global ECM',
  },
  [DatalabScreens.CAPITAL_RAISED_MATRIX]: {
    title: 'Global ECM Matrix',
  },
};

/**
 * Gets the default configuration for datalab table screens.
 * TODO rename to getDefaultTableConfig
 */
export const getTableConfig: (screen: string) => TableConfigType = memoize(
  (screen): TableConfigType => {
    const config = tableConfigs[screen] || defaultTableConfig;
    const showInternational = getInternationalPermission(screen);
    const columnSelection = cloneDeep(config.categorizedColumns);
    if (config.categorizedColumns && columnSelection) {
      for (const [category, columns] of Object.entries(config.categorizedColumns)) {
        columnSelection[category] = showInternational
          ? columns.map(overrideIntlProps)
          : columns.filter(localCurrencyColumnsFilter);
      }
    }
    const builtVisibleColumns = config.builtVisibleColumns.length
      ? config.builtVisibleColumns
      : getVisibleColumns(config.columnsConfig);

    const tableConfigOverride =
      showInternational && tableConfigsIntlOverride[screen] ? tableConfigsIntlOverride[screen] : [];
    return {
      ...config,
      ...tableConfigOverride,
      columnsConfig: showInternational
        ? config.columnsConfig.map(overrideIntlProps)
        : config.columnsConfig,
      summaryColumns: getSummaryColumns(config.columnsConfig),
      builtVisibleColumns:
        showInternational && config.localCurrencyColumns
          ? [...config.localCurrencyColumns, ...builtVisibleColumns]
          : builtVisibleColumns,
      categorizedColumns: columnSelection,
      groupedColumns: showInternational ? config.groupedColumns : undefined,
    };
  }
);

export const overrideIntlProps = (column: DatalabTableColumn): DatalabTableColumn =>
  Object.keys(intlOverridingProps).includes(column.field)
    ? {
        ...column,
        ...intlOverridingProps[column.field],
      }
    : column;

export const localCurrencyColumnsFilter = (column: DatalabTableColumn): boolean => {
  return !localCurrencyColumns.some(localCol => localCol.field === column.field);
};
