import { Collapse, Icon, Popover, TextInput } from '@cmg/common';
import { menuItemSelector, sidebarSelector, submenuItemSelector } from '@cmg/e2e-selectors';
import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import routeFactory from '../../../../common/util/routeFactory';
import PopoverContentTable from '../../../shared/components/PopoverContentTable';
import { getPath, isParentActive } from './sidebar.model';
import {
  SidebarDisclaimer,
  SidebarMenu,
  SItemIconWrapper,
  SItemLabel,
  SMenuFilter,
  SPullTabButton,
  StyledIcon,
  StyledLinkButton,
  StyledLinkLogo,
  StyledLinkLogoCollapsed,
  SWrapper,
} from './Sidebar.styles';
import { SidebarConfigArgs, SidebarConfigItem } from './SidebarConfig';

export type Props = {
  collapsed: boolean;
  configData: SidebarConfigArgs | null;
  filter: string;
  items: SidebarConfigItem[];
  canViewCalendar: boolean;
  canViewReports: boolean;
  manualActivatedKeys: any[];
  onSubmenuClicked: Function;
  onFilterChange: Function;
  onClearFilter: () => void;
  onCollapseChange: (event: React.MouseEvent) => void;
};

export default class Sidebar extends React.Component<Props> {
  getIsParentExpanded = item => {
    const { manualActivatedKeys, filter } = this.props;
    return manualActivatedKeys.includes(item.key) || !!filter;
  };

  render() {
    const {
      onCollapseChange,
      collapsed,
      items,
      filter,
      onSubmenuClicked,
      onFilterChange,
      onClearFilter,
      configData,
      canViewReports,
    } = this.props;

    return (
      <SWrapper
        collapsed={collapsed}
        data-test-id={sidebarSelector.testId}
        role="navigation"
        aria-label="Navigation Menu"
      >
        {collapsed ? (
          <StyledLinkLogoCollapsed to={routeFactory.calendar.getUrlPath()} />
        ) : (
          <StyledLinkLogo to={routeFactory.calendar.getUrlPath()} />
        )}
        <SPullTabButton onClick={onCollapseChange} aria-label={collapsed ? 'Expand' : 'Collapse'}>
          <Icon variant="solid" name={collapsed ? 'chevron-right' : 'chevron-left'} />
        </SPullTabButton>

        {!collapsed && canViewReports && (
          <SMenuFilter>
            <TextInput
              value={filter}
              onChange={value => onFilterChange(value ? value.toLowerCase() : '')}
              placeholder="Filter"
              aria-label="Filter"
              variant="filter"
              prefix={<StyledIcon name="filter" />}
              suffix={
                <span
                  onClick={() => onClearFilter()}
                  // to pass sonarcloud
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onClearFilter();
                    }
                  }}
                  role="button"
                  aria-label="Clear filter"
                >
                  <StyledIcon name="times-circle" variant="solid" />
                </span>
              }
            />
          </SMenuFilter>
        )}

        <SidebarMenu collapsed={collapsed}>
          {items.map(item => {
            // Root nav items
            if (!item.children) {
              const path = getPath(item, configData);

              return (
                <li key={path}>
                  <NavLink
                    to={path}
                    activeClassName="active"
                    data-test-id={menuItemSelector(item.key).testId}
                  >
                    {item.icon && (
                      <SItemIconWrapper>
                        <Icon {...item.icon} size={collapsed ? 'lg' : undefined} />
                      </SItemIconWrapper>
                    )}
                    {!collapsed && (
                      <SItemLabel>
                        {item.label} {item.tag}
                      </SItemLabel>
                    )}
                  </NavLink>
                </li>
              );
            }

            // Nested nav items
            const isParentExpanded = this.getIsParentExpanded(item);

            return (
              <li key={item.key}>
                <StyledLinkButton
                  className={classNames({ active: isParentActive(item, configData) })}
                  testId={menuItemSelector(item.key).testId}
                  onClick={() => onSubmenuClicked(item.key)}
                >
                  {item.icon && (
                    <React.Fragment>
                      <SItemIconWrapper>
                        <Icon {...item.icon} size={collapsed ? 'lg' : undefined} />
                      </SItemIconWrapper>
                      {!collapsed && (
                        <SItemLabel>
                          {item.label} {item.tag}
                        </SItemLabel>
                      )}
                    </React.Fragment>
                  )}
                  {!collapsed && (
                    <span className="submenu-indicator">
                      <Icon
                        name={isParentExpanded ? 'minus-circle' : 'plus-circle'}
                        variant="solid"
                      />
                    </span>
                  )}
                </StyledLinkButton>
                <Collapse isExpanded={isParentExpanded}>
                  <ul className="submenu">
                    {item.children.map(child => {
                      const path = getPath(child, configData, item.paramsMatch);
                      /**
                       * NavLink default isActive behaviour is based on checking if pathname starts with its to
                       * property value.
                       *
                       * We don't use parent paths for Datalab screens and rather navigate to /chart directly.
                       *
                       * This is why we have to use custom isActive logic in order to make NavLink active
                       * even when changing route to /table within the same screen.
                       */
                      const navLinkProps = child.isActive
                        ? {
                            isActive: (params, location) => {
                              return Boolean(child.isActive && child.isActive(location.pathname));
                            },
                          }
                        : {};

                      return (
                        <li key={child.key}>
                          <NavLink
                            {...navLinkProps}
                            to={path}
                            activeClassName="active"
                            data-test-id={submenuItemSelector(item.key, child.key).testId}
                          >
                            {child.icon && (
                              <SItemIconWrapper>
                                <Icon {...child.icon} />
                              </SItemIconWrapper>
                            )}
                            <SItemLabel>
                              {collapsed ? (
                                child.short
                              ) : (
                                <React.Fragment>
                                  {child.label} {child.tag}
                                </React.Fragment>
                              )}
                            </SItemLabel>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </Collapse>
              </li>
            );
          })}
        </SidebarMenu>
        <Popover
          trigger="hover"
          variant="TOOLTIP"
          placement="left"
          hideArrow
          content={
            <PopoverContentTable alignLastColumn="right">
              <tbody>
                <p>
                  Capital Markets Gateway (“CMG”) offers data analytics (“DataLab”) through a
                  password protected website solely to you. DataLab content is provided for
                  informational purposes only. CMG does not endorse or recommend any trading or
                  investing ideas, or any investment discussed on DataLab. DataLab is not intended
                  to provide tax, legal, or investment advice.
                </p>
                <p>
                  DataLab content is based on public information, which may include information
                  sourced by CMG from issuers, underwriters, or other third-parties (“Third-Party
                  Sources”). Third-Party Sources do not warrant that the provision of data,
                  software, or services will be uninterrupted, error free, timely, complete, or
                  accurate, nor do any of them make any warranties as to the results to be obtained
                  from use of the same. You acknowledge that third-party data, software, or services
                  do not constitute a recommendation, offer or solicitation of any kind and is
                  provided for informational purposes only. You expressly agree that your use of
                  third-party data, software, or services is at your own risk. Accordingly, the
                  Third-Party Sources will not in any way be liable to you or any other entity for
                  any inaccuracies, errors, omissions, delays, damages, claims, liabilities, or
                  losses, regardless of cause, in or arising from the use of the third-party sourced
                  data contained in the Information.
                </p>
              </tbody>
            </PopoverContentTable>
          }
        >
          <SidebarDisclaimer collapsed={collapsed}>
            {!collapsed && <SItemLabel>Data Disclaimer</SItemLabel>}
            <SItemIconWrapper>
              <StyledIcon name="info-circle" size={collapsed ? 'lg' : undefined} />
            </SItemIconWrapper>
          </SidebarDisclaimer>
        </Popover>
      </SWrapper>
    );
  }
}
