import React from 'react';

import { OfferingType } from '../../../../../../graphql/__generated__';
import {
  getFormattedCurrency,
  getFormattedCurrencyInMillion,
  getFormattedPercentageValue,
  isUS,
} from '../../../../../shared/model/utils';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import {
  CardRowItem,
  emptyValue,
  OfferingProfileFieldName,
  UnderwriterTermsSectionData,
} from '../types';

const getUnderwriterCurrencyValue = (
  value: number | null | undefined,
  pricingCurrencyCode?: string | undefined,
  type?: OfferingType | undefined
) => {
  if (value === null || value === undefined) {
    return '-';
  }
  return `${getFormattedCurrency(pricingCurrencyCode, value)}/${
    type === OfferingType.Convertible ? 'bond' : 'share'
  }`;
};

export const getUnderwriterTermsData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { attributes, pricingCurrency } = offeringProfile.offeringById ?? {};
  const indentation = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  const {
    netPrice,
    estimatedFeeUsd,
    pctGrossSpread,
    grossSpreadTotal,
    sellingConcessionUsd,
    managementFeeUsd,
    underwritingFeeUsd,
  } = attributes ?? {};
  const pricingCurrencyCode = pricingCurrency as string;

  return [
    {
      name: OfferingProfileFieldName.NetPrice,
      value: '',
      value2: getFormattedCurrency(pricingCurrencyCode, netPrice),
    },
    {
      name: OfferingProfileFieldName.EstimatedFee,
      value: '',
      value2: isUS(offeringProfile)
        ? getFormattedCurrencyInMillion(pricingCurrencyCode, estimatedFeeUsd)
        : emptyValue,
    },
    {
      name: OfferingProfileFieldName.GrossSpread,
      value: getFormattedPercentageValue(pctGrossSpread) ?? emptyValue,
      value2: getUnderwriterCurrencyValue(
        grossSpreadTotal,
        pricingCurrencyCode,
        offeringProfile.offeringById?.type
      ),
    },
    ...(!isUS(offeringProfile)
      ? []
      : [
          {
            name: `${indentation}${OfferingProfileFieldName.SellingConcessionUsd}`,
            value: '60.00%',
            value2: getUnderwriterCurrencyValue(
              sellingConcessionUsd,
              pricingCurrencyCode,
              offeringProfile.offeringById?.type
            ),
          },
          {
            name: `${indentation}${OfferingProfileFieldName.ManagementFee}`,
            value: '20.00%',
            value2: getUnderwriterCurrencyValue(
              managementFeeUsd,
              pricingCurrencyCode,
              offeringProfile.offeringById?.type
            ),
          },
          {
            name: `${indentation}${OfferingProfileFieldName.UnderwritingFee}`,
            value: '20.00%',
            value2: getUnderwriterCurrencyValue(
              underwritingFeeUsd,
              pricingCurrencyCode,
              offeringProfile.offeringById?.type
            ),
          },
        ]),
  ];
};
export const getUnderwriterNamesData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { attributes } = offeringProfile.offeringById ?? {};
  const { leftLeadName, stabilizationAgentName, settlementAgentName } = attributes ?? {};
  const rows = [
    {
      name: OfferingProfileFieldName.LeftLead,
      value: leftLeadName ?? emptyValue,
      isHidden: !isUS(offeringProfile),
    },
    {
      name: OfferingProfileFieldName.SettlementAgent,
      value: settlementAgentName ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.StabilizationAgent,
      value: stabilizationAgentName ?? emptyValue,
    },
  ];
  return rows.filter(row => !row.isHidden);
};

export const getUnderwriterPctData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] => {
  const { attributes } = offeringProfile.offeringById ?? {};
  const {
    totalPctToActiveManagers,
    totalPctToBookrunners,
    totalPctToNonBookrunners,
    totalPctToLeftLead,
  } = attributes ?? {};
  const rows = [
    {
      name: OfferingProfileFieldName.PctToActiveBookrunners,
      value: getFormattedPercentageValue(totalPctToActiveManagers) ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.PctToBookrunners,
      value: getFormattedPercentageValue(totalPctToBookrunners) ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.PctToNonBookrunners,
      value: getFormattedPercentageValue(totalPctToNonBookrunners) ?? emptyValue,
    },
    {
      name: OfferingProfileFieldName.PctToLeftLead,
      value: getFormattedPercentageValue(totalPctToLeftLead) ?? emptyValue,
      isHidden: !isUS(offeringProfile),
    },
  ];
  return rows.filter(row => !row.isHidden);
};

export const useGetUnderwriterTermsSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): UnderwriterTermsSectionData =>
  React.useMemo(() => {
    const underwriterTerms = getUnderwriterTermsData(offeringProfile);
    const underwriterNames = getUnderwriterNamesData(offeringProfile);
    const underwriterPct = getUnderwriterPctData(offeringProfile);
    return {
      underwriterTerms,
      underwriterNames,
      underwriterPct,
    };
  }, [offeringProfile]);
